import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BotaoDialogo } from '../Servicos/Utilidades';
import { Typography } from '@material-ui/core';

export default function AlertDialog({aberta, fecha, titulo, texto, botoes}: {aberta:boolean, fecha: (botaoretorno: string) => void, titulo: string, texto: string[], botoes: BotaoDialogo[]}) {

  const handleClose = ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, botaoretorno: string) => {
    fecha(botaoretorno);
  });

  return (
    <div>
      <Dialog
        open={aberta}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
        <DialogContent>
          <DialogContentText component='div' id="alert-dialog-description">
            {texto.map((txt,index) => {
              return (
                <Typography key={index+1}>
                  {txt}
                </Typography>
              )
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            {botoes.map((botao, index) => 
            {
              return (
              <Button key={index+1} onClick={event => handleClose(event, botao.retorno)} color="primary">
                  {botao.descricao}
              </Button> )
            })}
        </DialogActions>
      </Dialog>
    </div>
  );
}