import { RouteComponentProps, withRouter } from 'react-router';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Roteador } from '../Servicos/Roteador';
import { Resposta, TrataResposta, AchaFuncoes, TabPanel, BotaoDialogo, a11yProps, formataDecimal, Iheaders, RetornaClientes, RetornaStringData, RetornaDataString, ObjPdfImg, achaDescricao, DataSql, RetornaFornecedores, AlteraPadroesPagamento, formatarCampo, nulltoNumber, RetornaReceitasDespesas } from '../Servicos/Utilidades';
import ApiService from '../Servicos/ApiService';
import { Pagamentos } from '../Modelos/Pagamentos';
import BarraLateral from '../Componentes/Barralateral';
import Toast from '../Componentes/Toast';
import Progresso from '../Componentes/Progresso';
import { makeStyles, Theme, createStyles, Grid, Tabs, Tab, Button, Card, CardContent, Typography } from '@material-ui/core';
import { Detalhes_Pagamento } from '../Modelos/Detalhes_Pagamento';
import { Ocorrencias_Pagamento } from '../Modelos/Ocorrencias_Pagamento';
import { Situacoes_Pagamentos } from '../Modelos/Situacoes_Pagamentos';
import { Formas_Pagamento } from '../Modelos/Formas_Pagamento';
import { Tipos_Ocorrencia_Pagamento } from '../Modelos/Tipos_Ocorrencia_Pagamento';
import { Contas } from '../Modelos/Contas';
import { Receitas } from '../Modelos/Receitas';
import { Despesas } from '../Modelos/Despesas';
import { Redes } from '../Modelos/Redes';
import AlertDialog from '../Componentes/Dialogo';
import SelecaoAuto from '../Componentes/SelecaoAuto';
import React, { ChangeEvent } from 'react';
import CampoTexto from '../Componentes/CampoTexto';
import CancelIcon from '@material-ui/icons/Cancel';
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { Component } from 'react';
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@material-ui/lab/Autocomplete';
import CampoData from '../Componentes/CampoData';
import Selecao from '../Componentes/Selecao';
import CampoDecimal from '../Componentes/CampoDecimal';
import TabelaBase from '../Componentes/TabelaBase';
import ExibePdfImg from '../Componentes/ExibePdfImg';
import { StyledAppBar } from '../Componentes/Customizados';

const srv = new ApiService();

const useStyles = makeStyles((theme: Theme) => createStyles({
    griddados: {
      width: "100%",
      margin: "0 0 0 0"
    },
    gridtab: {
      width: "50%",
      margin: "0 0 0 0"
    },
    divgrid: {
        width: '90%',
        margin: '5% 7% 0'
    },
    divtabs: {
        width: '90%',
        margin: '0% 7% 0'
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    botao: {
        margin: '5% 5%',
        width: '90%'
      },
    tab: {
        minWidth: "50%",
        width: "50%"
    },
    gridcampos: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridfoto: {
        width: "20%",
        margin: "0 0 0 0"
    },
}));

let sted = 
{
    detalhes: false,
    ocorrencias: false
};
  
function InicializaEdicao() 
{
    sted = {
        detalhes: false,
        ocorrencias: false
    };
}

type PrincipalProps=
{
    dadosobt: RetornoAcesso
    dadospag: Pagamentos
    fornecedores: {id_forn: number, nome_forn: string}[]
    redes: Redes[]
    clientes: any[]
    formas: Formas_Pagamento[]
    contas: Contas[]
    situacoes: Situacoes_Pagamentos[]
    receitas: Receitas[]
    despesas: Despesas[]
    ocorrencias: Tipos_Ocorrencia_Pagamento[]
    mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void
    roteador: (recurso: number, id_edicao: number) => void
}

let titulodialogo: string;
let botoesdialogo: BotaoDialogo[] = [];
let textodialogo: string[] = [];

const PrincipalEdicaoPagamentos = ({dadosobt, dadospag, fornecedores, redes, clientes, formas, contas, situacoes, receitas, despesas, ocorrencias, mensagem, roteador }: PrincipalProps) =>
{
    const classes = useStyles();
    const [tabValue, settabValue] = React.useState(0);
    const [pagamento,setPagamento] = React.useState(dadospag);
    const [detdig, setdetDig] = React.useState(new Detalhes_Pagamento());
    const [inddet, setindDet] = React.useState(0);
    const [ocordig, setocorDig] = React.useState(new Ocorrencias_Pagamento());
    const [indocor, setindOcor] = React.useState(0);
    const [dialogo, setDialog] = React.useState(false);
    const [estado, setEstado] = React.useState('A');

    const acessos = {
        altbas: AchaFuncoes(dadosobt.objac, 42),
        altavc: AchaFuncoes(dadosobt.objac, 43),
        altbxa: AchaFuncoes(dadosobt.objac, 14),
        incdet: AchaFuncoes(dadosobt.objac, 44),
        altdet: AchaFuncoes(dadosobt.objac, 45),
        excdet: AchaFuncoes(dadosobt.objac, 46),
        incocor: AchaFuncoes(dadosobt.objac, 47),
        altocor: AchaFuncoes(dadosobt.objac, 48),
        excocor: AchaFuncoes(dadosobt.objac, 49),
    }

    const fechaDialogo = (retorno: string) => 
    {
        setDialog(false);
        if (retorno==='A')
        {
            salvaPagamento();
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        settabValue(newValue);
    };

    const onChangePagamentoAutoComplete = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined, campo?: string) =>
    {
        if(campo)
        {
            if(campo==='id_forn')
            {
                if(value && value[campo])
                {
                    setPagamento({...pagamento, [campo]: value[campo]});
                }
                else
                {
                    setPagamento({...pagamento, [campo]: 0});
                }
            }
            else if(campo==='id_cli')
            {
                if(value && value[campo])
                {
                    setdetDig({...detdig, [campo]: value[campo]});
                }
                else
                {
                    setdetDig({...detdig, [campo]: 0});
                }
            }
        }
    }

    const onChangePagamentoSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode, campo?: string | undefined) =>
    {
        if(campo && event.target.value)
        {
            let valor:number = 0;
            valor = typeof event.target.value === 'string' ? parseInt(event.target.value) : typeof event.target.value === 'number' ? event.target.value : 0; 
            if ('id_stpg, id_cta, id_frpg'.indexOf(campo)!==-1)
            {
                if ('id_stpg'.indexOf(campo)!==-1 && achaDescricao(valor,situacoes,'id_stpg','comportamento_strc')!=='B')
                {
                    setPagamento({...pagamento, id_stpg: valor, pagto_pgto: undefined});
                }
                else if ('id_frpg'.indexOf(campo)!==-1 && achaDescricao(valor,formas,'id_frpg','comportamento_frpg')!=='A')
                {
                    setPagamento({...pagamento, id_frpg: valor, id_cta: 0});
                }
                else
                {
                    setPagamento({...pagamento, [campo]: valor});
                }
            }
            else
            {
                if ('id_rcta, id_dsps, id_rede, id_cli'.indexOf(campo)!==-1)
                {
                   if ('id_rcta'.indexOf(campo)!==-1 && valor>0)
                    {
                        setdetDig({...detdig, id_rcta: valor, id_dsps: 0});
                    }
                    else if ('id_dsps'.indexOf(campo)!==-1 && valor>0)
                    {
                        setdetDig({...detdig, id_dsps: valor, id_rcta: 0})
                    }
                    else
                    {
                        setdetDig({...detdig, [campo]: valor});
                    }
                    sted.detalhes=true;
                }
                else
                {
                    if ('id_tpop'.indexOf(campo)!==-1)
                    {
                        setocorDig({...ocordig, [campo]: valor})
                        sted.ocorrencias=true;
                    }
                }
            }
        }
    }
  
    const onChangePagamentoDataInput = (date: any, valor: string | null | undefined, campo: string | undefined) =>
    {
      if (date && campo)
      {
        if ('vencto_pgto, pagto_pgto, datadesc_pgto'.indexOf(campo)!==-1)
        {
            setPagamento({...pagamento, [campo]: date });
        }
        else
        {
            if ('data_ocpg'.indexOf(campo)!==-1)
            {
                setocorDig({...ocordig, [campo]: date});
                sted.ocorrencias=true;
            }
        }
      }
    }
  
    const onChangePagamentoInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date, campo?: string | undefined) =>
    {
        if (campo)
        {
            if ('obs_ocpg'.indexOf(campo)!==-1)
            {
                setocorDig({...ocordig, [campo]: propratu});
                sted.ocorrencias=true;
            }
        }
    }
  
    const onChangePagamentoDecimalInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string, campo?: string | undefined) =>
    {
        if (campo)
        {
            if ('valor_pgto, desconto_pgto , multa_pgto, juros_pgto'.indexOf(campo)!==-1)
            {
                setPagamento({...pagamento, [campo]: parseFloat(formataDecimal(propratu))});
            }
            else
            {
                if ('valor_dtpg'.indexOf(campo)!==-1)
                {
                    setdetDig({...detdig, [campo]: parseFloat(formataDecimal(propratu))});
                    sted.detalhes=true;
                }
            }
        }
    }
  
    const limpaData = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, campo: string | undefined) =>
    {
        if (campo)
        {
            setPagamento({...pagamento, [campo]: undefined});
        }
    }
  
    const adicionaDetalhe = () =>
    {
        if (detdig.valor_dtpg>0 && (detdig.id_rcta!==0 || detdig.id_dsps!==0))
        {
            let detnovos: Detalhes_Pagamento[]=[];
            detnovos=detnovos.concat(pagamento.detalhes);
            if (inddet===0)
            {
                detnovos.push(detdig);
                detnovos[detnovos.length-1].alterado='A';
                detnovos[detnovos.length-1].id_oper=dadosobt.objac.id_oper;
                detnovos[detnovos.length-1].nome_oper=dadosobt.nome;
                detnovos[detnovos.length-1].id_pgto=pagamento.id_pgto;
            }
            else
            {
                detnovos[inddet-1]=detdig;
                detnovos[inddet-1].alterado='A';
                detnovos[inddet-1].id_oper=dadosobt.objac.id_oper;
                detnovos[inddet-1].nome_oper=dadosobt.nome;
            }
            setPagamento({...pagamento, detalhes: detnovos});
            setdetDig(new Detalhes_Pagamento());
            setindDet(0);
            sted.detalhes=false;
        }
        else
        {
            mensagem('Existem dados incorretos. Verifique, por gentileza','error',6000);
        }
    }

    const editaDetalhe = (val1: number, val2: number) =>
    {
        if(val1===1)
        {
            if(val2<=pagamento.detalhes.length && val2>0)
            {
                setdetDig(pagamento.detalhes[val2-1]);
                setindDet(val2);
            }
            else
            {
                setdetDig(new Detalhes_Pagamento());
                setindDet(0);
            }
        }
        else
        {
            if(val1===2)
            {
                if(val2<=pagamento.detalhes.length && val2>0)
                {
                    let detnovos: Detalhes_Pagamento[]=[]
                    detnovos=detnovos.concat(pagamento.detalhes);
                    detnovos[val2-1].alterado='B';
                    detnovos[val2-1].valor_dtpg=-1;
                    setPagamento({...pagamento, detalhes: detnovos});
                    if (val2===inddet)
                    {
                        setdetDig(new Detalhes_Pagamento());
                        setindDet(0);
                    }
                }
            }
        }
    }

    const adicionaOcorrencia = () =>
    {
        if (ocordig.data_ocpg && ocordig.id_tpop && ocordig.id_tpop>0)
        {
            let ocornovos: Ocorrencias_Pagamento[]=[];
            ocornovos=ocornovos.concat(pagamento.ocorrencias);
            if (indocor===0)
            {
                ocornovos.push(ocordig);
                ocornovos[ocornovos.length-1].data_ocpg=new Date();
                ocornovos[ocornovos.length-1].alterado='A';
                ocornovos[ocornovos.length-1].id_oper=dadosobt.objac.id_oper;
                ocornovos[ocornovos.length-1].nome_oper=dadosobt.nome;
                ocornovos[ocornovos.length-1].id_pgto=pagamento.id_pgto;
            }
            else
            {
                ocornovos[indocor-1]=ocordig;
                ocornovos[indocor-1].alterado='A';
                ocornovos[indocor-1].id_oper=dadosobt.objac.id_oper;
                ocornovos[indocor-1].nome_oper=dadosobt.nome;
            }
            setPagamento({...pagamento, ocorrencias: ocornovos});
            setocorDig(new Ocorrencias_Pagamento());
            setindOcor(0);
            sted.ocorrencias=false;
        }
        else
        {
            mensagem('Existem dados incorretos. Verifique, por gentileza','error',6000);
        }
    }

    const editaOcorrencia = (val1: number, val2: number) =>
    {
        if(val1===1)
        {
            if(val2<=pagamento.ocorrencias.length && val2>0)
            {
                setocorDig(pagamento.ocorrencias[val2-1]);
                setindOcor(val2);
            }
            else
            {
                setocorDig(new Ocorrencias_Pagamento());
                setindOcor(0);
            }
        }
        else
        {
            if(val1===2)
            {
                if(val2<=pagamento.ocorrencias.length && val2>0)
                {
                    let ocornovos: Ocorrencias_Pagamento[]=[]
                    ocornovos=ocornovos.concat(pagamento.ocorrencias);
                    ocornovos[val2-1].alterado='B';
                    ocornovos[val2-1].id_tpop=0;
                    setPagamento({...pagamento, ocorrencias: ocornovos});
                    if (val2===indocor)
                    {
                        setocorDig(new Ocorrencias_Pagamento());
                        setindOcor(0);
                    }
                }
            }
        }
    }

    const atualizaPdfImgPagamento = (objrec: ObjPdfImg) =>
    {
      setocorDig({...ocordig, gravadoc: true, tipodoc_ocpg: objrec.tipo, documento_ocpg: objrec.conteudo } )
      sted.ocorrencias=true;
    }

    const hddet: Iheaders[] = [
        {titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, 
        { titulo: 'Valor', alinhamento: 'right', coluna: 'valor_dtpg', mascara: 'decimal' }, 
        { titulo: 'Rec./Desp.', alinhamento: 'left', coluna: 'desc_rcds', mascara: '' }, 
        { titulo: 'Rede', alinhamento: 'center', coluna: 'nome_rede', mascara: '' }, 
        { titulo: 'Cliente', alinhamento: 'center', coluna: 'nome_cli', mascara: '' }, 
        { titulo: 'Operador', alinhamento: 'left', coluna: 'nome_oper', mascara: '' }]

    const RetornaDetalhes = () =>
    {
        let detret: {id: number; valor_dtpg: number; desc_rcds: string, nome_rede: string, nome_cli: string, nome_oper: string}[] = [];
        pagamento.detalhes.forEach((dtpg, index) => 
        {
            if (dtpg.alterado!=='B')
            {
                detret.push({
                    id: index+1, 
                    valor_dtpg: dtpg.valor_dtpg, 
                    desc_rcds: ((dtpg.id_rcta && dtpg.id_rcta>0) ? achaDescricao(dtpg.id_rcta, receitas, 'id_rcta', 'desc_rcta') : achaDescricao(dtpg.id_dsps, despesas, 'id_dsps', 'desc_dsps')),
                    nome_rede: ((dtpg.id_rede && dtpg.id_rede>0) ? achaDescricao(dtpg.id_rede, redes, 'id_rede', 'nome_rede') : ''),
                    nome_cli: ((dtpg.id_cli && dtpg.id_cli>0) ? achaDescricao(dtpg.id_cli, clientes, 'id_cli', 'nome_cli') : ''),
                    nome_oper: dtpg.nome_oper
                })
            }
        })
        return detret;
    }

    const hdoco: Iheaders[] = [
        { titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, 
        { titulo: 'Data/Hora', alinhamento: 'center', coluna: 'data_ocpg', mascara: '' }, 
        { titulo: 'Ocorrência', alinhamento: 'left', coluna: 'desc_tpop', mascara: '' }, 
        { titulo: 'Operador', alinhamento: 'left', coluna: 'nome_oper', mascara: '' },
        { titulo: 'Observações', alinhamento: 'left', coluna: 'obs_ocpg', mascara: ''},
        { titulo: '=', alinhamento:'right', coluna: 'imgpdf', mascara: '' },
        { titulo: '*',alinhamento: 'center', coluna: 'flagedicao', mascara: ''}
    ]

    const RetornaOcorrencias = () =>
    {
        let ocoret: {id: number, data_ocpg: string, desc_tpop: string, obs_ocpg: string, nome_oper: string, imgpdf:ObjPdfImg, flagedicao: string}[] = [];

        pagamento.ocorrencias.forEach((ocor, index) => 
        {
            if (ocor.alterado!=='B')
            {
                ocoret.push({
                    id: index+1,
                    data_ocpg: RetornaStringData(ocor.data_ocpg, true),
                    desc_tpop: achaDescricao(ocor.id_tpop, ocorrencias, 'id_tpop', 'desc_tpop'),
                    nome_oper: ocor.nome_oper,
                    obs_ocpg: ocor.obs_ocpg,
                    imgpdf: {tipo: ocor.tipodoc_ocpg, conteudo: ocor.documento_ocpg},
                    flagedicao: achaDescricao(ocor.id_tpop, ocorrencias, 'id_tpop', 'tipo_tpop')==='A' ? 'D' : 'C'
                })
            }
        });
        return ocoret;
    }

    const Verificadetalhes = (props: {tipo: string}) =>
    {
      const {tipo} = props;
      let tottit: number;
      let totdet: number;
      if(tipo==='A')
      {
        totdet = nulltoNumber(parseFloat(pagamento.detalhes.reduce((acum, det) => acum + (det.id_rcta && det.id_rcta!==0 && (!det.alterado || det.alterado!=='B') ? det.valor_dtpg : 0), 0).toFixed(2)));
        tottit = pagamento.desconto_pgto;
      }
      else
      {
        totdet = nulltoNumber(parseFloat(pagamento.detalhes.reduce((acum, det) => acum + (det.id_dsps && det.id_dsps!==0 && (!det.alterado || det.alterado!=='B') ? det.valor_dtpg : 0), 0).toFixed(2)));
        tottit = pagamento.valor_pgto+pagamento.juros_pgto+pagamento.multa_pgto;
      }

      return(
        <>
          <Grid container spacing={1} style={{width: '100%'}}>
            <Grid item xs={4}>
              <Card style={{ borderRadius: 3, border: 0, color: 'white', width: '100%', height: 55, padding: '0 0 0 0', margin: '5px 0 0 0', boxShadow: '0 3px 5px 2px lightblue'}} variant="outlined">
                <CardContent style={{ margin: '5px 5px 5px 5px', width: '100%', padding: '0 0 0 0'}}>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }}>Título</Typography>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '18px', padding: '0 0 0 0', margin: '0 0 0 0', width: '90%', textAlign: 'right' }}>{formatarCampo(tottit.toString(),'decimal',true)}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card style={{ borderRadius: 3, border: 0, color: 'white', width: '100%', height: 55, padding: '0 0 0 0', margin: '5px 0 0 0', boxShadow: '0 3px 5px 2px lightblue'}} variant="outlined">
                <CardContent style={{ margin: '5px 5px 5px 5px', width: '100%', padding: '0 0 0 0'}}>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }}>Detalhes</Typography>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '18px', padding: '0 0 0 0', margin: '0 0 0 0', width: '90%', textAlign: 'right' }}>{formatarCampo(totdet.toString(),'decimal',true)}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card style={{ borderRadius: 3, border: 0, color: 'white', width: '100%', height: 55, padding: '0 0 0 0', margin: '5px 0 0 0', boxShadow: '0 3px 5px 2px lightblue'}} variant="outlined">
                <CardContent style={{ margin: '5px 5px 5px 5px', width: '100%', padding: '0 0 0 0'}}>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }}>Diferença</Typography>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '18px', padding: '0 0 0 0', margin: '0 0 0 0', width: '90%', textAlign: 'right' }}>{formatarCampo((tottit-totdet).toString(),'decimal',true)}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }

    const Desiste = () =>
    {
        roteador(4,0);
    }
  
    const GravaPagamento = () =>
    {
        let errogrv: string = '';
        if (!pagamento.id_forn || pagamento.id_forn===0)
        {
            errogrv = errogrv +'É necessário informar o cliente \n' ;
        }
        if (!pagamento.id_stpg || pagamento.id_stpg===0 || (!pagamento.pagto_pgto && achaDescricao(pagamento.id_stpg, situacoes, 'id_stpg', 'comportamento_stpg') ==='B') || pagamento.valor_pgto<0 || pagamento.desconto_pgto>pagamento.valor_pgto || pagamento.desconto_pgto<0 || pagamento.multa_pgto<0 || pagamento.juros_pgto<0 || (achaDescricao(pagamento.id_frpg,formas,'id_frpg','comportamento_frpg')==='A' && (!pagamento.id_cta || pagamento.id_cta===0)))
        {
            errogrv = errogrv + 'Verifique os dados do pagamento \n';
        }
        let totrec = nulltoNumber(parseFloat(pagamento.detalhes.reduce((acum, det) => acum + (det.id_rcta && det.id_rcta!==0 && (!det.alterado || det.alterado!=='B') ? det.valor_dtpg : 0), 0).toFixed(2)));
        let totdsp = nulltoNumber(parseFloat(pagamento.detalhes.reduce((acum, det) => acum + (det.id_dsps && det.id_dsps!==0 && (!det.alterado || det.alterado!=='B') ? det.valor_dtpg : 0), 0).toFixed(2)));
        if (totdsp!==(pagamento.valor_pgto+pagamento.juros_pgto+pagamento.multa_pgto))
        {
            errogrv = errogrv + 'Soma das despesas (aba detalhes) deve ser igual à soma do valor, juros e multa pagos \n';
        }
        if (totrec!==pagamento.desconto_pgto)
        {
            errogrv = errogrv + 'Soma das receitas (aba detalhes) deve ser igual ao valor do desconto recebido';
        }

        if (errogrv.length>0)
        {
            mensagem(errogrv,'error',10000);
        }
        else
        {
            textodialogo=[]
            if (sted.detalhes)
            {
                textodialogo.push('Detalhes');
            }
            if (sted.ocorrencias)
            {
                textodialogo.push('Ocorrências');
            }
            if (textodialogo.length>0)
            {
                botoesdialogo=[{descricao: 'Confirma', retorno: 'A'},{descricao: 'Cancela', retorno: 'B'}]
                titulodialogo='Há informações alteradas e não confirmadas nas seguintes áreas:'
                textodialogo.push('Confirma a operação?');
                setDialog(true)
            }
            else
            {
                salvaPagamento();
            }
        }
    }
  
    const salvaPagamento = () =>
    {
        if (estado==='A')
        {
            setEstado('B');
            let pggrv: Pagamentos = JSON.parse(JSON.stringify(pagamento));
            pggrv.vencto_pgto=DataSql(pagamento.vencto_pgto);
            if (pagamento.datadesc_pgto)
            {
                pggrv.datadesc_pgto=DataSql(pagamento.datadesc_pgto);
            }
            if (pagamento.pagto_pgto)
            {
                pggrv.pagto_pgto=DataSql(pagamento.pagto_pgto);
            }
            pggrv.id_oper=dadosobt.objac.id_oper;
            pggrv.detalhes=[];
            pagamento.detalhes.forEach(det =>
            {
                if (det.alterado && (det.alterado==='B' || det.alterado==='A'))
                {
                    pggrv.detalhes.push(det);
                }
            });
            pggrv.ocorrencias=[];
            pagamento.ocorrencias.forEach(ocor =>
            {
                if (ocor.alterado && (ocor.alterado==='B' || ocor.alterado==='A'))
                {
                    ocor.data_ocpg=DataSql(ocor.data_ocpg);
                    pggrv.ocorrencias.push(ocor);
                }
            });
    
            srv.Post('Pagamentos/','',JSON.stringify(pggrv),dadosobt.objac.token,false)
            .then((ddret: any) => {
                let dadosret: Resposta = TrataResposta(ddret);
                if (!dadosret.ok)
                {
                    mensagem(dadosret.erro,'error',6000)
                    setEstado('A');
                }
                else
                {
                    mensagem('Pagamento: '+dadosret.retorno.id.toString()+' salvo com sucesso','info',8000,true);
                    setEstado('A');
                }
            })
            .catch(Error => 
            {
                mensagem('Erro acessando a API gravando pagamento (' + Error.name + ' - ' + Error.message + ')', 'error',6000);
                setEstado('A');
            })
        }
    }

    return (
        <div>
            <div className={classes.divgrid}>
                <AlertDialog aberta={dialogo} fecha={fechaDialogo} titulo={titulodialogo} texto={textodialogo} botoes={botoesdialogo}/>
                <Grid container spacing={1} className={classes.griddados}>
                    <Grid item xs={4}>
                        <CampoTexto valor={pagamento.id_pgto>0 ? pagamento.id_pgto : 0} id='id_pgto' label='Id' placeholder='Id' classe={classes.textoform} tipo='number' required={false} habilitado={false} onchange={undefined}/>
                    </Grid>
                    <Grid item xs={8}>
                        <SelecaoAuto valor={pagamento.id_forn} nome='id_forn' label='Fornecedor' classe={classes.textoform} conteudo={fornecedores} habilitado={acessos.altavc} colunas={{id: 'id_forn', desc: 'nome_forn'}} erro={!pagamento.id_forn || pagamento.id_forn<1} onchange={onChangePagamentoAutoComplete}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Selecao valor={pagamento.id_stpg ? pagamento.id_stpg : 0 }  nome='id_stpg' label='Situação' required={true} habilitado={acessos.altavc} onchange={onChangePagamentoSelect} conteudo={situacoes} nulo={[0,'Indefinida']} classe={classes.textoform} erro={!pagamento.id_stpg || pagamento.id_stpg<1} />
                    </Grid>
                    <Grid item xs={2}>
                        <CampoData valor={pagamento.vencto_pgto} id='vencto_pgto' label='Vencimento' placeholder='Vencimento' classe={classes.textoform} required={true} habilitado={acessos.altbas || acessos.altavc} onchange={onChangePagamentoDataInput} erro={!pagamento.vencto_pgto} />
                    </Grid>
                    <Grid item xs={2}>
                        <CampoData valor={pagamento.pagto_pgto} id='pagto_pgto' label='Pagamento' placeholder='Pagamento' classe={classes.textoform} required={false} habilitado={acessos.altbxa && achaDescricao(pagamento.id_stpg, situacoes, 'id_stpg', 'comportamento_stpg')==="B"} onchange={onChangePagamentoDataInput} limpadata={limpaData} erro={!pagamento.pagto_pgto && achaDescricao(pagamento.id_stpg, situacoes, 'id_stpg', 'comportamento_stpg')==="B"}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={pagamento.valor_pgto}  id='valor_pgto' label='Valor' placeholder='Valor' classe={classes.textoform} tipo='text' required={true} habilitado={acessos.altavc} onchange={onChangePagamentoDecimalInput} erro={pagamento.valor_pgto<=0}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={pagamento.desconto_pgto}  id='desconto_pgto' label='Desconto' placeholder='Desconto' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangePagamentoDecimalInput} erro={pagamento.desconto_pgto<0 || pagamento.desconto_pgto>pagamento.valor_pgto}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoData valor={pagamento.datadesc_pgto} id='datadesc_pgto' label='Desconto até' placeholder='Desconto até' classe={classes.textoform} required={false} habilitado={acessos.altavc} onchange={onChangePagamentoDataInput} limpadata={limpaData}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={pagamento.multa_pgto}  id='multa_pgto' label='Multa' placeholder='Multa paga' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangePagamentoDecimalInput} erro={pagamento.multa_pgto<0}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={pagamento.juros_pgto}  id='juros_pgto' label='Juros' placeholder='Juros pagos' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangePagamentoDecimalInput} erro={pagamento.juros_pgto<0}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Selecao valor={pagamento.id_frpg ? pagamento.id_frpg : 0 }  nome='id_frpg' label='Forma de pagamento' required={false} habilitado={acessos.altavc || acessos.altbas} onchange={onChangePagamentoSelect} conteudo={formas} nulo={[0,'Indefinida']} classe={classes.textoform} />
                    </Grid>
                    <Grid item xs={4}>
                        <Selecao valor={pagamento.id_cta ? pagamento.id_cta : 0 }  nome='id_cta' label='Conta' required={(acessos.altavc || acessos.altbas) && achaDescricao(pagamento.id_frpg,formas,'id_frpg','comportamento_frpg')==='A'} habilitado={(acessos.altavc || acessos.altbas) && achaDescricao(pagamento.id_frpg,formas,'id_frpg','comportamento_frpg')==='A'} onchange={onChangePagamentoSelect} conteudo={contas} nulo={[0,'Indefinida']} classe={classes.textoform} erro={((acessos.altavc || acessos.altbas) && achaDescricao(pagamento.id_frpg,formas,'id_frpg','comportamento_frpg')==='A') && (!pagamento.id_cta || pagamento.id_cta===0)}/>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.divtabs}>
                <StyledAppBar position="static">
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        variant='scrollable'
                        scrollButtons="auto"
                        aria-label="scrollable prevent tabs example"
                    >
                        <Tab classes={{root: classes.tab}} label={'Detalhes'} icon={<DescriptionIcon />} aria-label="detalhes" {...a11yProps(0)} />
                        <Tab classes={{root: classes.tab}} label={'Ocorrências'} icon={<ListIcon/>} aria-label="ocorrencias" {...a11yProps(1)} />
                    </Tabs>
                </StyledAppBar>
                <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={1} className={classes.griddados}>
                        <Grid item xs={4}>
                            <CampoDecimal valor={detdig.valor_dtpg}  id='valor_dtpg' label='Valor' placeholder='Valor' classe={classes.textoform} tipo='text' required={true} habilitado={(acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)} onchange={onChangePagamentoDecimalInput} erro={detdig.valor_dtpg<=0}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Selecao valor={detdig.id_rcta ? detdig.id_rcta : 0}  nome='id_rcta' label='Receita' required={true} habilitado={((acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)) && (!detdig.id_dsps || detdig.id_dsps===0)} onchange={onChangePagamentoSelect} conteudo={receitas} nulo={[0,'Indefinida']} classe={classes.textoform} erro={(!detdig.id_rcta || detdig.id_rcta<1) && (!detdig.id_dsps || detdig.id_dsps<1)} />
                        </Grid>
                        <Grid item xs={4}>
                            <Selecao valor={detdig.id_dsps ? detdig.id_dsps : 0}  nome='id_dsps' label='Despesa' required={true} habilitado={((acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)) && (!detdig.id_rcta || detdig.id_rcta===0)} onchange={onChangePagamentoSelect} conteudo={despesas} nulo={[0,'Indefinida']} classe={classes.textoform} erro={(!detdig.id_rcta || detdig.id_rcta<1) && (!detdig.id_dsps || detdig.id_dsps<1)} />
                        </Grid>
                        <Grid item xs={4}>
                            <Selecao valor={detdig.id_rede ? detdig.id_rede : 0}  nome='id_rede' label='Rede' required={false} habilitado={(acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)} onchange={onChangePagamentoSelect} conteudo={redes} nulo={[0,'Indefinida']} classe={classes.textoform} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelecaoAuto valor={detdig.id_cli} nome='id_cli' label='Cliente' classe={classes.textoform} conteudo={clientes} habilitado={acessos.altavc} colunas={{id: 'id_cli', desc: 'nome_cli'}} onchange={onChangePagamentoAutoComplete}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" disabled={(!acessos.altdet && inddet>0) || (!acessos.incdet && inddet===0)} className={classes.botao} onClick={adicionaDetalhe} endIcon={<VerticalAlignBottomIcon/>}>Adiciona</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TabelaBase linhasoriginais={RetornaDetalhes()} cab={hddet} selecao={false} edicao={acessos.altdet} adicao={acessos.incdet} habfiltro={false} onClickEdicao={editaDetalhe} titulo={''} full={true} linhasiniciais={5} exclusao={acessos.excdet} auxedit={1} auxexclui={2} />
                        </Grid>
                        <Grid style={{margin: '-4% 0 0 0'}} item xs={5}>
                          <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: '90px', padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '0 3px 5px 2px lightblue'}} variant="outlined">
                              <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                  <Typography style={{color:'darkblue', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Despesas</Typography>
                                  <Verificadetalhes tipo='B'/>
                              </CardContent>
                          </Card>
                        </Grid>
                        <Grid style={{margin: '-4% 0 0 0'}} item xs={5}>
                          <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 90, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '0 3px 5px 2px rgba(0, 105, 255, .3)'}} variant="outlined">
                              <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                  <Typography style={{color:'darkblue', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Receitas</Typography>
                                  <Verificadetalhes tipo='A'/>
                              </CardContent>
                          </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                <Grid container spacing={1}>
                    <Grid item className={classes.gridcampos}>
                        <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <CampoData valor={RetornaDataString(ocordig.data_ocpg)} id='data_ocpg' label='Data' placeholder='Data' classe={classes.textoform} required={true} habilitado={acessos.altocor && indocor>0} onchange={onChangePagamentoDataInput} hora={true}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Selecao valor={ocordig.id_tpop}  nome='id_tpop' label='Ocorrência' required={true} habilitado={(acessos.altocor && indocor>0) || (acessos.incocor && indocor===0)} onchange={onChangePagamentoSelect} conteudo={ ocorrencias.filter((ocor: Tipos_Ocorrencia_Pagamento) => ocor.tipo_tpop && ocor.tipo_tpop!=='B') } nulo={[0,'Indefinida']} classe={classes.textoform} erro={!ocordig.id_tpop || ocordig.id_tpop<1}/>
                        </Grid>
                        <Grid item xs={6}>
                            <CampoTexto valor={ocordig.obs_ocpg} id='obs_ocpg' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={(acessos.altocor && indocor>0) || (acessos.incocor && indocor===0)} onchange={onChangePagamentoInput}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" disabled={!((acessos.altocor && indocor>0) || (acessos.incocor && indocor===0))} className={classes.botao} onClick={adicionaOcorrencia} endIcon={<VerticalAlignBottomIcon/>}>Adiciona</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TabelaBase linhasoriginais={RetornaOcorrencias()} cab={hdoco} selecao={false} edicao={acessos.altocor} adicao={acessos.altocor} habfiltro={false} onClickEdicao={editaOcorrencia} titulo={''} full={true} linhasiniciais={5} exclusao={acessos.excocor} auxedit={1} auxexclui={2} />
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.gridfoto}>
                        <ExibePdfImg id='documento_ocpg' exibicao={false} objeto={{tipo: ocordig.tipodoc_ocpg, conteudo: ocordig.documento_ocpg}} tpaceito='C'  retorno={atualizaPdfImgPagamento} altera={(acessos.altocor && indocor>0) || (acessos.incocor && indocor===0)}/>
                    </Grid>
                    </Grid>
                </TabPanel>
                <Grid container spacing={1} className={classes.griddados}>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled={estado==='B'} className={classes.botao} onClick={GravaPagamento} endIcon={<SaveIcon/>}>Confirma</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={Desiste} endIcon={<CancelIcon/>}>Desiste</Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}  

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  openToast: boolean,
  severidadeToast: string,
  mensagemToast: string,
  duracaoToast: number,
  acessosok: boolean,
}

class EdicaoPagamentos extends Component<PropsType, IState>
{

    private _dadosobt: RetornoAcesso;
    private _roteador: Roteador;
    private _id_pgto: number;
    private _dadoobtido: boolean;
    private _acessos: number;
    private _retornamensagem: boolean;
    private _redes: Redes[];
    private _clientes: {id_cli: number, nome_cli: string} [];
    private _fornecedores: {id_forn: number, nome_forn: string} [];
    private _pagamento: Pagamentos;
    private _formas: Formas_Pagamento[];
    private _contas: Contas[];
    private _situacoes: Situacoes_Pagamentos[];
    private _receitas: Receitas[];
    private _despesas: Despesas[];
    private _ocorrencias: Tipos_Ocorrencia_Pagamento[];
    
    constructor(props: any)
    {
        super(props);

        this._dadosobt=new RetornoAcesso();
        this._id_pgto=0;

        if (props.location.state)
        {
            if(props.location.state.dadosret)
            {
                this._dadosobt=props.location.state.dadosret;
            }
            if (props.location.state.id_edicao)
            {
                this._id_pgto=props.location.state.id_edicao;
            }
        }

        if (!this._dadosobt.ok)
        {
            this.props.history.push({pathname: '/'})
        }

        this._roteador=new Roteador(this._dadosobt, '/Pagamentos');
        this._roteador.history=props.history;
        this._dadoobtido=false;
        this._ocorrencias=[];
        this._situacoes=[];
        this._clientes = [];
        this._receitas = [];
        this._despesas = [];
        this._pagamento =  new Pagamentos();
        this._contas = [];
        this._redes = [];
        this._fornecedores = [];
        this._formas = [];
        this._acessos=0;
        this._retornamensagem=false;
    
        if (this._id_pgto<0 && this._dadosobt.ok)
        {
            this._dadoobtido=true;
        }
        this.state =
        {
            dadoobtido: this._dadoobtido,
            openToast: false,
            severidadeToast: 'success',
            mensagemToast: '',
            duracaoToast: 6000,
            acessosok: false,
        }
        if (this._dadosobt.ok)
        {
            if (this._id_pgto>0)
            {
                srv.Get('Pagamentos/'+this._id_pgto, '', '', this._dadosobt.objac.token, false)
                .then((ddret: any) => 
                {
                    let dadosret: Resposta = TrataResposta(ddret);
                    if (dadosret.ok)
                    {
                        this._pagamento=dadosret.retorno; 
                        this.aumentaAcessos(); 
                        this.setState({dadoobtido: true});
                    }
                    else
                    {
                        this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo pagamento (' + dadosret.erro + ')' });
                    }
                })
                .catch(Error => this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo pagamento (' + Error.name + ' - ' + Error.message + ')' }));
            }
            else
            {
                this.aumentaAcessos();
            }
            this.ObtemDados('Clientes/0/0','clientes',this.setaclientes.bind(this));
            this.ObtemDados('Fornecedores','fornecedores',this.setafornecedores.bind(this));
            this.ObtemDados('FormasPagamento/','formas de pagamento',this.setaformas.bind(this));
            this.ObtemDados('Contas/','contas',this.setacontas.bind(this));
            this.ObtemDados('Receitas/','receitas',this.setareceitas.bind(this));
            this.ObtemDados('Despesas/','despesas',this.setadespesas.bind(this));
            this.ObtemDados('SituacoesPagamentos/','situações de pagamentos',this.setasituacoes.bind(this));
            this.ObtemDados('TiposOcorrenciaPagamento/','tipos de ocorrência',this.setaocorrencias.bind(this));
            this.ObtemDados('Redes/','redes',this.setaredes.bind(this));
            this.ObtemDados('Parametros/','parâmnetros',this.setaparametros.bind(this));
        }
    }

    setaclientes(registros: any) {this._clientes=RetornaClientes(registros); this.aumentaAcessos();};
    setafornecedores(registros: any) {this._fornecedores=RetornaFornecedores(registros); this.aumentaAcessos();};
    setaformas(registros: any) {this._formas=registros; this.aumentaAcessos();};
    setacontas(registros: any) {this._contas=registros; this.aumentaAcessos();};
    setareceitas(registros: any) {this._receitas=RetornaReceitasDespesas(registros,'A'); this.aumentaAcessos();};
    setadespesas(registros: any) {this._despesas=RetornaReceitasDespesas(registros,'B'); this.aumentaAcessos();};
    setasituacoes(registros: any) {this._situacoes=registros; this.aumentaAcessos();};
    setaocorrencias(registros: any) {this._ocorrencias=registros; this.aumentaAcessos();};
    setaredes(registros: any) {this._redes=registros; this.aumentaAcessos();};
    setaparametros(registros: any) {AlteraPadroesPagamento(registros); this.aumentaAcessos();};

    aumentaAcessos()
    {
        this._acessos = this._acessos+1
        if(this._acessos>10)
        {
            InicializaEdicao();
            this.setState({acessosok: true});
        }
    }
        
    ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
    {
        srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
            let dadosret:Resposta = TrataResposta(ddret);
            if(dadosret.ok)
            {
                obret(dadosret.retorno)
            }
            else
            {
                this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
                this.aumentaAcessos();
            }
        })
        .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
    }

    ExibeMensagem(mensagem: string, severidade: string, duracao:number, retorna:boolean=false)
    {
        this.setState({openToast: true, severidadeToast: severidade, mensagemToast: mensagem, duracaoToast: duracao})
        this._retornamensagem=retorna;
    }

    FechaMensagem()
    {
        this.setState({openToast: false});
        if (this._retornamensagem)
        {
            this._roteador.rota(4,0);
        }
    }

    render()
    {
        if (!this._dadosobt.ok)
        {
        return null
        }
        else
        {
            return (
            <div className="Home">
                <header className="Home-header">
                <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador} recurso='Edição de Pagamento'/>
                </header>
                <main>
                { this.state.acessosok ?
                    <PrincipalEdicaoPagamentos dadospag={this._pagamento} dadosobt={this._dadosobt} clientes={this._clientes} fornecedores={this._fornecedores} formas={this._formas} contas={this._contas} situacoes={this._situacoes} ocorrencias={this._ocorrencias} receitas={this._receitas} despesas={this._despesas} redes={this._redes} mensagem={this.ExibeMensagem.bind(this)} roteador={this._roteador.rota.bind(this._roteador)} />
                    :
                    <Progresso/>
                }
                </main>
                <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
            </div>
            )
        }
    }
}

export default withRouter(EdicaoPagamentos)
