import React,{ Component } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { GrupoResultado, RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import TabelaBase from '../Componentes/TabelaBase';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { Iheaders, TrataResposta, Resposta, datas, RetornaPeriodos, RetornaDataAAAAMMDD, formatarCampo, formataDecimal } from '../Servicos/Utilidades';
import { makeStyles, Theme, createStyles, Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Button, Card, CardContent, Divider, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FilterListIcon from '@material-ui/icons/FilterList'
import Periodo from '../Componentes/Periodo';
import Progresso from '../Componentes/Progresso';

const useStyles = makeStyles((theme: Theme) => createStyles({
  griddados: {
    width: '100%',
    margin: '0 0 0 0'
  },
  divgrid: {
    width: '90%',
    margin: '5% 7% 0'
  },
  botao: {
    margin: '2% 4% 2% 0%',
    width: '96%'
  },
  textoform: {
    margin: "0% 0%",
    width: "100%",
    background: 'white'
  }
}));

let filtper: datas | undefined = RetornaPeriodos(7);
let abrtini: boolean = false;

const AtualizaFiltro = (campo:string, valor: any) =>
{
  if(campo==='Periodo')
  {
    filtper={dtini: valor.dtini, dtfin: valor.dtfin}
  }
}

const AreaFiltros = ({atufiltro, resultados, totalizadores}: {atufiltro: () => void, resultados: GrupoResultado[], totalizadores: {tipo: string, titulo: string, cor: string, valor: number}[]}) =>
{
 
  const classes = useStyles();
  const [aberto, setAberto]=React.useState(abrtini);

  const ClickFiltro = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
  {
    atufiltro();
  }

  return (
    <div className={classes.divgrid}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Accordion expanded={aberto} style = {{boxShadow: "3px 3px 1px lightblue"}}>
            <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <Typography>Filtros</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Periodo capper='Periodo' camporet='Periodo' atualiza={AtualizaFiltro} perpad={7} dataspad={filtper ? filtper : {dtini: undefined, dtfin: undefined}} tipoper='AB'/>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={(event) => ClickFiltro(event)} endIcon={<FilterListIcon/>}>Aplica Filtros</Button>
                    </Grid>
                    <Grid item xs={1} >
                        <IconButton onClick={() => {setAberto(!aberto); abrtini=!aberto}} style={{width: '40%', margin: '0 0 0 60%'}} > {aberto ? <ExpandLessIcon /> : <ExpandMoreIcon/> }</IconButton>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    {resultados.length>0 
                    ?
                    <React.Fragment>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}> 
                                    <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 80, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '3px 3px 1px lightblue'}} variant="outlined">
                                        <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                            <Typography noWrap={true} style={{color: totalizadores[0].cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{totalizadores[0].titulo}</Typography>
                                            <Divider/>
                                            <Typography style={{textAlign: 'right', color: totalizadores[0].cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '30px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(totalizadores[0].valor.toString(),'decimal',true)}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}> 
                                    <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 80, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '3px 3px 1px lightblue'}} variant="outlined">
                                        <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                            <Typography noWrap={true} style={{color: totalizadores[1].cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{totalizadores[1].titulo}</Typography>
                                            <Divider/>
                                            <Typography style={{textAlign: 'right', color: totalizadores[1].cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '30px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(totalizadores[1].valor.toString(),'decimal',true)}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}> 
                                    <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 80, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '3px 3px 1px lightblue'}} variant="outlined">
                                        <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                            <Typography noWrap={true} style={{color: totalizadores[2].cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{totalizadores[2].titulo}</Typography>
                                            <Divider/>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}> 
                                                    <Typography style={{textAlign: 'right', color: totalizadores[2].cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '30px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(totalizadores[2].valor.toString(),'decimal',true)}</Typography>
                                                </Grid>
                                                <Grid item xs={6}> 
                                                    <Typography style={{textAlign: 'right', color: totalizadores[2].cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '30px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo((totalizadores[0].valor>0 ? (totalizadores[2].valor/totalizadores[0].valor)*100 : 0).toFixed(2),'decimal',true)+' %'}</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    :
                    null}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  )
};

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  registros: GrupoResultado[],
  principal: number,
  detalhe: number,
  open: boolean,
  mensagem: string,
  duracao: number,
  severidade: string,
}

const srv=new ApiService();

const headers: Iheaders[] = 
[
    { titulo: 'Grupo', alinhamento:'right', coluna: 'idgr', mascara: '' }, 
    { titulo: 'Descrição', alinhamento: 'left', coluna: 'descricao', mascara: '' }, 
    { titulo: 'Valor', alinhamento: 'right', coluna: 'valortot', mascara: 'decimal' },
    { titulo: '%', alinhamento: 'right', coluna: '', mascara: 'decimal', calculo: {basenum: 0, colbase: 'valortot'}}
]

const headdet: Iheaders[] = 
[
    { titulo: 'Subgrupo', alinhamento:'right', coluna: 'idsg', mascara: '' }, 
    { titulo: 'Descrição', alinhamento: 'left', coluna: 'descricao', mascara: '' }, 
    { titulo: 'Valor', alinhamento: 'right', coluna: 'valortot', mascara: 'decimal' },
    { titulo: '%', alinhamento: 'right', coluna: '', mascara: 'decimal', calculo: {basenum: 0, colbase: 'valortot'}}
]

const headsnd = (flrd:string, basenum: number): Iheaders[] =>
{
  return(
  [
    { titulo: flrd==='A' ? 'Receita' : 'Despesa', alinhamento:'right', coluna: 'idrd', mascara: '' }, 
    { titulo: 'Descrição', alinhamento: 'left', coluna: 'descricao', mascara: '' }, 
    { titulo: 'Valor', alinhamento: 'right', coluna: 'valortot', mascara: 'decimal' },
    { titulo: '%', alinhamento: 'right', coluna: '', mascara: 'decimal', calculo: {basenum: basenum, colbase: 'valortot'}}
  ])
}

const headsnddet: Iheaders[] = 
[
    { titulo: 'Documento', alinhamento:'center', coluna: 'docto', mascara: '' }, 
    { titulo: 'Operador', alinhamento: 'left', coluna: 'nomeoper', mascara: '' }, 
    { titulo: 'Cliente/Fornecedor', alinhamento: 'left', coluna: 'nome', mascara: '' }, 
    { titulo: 'Vencimento', alinhamento: 'center', coluna: 'vencimento', mascara: 'data' },
    { titulo: 'Pagamento', alinhamento: 'center', coluna: 'pagamento', mascara: 'data' },
    { titulo: 'Valor', alinhamento: 'right', coluna: 'valor', mascara: 'decimal' },
    { titulo: '%', alinhamento: 'right', coluna: '', mascara: 'decimal', calculo: {basenum: 0, colbase: 'valor'}}
]

class ResultadosDetalhados extends Component<PropsType, IState>
{

  private _dadosobt: RetornoAcesso;
  private _roteador: Roteador;
  private _totalizadores: {tipo: string, titulo: string, cor: string, valor: number}[]=
  [
    {tipo: 'A', titulo: 'Receitas', cor: '#228B22', valor: 0},
    {tipo: 'B', titulo: 'Despesas', cor: '#FF1493', valor: 0},
    {tipo: 'C', titulo: 'Resultado', cor: 'blue', valor: 0},
  ];

  constructor(props: any)
  {
    super(props);

    this._dadosobt=new RetornoAcesso();

    if (props.location.state)
    {
      if(props.location.state.dadosret)
      {
        this._dadosobt=props.location.state.dadosret;
      }
    }

    if (!this._dadosobt.ok)
    {
      this.props.history.push({pathname: '/'})
    }

    this._roteador=new Roteador(this._dadosobt, '/Recebimentos');
    this._roteador.history=props.history;
    this.state =
    {
      dadoobtido: false,
      registros: [],
      principal: -1,
      detalhe: -1,
      open: false,
      mensagem: '',
      duracao: 2000,
      severidade: '',
    }
    if (this._dadosobt.ok)
    {
      this.ObtemResultados();
    }
  }

  ObtemResultados()
  {
    srv.Get('Pagamentos/ResultadosDetalhados/'+RetornaDataAAAAMMDD(filtper?.dtini)+'/'+RetornaDataAAAAMMDD(filtper?.dtfin), '', '', this._dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let dadosret: Resposta = TrataResposta(ddret);
      if (dadosret.ok)
      {
        let indtot: number;
        let resultados: GrupoResultado[] = dadosret.retorno;
        this._totalizadores.forEach(tot => tot.valor=0);
        resultados.forEach((res,idx) =>
        {
          indtot='AB'.indexOf(res.flagrd);
          if (indtot>=0)
          {
            this._totalizadores[indtot].valor=this._totalizadores[indtot].valor+res.valortot;
          }
        });
        this._totalizadores[2].valor=this._totalizadores[0].valor-this._totalizadores[1].valor;
        this._totalizadores[2].cor=(this._totalizadores[2].valor>=0 ? 'blue' : 'red');
        this.setState({registros: resultados, dadoobtido: true});
      }
      else
      {
        this.ExibeMensagem('Erro: Erro obtendo dados de resultados (' + dadosret.erro + ')', 'error',5000);
      }
    })
    .catch(Error => {this.ExibeMensagem('Erro acessando a API - obtendo dados de resultados (' + Error.name + ' - ' + Error.message + ')','error',5000);})
  }

  ExibeMensagem(mensagem: string, severidade: string, duracao:number)
  {
    this.setState({open: true, severidade: severidade, mensagem: mensagem, duracao: duracao})
  }

  FechaMensagem()
  {
    this.setState({open: false});
  }

  RetornaTabela(id: number)
  {
    let copiareg:GrupoResultado[]=[];
    copiareg=copiareg.concat(this.state.registros);
    if (id>=0 && id<this.state.registros.length)
    {
        copiareg[id].aberta=!copiareg[id].aberta;
        this.setState({registros: copiareg});
    }
  }

  RetornaDetalhe(lnpr: number, lndet:number)
  {
    this.setState({principal: lnpr, detalhe: lndet})
  }

  RetornaTabelaSnd(id: number)
  {
    let copiareg: GrupoResultado[]=[];
    copiareg=copiareg.concat(this.state.registros);
    if (id>=0 && id<this.state.registros[this.state.principal].subgrupos[this.state.detalhe].resultados.length)
    {
        copiareg[this.state.principal].subgrupos[this.state.detalhe].resultados[id].aberta=!copiareg[this.state.principal].subgrupos[this.state.detalhe].resultados[id].aberta;
        this.setState({registros: copiareg});
    }
  }

  render()
  {
    if (!this._dadosobt.ok)
    {
      return null
    }
    else
    {
      return (
        <div className="Home">
          <header className="Home-header">
              <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador}/>
          </header>
          <main>
              {this.state.dadoobtido ?
                <div>
                  <AreaFiltros atufiltro={this.ObtemResultados.bind(this)} resultados={this.state.registros} totalizadores={this._totalizadores} />
                  <TabelaBase linhasoriginais={this.state.registros} cab={headers} selecao={false} edicao={false} adicao={false} habfiltro={false} onClickDet={this.RetornaDetalhe.bind(this)} titulo={'Resultados'} full={false} exclusao={false} classe='filtro' colcnc='flagrd' cncvlr='B' cabdet={headdet} coldet='subgrupos' coldetst='aberta' onOpen={this.RetornaTabela.bind(this)} tamdet={5} semespaco={true} baseclperc={[this._totalizadores[0].valor, this._totalizadores[1].valor]} colbsind='flagrd' basind='AB' colbsclprdet='valortot'/>
                  {this.state.principal>=0 && this.state.principal<this.state.registros.length && this.state.detalhe>=0 && this.state.detalhe<this.state.registros[this.state.principal].subgrupos.length
                  ?
                  <TabelaBase linhasoriginais={this.state.registros[this.state.principal].subgrupos[this.state.detalhe].resultados} cab={headsnd(this.state.registros[this.state.principal].subgrupos[this.state.detalhe].flagrd,0)} selecao={false} edicao={false} adicao={false} habfiltro={false} titulo={this.state.registros[this.state.principal].subgrupos[this.state.detalhe].descricao.trim()+' (R$ '+formatarCampo(this.state.registros[this.state.principal].subgrupos[this.state.detalhe].valortot.toFixed(2),'decimal',true)+')'} cortit={this.state.registros[this.state.principal].subgrupos[this.state.detalhe].flagrd === 'A' ? 'blue' : 'red'} full={false} exclusao={false} classe='filtro' colcnc='flagrd' cncvlr='B' cabdet={headsnddet} coldet='detalhes' coldetst='aberta' onOpen={this.RetornaTabelaSnd.bind(this)} tamdet={5} semespaco={true} baseclperc={[this.state.registros[this.state.principal].subgrupos[this.state.detalhe].valortot]} colbsclprdet='valortot' />
                  :
                  null}
                </div>
                :
                <div>
                  <Progresso/>
                </div>
              }
          </main>
          <Toast open = {this.state.open} handleClose = {this.FechaMensagem.bind(this)} severity = {this.state.severidade} duracao= {this.state.duracao}>{this.state.mensagem}</Toast>
        </div>
      );
    }
  }
}

export default withRouter(ResultadosDetalhados)
