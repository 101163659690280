import React from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Button, Grid, Toolbar, Typography } from '@material-ui/core';
import CampoMascara from '../Componentes/CampoMascara';
import CampoTexto from '../Componentes/CampoTexto';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import TabelaBase from '../Componentes/TabelaBase';
import { Emails } from '../Modelos/Emails';
import { Telefones } from '../Modelos/Telefones';
import { ClicaBotao, Iheaders, ValidaEmail } from "../Servicos/Utilidades";

const useStyles = makeStyles((theme: Theme) => createStyles({
    gridtabemail: {
        width: "50%",
        margin: "0 0 0 0"
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    botao: {
        margin: '5% 5%',
        width: '90%'
    }
}));

let botcli: string = '';  

const AposRender = () =>
{
  if(botcli)
  {
    ClicaBotao(botcli);
    botcli = '';
  }
}

type EmTelProps=
{
    indem: number,
    email: Emails,
    emails: Emails[],
    retindemail: (indem: number) => void,
    retemail: (email: Emails, altdados: boolean) => void,
    retemails: (emails: Emails[]) => void,
    indfn: number,
    fone: Telefones,
    fones: Telefones[],
    retindfone: (indfn: number) => void,
    retfone: (fone: Telefones, altdados: boolean) => void,
    retfones: (fones: Telefones[]) => void,
    retmsg: (msgerro: string) => void,
    acessos: boolean
}

export default function EmailsTelefones({indem, email, emails, retindemail, retemail, retemails, indfn, fone, fones, retindfone, retfone, retfones, retmsg, acessos}: EmTelProps)
{

    const classes = useStyles();
    const [emaildig, setemailDig] = React.useState(email);
    const [indemail, setindEmail] = React.useState(indem);
    const [fonedig, setfoneDig] = React.useState(fone);
    const [indfone, setindFone] = React.useState(indfn);

    const onChangeEmailsInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date, campo?: string | undefined) =>
    {
        if (campo && (campo==='endereco_email' || campo==='obs_email') && propratu!==undefined && typeof propratu==='string')
        {
            let novemaildig: Emails = JSON.parse(JSON.stringify(emaildig));
            novemaildig[campo]=propratu;
            retemail(novemaildig, true);
            setemailDig(novemaildig);
        }
    }

    const onChangeTelefonesInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date, campo?: string | undefined) =>
    {
        if (campo && (campo==='numero_fone' || campo==='obs_fone') && propratu!==undefined && typeof propratu==='string')
        {
            let novfone: Telefones = JSON.parse(JSON.stringify(fonedig));
            novfone[campo]=propratu;
            retfone(novfone, true);
            setfoneDig(novfone);
        }
    }

    const AtuEmailDigitado = (emrc: Emails, indemrc: number) =>
    {
        retemail(emrc, false);
        retindemail(indemrc);
        setemailDig(emrc);
        setindEmail(indemrc);
    }

    const AtuFoneDigitado = (fnrc: Telefones, indfnrc: number) =>
    {
        retfone(fnrc, false);
        retindfone(indfnrc);
        setfoneDig(fnrc);
        setindFone(indfnrc);
    }
  
    const adicionaEmail = () =>
    {
        if (emaildig.endereco_email && ValidaEmail(emaildig.endereco_email))
        {
            let emailnovos: Emails[]=[];
            emailnovos=emailnovos.concat(emails);
            if (indemail===0)
            {
                emailnovos.push(emaildig);
                emailnovos[emailnovos.length-1].alterado='A'
                botcli='ultpagtab'
            }
            else
            {
                emailnovos[indemail-1]=emaildig;
                emailnovos[indemail-1].alterado='A';
            }
            retemails(emailnovos);
            AtuEmailDigitado(new Emails(),0);
        }
        else
        {
            retmsg('Existem dados incorretos. Verifique, por gentileza');
        }
    }
  
    const adicionaFone = () =>
    {
        if (fonedig.numero_fone)
        {
            let fonenovos: Telefones[]=[];
            fonenovos=fonenovos.concat(fones);
            if (indfone===0)
            {
                fonenovos.push(fonedig)
                fonenovos[fonenovos.length-1].alterado='A'
                botcli='btultpgfone'
            }
            else
            {
                fonenovos[indfone-1]=fonedig
                fonenovos[indfone-1].alterado='A';
            }
            retfones(fonenovos);
            AtuFoneDigitado(new Telefones(),0);
        }
        else
        {
            retmsg('Preencha todos os campos obrigatórios, por gentileza');
        }
    }
  
    const editaEmails = (val1: number, val2: number) =>
    {
        if(val1===1)
        {
            if(val2<=emails.length && val2>0)
            {
                AtuEmailDigitado(emails[val2-1],val2);
            }
            else
            {
                AtuEmailDigitado(new Emails(),0);
            }
        }
        else
        {
            if(val1===2)
            {
                if(val2<=emails.length && val2>0)
                {
                    let emailnovos: Emails[]=[]
                    emailnovos=emailnovos.concat(emails);
                    emailnovos[val2-1].alterado='B';
                    retemails(emailnovos);
                    if (val2===indemail)
                    {
                        AtuEmailDigitado(new Emails(),0);
                    }
                }
            }
        }
    }
  
    const editaFones = (val1: number, val2: number) =>
    {
        if(val1===1)
        {
            if(val2<=fones.length && val2>0)
            {
                AtuFoneDigitado(fones[val2-1],val2);
            }
            else
            {
                AtuFoneDigitado(new Telefones(),0);
            }
        }
        else
        {
            if(val1===2)
            {
                if(val2<=fones.length && val2>0)
                {
                    let fonenovos: Telefones[]=[]
                    fonenovos=fonenovos.concat(fones);
                    fonenovos[val2-1].alterado='B';
                    retfones(fonenovos);
                    if (val2===indfone)
                    {
                        AtuFoneDigitado(new Telefones(),0);
                    }
                }
            }
        }
    }
  
    const hdemails: Iheaders[] = [{titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, {titulo: 'Email', alinhamento:'left', coluna: 'endereco_email', mascara: '' }, { titulo: 'Observações', alinhamento: 'left', coluna: 'obs_email', mascara: '' }]

    const RetornaEmails = () =>
    {
      let emailret: {id: number; endereco_email: string; obs_email: string}[]=[];
      emails.forEach((em: Emails, index) =>
      {
        if (em.alterado!=='B')
        {
          emailret.push({id: index+1, endereco_email: em.endereco_email, obs_email: em.obs_email});
        }
      })
      return emailret;
    }
  
    const hdfones: Iheaders[] = [{titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, {titulo: 'Telefone', alinhamento:'left', coluna: 'numero_fone', mascara: '' }, { titulo: 'Observações', alinhamento: 'left', coluna: 'obs_fone', mascara: '' }]
  
    const RetornaFones = () =>
    {
      let telefones: {id: number; numero_fone: string; obs_fone: string}[]=[];
      fones.forEach((fn: Telefones, index) =>
      {
        if (fn.alterado!=='B')
        {
          telefones.push({id: index+1, numero_fone: fn.numero_fone, obs_fone: fn.obs_fone});
        }
      })
      return telefones;
    }

    return (
        <Grid container spacing={1}>
            <Grid item className={classes.gridtabemail}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <AppBar position="static">
                            <Toolbar variant="dense">
                                <Typography variant="h6" color="inherit">
                                    E-mails
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <Grid item xs={5}>
                        <CampoTexto valor={ emaildig.endereco_email} id='endereco_email' label='E-mail' placeholder='E-mail' classe={classes.textoform} tipo='text' required={true} habilitado={acessos} onchange={onChangeEmailsInput} erro={!(emaildig.endereco_email && ValidaEmail(emaildig.endereco_email))} tamanho={200}/>
                    </Grid>
                    <Grid item xs={4}>
                        <CampoTexto valor={ emaildig.obs_email} id='obs_email' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={acessos} onchange={onChangeEmailsInput} tamanho={30}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="primary" disabled={!acessos} className={classes.botao} onClick={adicionaEmail} endIcon={<VerticalAlignBottomIcon/>}>Adiciona</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TabelaBase linhasoriginais={RetornaEmails()} cab={hdemails} selecao={false} edicao={acessos} adicao={acessos} habfiltro={false} onClickEdicao={editaEmails} titulo={''} full={true} linhasiniciais={5} exclusao={acessos} auxedit={1} auxexclui={2} ultpag={true} aposRender={AposRender} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.gridtabemail}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <AppBar position="static">
                            <Toolbar variant="dense">
                                <Typography variant="h6" color="inherit">
                                    Telefones
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <Grid item xs={5}>
                        <CampoMascara valor={ fonedig.numero_fone.replace(/[^\d]+/g,'')} id='numero_fone' label='Telefone' placeholder='Telefone' classe={classes.textoform} mascara='Telefone' required={true} habilitado={acessos} onchange={onChangeTelefonesInput} erro={!fonedig.numero_fone}/>
                    </Grid>
                    <Grid item xs={4}>
                        <CampoTexto valor={ fonedig.obs_fone} id='obs_fone' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={true} habilitado={acessos} onchange={onChangeTelefonesInput} tamanho={30}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="primary" disabled={!acessos} className={classes.botao} onClick={adicionaFone} endIcon={<VerticalAlignBottomIcon/>}>Adiciona</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TabelaBase linhasoriginais={RetornaFones()} cab={hdfones} selecao={false} edicao={acessos} adicao={acessos} habfiltro={false} onClickEdicao={editaFones} titulo={''} full={true} linhasiniciais={5} exclusao={acessos} auxedit={1} auxexclui={2} btultpg='btultpgfone' ultpag={true} aposRender={AposRender}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}