import React from 'react';
import ReactPDF, { Page, Text, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { DocPDF } from '../Servicos/MontaPDF';
import TabelaPDF from '../Componentes/TabelaPDF';

const styles = StyleSheet.create({
    body: {
        margin: '0px 12px 0px 12px',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: 'Oswald'
    },
    negrito: {
        fontSize: 18,
        textAlign: 'justify',
        fontFamily: 'Times-Bold',
        fontStyle: 'italic',
        fontWeight: 'bold'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
        fontStyle: 'italic',
        fontWeight: 'bold'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        margin: '5% 5% 5% 5%',
        width: '90%',
        maxHeight: '300px'
    },
    header: {
        margin: '0 25% 0 25%',
        borderTop: 2,
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
        width: '45%'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 12,
        right: 0,
        fontFamily: 'Arial-Bold',
        textAlign: 'center',
        color: 'grey',
    },
    rodape: {
        position: 'absolute',
        fontSize: 6,
        bottom: 30,
        left: 12,
        right: 20,
        fontFamily: 'Arial-Bold',
        textAlign: 'right',
        color: '#BFEFFF',
    },
});
   
export default function DocumentoPDF(docpdf: DocPDF, orientacao?: ReactPDF.Orientation)
{
    return (
        <Document title={docpdf.titulo}>
            <Page orientation={orientacao ? orientacao : 'portrait'} style={styles.body}>
                {docpdf.conteudo.map((cont,idx1) => 
                    cont.paragrafo ?
                        <Text key={(idx1+1)*10000} style={cont.paragrafo.style} break={cont.paragrafo.quebra ? cont.paragrafo.quebra : false}>
                            {cont.paragrafo.texto.map((txt,idx2) => 
                                <Text key={((idx1+1)*10000)+idx2+1} style={txt.style}>{txt.conteudo}</Text>
                            )}
                        </Text>
                    :
                    cont.tabela ?
                        <TabelaPDF key={(idx1+1)*10000} style={cont.tabela.style} quebra={cont.tabela.quebra ? cont.tabela.quebra : false} header={cont.tabela.header} dados={cont.tabela.dados} valores={cont.tabela.valores}/>
                    :
                    cont.imagem ?
                        <Image key={(idx1+1)*10000} style={cont.imagem.style} break={cont.imagem.quebra ? cont.imagem.quebra : false}  src={cont.imagem.src} />
                    :
                    null
                )}
               <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
               <Text style={styles.rodape} fixed>{'Master Office 1.0'}</Text>
            </Page>
        </Document>
    );
}