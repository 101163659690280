import {Servicos_Contratos} from '../Modelos/Servicos_Contratos';

export class Contratos
{
    id_cont: number=0;
    id_cli : number=0;
    id_vend: number=0;
    dataass_cont: Date = new Date();
    vigini_cont: Date = new Date();
    vigfin_cont: Date = new Date(); 
    situacao_cont: string='';
    id_idrj: number=0
    freqreaj_cont: string=''; 
    database_cont: Date = new Date();
    obs_cont: string=''; 
    datains_cont: Date = new Date();
    gravadoc: boolean = false; 
    documento_cont?: Uint32Array = undefined; 
    srvcont: Servicos_Contratos[] = [];
    alterado: string = '';
}