import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';

type ToastProps=
{
  open: boolean
  handleClose: any,
  children: string,
  severity: any
  duracao: number
}

const DesmembraMensagem = (msg: string): string [] =>
{
  let msglin: string[]=[msg];
  if ('\n'.indexOf(msg))
  {
    msglin = msg.split('\n');
    msglin = msglin.filter(lnms => lnms.trim().length>0);
  }
  return msglin; 
}


export default function Toast ({open, handleClose, children, severity, duracao}: ToastProps)
{
    return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={duracao} anchorOrigin={{vertical: 'top', horizontal: 'center'}} style = {{boxShadow: "3px 3px 1px lightblue"}}>
        <Alert onClose={handleClose} variant='standard' severity={severity}>
          {DesmembraMensagem(children).map((lnms,index) => {
          return (
            <Typography key={index+1}>{lnms}</Typography>)
            })
  	      }
        </Alert>
    </Snackbar>)
}
