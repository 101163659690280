import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { RetornaDataAAAAMMDD, Resposta, TrataResposta, DifSegundos, formatarCampo, retPeriodoFiltro, achaDescricao, DashProps, RetornaStringData, MatrizCores } from '../Servicos/Utilidades';
import { ApiService } from '../Servicos/ApiService';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Dashboard_Operador } from '../Modelos/Dashboard_Operador';
import TituloDash from '../Componentes/TituloDash';
import Grafico from './Grafico';

const srv = new ApiService();

interface DashPgtoState {
    atutela: boolean,
    erro: string,
    categorias: string[],
    series: any[],
    atuagora: boolean,
}

class DashInadimplencia extends Component<DashProps, DashPgtoState>
{
    private _idinterval: number;
    private _dadosobt: RetornoAcesso;
    private _inicial: Date;
    private _parms: {periodo: number, datini: Date | undefined, datfin: Date |undefined};
    private _dash: Dashboard_Operador;
    private _dados: { pagamento: Date, valor: number, qtde: number}[];
    
    constructor(props: any)
    {
        super(props);

        this._parms={periodo:0, datini: undefined, datfin: undefined};
        this._dash=this.props.dashoper;
        this._parms=retPeriodoFiltro(achaDescricao(1,this._dash.filtros,'id_tfdb','valores_fdbo'));
        this._dadosobt=this.props.dadosacesso;
        this._inicial=new Date();
        this._idinterval=-1;
        this._dados=[];

        this.state =
        {
            atutela: false,
            erro: '',
            categorias: [],
            series: [],
            atuagora: false,
        }

        if (window)
        {
            this._idinterval=window.setInterval(this.atualizahora.bind(this),1000)
        }
        this.atualizaDados();
    }

    atualizaDash(dashatu: Dashboard_Operador)
    {
        this._dash=dashatu;
        this._parms=retPeriodoFiltro(achaDescricao(1,this._dash.filtros,'id_tfdb','valores_fdbo'));
        this.setState(
        {
            atuagora: true
        })
    }

    atualizaDados()
    {
        srv.Get('Recebimentos/Inadimplencia/'+RetornaDataAAAAMMDD(this._parms.datini, false)+'/'+RetornaDataAAAAMMDD(this._parms.datfin, false), '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
            let dadosret: Resposta = TrataResposta(ddret);
            if (dadosret.ok)
            {
                this._dados=dadosret.retorno;
                this.atualizaElementos();
            }
            else
            {
                this.setState({atutela: true, erro: 'Erro: Erro obtendo dados de inadimplencia (' + dadosret.erro + ')' });
            }
        })
        .catch(Error => this.setState({atutela: true, erro: 'Erro acessando a API - obtendo dados de inadimplencia (' + Error.name + ' - ' + Error.message + ')' }))
    }

    atualizahora()
    {
        let dif=DifSegundos(this._inicial, new Date());
        if ((dif>this._dash.intervalo_dsbo && this._dash.intervalo_dsbo>0) || this.state.atuagora)
        {
            this.setState({atuagora: false});
            this._inicial=new Date();
            this.atualizaDados();
        }
    }

    componentWillUnmount() {
        if (this._idinterval>=0)
        {
            window.clearInterval(this._idinterval);
            this._idinterval=-1;
        }
    }

    atualizaElementos()
    {
        let novacat: string[]=[];
        let novaser: any[] = [];
        novaser.push({type: 'column', name: 'Pagos no dia', data: [], color: {linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, stops: [[0, '#00CD66'],[1, '#00FF7F']]}});
        novaser.push({type: 'spline', name: 'Evoluçao', data: [], color: 'blue', marker: {lineWidth: 2, lineColor: 'lightblue', fillColor: 'cyan'}});
        let totpagos: number = 0;
        let totger: number = 0;
        let qtdepagos: number = 0;
        let qtdeger: number=0;
        this._dados.forEach(dd => 
            {
                totger=totger+dd.valor;
                qtdeger=qtdeger+dd.qtde;
                if (dd.pagamento)
                {
                    totpagos=totpagos+dd.valor;
                    qtdepagos=qtdepagos+dd.qtde;
                    novacat.push(RetornaStringData(dd.pagamento,false));
                    novaser[0].data.push({y: dd.valor, valorbr: formatarCampo((dd.valor/1000).toString(),'decimal',true), qtde: formatarCampo(dd.qtde.toString(),'decimal',false)});
                    novaser[1].data.push({y: totpagos, valorbr: formatarCampo((totpagos/1000).toString(),'decimal',true), qtde: formatarCampo(qtdepagos.toString(),'decimal',false)});
                }
            });
            novaser.push(
            {
                type: 'pie',
                name: 'Totais',
                data: [
                    {name: 'Pagos', y: totpagos, valorbr: formatarCampo(totpagos.toString(),'decimal',true), qtde: formatarCampo(qtdepagos.toString(),'decimal',false), color: MatrizCores[0].cor},
                    {name: 'Pendentes', y: totger-totpagos, valorbr: formatarCampo((totger-totpagos).toString(),'decimal',true), qtde: formatarCampo((qtdeger-qtdepagos).toString(),'decimal',false), color: MatrizCores[2].cor}],
                center: [20, 30],
                size: 100,
                showInLegend: false,
                dataLabels: {enabled: false}});

        this.setState({categorias: novacat, series: novaser, atutela: true})
    }

    render()
    {
        if (!this.state.atutela)
        {
            return null
        }
        else
        {
            return (
                <>
                    <Grid style={{ backgroundColor: '#F0FFFF', width: '100%', height: this._dash.tamanho_dsbo === 'C' ? '22% ' : '15%' }} container spacing={0}>
                        <TituloDash altura={this.props.altura} dash={this._dash} quadro={this.props.quadro} edita={this.props.edita} atualiza={this.atualizaDash.bind(this)} intervalo={{ dtini: this._parms.datini, dtfin: this._parms.datfin }} />
                    </Grid>
                    <Grid style={{ backgroundColor: '#F0FFFF', width: '100%', height: this._dash.tamanho_dsbo === 'C' ? '78% ' : '85%' }} container spacing={1}>
                        <Grid item xs={12}>
                            <Grafico estilo={{ backgroundColor: '#F0FFFF', width: '100%', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.415 : 0.38)).toString().trim() + 'px', boxShadow: "3px 3px 1px lightblue" }} opcoes={{
                                chart: { backgroundColor: '#F0FFFF', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.405 : 0.375)).toString().trim() + 'px' },
                                credits: { enabled: false },
                                title: { text: '' },
                                xAxis: { categories: this.state.categorias},
                                yAxis: { min: 0, title: { text: 'Valores em R$' } },
                                legend: { enabled: true, labelFormat: '{name}'},
                                tooltip: { pointFormat: '{point.qtde} - {point.valorbr}' },
                                plotOptions: {series: {dataLabels: {format: '{point.valorbr}', enabled: true}}},
                                series: this.state.series
                            }} />
                        </Grid>
                    </Grid>
                </>
            );
        }
    }
}

export default DashInadimplencia
