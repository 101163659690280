export class Ocorrencias_Recebimento
{
    id_ocrc: number=0;
    id_tpor: number=0;
    id_rcbt: number=0;
    data_ocrc: Date = new Date();
    id_oper: number=0;
    nome_oper: string='';
    nomearq_ocrc: string='';
    tipoarq_ocrc: string='';
    regbanco_ocrc: number=0;
    ocorbanco_ocrc: string='';
    motbanco_ocrc: string='';
    obs_ocrc: string='';
    gravadoc: boolean=false;
    tipodoc_ocrc: string='';
    documento_ocrc?: Uint32Array=undefined;
    alterado: string='';
}