import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { DataTableCell } from '@david.kucsai/react-pdf-table/lib/DataTableCell';
import { TableBody } from '@david.kucsai/react-pdf-table/lib/TableBody';
import { Table } from '@david.kucsai/react-pdf-table/lib/Table';
import { TableHeader } from '@david.kucsai/react-pdf-table/lib/TableHeader';
import { TableCell } from '@david.kucsai/react-pdf-table';
import { DadosTabelaPDF } from '../Servicos/MontaPDF';
import { formatarCampo } from '../Servicos/Utilidades';

export default function TabelaPDF({style, quebra, header, dados, valores}: DadosTabelaPDF)
{
    return (
        <View style={style} break={quebra ? quebra : false}>
            <Table key={1} data={valores.length>0 ? valores : undefined}>
                <TableHeader key={2} includeTopBorder={header.bordas.top} includeBottomBorder={header.bordas.bottom} includeLeftBorder={header.bordas.left} includeRightBorder={header.bordas.right}>
                    {header.colunas.map((cab,index) => 
                        {
                            return (
                                <TableCell key={(index*100)+1} style={{border: cab.borda==='none' ? cab.borda : undefined, fontFamily: cab.familia, fontSize: cab.fonte, margin: '0 2px 0 2px', textAlign: cab.alinhamento, maxWidth: cab.tamanho.toString().trim()+'%' }}>
                                    <Text key={index+1} style={{borderTop: cab.bordas.top, borderBottom: cab.bordas.bottom, borderLeft: cab.bordas.left, borderRight: cab.bordas.right}}>{cab.descricao}</Text>
                                </TableCell>
                            );   
                        }
                    )}
                </TableHeader>
                {valores.length>0 ?
                    <TableBody key={3} includeTopBorder={header.bordas.top} includeBottomBorder={header.bordas.bottom} includeLeftBorder={header.bordas.left} includeRightBorder={header.bordas.right}>
                        {dados.colunas.map((dado,index) => 
                            {
                                return (
                                    <DataTableCell key={index+1} weighting={0.5} style={{border: dado.borda==='none' ? dado.borda : undefined, fontFamily: dado.familia, fontSize: dado.fonte, margin: '0 2px 0 2px', textAlign: dado.alinhamento, maxWidth: dado.tamanho.toString().trim()+'%'}} getContent={(l) => dado.descricao.length>0 && l[dado.descricao] ? (dado.formato ? formatarCampo(l[dado.descricao].toString(),dado.formato) : l[dado.descricao].toString()).trim() : ''} />
                                )
                            }
                        )}
                    </TableBody>
                :
                <></>
                }
            </Table>
        </View>
  );
}