import React from 'react';
import Selecao from './Selecao';
import CampoData from './CampoData';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { datas, RetornaPeriodos, ObtemPeriodos } from '../Servicos/Utilidades';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    campoperiodo: {
      margin: "0 1% 0 1%",
      width: "26%",
      background: 'white',

    },
    campodata: {
      margin: "0 1% 0 0",
      width: "35%",
      background: 'white',
    },
  })
);

export default function Periodo({capper, capini, capfin, camporet, atualiza, perpad, dataspad, tipoper, tamletra, altura, habilitado=true}: {capper?: string, capini?:string, capfin?:string, camporet: string, atualiza: (campo:string, valor: datas, periodo?: number) => void, perpad:number, dataspad: datas, tipoper: string, tamletra?: string, altura?: string, habilitado?: boolean})
{ 
  
  const [periodo, setPeriodo] = React.useState(perpad);
  const [dtperiodo, setDtperiodo] = React.useState(dataspad);

  const classes = useStyles();

  const onChangePeriodoSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode, campo?: string | undefined) =>
  {
    if(campo && event.target.value)
    {
      if (camporet+'periodo'===campo)
      {
        let valor:number = typeof event.target.value === 'string' ? parseInt(event.target.value) : typeof event.target.value === 'number' ? event.target.value : 0;
        setPeriodo(valor);
        let novasdatas=RetornaPeriodos(valor);
        if (novasdatas)
        {
            setDtperiodo(novasdatas);
            atualiza(camporet, novasdatas, valor);
        }
      }
    }
  }

  const onChangePeriodoDataInput = (date: any, valor: string | null | undefined, campo: string | undefined) =>
  {
    if (campo)
    {
      if (camporet+'dtini'===campo)
      {
        setDtperiodo({dtini: date, dtfin: dtperiodo.dtfin});
        atualiza(camporet, {dtini: date, dtfin: dtperiodo.dtfin}, 0);
        setPeriodo(0)
      }
      else if(camporet+'dtfim'===campo)
      {
        setDtperiodo({dtini: dtperiodo.dtini, dtfin: date});
        atualiza(camporet, {dtini: dtperiodo.dtini, dtfin: date}, 0);
        setPeriodo(0)
      }
    }
  }

  const limpaData = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, campo: string | undefined) =>
  {
    if (campo)
    {
        if (camporet+'dtini'===campo)
        {
           setDtperiodo({dtini: undefined, dtfin: dtperiodo.dtfin});
           atualiza(camporet, {dtini: undefined, dtfin: dtperiodo.dtfin});
          }
        else if(camporet+'dtfim'===campo)
        {
           setDtperiodo({dtini: dtperiodo.dtini, dtfin: undefined});
           atualiza(camporet, {dtini: dtperiodo.dtini, dtfin: undefined});
        }
      }
  }

  return (
    <div>
        <Selecao valor={periodo} nome={camporet+'periodo'} label={capper ? capper : 'Período'} required={habilitado} habilitado={habilitado} onchange={onChangePeriodoSelect} conteudo={ObtemPeriodos(tipoper)} nulo={[0,'Indefinido']} classe={classes.campoperiodo} tamletra={tamletra} altura={altura} />
        <CampoData valor={dtperiodo.dtini} id={camporet+'dtini'} label={capini ?  capini : 'Início'} placeholder='Início' classe={classes.campodata} required={false} habilitado={habilitado} onchange={onChangePeriodoDataInput} limpadata={limpaData} estilo={tamletra && altura ? {style: {fontSize: tamletra, maxHeight: altura}} : undefined} tamletra={tamletra} altura={altura} />
        <CampoData valor={dtperiodo.dtfin} id={camporet+'dtfim'} label={capfin ? capfin : 'Fim'} placeholder='Fim' classe={classes.campodata} required={false} habilitado={habilitado} onchange={onChangePeriodoDataInput} limpadata={limpaData} estilo={tamletra && altura ? {style: {fontSize: tamletra, maxHeight: altura}} : undefined} tamletra={tamletra} altura={altura}/>
    </div>
  );
}
