import React, { Component } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import TabelaBase from '../Componentes/TabelaBase';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { Iheaders, TrataResposta, Resposta } from '../Servicos/Utilidades';

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  registros: [],
  open: boolean,
  mensagem: string,
  duracao: number,
  severidade: string
}

const srv=new ApiService();
const headers: Iheaders[] = [{titulo: 'Código', alinhamento:'right', coluna: 'id_oper', mascara:'' }, { titulo: 'CPF/CNPJ', alinhamento: 'left', coluna: 'cpfcnpj', mascara: 'cpfcnpj' }, { titulo: 'Nome', alinhamento: 'left', coluna: 'nome_pessoa', mascara:'' }, { titulo: 'Apelido', alinhamento: 'left', coluna: 'fantasia_pessoa', mascara:'' }]

class Operadores extends Component<PropsType, IState>
{

  private _dadosobt: RetornoAcesso;
  private _roteador: Roteador;
  private _adicao=false;
  private _edicao=false;

  constructor(props: any)
  {
    super(props);

    this._dadosobt=new RetornoAcesso();

    if (props.location.state)
    {
      if(props.location.state.dadosret)
      {
        this._dadosobt=props.location.state.dadosret;
      }
    }

    if (!this._dadosobt.ok)
    {
      this.props.history.push({pathname: '/'})
    }

    this._roteador=new Roteador(this._dadosobt, '/Operadores');
    this._roteador.history=props.history;
    this.state =
    {
      registros: [],
      open: false,
      mensagem: '',
      duracao: 2000,
      severidade: ''
    }
    if (this._dadosobt.ok)
    {
      if (this._dadosobt.objac.tipoacesso_perf==='A')
      {
        this._adicao=true;
        this._edicao=true;
      }
      else
      {
        let fnrc = this._dadosobt.objac.funcoes.filter((funcao) => funcao.id_rcrs===9 );
        this._edicao=fnrc.some(funcao => funcao.id_fnrc===40)
        this._adicao=fnrc.some(funcao => funcao.id_fnrc===39)
      }
      srv.Get('Operadores', '', '', this._dadosobt.objac.token, false)
      .then((ddret: any) => 
      {
        let dadosret: Resposta = TrataResposta(ddret);
        if (dadosret.ok)
        {
          this.setState({registros: dadosret.retorno})
        }
        else
        {
          this.setState({open: true, mensagem: 'Erro obtendo dados de operadores (' + dadosret.erro + ')', duracao: 5000, severidade: 'error' })          
        }
      })
      .catch(Error => this.setState({open: true, mensagem: 'Erro obtendo dados de operadores acessando a API (' + Error.name + ' - ' + Error.message + ')', duracao: 5000, severidade: 'error' }))
    }
  }

  FechaMensagem()
  {
    this.setState({open: false});
  }

  render()
  {
    if (!this._dadosobt.ok)
    {
      return null
    }
    else
    {
      return (
        <div className="Home">
          <header className="Home-header">
              <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador}/>
          </header>
          <main>
            <div>
              <TabelaBase linhasoriginais={this.state.registros} cab={headers} selecao={false} edicao={this._edicao} adicao={this._adicao} habfiltro={true} onClickEdicao={this._roteador.rota.bind(this._roteador)} titulo={'Operadores'} full={false} exclusao={false} auxedit={994} auxexclui={994}/>
            </div>
          </main>
          <Toast open = {this.state.open} handleClose = {this.FechaMensagem.bind(this)} severity = {this.state.severidade} duracao= {this.state.duracao}>{this.state.mensagem}</Toast>
        </div>
      );
    }
  }
}

export default withRouter(Operadores)
