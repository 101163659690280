import { makeStyles, Theme, createStyles, CircularProgress, LinearProgress } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => createStyles({
    divgrid: {
        width: '80%',
        margin: '5% 15% 0 10%'
    },
    progresso: {
      width: '10%',
      margin: '0 45%'
    },
    proglinear: {
      width: '100%',
      margin: '0 0 0 0'
    }
  }),
);

export default function Progresso({tipo}: {tipo?: string})
{
  const classes=useStyles();
  return (
    <div>
      <div className={classes.divgrid}>
        {! tipo || tipo==='A'
        ?
          <CircularProgress className={classes.progresso} />
        :
          <LinearProgress className={classes.proglinear} />
        }
      </div>
    </div>
  )
}
