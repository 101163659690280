import React, { RefObject, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Checkbox, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {RetornaUrlPdfImg, arrayBufferToBase64, ObjPdfImg} from '../Servicos/Utilidades';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        botaopdfimg: {
            margin: '0 2',
            width: '100%'
        },
        inputpdfimg: {
            display: "none"
        },
        pdf: {
            width: "98%",
            maxHeight: "400px",
            minHeight: "400px",
            margin: "1% 1% 0 0",
            boxShadow: "2px 2px 5px lightblue"
        },
        img: {
            width: "98%",
            maxHeight: "400px",
            margin: "1% 1% 0 0",
            boxShadow: "2px 2px 5px lightblue"
        },
    })
);

type ExibePdfImgProps=
{
  id: string,
  exibicao: boolean,
  objeto: ObjPdfImg,
  tpaceito: string,
  retorno: (objret: ObjPdfImg) =>void,
  altera: boolean
}

export default function ExibePdfImg({id, exibicao, objeto, tpaceito, retorno, altera}: ExibePdfImgProps)
{
    const classes = useStyles();
    const inputFilePdfImg: RefObject<HTMLInputElement> = useRef(null);
    const [exibe, setexibe]= React.useState(exibicao);

    const atualizaObjeto = (objrec: ObjPdfImg) =>
    {
        retorno(objrec);
    };

    const onButtonClickPdfImg = (event: React.MouseEvent<HTMLButtonElement | HTMLImageElement | HTMLIFrameElement, MouseEvent>) => 
    {
        if (inputFilePdfImg.current)
        {
            inputFilePdfImg.current.click()
        }
    };

    const ApagaPdfImg = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
    {
      const doc: any = document.getElementById('filepdfimg');
      if(doc)
      {
        doc.value='';
      }
      atualizaObjeto({tipo: '', conteudo: undefined});
    };

    const AbrePdfImg = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
    {
        window.open(RetornaUrlPdfImg(objeto.conteudo, objeto.tipo));
    };

    const onChangePdfImg = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    {
      const filereader = new FileReader();
      let tparq: string = '';
      const doc: any = document.getElementById(event.target.id);
      if(doc)
      {
        if(doc.files)
        {
          if(doc.files.length>0)
          {
            const arquivo = doc.files[0];
            tparq=arquivo.type.indexOf('image')===-1 ? 'B' : 'A';
            filereader.readAsArrayBuffer(arquivo);
          }
        }
      };
  
      filereader.onloadend = function()
      {
        let result: any = filereader.result
        atualizaObjeto({tipo: tparq, conteudo: arrayBufferToBase64(result)});
      };
    };
    return(
        <div>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Checkbox id={id} className={classes.botaopdfimg} color='primary' icon={<VisibilityOffIcon/>}checkedIcon={<VisibilityIcon/>} disabled={!objeto.conteudo || objeto.conteudo.length<1} name="pdfimgchk" checked={exibe} onChange={(event, checked) => setexibe(checked)}/>
                </Grid>
                <Grid item xs={3}>
                    <IconButton id={id} color="primary" onClick={onButtonClickPdfImg} disabled={!altera} className={classes.botaopdfimg}><SearchIcon/></IconButton>
                </Grid>
                <Grid item xs={3}>
                    <IconButton id={id} color="primary" onClick={ApagaPdfImg} className={classes.botaopdfimg} disabled={!objeto.conteudo || objeto.conteudo.length<1 || !altera}><DeleteForeverIcon/></IconButton>
                </Grid>
                <Grid item xs={3}>
                    <IconButton id={id} color="primary" onClick={AbrePdfImg} className={classes.botaopdfimg} disabled={!objeto.conteudo || objeto.conteudo.length<1} ><OpenInNewIcon/></IconButton>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <input type='file' id='filepdfimg' accept={tpaceito==='A' ? 'image/*' : tpaceito==='B' ? '.pdf' : '.pdf,image/*'} ref={inputFilePdfImg} className={classes.inputpdfimg} onChange={altera ? onChangePdfImg : undefined}/>
                    { !objeto.conteudo || objeto.conteudo.length<1 || !exibe ?
                        <img id={id} alt={'imagem'} src={objeto.conteudo && objeto.conteudo.length>0 ? objeto.tipo==='B' ? 'Imagens/PDF.png' : 'Imagens/maquinafotografica.png' : 'Imagens/vazio.png'} className={classes.img} onClick={altera ? onButtonClickPdfImg : undefined} />
                    :
                        objeto.tipo==='B' ?
                            <iframe id={id} title="PDF" src={RetornaUrlPdfImg(objeto.conteudo,objeto.tipo)} scrolling="auto" className={classes.pdf} onClick={altera ? onButtonClickPdfImg : undefined} ></iframe>
                        :
                            <img id={id} alt={'imagem'} src={'data:image/jpeg;base64, ' + objeto.conteudo} className={classes.img} onClick={altera ? onButtonClickPdfImg : undefined} />
                    }
                </Grid>
            </Grid>
        </div>
    );
}