import React from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { FilledInputProps, InputProps, OutlinedInputProps } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    campotexto: {
      margin: "2% 10%",
      width: "80%",
      height: '10px'
    },
    resize:{
      fontSize: '10px',
      height: '0px'
    },
  })
);


type DataProps=
{
  id: string,
  label: string,
  placeholder: string,
  required: boolean,
  classe: string,
  valor: string | number | Date | undefined,
  habilitado: boolean,
  limpadata?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>, campo?:string) => void),
  onchange: (date: any, retorno: string | null | undefined, campo?: string) =>void,
  onblur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => any,
  erro?: boolean,
  hora?: boolean,
  tamletra?: string,
  altura?: string,
  estilo?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps> 
}

export default function CampoData({id, label, placeholder, required, classe, valor, habilitado, onchange, onblur, erro, limpadata, hora, estilo, tamletra, altura }: DataProps) {

  const classes = useStyles();

  return (
        <MuiPickersUtilsProvider  utils={DateFnsUtils} locale={ptBR}>
          {hora ? 
            <KeyboardDateTimePicker
              style = {{fontSize: tamletra ? tamletra : undefined, boxShadow: "3px 3px 1px lightblue"}}
              ampm={false}
              error = {erro === undefined ? false : erro}
              className={classe ? classe : classes.campotexto}
              required={required}
              id={id}
              label={label}
              placeholder={placeholder}
              inputVariant="outlined"
              value={valor ? valor : null}
              disabled={!habilitado}
              onBlur={ onblur ? event => onblur(event) : undefined }
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
              'aria-label': 'change date',}}
              onDoubleClick={limpadata ? event => limpadata(event, id) : undefined}
              onChange={(dt, ret) => onchange(dt, ret, id)}
              InputLabelProps={{style: {fontSize: tamletra ? tamletra : undefined }}}
              InputProps={tamletra && altura ? {style: {fontSize: tamletra, maxHeight: altura}} : undefined}
            />
          :
            <KeyboardDatePicker
              style = {{fontSize: tamletra ? tamletra : undefined, boxShadow: "3px 3px 1px lightblue"}}
              error = {erro === undefined ? false : erro}
              className={classe ? classe : classes.campotexto}
              required={required}
              id={id}
              label={label}
              placeholder={placeholder}
              InputLabelProps={{style: {fontSize: tamletra ? tamletra : undefined }}}
              inputVariant="outlined"
              value={valor ? valor : null}
              disabled={!habilitado}
              onBlur={ onblur ? event => onblur(event) : undefined }
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
              'aria-label': 'change date',}}
              onDoubleClick={limpadata ? event => limpadata(event, id) : undefined}
              onChange={(dt, ret) => onchange(dt, ret, id)}
              InputProps={tamletra && altura ? {style: {fontSize: tamletra, maxHeight: altura}} : undefined}
            />
          }
        </MuiPickersUtilsProvider>
    );
}