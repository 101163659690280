import { Contratos } from "./Contratos";
import { Servicos_Clientes } from "./Servicos_Clientes";
import { Contatos_Cliente } from "./Contatos_Cliente";
import { Clientes_Valores_Grupos } from "./Clientes_Valores_Grupos";
import { Tabelas_Dados_Adicionais } from "./Tabelas_Dados_Adicionais";
import { Pessoas } from "./Pessoas";
import { Pontualidade, TitulosAbertos } from "./ObjetosDiversos";

export class Clientes
{
    id_cli: number = 0;
    pessoa: Pessoas = new Pessoas();
    id_reg: number = 0;
    id_vend: number = 0;
    id_stcl: number = 0;
    datacad_cli?: Date = undefined;
    limcred_cli: number = 0;
    inativo_cli: string = '';
    tiponfe_cli: string = '';
    id_cnvd: number = 0;
    id_rede: number = 0;
    loja_cli: number = 0;
    contratos: Contratos[] = [];
    servicos: Servicos_Clientes[] = [];
    contatos: Contatos_Cliente[] = [];
    valores_grupos: Clientes_Valores_Grupos[] = [];
    dados_adicionais: Tabelas_Dados_Adicionais[] = [];
    pontualidade: Pontualidade[] = [];
    abertos: TitulosAbertos[] = [];
}