import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Recursos } from '../Modelos/Recursos';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import SettingsApplicationsTwoToneIcon from '@material-ui/icons/SettingsApplicationsTwoTone';
import GroupAddTwoToneIcon from '@material-ui/icons/GroupAddTwoTone';
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import MoneyOffTwoToneIcon from '@material-ui/icons/MoneyOffTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import CropSquareTwoToneIcon from '@material-ui/icons/CropSquareTwoTone';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShoppingBasketTwoToneIcon from '@material-ui/icons/ShoppingBasketTwoTone';
import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
import StorefrontTwoToneIcon from '@material-ui/icons/StorefrontTwoTone';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';
import TrendingUpTwoToneIcon from '@material-ui/icons/TrendingUpTwoTone';
import { Grid, ListItemText, Menu, Tooltip, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DadosUsuario from '../Componentes/DadosUsuario';
import { Roteador } from '../Servicos/Roteador';
import { StyledMenuItem } from './Customizados';

const drawerWidth = 240;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    title: {
        width: "100%",
        margin: '15px 0 0 0',
        textAlign: 'left',
        cursor: 'pointer'
      },
    username: {
      width: "100%",
      margin: '15px 0 0 0',
      textAlign: 'right',
      cursor: 'pointer'
    },
    textouser: {
      textAlign: 'right'
    },
    avatar: {
      width: "100%",
      margin: '0 0 0 40%',
      cursor: 'pointer'
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      backgroundColor: theme.palette.primary.main,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    sectionDesktop: {
        display: 'none',
        marginRight: "2em",
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
    grow: {
        width: "100%",
        flexGrow: 1,
      },
    separador: {
      background: 'linear-gradient(to bottom,  #00BBFF 0%, #0000FF 100%)',
      borderRadius: '7px'
      },
    separadordesc: {
        color: "#FFFFFF",
        fontWeight: 'bold',
        fontSize: "1.2em"
      },
    itemmenu: {
      background: 'linear-gradient(to bottom,  #E0FFFF 0%, #F0FFFF 100%)',
      borderRadius: '7px'
    },
    itemmenudesc: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: "#1874CD",
        fontWeight: 'bold',
        fontSize: "1em"
      },
    iconeGrupo: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      fill: "#FFFFFF",
      fontSize: "2em",
    },
    icone: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fill: "#0000DD",
        fontSize: "2em",
      }
    }),
);

type BLProps=
{
  recurso?: string
  dadosacesso: RetornoAcesso
  clasrot: Roteador
  botaoclick: any
}

type CriaIconeProps =
{
  indicone: number,
  dica?: string
}

function CriaIcone({indicone, dica}: CriaIconeProps)
{
  const classes = useStyle();

  switch(indicone)
  {
    case 100:
      return (<SettingsApplicationsTwoToneIcon className={classes.iconeGrupo}/>)
    case 101:
      return (<Tooltip title={dica ? dica : ''} placement='top'><AccountCircleTwoToneIcon className={classes.icone}/></Tooltip>)
    case 107:
      return (<Tooltip title={dica ? dica : ''} placement='top'><DescriptionTwoToneIcon className={classes.icone}/></Tooltip>)
    case 113:
      return (<Tooltip title={dica ? dica : ''} placement='top'><StorefrontTwoToneIcon className={classes.icone}/></Tooltip>)
    case 200:
      return (<PeopleAltTwoToneIcon className={classes.iconeGrupo} />)
    case 202:
      return (<Tooltip title={dica ? dica : ''} placement='top'><GroupAddTwoToneIcon className={classes.icone} /></Tooltip>)
    case 209:
      return (<Tooltip title={dica ? dica : ''} placement='top'><ShoppingBasketTwoToneIcon className={classes.icone} /></Tooltip>)
    case 211:
      return (<Tooltip title={dica ? dica : ''} placement='top'><BusinessCenterTwoToneIcon className={classes.icone} /></Tooltip>)
    case 300:
      return (<MonetizationOnTwoToneIcon className={classes.iconeGrupo} />)
    case 303:
      return (<Tooltip title={dica ? dica : ''} placement='top'><AttachMoneyTwoToneIcon className={classes.icone} /></Tooltip>)
    case 304:
      return (<Tooltip title={dica ? dica : ''} placement='top'><MoneyOffTwoToneIcon className={classes.icone} /></Tooltip>)
    case 305:
      return (<Tooltip title={dica ? dica : ''} placement='top'><PaymentTwoToneIcon className={classes.icone} /></Tooltip>)
    case 306:
      return (<Tooltip title={dica ? dica : ''} placement='top'><AssignmentReturnIcon className={classes.icone} /></Tooltip>)
    case 308:
      return (<Tooltip title={dica ? dica : ''} placement='top'><AccountBalanceTwoToneIcon className={classes.icone} /></Tooltip>)
    case 310:
      return (<Tooltip title={dica ? dica : ''} placement='top'><ReceiptTwoToneIcon className={classes.icone} /></Tooltip>)
    case 312:
      return (<Tooltip title={dica ? dica : ''} placement='top'><LocalAtmTwoToneIcon className={classes.icone} /></Tooltip>)
    case 314:
      return (<Tooltip title={dica ? dica : ''} placement='top'><TrendingUpTwoToneIcon className={classes.icone} /></Tooltip>)
    case 999:
      return (<Tooltip title={dica ? dica : ''} placement='top'><ExitToAppTwoToneIcon className={classes.icone} /></Tooltip>)
    default:
      return (<Tooltip title={dica ? dica : ''} placement='top'><CropSquareTwoToneIcon className={classes.icone} /></Tooltip>)
  }
}


type CriaItemMenuProps =
{
  idgr: number,
  rcrs: Recursos[],
  itclck: any
}

function CriaItemMenu({idgr, rcrs, itclck}: CriaItemMenuProps): any
{
  const classes = useStyle();
  return (
    rcrs.map((recurso, index) => (
        <ListItem button key={idgr + recurso.id_rcrs} onClick={itclck.bind(null,recurso.id_rcrs)} className={classes.itemmenu}>
          <ListItemIcon ><CriaIcone indicone={idgr+recurso.id_rcrs} dica={recurso.desc_rcrs} /></ListItemIcon>
          <Typography className={classes.itemmenudesc}>{recurso.desc_rcrs}</Typography>
        </ListItem>)
      )
    )
}

export default function BarraLateral({dadosacesso, botaoclick, recurso, clasrot}: BLProps) {
  const classes = useStyle();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openmenu = Boolean(anchorEl);
  const [ddusu, setDduso] = React.useState('');
  const [avatar, setAvatar] = React.useState('data:image/jpeg;base64, ' + dadosacesso.foto);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const alteraFoto = () => {
    handleClose();
    setDduso('A');
  }

  const alteraSenha = () => {
    handleClose();
    setDduso('B');
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const fechaDadosUsuario = (retddusu: string, foto?: Uint32Array | undefined) =>
  {
    setDduso('');
    if (retddusu==='A')
    {
      if (clasrot)
      {
        clasrot.dadosacesso.foto=foto;
      }
      setAvatar('data:image/jpeg;base64, ' + foto)
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {
        ddusu.length>0 ?
        <DadosUsuario aberta={true} fecha={fechaDadosUsuario} dadosacesso={dadosacesso} idoper={ddusu==='A' ? 0 : dadosacesso.objac.id_oper} ></DadosUsuario>
        :
        null
      }
      <AppBar
        color={dadosacesso && dadosacesso.dadosconexao && dadosacesso.dadosconexao.homologa ? 'secondary' : 'primary'}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <Typography onClick={botaoclick.bind(null,0)} className={classes.title} variant="h6" noWrap>
                {'RCKY - Master Office 1.0'+(recurso ? ' - '+recurso : '')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography onClick={handleMenu} className={classes.username} variant="h6" noWrap>
                {dadosacesso.nome}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className={classes.avatar}
                edge='end'
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={handleMenu}
              >
                <Avatar alt={dadosacesso.nome} src={avatar} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openmenu}
                onClose={handleClose}
              >
                <StyledMenuItem onClick={alteraFoto}><ListItemIcon><PhotoCameraIcon/></ListItemIcon><ListItemText primary='Alterar foto'/></StyledMenuItem>
                <Divider style={{backgroundColor: 'blue'}} />
                <StyledMenuItem onClick={alteraSenha}><ListItemIcon><VpnKeyIcon/></ListItemIcon><ListItemText primary='Alterar senha'/></StyledMenuItem>
                <Divider style={{backgroundColor: 'blue'}} />
                <StyledMenuItem onClick={botaoclick.bind(null,999)}><ListItemIcon><ExitToAppTwoToneIcon/></ListItemIcon><ListItemText primary='Sair'/></StyledMenuItem>
              </Menu>              
            </Grid>
          </Grid>
          <div className={classes.sectionDesktop}>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon className={classes.iconeGrupo} /> : <ChevronLeftIcon className={classes.iconeGrupo} />}
          </IconButton>
        </div>
        <List>
          {dadosacesso.menu.map((grupo, index) => (
            <div key={index}>
              <ListItem key={grupo.id_grrc*100} className={classes.separador}>
                <ListItemIcon ><CriaIcone indicone={grupo.id_grrc*100} /></ListItemIcon>
                <Typography className={classes.separadordesc} >{grupo.desc_grrc}</Typography>
              </ListItem>
              <CriaItemMenu idgr={grupo.id_grrc * 100} rcrs={grupo.recursos} itclck={botaoclick} />
              <Divider></Divider>
            </div>
            ))}
          <ListItem button key={999} onClick={botaoclick.bind(null,999)} className={classes.itemmenu}>
            <ListItemIcon><CriaIcone indicone={999} dica={'Sair'} /></ListItemIcon>
            <Typography className={classes.itemmenudesc} >{'Sair'}</Typography>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}