export class Servicos_Clientes
{
    id_svcl: number=0;
    id_cli: number=0;
    id_serv: number=0;
    id_vend: number=0;
    data_svcl: Date = new Date();
    valor_svcl: number=0;
    basecom_svcl: number=0;
    obs_svcl: string = '';
    gravadoc: boolean = false;
    tipodoc_svcl: string = '';
    documento_svcl?: Uint32Array = undefined;
    id_dtrc: number = 0;
    alterado: string = '';
}

