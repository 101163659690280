import React,{ Component, ChangeEvent } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import TabelaBase from '../Componentes/TabelaBase';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { Iheaders, TrataResposta, Resposta, datas, RetornaPeriodos, RetornaDataAAAAMMDD, RetornaFornecedores, PagamentosObtidos, DifDias, formatarCampo } from '../Servicos/Utilidades';
import { makeStyles, Theme, createStyles, Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Button, IconButton, Card, CardContent, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FilterListIcon from '@material-ui/icons/FilterList'
import Periodo from '../Componentes/Periodo';
import SelecaoAuto from '../Componentes/SelecaoAuto';
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@material-ui/lab/Autocomplete';
import Progresso from '../Componentes/Progresso';
import AlertDialog from '../Componentes/Dialogo';

const useStyles = makeStyles((theme: Theme) => createStyles({
  griddados: {
    width: '100%',
    margin: '0 0 0 0'
  },
  divgrid: {
    width: '90%',
    margin: '5% 7% 0'
  },
  botao: {
    margin: '2% 2% 2% 2%',
    width: '90%'
  }
}));

let filtvenc: datas | undefined = RetornaPeriodos(10);
let filtpgto: datas | undefined = undefined;
let fornpesq: number=0;
let fornecedores: {id_forn: number, nome_forn: string} [];
let abrtini: boolean = true;

const AtualizaFiltro = (campo:string, valor: any) =>
{
  if(campo==='Vencimento')
  {
    filtvenc={dtini: valor.dtini, dtfin: valor.dtfin}
  }
  if(campo==='Pagamento')
  {
    filtpgto={dtini: valor.dtini, dtfin: valor.dtfin}
  }
}

const AreaFiltros = ({atufiltro, pagamentos}: {atufiltro: (obcl:boolean) => void, pagamentos: PagamentosObtidos[]}) =>
{
 
  const classes = useStyles();
  const [fornesc, setfornesc]=React.useState(fornpesq);
  const [aberto, setAberto]=React.useState(abrtini);

  let totalizadores: {tipo: string, titulo: string, cor: string, qtde: number, valor: number, descontos: number, acrescimos: number, indices: number[]}[]=
  [
    {tipo: 'B', titulo: 'Pagos', cor: '#1E90FF', qtde: 0, valor: 0, descontos: 0, acrescimos: 0, indices: []},
    {tipo: 'C', titulo: 'Cancelados', cor: '#FF1493', qtde: 0, valor: 0, descontos: 0, acrescimos: 0, indices: []},
    {tipo: 'A1', titulo: 'Vencidos', cor: 'red', qtde: 0, valor: 0, descontos: 0, acrescimos: 0, indices: []},
    {tipo: 'A2', titulo: 'A vencer', cor: '#228B22', qtde: 0, valor: 0, descontos: 0, acrescimos: 0, indices: []}
  ];

  let indtot: number;
  let hoje=new Date();
  hoje=new Date(hoje.getFullYear(),hoje.getMonth(),hoje.getDate(),0,0,0,0);
  let difd: number;

  pagamentos.forEach((pgt,idx) =>
  {
    indtot='ABC'.indexOf(pgt.comportamento_stpg);
    if (indtot>0)
    {
      totalizadores[indtot-1].qtde=totalizadores[indtot-1].qtde+1;
      totalizadores[indtot-1].valor=totalizadores[indtot-1].valor+pgt.valor_pgto;
      totalizadores[indtot-1].descontos=totalizadores[indtot-1].descontos+pgt.desconto_pgto;
      totalizadores[indtot-1].acrescimos=totalizadores[indtot-1].acrescimos+pgt.juros_pgto+pgt.multa_pgto;
    }
    else if(indtot===0)
    {
      difd=DifDias(hoje, pgt.vencto_pgto, true);
      if (difd<0)
      {
        totalizadores[2].qtde=totalizadores[2].qtde+1;
        totalizadores[2].valor=totalizadores[2].valor+pgt.valor_pgto;
        totalizadores[2].indices.push(pgt.id_pgto);
      }
      else
      {
        totalizadores[3].qtde=totalizadores[3].qtde+1;
        totalizadores[3].valor=totalizadores[3].valor+pgt.valor_pgto;
      }
    }
  })

  const ClickFiltro = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
  {
    atufiltro(false);
  }

  const onChangeFiltroAutoComplete = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined, campo?: string) =>
  {
      if(campo && value &&value[campo])
      {
        fornpesq=value[campo];
        setfornesc(value[campo])
      }
      else
      {
          if(campo)
          {
              fornpesq=0;
              setfornesc(0)
            }
      }
  }
  
  return (
    <div className={classes.divgrid}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Accordion expanded={aberto} style = {{boxShadow: "3px 3px 1px lightblue"}}>
            <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <Typography>Filtros</Typography>
              </Grid>
              <Grid item xs={5}>
                <Periodo capper='Vencimento' camporet='Vencimento' atualiza={AtualizaFiltro} perpad={10} dataspad={filtvenc ? filtvenc : {dtini: undefined, dtfin: undefined}} tipoper='ABC'/>
              </Grid>
              <Grid item xs={5}>
                <Periodo capper='Pagamento' camporet='Pagamento' atualiza={AtualizaFiltro} perpad={0} dataspad={filtpgto ? filtpgto : {dtini: undefined, dtfin: undefined}} tipoper='ABC'/>
              </Grid>
              <Grid item xs={1} >
                <IconButton onClick={() => {setAberto(!aberto); abrtini=!aberto}} style={{width: '40%', margin: '0 0 0 60%'}} > {aberto ? <ExpandLessIcon /> : <ExpandMoreIcon/> }</IconButton>
              </Grid>
            </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={7}>
                  <SelecaoAuto valor={fornesc} nome='id_forn' label='Fornecedor' classe='' conteudo={fornecedores} habilitado={true} colunas={{id: 'id_forn', desc: 'nome_forn'}} onchange={onChangeFiltroAutoComplete}/>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={(event) => ClickFiltro(event)} endIcon={<FilterListIcon/>}>Aplica Filtros</Button>
                </Grid>
                <Grid item xs={1}>
                </Grid>
                {pagamentos.length>0 
                ?
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Grid container spacing={1} style={{width: '100%'}}>
                        <Grid item xs={4}>
                          <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 80, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '3px 3px 1px lightblue'}} variant="outlined">
                            <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <Typography noWrap={true} style={{color: totalizadores[0].cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{totalizadores[0].titulo}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography noWrap={true} style={{color: totalizadores[0].cor, textAlign: 'right', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 3% 0 0'}}>{formatarCampo(totalizadores[0].qtde.toString(),'decimal',false)}</Typography>
                                </Grid>
                              </Grid>
                              <Divider/>
                              <Grid container spacing={2} className={classes.griddados}>
                                <Grid item xs={3}>
                                  <Typography style={{textAlign: 'right', color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }} >Principal</Typography>
                                  <Divider/>
                                  <Typography style={{textAlign: 'right', color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(totalizadores[0].valor.toString(),'decimal',true)}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography style={{textAlign: 'right', color:'#228B22', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Descontos</Typography>
                                  <Divider/>
                                  <Typography style={{textAlign: 'right', color:'#228B22', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(totalizadores[0].descontos.toString(),'decimal',true)}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography style={{textAlign: 'right', color:'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Acréscimos</Typography>
                                  <Divider/>
                                  <Typography style={{textAlign: 'right', color:'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(totalizadores[0].acrescimos.toString(),'decimal',true)}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography style={{textAlign: 'right', color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Recebido</Typography>
                                  <Divider/>
                                  <Typography style={{textAlign: 'right', color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(((totalizadores[0].valor+totalizadores[0].acrescimos)-totalizadores[0].descontos).toString(),'decimal',true)}</Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={8}>
                          <Grid container spacing={1} style={{width: '100%'}}>
                            {totalizadores.filter(ttf => ttf.tipo!=='B').map((tt, idx) => 
                              {
                                return (
                                  <Grid item xs={4} key={idx}>
                                    <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 80, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '3px 3px 1px lightblue'}} variant="outlined">
                                      <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                        <Grid container spacing={1}>
                                          <Grid item xs={6}>
                                            <Typography noWrap={true} style={{color: tt.cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{tt.titulo}</Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                          </Grid>
                                        </Grid>
                                        <Divider/>
                                        <Grid container spacing={2} className={classes.griddados}>
                                          <Grid item xs={4}>
                                            <Typography style={{color: 'darkblue', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }} >Qtde.</Typography>
                                            <Divider/>
                                            <Typography style={{color: 'darkblue', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(tt.qtde.toString(),'decimal',false)}</Typography>
                                          </Grid>
                                          <Grid item xs={8}>
                                            <div style={{textAlign: 'right'}}>
                                              <Typography style={{color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Valor</Typography>
                                              <Divider/>
                                              <Typography style={{color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(tt.valor.toString(),'decimal')}</Typography>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                              )}
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                :
                  null}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  )
};

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  registros: PagamentosObtidos[],
  open: boolean,
  mensagem: string,
  duracao: number,
  severidade: string,
  dialogo: boolean
}

const srv=new ApiService();
const headers: Iheaders[] = [{titulo: 'Id', alinhamento:'right', coluna: 'id_pgto', mascara:''}, {titulo: 'Situacao', alinhamento:'center', coluna: 'desc_stpg', mascara:''}, { titulo: 'Id Fornecedor', alinhamento: 'right', coluna: 'id_forn', mascara:'' }, { titulo: 'Fornecedor', alinhamento: 'left', coluna: 'nome_pessoa', mascara:'' }, { titulo: 'Valor', alinhamento: 'right', coluna: 'valor_pgto', mascara:'decimal' }, { titulo: 'Vencto.', alinhamento: 'center', coluna: 'vencto_pgto', mascara:'data' }, { titulo: 'Pagto.', alinhamento: 'center', coluna: 'pagto_pgto', mascara:'data' }]

class Pagamentos extends Component<PropsType, IState>
{

  private _dadosobt: RetornoAcesso;
  private _roteador: Roteador;
  private _adicao=false;
  private _edicao=false;
  private _exclusao=false;
  private _idpgtoexc=0;

  constructor(props: any)
  {
    super(props);

    this._dadosobt=new RetornoAcesso();

    if (props.location.state)
    {
      if(props.location.state.dadosret)
      {
        this._dadosobt=props.location.state.dadosret;
      }
    }

    if (!this._dadosobt.ok)
    {
      this.props.history.push({pathname: '/'})
    }

    this._roteador=new Roteador(this._dadosobt, '/Pagamentos');
    this._roteador.history=props.history;
    this.state =
    {
      dadoobtido: false,
      registros: [],
      open: false,
      mensagem: '',
      duracao: 2000,
      severidade: '',
      dialogo: false
    }
    if (this._dadosobt.ok)
    {
      if (this._dadosobt.objac.tipoacesso_perf==='A')
      {
        this._adicao=true;
        this._edicao=true;
        this._exclusao=true;
      }
      else
      {
        let fnrc = this._dadosobt.objac.funcoes.filter(funcao => funcao.id_rcrs===4);
        this._edicao=fnrc.some(funcao => funcao.id_fnrc===12)
        this._adicao=fnrc.some(funcao => funcao.id_fnrc===11)
        this._exclusao=fnrc.some(funcao => funcao.id_fnrc===13)
      }
      this.ObtemPagamentos(true);
    }
  }

  ObtemPagamentos(obcl: boolean)
  {
    srv.Get('Pagamentos/2,3,4/'+fornpesq.toString()+','+RetornaDataAAAAMMDD(filtvenc?.dtini)+','+RetornaDataAAAAMMDD(filtpgto?.dtini)+'/'+fornpesq.toString()+','+RetornaDataAAAAMMDD(filtvenc?.dtfin)+','+RetornaDataAAAAMMDD(filtpgto?.dtfin)+'/A', '', '', this._dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let dadosret: Resposta = TrataResposta(ddret);
      if (dadosret.ok)
      {
        let listapag=dadosret.retorno;
        if (obcl)
        {
          srv.Get('Fornecedores/', '', '', this._dadosobt.objac.token, false)
          .then((ddret: any) => 
          {
            dadosret = TrataResposta(ddret);
            if(dadosret.ok)
            {
              fornecedores=RetornaFornecedores(dadosret.retorno);
              this.setState({registros: listapag, dadoobtido: true});
            }
            else
            {
              this.setState( {open: true, severidade: 'error', duracao: 6000, mensagem: 'Erro obtendo fornecedores (' + dadosret.erro + ')' }); 
            }
          })
          .catch(Error => this.setState( {open: true, severidade: 'error', duracao: 6000, mensagem: 'Erro acessando a API - obtendo fornecedores (' + Error.name + ' - ' + Error.message + ')' }));
        }
        else
        {
          this.setState({registros: listapag});
        }
      }
      else
      {
        this.setState({open: true, mensagem: 'Erro: Erro obtendo dados de pagamentos (' + dadosret.erro + ')', duracao: 5000, severidade: 'error' })          
      }
    })
    .catch(Error => this.setState({open: true, mensagem: 'Erro acessando a API - obtendo dados de pagamentos (' + Error.name + ' - ' + Error.message + ')', duracao: 5000, severidade: 'error' }))
  }

  ExibeMensagem(mensagem: string, severidade: string, duracao:number)
  {
    this.setState({open: true, severidade: severidade, mensagem: mensagem, duracao: duracao})
  }

  FechaMensagem()
  {
    this.setState({open: false});
  }

  RetornaTabela(aux: number, id: number)
  {
    if(aux===995)
    {
      this._roteador.rota(aux, id);
    }
    else if (aux===9950)
    {
      this._idpgtoexc=id;
      this.setState({dialogo:true});
    }
  }

  ExcluiPagamento(retorno: string)
  {
    this.setState({dialogo: false});
    if (this._idpgtoexc>0 && retorno==='A')
    {
      srv.Delete('Pagamentos/'+(this._idpgtoexc.toString().trim()), '', '', this._dadosobt.objac.token, false)
      .then((ddret: any) => 
      {
        let dadosret:Resposta = TrataResposta(ddret);
        if(dadosret.ok)
        {
          this.ExibeMensagem('Pagamento '+dadosret.retorno.id.toString().trim()+' excluído com sucesso','info',6000)
          this._idpgtoexc=0;
          this.ObtemPagamentos(false);
        }
        else
        {
          this.ExibeMensagem('Erro excluindo recebimento (' + dadosret.erro + ')','error', 6000); 
          this._idpgtoexc=0;
        }
      })
      .catch(Error => {this.ExibeMensagem('Erro acessando a API - excluindo recebimento (' + Error.name + ' - ' + Error.message + ')','error',6000); this._idpgtoexc=0;});
    }
  }

  render()
  {
    if (!this._dadosobt.ok)
    {
      return null
    }
    else
    {
      return (
        <div className="Home">
          <header className="Home-header">
              <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador}/>
          </header>
          <main>
              {this.state.dadoobtido ?
                <div>
                  <AlertDialog aberta={this.state.dialogo} fecha={this.ExcluiPagamento.bind(this)} titulo={'Exclusão de pagamento'} texto={['Após a confirmação não será possível reverter esta operação','Confirma a operação?']} botoes={[{descricao: 'Confirma', retorno: 'A'},{descricao: 'Cancela', retorno: 'B'}]}/>
                  <AreaFiltros atufiltro={this.ObtemPagamentos.bind(this)} pagamentos={this.state.registros} />
                  <TabelaBase linhasoriginais={this.state.registros} cab={headers} selecao={false} edicao={this._edicao} adicao={this._adicao} habfiltro={true} onClickEdicao={this.RetornaTabela.bind(this)} titulo={'Pagamentos'} full={false} exclusao={this._exclusao} auxedit={995} auxexclui={9950} classe='filtro' colcnc='desc_stpg' cncvlr='CANCELADO' />
                </div>
                :
                <div>
                  <Progresso/>
                </div>
              }
          </main>
          <Toast open = {this.state.open} handleClose = {this.FechaMensagem.bind(this)} severity = {this.state.severidade} duracao= {this.state.duracao}>{this.state.mensagem}</Toast>
        </div>
      );
    }
  }
}

export default withRouter(Pagamentos)
