import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { CenterMap, ChaveApiGoogle, LocalizacaoRCKY } from '../Servicos/Utilidades';
import Geocode from "react-geocode";
import { Endereco_Pessoas } from '../Modelos/Endereco_Pessoas';
import { Typography } from '@material-ui/core';


Geocode.setApiKey(ChaveApiGoogle);
Geocode.setLanguage('pr-BR');
Geocode.setRegion('br');

const containerStyle = {
  width: '100%',
  height: '400px',
  boxShadow: "3px 3px 1px lightblue"
};

type MapaProps = 
{
  endereco: Endereco_Pessoas,
  zoom?: number,
  titulo?: string,
  retgeo?: (latlong: CenterMap) => void
  icone?: string
}

let errmsg: string ='';
let ultend: string ='*';

function MapaGoogle({ endereco, zoom, titulo, retgeo, icone}: MapaProps) {

  const [center,setCenter] = React.useState<CenterMap | undefined>(endereco.latitude_enps && endereco.latitude_enps!==0 && endereco.longitude_enps && endereco.longitude_enps!==0  ? {lat: endereco.latitude_enps, lng: endereco.longitude_enps} : undefined);

  const RetornaCentro = (end: Endereco_Pessoas) =>
  {
    let endret: string=''
    if (end.endereco.logr_end && end.endereco.logr_end.trim().length>0 && end.numero_end && end.numero_end.trim().length>0 && end.endereco.cidade && end.endereco.cidade.nome_cid && end.endereco.cidade.nome_cid.trim().length>0)
    {
      endret=end.endereco.logr_end.trim()+','+end.numero_end.trim()+' '+(end.endereco.bairro_end ? end.endereco.bairro_end.trim()+' ' : '')+end.endereco.cidade.nome_cid.trim()+(end.endereco.cidade.uf ? end.endereco.cidade.uf.sigla_uf ? ' '+end.endereco.cidade.uf.sigla_uf : '' : '')
      if (ultend!==endret)
      {
        ultend=endret;
        if (end.latitude_enps===0 && end.longitude_enps===0)
        {
          Geocode.fromAddress(endret).then(
            response => {
              const centerobt: CenterMap = response.results[0].geometry.location;
              setCenter(centerobt);
              if (retgeo)
              {
                retgeo(centerobt);
              }
            },
            error => {
              errmsg=error;
            }
          );
        }
        else
        {
          setCenter({lat: end.latitude_enps, lng: end.longitude_enps});
        }
      }
    }
    else
    {
      if(ultend.length>0 || !center)
      {
        ultend=''
        setCenter(LocalizacaoRCKY);
      }
    }
  }
  
  RetornaCentro(endereco);

  return (
    center ?
        <LoadScript googleMapsApiKey={ChaveApiGoogle}>
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom ? zoom : 10}>
            {center.lat!==LocalizacaoRCKY.lat || center.lng!==LocalizacaoRCKY.lng ?
            icone ? 
            <Marker options={{icon: icone}} title={titulo ? titulo : ''} position= {center}/> 
            :
            <Marker title={titulo ? titulo : ''} position= {center}/>
            : null}
            <Marker options={{icon: 'Imagens/RCKY64.ico'}} title={'RCKY'} position= {LocalizacaoRCKY}/>
          </GoogleMap>
        </LoadScript>
      :
      <Typography>{errmsg}</Typography>
  )
}

export default React.memo(MapaGoogle)