import React,{ Component, RefObject, useRef } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import TabelaBase from '../Componentes/TabelaBase';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { Iheaders, TrataResposta, Resposta, arrayBufferToBase64, nulltoNumber } from '../Servicos/Utilidades';
import { makeStyles, Theme, createStyles, Grid, Button, } from '@material-ui/core';
import Progresso from '../Componentes/Progresso';
import { Redes } from '../Modelos/Redes';
import AlertDialog from '../Componentes/Dialogo';
import CampoTexto from '../Componentes/CampoTexto';
import SaveIcon from '@material-ui/icons/Save';
import Selecao from '../Componentes/Selecao';
import { Tipos_Contrato } from '../Modelos/Tipos_Contrato';

const useStyles = makeStyles((theme: Theme) => createStyles({
  divgrid: {
    width: '90%',
    margin: '5% 7% 0'
  },
  botao: {
    margin: '5% 2% 2% 2%',
    width: '90%'
  },
  textoform: {
    margin: "0% 0%",
    width: "100%",
    background: 'white'
  },
  icone: {
    width: "98%",
    maxHeight: '50px',
    minHeight: '50px',
    maxWidth: '50%',
    minWidth: '50%',
    margin: "1% 25% 0 25%",
    boxShadow: "2px 2px 5px lightgrey"
  },
  iconeinput: {
    display: "none"
  }
}));

const srv=new ApiService();

let indctexc: number = 0;

const EdicaoRedes = ({dadosobt, mensagem,  rds, tpcs, inclusao, alteracao, exclusao}: {dadosobt: RetornoAcesso,  mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void, rds: Redes[], tpcs: Tipos_Contrato[], inclusao: boolean, alteracao: boolean, exclusao:boolean}) =>
{

  const [dialogo,setaDialogo] = React.useState(false);
  const [rede, setRede] = React.useState(new Redes());
  const [indrede, setIndrede] = React.useState(0);
  const [redes, setRedes] = React.useState(rds);
  
  const classes = useStyles();

  const inputFileIcone: RefObject<HTMLInputElement> = useRef(null) ;

  const onButtonClickIcone = (event:any) => 
  {
    if(event.ctrlKey)
    {
      setRede({...rede, icone_rede: undefined});
    }
    else
    {
      if (inputFileIcone.current)
      {
        inputFileIcone.current.click()
      }
    }
  };

  const onChangeIcone = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
  {
    const filereader = new FileReader();
    const doc: any = document.getElementById('fileicone');
    if(doc)
    {
      if(doc.files)
      {
        if(doc.files.length>0)
        {
          filereader.readAsArrayBuffer(doc.files[0]);
        }
      }
    }
    filereader.onloadend = function()
    {
      let result: any = filereader.result
      setRede({...rede, icone_rede: arrayBufferToBase64(result)});
    };
  }

  const AtualizaTipoContrato = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {name?: string | undefined; value: unknown;}>, valor:string | number | Date | React.ReactNode, campo?: string | undefined) => 
  {
    if (campo)
    {
      if (campo==='nome_rede' && typeof valor==='string')
      {
        setRede({...rede, nome_rede: valor});
      }
      else if (campo==='id_tpct')
      {
        if (typeof event.target.value==='string')
        {
          setRede({...rede, id_tpct:  nulltoNumber(parseInt(event.target.value))});
        }
      }
    }
  }

  const confirmaExclusao = (resposta: string) =>
  {
    setaDialogo(false);
    if (resposta==='A')
    {
      if (indctexc>0 && redes.length>=indctexc)
      {
        let novarede: Redes = new Redes();
        novarede.id_rede=redes[indctexc-1].id_rede
        novarede.nome_rede='';
        ConfirmaOperacao(novarede);
      }
    }
  }
  
  const editaTipo = (operacao: number, registro: number) =>
  {

    if (operacao===1)
    {
      if (registro>0 && redes.length>=registro)
      {
        setRede(redes[registro-1]);
        setIndrede(registro);
      }
      else
      {
        setRede(new Redes());
        setIndrede(0);
      }
    }
    else if(operacao===2)
    {
      indctexc=registro;
      setaDialogo(true);
    }
  }

  const VerificaCampos = () =>
  {
    if (!rede.nome_rede || rede.nome_rede.trim().length<0)
    {
      mensagem('Existem dados incorretos. Verifique, por gentileza','error',5000);
    }
    else
    {
      ConfirmaOperacao(rede);
    }
  }

  const ConfirmaOperacao = (objrede: Redes) =>
  {
    srv.Put('Redes/','',JSON.stringify(objrede), dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let dadosret: Resposta = TrataResposta(ddret);
      if (dadosret.ok)
      {
        if (objrede.nome_rede.trim().length<1)
        {
          rds = redes.filter(rd => rd.id_rede!==objrede.id_rede);
          setRedes(rds);
        }
        else
        {
          rds=[];
          rds=rds.concat(redes);
          if (objrede.id_rede===0)
          {
            let novarede: Redes = {
              id_rede: dadosret.retorno.id,
              nome_rede: objrede.nome_rede,
              icone_rede: objrede.icone_rede,
              id_tpct: objrede.id_tpct
            }
            rds.push(novarede);
            setRedes(rds);
          }
          else
          {
            if (indrede>0 && redes.length>=indrede)
            {
              rds[indrede-1].nome_rede=objrede.nome_rede;
              rds[indrede-1].icone_rede=objrede.icone_rede;
              rds[indrede-1].id_tpct=objrede.id_tpct;
              setRedes(rds);
            }
          }
        }
        mensagem('Rede '+(objrede.nome_rede.trim().length<1 ? 'excluida' : objrede.nome_rede.trim()+' salva')+' com sucesso', 'info', 5000);
        editaTipo(1,0);
      }
      else
      {
        mensagem('Erro: Erro '+(objrede.nome_rede.trim().length<1 ? 'excluindo' : 'salvando')+' rede (' + dadosret.erro + ')', 'error', 5000);
      }
    })
    .catch(Error => mensagem('Erro: Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', 'error', 5000))
  }

  const headers: Iheaders[] = [{titulo: 'Nº', alinhamento:'right', coluna: '*index*', mascara:''}, { titulo: 'Id', alinhamento: 'right', coluna: 'id_rede', mascara: '' }, { titulo: 'Nome', alinhamento: 'left', coluna: 'nome_rede', mascara: '' }, {titulo: 'Tipo de contrato', alinhamento:'right', coluna: 'id_tpct', mascara:''}, { titulo: '=', alinhamento: 'center', coluna: 'icone_rede', mascara: 'A' }]

  return (
    <div className={classes.divgrid}>
      <AlertDialog aberta={dialogo} fecha={confirmaExclusao} titulo={'Exclusão de tipo de contrato'} texto={['Confirma a exclusão deste tipo de contrato?']} botoes={[{descricao: 'Confirma', retorno:'A'},{descricao: 'Desiste', retorno:'B'}]}/>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <CampoTexto valor={rede.id_rede>0 ? rede.id_rede : 0}  id='id_rede' label='Id' placeholder='Id da rede' classe={classes.textoform} tipo='number' required={false} habilitado={false}/>
        </Grid>
        <Grid item xs={6}>
          <CampoTexto valor={rede.nome_rede} id='nome_rede' label='Nome' placeholder='Nome da rede' classe={classes.textoform} tipo='text' required={true} habilitado={true} onchange={AtualizaTipoContrato} erro={rede.nome_rede.trim().length<1} tamanho={60} />
        </Grid>
        <Grid item xs={2}>
          <Selecao valor={rede.id_tpct} nome='id_tpct' label='Tipo de contrato' required={false} habilitado={true} onchange={AtualizaTipoContrato} conteudo={ tpcs } nulo={[0,'Indefinido']} classe={classes.textoform} erro={false} />
        </Grid>
        <Grid item xs={1}>
          <input type='file' id='fileicone' accept='image/*' ref={inputFileIcone} className={classes.iconeinput} onChange={onChangeIcone}/>
          <img id='foto_pessoa' alt='iconerede' src={rede.icone_rede && rede.icone_rede.length>0 ? 'data:image/jpeg;base64, ' + rede.icone_rede : 'Imagens/maquinafotografica.png'}  className={classes.icone} onClick={onButtonClickIcone} />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" disabled={(rede.id_rede!==0 && !alteracao) || (rede.id_rede===0 && !inclusao)} className={classes.botao} onClick={VerificaCampos} endIcon={<SaveIcon/>}>Salvar</Button>
        </Grid>
        <Grid item xs={12}>
          <TabelaBase linhasoriginais={redes} cab={headers} selecao={false} edicao={alteracao} adicao={inclusao} habfiltro={false} onClickEdicao={editaTipo} titulo={'Redes'} full={true} exclusao={exclusao} auxedit={1} auxexclui={2} linhasiniciais={15} />
        </Grid>
      </Grid>
    </div>
  )
};

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  registros: Redes[],
  tiposcontr: Tipos_Contrato[],
  open: boolean,
  mensagem: string,
  duracao: number,
  severidade: string
}

class CadastroRedes extends Component<PropsType, IState>
{

  private _dadosobt: RetornoAcesso;
  private _roteador: Roteador;
  private _adicao: boolean;
  private _edicao: boolean;
  private _exclusao: boolean;

  constructor(props: any)
  {
    super(props);

    this._dadosobt=new RetornoAcesso();

    if (props.location.state)
    {
      if(props.location.state.dadosret)
      {
        this._dadosobt=props.location.state.dadosret;
      }
    }

    if (!this._dadosobt.ok)
    {
      this.props.history.push({pathname: '/'})
    }

    this._roteador=new Roteador(this._dadosobt, '/TiposContrato');
    this._roteador.history=props.history;
    this.state =
    {
      dadoobtido: false,
      registros: [],
      tiposcontr: [],
      open: false,
      mensagem: '',
      duracao: 2000,
      severidade: ''
    }

    this._adicao=false;
    this._edicao=false;
    this._exclusao=false;

    if (this._dadosobt.ok)
    {
      if (this._dadosobt.objac.tipoacesso_perf==='A')
      {
        this._adicao=true;
        this._edicao=true;
        this._exclusao=true;
      }
      else
      {
        let fnrc = this._dadosobt.objac.funcoes.filter(funcao => funcao.id_rcrs===13);
        this._adicao=fnrc.some(funcao => funcao.id_fnrc===55)
        this._edicao=fnrc.some(funcao => funcao.id_fnrc===56)
        this._exclusao=fnrc.some(funcao => funcao.id_fnrc===57)
      }
      this.ObtemRedes();
    }
  }

  ObtemRedes()
  {
    srv.Get('Redes', '', '', this._dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let redesret: Resposta = TrataResposta(ddret);
      if (redesret.ok)
      {
        srv.Get('TiposContrato', '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
          let dadosret: Resposta = TrataResposta(ddret);
          if (dadosret.ok)
          {
            let retorno=dadosret.retorno;
            this.setState({registros: redesret.retorno, tiposcontr: dadosret.retorno, dadoobtido: true});
          }
          else
          {
            this.setState({open: true, mensagem: 'Erro: Erro obtendo dados de Tipos de contratos (' + dadosret.erro + ')', duracao: 5000, severidade: 'error' })
          }
        })
        .catch(Error => this.setState({open: true, mensagem: 'Erro: Erro acessando a API (obtendo tipos de contratos) (' + Error.name + ' - ' + Error.message + ')', duracao: 5000, severidade: 'error' }))
      }
      else
      {
        this.setState({open: true, mensagem: 'Erro: Erro obtendo dados de redes (' + redesret.erro + ')', duracao: 5000, severidade: 'error' })
      }
    })
    .catch(Error => this.setState({open: true, mensagem: 'Erro: Erro acessando a API (obtendo redes) (' + Error.name + ' - ' + Error.message + ')', duracao: 5000, severidade: 'error' }))
  }

  ExibeMensagem(mensagem: string, severidade: string, duracao:number)
  {
    this.setState({open: true, severidade: severidade, mensagem: mensagem, duracao: duracao})
  }

  FechaMensagem()
  {
    this.setState({open: false});
  }

  render()
  {
    if (!this._dadosobt.ok)
    {
      return null
    }
    else
    {
      return (
        <div className="Home">
          <header className="Home-header">
              <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador}/>
          </header>
          <main>
              {this.state.dadoobtido ?
                <div>
                  <EdicaoRedes dadosobt={this._dadosobt} mensagem={this.ExibeMensagem.bind(this)} rds={this.state.registros} tpcs={this.state.tiposcontr} inclusao={this._adicao} alteracao={this._edicao} exclusao={this._exclusao} />
                </div>
                :
                <div>
                  <Progresso/>
                </div>
              }
          </main>
          <Toast open = {this.state.open} handleClose = {this.FechaMensagem.bind(this)} severity = {this.state.severidade} duracao= {this.state.duracao}>{this.state.mensagem}</Toast>
        </div>
      );
    }
  }
}

export default withRouter(CadastroRedes)
