import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@material-ui/lab/Autocomplete';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    campo: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
  }})
);

type AutoProps=
{
  nome: string,
  label: string,
  classe: string,
  valor?: any,
  habilitado: boolean,
  conteudo: any [],
  colunas: {id: string, desc: string},
  erro?: boolean,
  onchange: ((event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason,  details?: AutocompleteChangeDetails<any> | undefined, campo?: string) => void),
  ident?: string,
  multiplo?: boolean
}

export default function SelecaoAuto({nome, label, classe, valor, conteudo, habilitado, colunas, erro, onchange, ident, multiplo}: AutoProps) {

  const classes=useStyles();

  const achaItem = (id: number | number[]) =>
  {
      let clesc:any[] = [];
      let proc: number[] = typeof id==='number' ? [id] : id;
      if(conteudo)
      {
        proc.forEach(vl => {
          let indice=conteudo.findIndex((item:any) => item[colunas.id]===vl)
          if(indice>=0)
          {
              clesc.push(conteudo[indice]);
          }
        })
      }
      return multiplo ? clesc : clesc.length>0 ? clesc[0] : [];
  }

  return (
    <Autocomplete
      multiple={multiplo}
      limitTags={1}
      style = {{boxShadow: "3px 3px 1px lightblue"}}
      className={classe ? classe : classes.campo}
      id={ident ? ident : nome}
      options={conteudo}
      getOptionLabel={(option) => {return option[colunas.desc] ? option[colunas.desc] : ''}}
      onChange={(event, value, reason, details) => onchange(event, value , reason, details, nome)}
      disabled={!habilitado}
      value={achaItem(valor)}
      ChipProps={{style: {backgroundColor: '#BFEFFF', maxWidth: '70%'}}}
      renderInput={(params) => <TextField {...params} label={label} placeholder={label} error={erro} variant="outlined" />}
    />    
  );
}

