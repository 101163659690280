import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { LinearProgressProps, LinearProgress } from '@material-ui/core';

export default function ProgressoEtiqueta(props: CircularProgressProps & LinearProgressProps & { value: number, tipo: string }) {

    return (
        <div>
            {props.tipo==='A' ?
                <Box position="relative" display="inline-flex" width='100%' margin="0 50%">
                <CircularProgress variant="static" {...props} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    margin="0 0 0 5px"
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="primary">{`${Math.round(props.value,)}%`}</Typography>
                </Box>
                </Box>
            
            :
                <Box display="flex" alignItems="center">
                <Box width="100%" margin='20px 0 0 0' mr={1}>
                <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box minWidth={35} margin='20px 0 0 0'>
                <Typography variant="body2" color="primary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
                </Box>
                </Box>
            }
        </div>
    );
}