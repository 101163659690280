import {Enderecos} from '../Modelos/Enderecos';

export class Endereco_Pessoas
{
    id_enps: number = 0;
    id_pessoa: number = 0;
    id_tpen: number = 0;
    numero_end: string = '';
    compl_end: string = '';
    padrao_enps: string = '';
    latitude_enps: number = 0;
    longitude_enps: number = 0;
    endereco: Enderecos = new Enderecos();
    alterado: string = '';
}