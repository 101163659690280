import React,{ Component } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import TabelaBase from '../Componentes/TabelaBase';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { Iheaders, TrataResposta, Resposta, AlteraPadroesContrato } from '../Servicos/Utilidades';
import { makeStyles, Theme, createStyles, Grid, Button } from '@material-ui/core';
import Progresso from '../Componentes/Progresso';
import { Tipos_Contrato } from '../Modelos/Tipos_Contrato';
import AlertDialog from '../Componentes/Dialogo';
import CampoTexto from '../Componentes/CampoTexto';
import Selecao from '../Componentes/Selecao';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme: Theme) => createStyles({
  divgrid: {
    width: '90%',
    margin: '5% 7% 0'
  },
  botao: {
    margin: '2% 2% 2% 2%',
    width: '90%'
  },
  textoform: {
    margin: "0% 0%",
    width: "100%",
    background: 'white'
  }
}));

const srv=new ApiService();

let indctexc: number = 0;

const EdicaoTiposContratos = ({dadosobt, mensagem,  tiposcontr, inclusao, alteracao, exclusao}: {dadosobt: RetornoAcesso,  mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void, tiposcontr: Tipos_Contrato[], inclusao: boolean, alteracao: boolean, exclusao:boolean}) =>
{

  const [dialogo,setaDialogo] = React.useState(false);
  const [tipocont, setTipocont] = React.useState(new Tipos_Contrato());
  const [indtipocont, setIndtipocont] = React.useState(0);
  const [tiposcontrato, setTiposcontrato] = React.useState(tiposcontr);
  
  const classes = useStyles();

  const AtualizaTipoContrato = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {name?: string | undefined; value: unknown;}>, valor:string | number | Date | React.ReactNode, campo?: string | undefined) => 
  {
      if (campo)
      {
          let novotpcon: Tipos_Contrato = JSON.parse(JSON.stringify(tipocont));

          if ((campo==='desc_tpct' || campo==='conteudo_tpct') && typeof valor==='string')
          {
            novotpcon[campo]=valor;
          }
          else if (campo==='situacao_tpct' && event.target.value)
          {
              novotpcon.situacao_tpct=typeof event.target.value === 'string' ? event.target.value : ' ';
          }
          setTipocont(novotpcon);
      }
  }

  const confirmaExclusao = (resposta: string) =>
  {
    setaDialogo(false);
    if (resposta==='A')
    {
      if (indctexc>0 && tiposcontrato.length>=indctexc)
      {
        let novotpcon: Tipos_Contrato = new Tipos_Contrato();
        novotpcon.id_tpct=tiposcontrato[indctexc-1].id_tpct
        novotpcon.desc_tpct='';
        ConfirmaOperacao(novotpcon);
      }
    }
  }
  
  const editaTipo = (operacao: number, registro: number) =>
  {

    if (operacao===1)
    {
      if (registro>0 && tiposcontrato.length>=registro)
      {
        srv.Get('TiposContrato/'+(tiposcontrato[registro-1].id_tpct.toString().trim()), '', '', dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
          let dadosret: Resposta = TrataResposta(ddret);
          if (dadosret.ok)
          {
            if (dadosret.retorno.id_tpct===0)
            {
              mensagem('Tipo de contrato não encontrado no banco de dados','error',5000);
            }
            else
            {
              setTipocont(dadosret.retorno);
              setIndtipocont(registro);
            }
          }
          else
          {
            mensagem('Erro: Erro obtendo dados de Recebimentos (' + dadosret.erro + ')', 'error', 5000)          
          }
        })
        .catch(Error => mensagem('Erro: Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', 'error', 5000))
      }
      else
      {
        setTipocont(new Tipos_Contrato());
        setIndtipocont(0);
      }
    }
    else if(operacao===2)
    {
      indctexc=registro;
      setaDialogo(true);
    }
  }

  const VerificaCampos = () =>
  {
    if (!tipocont.desc_tpct || tipocont.desc_tpct.trim().length<0 || !tipocont.situacao_tpct || 'AB'.indexOf(tipocont.situacao_tpct)===-1)
    {
      mensagem('Existem dados incorretos. Verifique, por gentileza','error',5000);
    }
    else
    {
      ConfirmaOperacao(tipocont);
    }
  }

  const ConfirmaOperacao = (objtpcont: Tipos_Contrato) =>
  {
    srv.Put('TiposContrato/','',JSON.stringify(objtpcont), dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let dadosret: Resposta = TrataResposta(ddret);
      if (dadosret.ok)
      {
        if (objtpcont.desc_tpct.trim().length<1)
        {
          tiposcontr = tiposcontrato.filter(tpc => tpc.id_tpct!==objtpcont.id_tpct);
          setTiposcontrato(tiposcontr);
        }
        else
        {
          tiposcontr=[];
          tiposcontr=tiposcontr.concat(tiposcontrato);
          if (objtpcont.id_tpct===0)
          {
            let novotpcon: Tipos_Contrato = {
              id_tpct: dadosret.retorno.id,
              desc_tpct: objtpcont.desc_tpct,
              conteudo_tpct: '',
              situacao_tpct: objtpcont.situacao_tpct==='A' ? 'Ativo' : 'Inativo',
              exclusao_tpct: true
            }
            tiposcontr.push(novotpcon);
            setTiposcontrato(tiposcontr);
          }
          else
          {
            if (indtipocont>0 && tiposcontrato.length>=indtipocont)
            {
              tiposcontr[indtipocont-1].desc_tpct=objtpcont.desc_tpct;
              tiposcontr[indtipocont-1].situacao_tpct=(objtpcont.situacao_tpct==='A' ? 'Ativo' : 'Inativo');
              setTiposcontrato(tiposcontr);
            }
          }
        }
        mensagem('Tipo de contrato '+(objtpcont.desc_tpct.trim().length<1 ? 'excluido' : objtpcont.desc_tpct.trim()+' salvo')+' com sucesso', 'info', 5000);
        editaTipo(1,0);
      }
      else
      {
        mensagem('Erro: Erro '+(objtpcont.desc_tpct.trim().length<1 ? 'excluindo' : 'salvando')+' tipo de contrato (' + dadosret.erro + ')', 'error', 5000);
      }
    })
    .catch(Error => mensagem('Erro: Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', 'error', 5000))
  }

  const headers: Iheaders[] = [{titulo: 'Id', alinhamento:'right', coluna: '*index*', mascara:''}, { titulo: 'Descrição', alinhamento: 'left', coluna: 'descricao', mascara: '' }, { titulo: 'Situacao', alinhamento: 'center', coluna: 'situacao', mascara:'' }, { titulo: '*', alinhamento: 'right', coluna: 'flagedicao', mascara: '' }]

  const retornaContratos = (): {descricao: string, situacao: string, flagedicao: string }[] =>
  {
    let retcont: {descricao: string, situacao: string, flagedicao: string }[] = [];
    tiposcontrato.forEach(tipocont =>
      {
        retcont.push({descricao: tipocont.desc_tpct, situacao: tipocont.situacao_tpct, flagedicao: tipocont.exclusao_tpct ? 'D' : 'B'})
      })
    return retcont;
  }

  return (
    <div className={classes.divgrid}>
      <AlertDialog aberta={dialogo} fecha={confirmaExclusao} titulo={'Exclusão de tipo de contrato'} texto={['Confirma a exclusão deste tipo de contrato?']} botoes={[{descricao: 'Confirma', retorno:'A'},{descricao: 'Desiste', retorno:'B'}]}/>
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <CampoTexto valor={tipocont.desc_tpct} id='desc_tpct' label='Descrição' placeholder='Descrição' classe={classes.textoform} tipo='text' required={true} habilitado={(tipocont.id_tpct!==0 && alteracao) || (tipocont.id_tpct===0 && inclusao)} onchange={AtualizaTipoContrato} erro={tipocont.desc_tpct.trim().length<1} tamanho={100} />
        </Grid>
        <Grid item xs={3}>
          <Selecao valor={tipocont.situacao_tpct}  nome='situacao_tpct' label='Situação' required={true} habilitado={(tipocont.id_tpct!==0 && alteracao) || (tipocont.id_tpct===0 && inclusao)} onchange={AtualizaTipoContrato} conteudo={[{id: 'A', descricao: 'Ativo'},{id: 'B', descricao: 'Inativo'}]} nulo={[' ','Indefinida']} classe={classes.textoform} erro={'AB'.indexOf(tipocont.situacao_tpct)===-1 || tipocont.situacao_tpct===''}/>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" disabled={(tipocont.id_tpct!==0 && !alteracao) || (tipocont.id_tpct===0 && !inclusao)} className={classes.botao} onClick={VerificaCampos} endIcon={<SaveIcon/>}>Salvar</Button>
        </Grid>
        <Grid item xs={12}>
          <CampoTexto valor={tipocont.conteudo_tpct} id='conteudo_tpct' label='Conteúdo' placeholder='Conteúdo' classe={classes.textoform} tipo='text' required={true} habilitado={(tipocont.id_tpct!==0 && alteracao) || (tipocont.id_tpct===0 && inclusao)} onchange={AtualizaTipoContrato} multi={true} qtdlinhas={15} />
        </Grid>
        <Grid item xs={12}>
          <TabelaBase linhasoriginais={retornaContratos()} cab={headers} selecao={false} edicao={alteracao} adicao={inclusao} habfiltro={false} onClickEdicao={editaTipo} titulo={'Tipos de Contratos'} full={true} exclusao={exclusao} auxedit={1} auxexclui={2} linhasiniciais={5} />
        </Grid>
      </Grid>
    </div>
  )
};

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  registros: Tipos_Contrato[],
  open: boolean,
  mensagem: string,
  duracao: number,
  severidade: string
}

class TiposContrato extends Component<PropsType, IState>
{

  private _dadosobt: RetornoAcesso;
  private _roteador: Roteador;
  private _adicao: boolean;
  private _edicao: boolean;
  private _exclusao: boolean;

  constructor(props: any)
  {
    super(props);

    this._dadosobt=new RetornoAcesso();

    if (props.location.state)
    {
      if(props.location.state.dadosret)
      {
        this._dadosobt=props.location.state.dadosret;
      }
    }

    if (!this._dadosobt.ok)
    {
      this.props.history.push({pathname: '/'})
    }

    this._roteador=new Roteador(this._dadosobt, '/TiposContrato');
    this._roteador.history=props.history;
    this.state =
    {
      dadoobtido: false,
      registros: [],
      open: false,
      mensagem: '',
      duracao: 2000,
      severidade: ''
    }

    this._adicao=false;
    this._edicao=false;
    this._exclusao=false;

    if (this._dadosobt.ok)
    {
      if (this._dadosobt.objac.tipoacesso_perf==='A')
      {
        this._adicao=true;
        this._edicao=true;
        this._exclusao=true;
      }
      else
      {
        let fnrc = this._dadosobt.objac.funcoes.filter(funcao => funcao.id_rcrs===7);
        this._adicao=fnrc.some(funcao => funcao.id_fnrc===35)
        this._edicao=fnrc.some(funcao => funcao.id_fnrc===36)
        this._exclusao=fnrc.some(funcao => funcao.id_fnrc===37)
      }
      this.ObtemTiposContrato();
    }
  }

  ObtemTiposContrato()
  {
    srv.Get('TiposContrato', '', '', this._dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let dadosret: Resposta = TrataResposta(ddret);
      if (dadosret.ok)
      {
        let retorno=dadosret.retorno;
        srv.Get('Parametros', '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
          let dadosret: Resposta = TrataResposta(ddret);
          if (dadosret.ok)
          {
            AlteraPadroesContrato(dadosret.retorno);
            this.setState({registros: retorno, dadoobtido: true});
          }
          else
          {
            this.setState({open: true, mensagem: 'Erro: Erro obtendo dados de Parâmetros (' + dadosret.erro + ')', duracao: 5000, severidade: 'error' })
          }
        })
        .catch(Error => this.setState({open: true, mensagem: 'Erro: Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', duracao: 5000, severidade: 'error' }))
      }
      else
      {
        this.setState({open: true, mensagem: 'Erro: Erro obtendo dados de Recebimentos (' + dadosret.erro + ')', duracao: 5000, severidade: 'error' })
      }
    })
    .catch(Error => this.setState({open: true, mensagem: 'Erro: Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', duracao: 5000, severidade: 'error' }))
  }

  ExibeMensagem(mensagem: string, severidade: string, duracao:number)
  {
    this.setState({open: true, severidade: severidade, mensagem: mensagem, duracao: duracao})
  }

  FechaMensagem()
  {
    this.setState({open: false});
  }

  render()
  {
    if (!this._dadosobt.ok)
    {
      return null
    }
    else
    {
      return (
        <div className="Home">
          <header className="Home-header">
              <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador}/>
          </header>
          <main>
              {this.state.dadoobtido ?
                <div>
                  <EdicaoTiposContratos dadosobt={this._dadosobt} mensagem={this.ExibeMensagem.bind(this)} tiposcontr={this.state.registros} inclusao={this._adicao} alteracao={this._edicao} exclusao={this._exclusao} />
                </div>
                :
                <div>
                  <Progresso/>
                </div>
              }
          </main>
          <Toast open = {this.state.open} handleClose = {this.FechaMensagem.bind(this)} severity = {this.state.severidade} duracao= {this.state.duracao}>{this.state.mensagem}</Toast>
        </div>
      );
    }
  }
}

export default withRouter(TiposContrato)
