import React,{ Component, ChangeEvent } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { EmailCobranca, RetornoAcesso, TitulosCobranca } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import TabelaBase from '../Componentes/TabelaBase';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { Iheaders, TrataResposta, Resposta, datas, RetornaPeriodos, RetornaDataAAAAMMDD, RetornaClientes, RecebimentosObtidos, DifDias, formatarCampo, RetornaStringData, linhadigitavel, mascaraLinhadigitavel, AlteraPadroesRecebimento, ParametrosDiversos, RetornaValoresGrupos, nulltoNumber } from '../Servicos/Utilidades';
import { makeStyles, Theme, createStyles, Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Button, Card, CardContent, Divider, IconButton, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FilterListIcon from '@material-ui/icons/FilterList'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Periodo from '../Componentes/Periodo';
import SelecaoAuto from '../Componentes/SelecaoAuto';
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@material-ui/lab/Autocomplete';
import Progresso from '../Componentes/Progresso';
import { Contas } from '../Modelos/Contas';
import { Tipos_Grupos } from '../Modelos/Tipos_Grupos';
import { Parametros } from '../Modelos/Parametros';
import Selecao from '../Componentes/Selecao';
import AlertDialog from '../Componentes/Dialogo';

const useStyles = makeStyles((theme: Theme) => createStyles({
  griddados: {
    width: '100%',
    margin: '0 0 0 0'
  },
  divgrid: {
    width: '90%',
    margin: '5% 7% 0'
  },
  botao: {
    margin: '2% 4% 2% 0%',
    width: '96%'
  },
  textoform: {
    margin: "0% 0%",
    width: "100%",
    background: 'white'
  },
}));

let filtvenc: datas | undefined = RetornaPeriodos(10);
let filtpgto: datas | undefined = undefined;
let clipesq: number=0;
let clientes: any[];
let grupos: Tipos_Grupos[];
let contas: Contas[] = [];
let flag: string = '***';
let abrtini: boolean = true;
let grpini: number = 0;
let atvini: string = 'A';

const AtualizaFiltro = (campo:string, valor: any) =>
{
  if(campo==='Vencimento')
  {
    filtvenc={dtini: valor.dtini, dtfin: valor.dtfin}
  }
  if(campo==='Pagamento')
  {
    filtpgto={dtini: valor.dtini, dtfin: valor.dtfin}
  }
}

const AreaFiltros = ({atufiltro, recebimentos, dadosobt, mensagem}: {atufiltro: () => void, recebimentos: RecebimentosObtidos[], dadosobt: RetornoAcesso, mensagem: (mensagem: string, severidade: string, duracao:number) => void}) =>
{
 
  const classes = useStyles();
  const [cliesc, setcliesc]=React.useState(clipesq);
  const [aberto, setAberto]=React.useState(abrtini);
  const [enviados, setEnviados]=React.useState('');
  const [estado, setEstado]=React.useState('A');
  const [ativo, setAtivo]=React.useState(atvini);
  const [idvlgr, setIdvlr]=React.useState(grpini);

  let recvenc: RecebimentosObtidos[]=[];

  let totalizadores: {tipo: string, titulo: string, cor: string, qtde: number, valor: number, descontos: number, acrescimos: number, indices: number[]}[]=
  [
    {tipo: 'B', titulo: 'Pagos', cor: '#1E90FF', qtde: 0, valor: 0, descontos: 0, acrescimos: 0, indices: []},
    {tipo: 'C', titulo: 'Cancelados', cor: '#FF1493', qtde: 0, valor: 0, descontos: 0, acrescimos: 0, indices: []},
    {tipo: 'A1', titulo: 'Vencidos', cor: 'red', qtde: 0, valor: 0, descontos: 0, acrescimos: 0, indices: []},
    {tipo: 'A2', titulo: 'A vencer', cor: '#228B22', qtde: 0, valor: 0, descontos: 0, acrescimos: 0, indices: []}
  ];

  let indtot: number;
  let hoje=new Date();
  hoje=new Date(hoje.getFullYear(),hoje.getMonth(),hoje.getDate(),0,0,0,0);
  let difd: number;

  recebimentos.forEach((rec,idx) =>
  {
    indtot='ABC'.indexOf(rec.comportamento_strc);
    if (indtot>0)
    {
      totalizadores[indtot-1].qtde=totalizadores[indtot-1].qtde+1;
      totalizadores[indtot-1].valor=totalizadores[indtot-1].valor+rec.valor_rcbt;
      totalizadores[indtot-1].descontos=totalizadores[indtot-1].descontos+rec.descapl_rcbt+rec.despesas_rcbt;
      totalizadores[indtot-1].acrescimos=totalizadores[indtot-1].acrescimos+rec.jurosrec_rcbt+rec.multarec_rcbt;
    }
    else if(indtot===0)
    {
      difd=DifDias(hoje, rec.vencto_rcbt, true);
      if (difd<0)
      {
        totalizadores[2].qtde=totalizadores[2].qtde+1;
        totalizadores[2].valor=totalizadores[2].valor+rec.valor_rcbt;
        totalizadores[2].indices.push(rec.id_rcbt);
      }
      else
      {
        totalizadores[3].qtde=totalizadores[3].qtde+1;
        totalizadores[3].valor=totalizadores[3].valor+rec.valor_rcbt;
      }
    }
  })

  const ClickFiltro = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
  {
    atufiltro();
  }

  const onChangeFiltroAutoComplete = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined, campo?: string) =>
  {
      if(campo && value &&value[campo])
      {
        clipesq=value[campo];
        setcliesc(value[campo])
      }
      else
      {
          if(campo)
          {
              clipesq=0;
              setcliesc(0)
            }
      }
  }

  const onChangeSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode, campo?: string | undefined) =>
  {
    if(campo && event.target.value)
    {
      if (campo==='id_tpgr')
      {
        let valor:number = 0;
        valor = typeof event.target.value === 'string' ? nulltoNumber(parseInt(event.target.value)) : typeof event.target.value === 'number' ? event.target.value : 0; 
        setIdvlr(valor);
        grpini=valor;
      }
      else if (campo==='inativo_cli')
      {
        if (typeof event.target.value==='string')
        {
          atvini=event.target.value;
          setAtivo(atvini)
        }
      }
    }
  }

  const enviaEmails = () =>
  {
    if (totalizadores[2].indices.length>0)
    {
      recvenc = recebimentos.filter((rc,idx) => totalizadores[2].indices.indexOf(rc.id_rcbt)!==-1).sort(function (el1, el2) {return el1.id_cli - el2.id_cli});
      flag='***';
      setEstado('B');
      proximoEmail(0);
    }
  }

  const enviaEmail = (indenv:number) =>
  {
    setEnviados((indenv+1).toString().trim()+'/'+recvenc.length.toString().trim());
    let indatu=indenv
    let mailcob: EmailCobranca = new EmailCobranca();
    mailcob.id_cli=recvenc[indatu].id_cli;
    mailcob.id_oper=dadosobt.objac.id_oper;
    while (indatu<recvenc.length && recvenc[indatu].id_cli===mailcob.id_cli)
    {
      let indcta=contas.findIndex(ct => ct.id_cta===recvenc[indatu].id_cta);
      let titulo=new TitulosCobranca();
      titulo.id_rcbt=recvenc[indatu].id_rcbt;
      titulo.id=recvenc[indatu].id_cli.toString().trim();
      titulo.loja=recvenc[indatu].loja_cli.toString().trim();
      titulo.vencimento=RetornaStringData(recvenc[indatu].vencto_rcbt,false);
      titulo.valor=formatarCampo(recvenc[indatu].valor_rcbt.toString().trim(),'decimal',true);
      titulo.linha=(indcta>=0 ? mascaraLinhadigitavel(linhadigitavel(contas[indcta].banco_cta,contas[indcta].agencia_cta,contas[indcta].conta_cta,recvenc[indatu].carteira_rcbt,recvenc[indatu].numban_rcbt,recvenc[indatu].vencto_rcbt,recvenc[indatu].valor_rcbt)) : '');
      mailcob.titulos.push(titulo);
      indatu=indatu+1;
    }

    console.log('antes do post '+mailcob.id_cli.toString()+' '+mailcob.id_oper.toString()+' '+mailcob.titulos);

    srv.Post('Recebimentos/EmailCobranca', '', JSON.stringify(mailcob), dadosobt.objac.token, false)
    .then((ddret: any) => {

      console.log('enviando '+indenv.toString()+' '+ddret.toString());

      let dadosret: Resposta = TrataResposta(ddret);

      console.log('Resposta '+indenv.toString()+' '+dadosret.toString());

      if (!dadosret.ok)
      {
        mensagem(dadosret.erro.trim().substr(0,5)+'    -     Erro enviando e-mail (cliente: '+mailcob.id_cli.toString().trim()+') - Erro: '+dadosret.erro, 'warning',3000);
        proximoEmail(indatu);
      }
      else
      {
        proximoEmail(indatu);
      }
    })
    .catch(Error => 
    {
      mensagem('Erro acessando a API - enviando e-mail (cliente: '+mailcob.id_cli.toString().trim()+') - ('+ Error.name + ' - ' + Error.message +')', 'warning',3000);
      proximoEmail(indatu);
    });
  }

  const proximoEmail = (indenv:number) =>
  {
    if (flag!=='***' || indenv>=recvenc.length)
    {
      setEstado('A');
      setEnviados('');
    }
    else
    {
      enviaEmail(indenv);
    }
  }

  const interrompe = () =>
  {
    recvenc=[]; 
    flag='###'; 
    setEstado('A');
  }
  
  return (
    <div className={classes.divgrid}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Accordion expanded={aberto} style = {{boxShadow: "3px 3px 1px lightblue"}}>
            <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <Typography>Filtros</Typography>
              </Grid>
              <Grid item xs={5}>
                <Periodo capper='Vencimento' camporet='Vencimento' atualiza={AtualizaFiltro} perpad={10} dataspad={filtvenc ? filtvenc : {dtini: undefined, dtfin: undefined}} tipoper='ABC'/>
              </Grid>
              <Grid item xs={5}>
                <Periodo capper='Pagamento' camporet='Pagamento' atualiza={AtualizaFiltro} perpad={0} dataspad={filtpgto ? filtpgto : {dtini: undefined, dtfin: undefined}} tipoper='ABC'/>
              </Grid>
              <Grid item xs={1} >
                <IconButton onClick={() => {setAberto(!aberto); abrtini=!aberto}} style={{width: '40%', margin: '0 0 0 60%'}} > {aberto ? <ExpandLessIcon /> : <ExpandMoreIcon/> }</IconButton>
              </Grid>
            </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Selecao valor={idvlgr}  nome='id_tpgr' label={grupos.length>0 ? grupos[0].desc_tpgr : 'Grupo'} required={false} habilitado={true} onchange={onChangeSelect} conteudo={grupos.length>0 ? RetornaValoresGrupos(grupos[0].valores) : []} nulo={[0,'Todos']} classe={classes.textoform} />
                </Grid>
                <Grid item xs={2}>
                  <Selecao valor={ativo}  nome='inativo_cli' label='Ativo' required={false} habilitado={true} onchange={onChangeSelect} conteudo={[{valor: 'A', descricao: 'Sim'},{valor: 'B', descricao: 'Não'}]} nulo={[' ','Todos']} classe={classes.textoform} />
                </Grid>
                <Grid item xs={5}>
                  <SelecaoAuto valor={cliesc} nome='id_cli' label='Cliente' classe='' conteudo={clientes} habilitado={true} colunas={{id: 'id_cli', desc: 'nome_cli'}} onchange={onChangeFiltroAutoComplete}/>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={(event) => ClickFiltro(event)} endIcon={<FilterListIcon/>}>Aplica Filtros</Button>
                </Grid>
                {recebimentos.length>0 
                ?
                <React.Fragment>
                  <Grid item xs={12}>
                    <Grid container spacing={1} style={{width: '100%'}}>
                      <Grid item xs={4}>
                        <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 80, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '3px 3px 1px lightblue'}} variant="outlined">
                          <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography noWrap={true} style={{color: totalizadores[0].cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{totalizadores[0].titulo}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography noWrap={true} style={{color: totalizadores[0].cor, textAlign: 'right', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 3% 0 0'}}>{formatarCampo(totalizadores[0].qtde.toString(),'decimal',false)}</Typography>
                              </Grid>
                            </Grid>
                            <Divider/>
                            <Grid container spacing={2} className={classes.griddados}>
                              <Grid item xs={3}>
                                <Typography style={{textAlign: 'right', color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }} >Principal</Typography>
                                <Divider/>
                                <Typography style={{textAlign: 'right', color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(totalizadores[0].valor.toString(),'decimal',true)}</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography style={{textAlign: 'right', color:'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Descontos</Typography>
                                <Divider/>
                                <Typography style={{textAlign: 'right', color:'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(totalizadores[0].descontos.toString(),'decimal',true)}</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography style={{textAlign: 'right', color:'#228B22', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Acréscimos</Typography>
                                <Divider/>
                                <Typography style={{textAlign: 'right', color:'#228B22', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(totalizadores[0].acrescimos.toString(),'decimal',true)}</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography style={{textAlign: 'right', color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Recebido</Typography>
                                <Divider/>
                                <Typography style={{textAlign: 'right', color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(((totalizadores[0].valor+totalizadores[0].acrescimos)-totalizadores[0].descontos).toString(),'decimal',true)}</Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={1} style={{width: '100%'}}>
                            {totalizadores.filter(ttf => ttf.tipo!=='B').map((tt, idx) => 
                            {
                              return (
                                <Grid item xs={4} key={idx}>
                                  <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 80, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '3px 3px 1px lightblue'}} variant="outlined">
                                    <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                          <Typography noWrap={true} style={{color: tt.cor, fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{tt.titulo}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          {estado==='B' && tt.tipo==='A1'?
                                          <Typography align='right' noWrap={true} color='primary' style={{alignSelf: 'right', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{enviados}</Typography>
                                          :
                                          null
                                          }
                                        </Grid>
                                        <Grid item xs={2}>
                                        {
                                          tt.tipo==='A1'
                                          ?
                                            estado==='A'
                                            ?
                                              <Tooltip title='Clique aqui para enviar e-mail de cobrança para estes títulos'>
                                                <IconButton disabled={totalizadores[2].indices.length<1} style={{margin: '-5% 0 0 30%', padding: '0 0 0 0'}} color='primary' size='small' onClick={enviaEmails} ><MailOutlineIcon/></IconButton>
                                              </Tooltip>
                                            :
                                              <Tooltip title='Clique aqui para cancelar o envio de e-mails'>
                                                <IconButton style={{margin: '-5% 0 0 30%', padding: '0 0 0 0'}} color='primary' size='small' onClick={interrompe} ><CancelPresentationIcon/></IconButton>
                                              </Tooltip>
                                          :
                                          null
                                        }
                                        </Grid>
                                      </Grid>
                                      <Divider/>
                                      <Grid container spacing={2} className={classes.griddados}>
                                        <Grid item xs={4}>
                                          <Typography style={{color: 'darkblue', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }} >Qtde.</Typography>
                                          <Divider/>
                                          <Typography style={{color: 'darkblue', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(tt.qtde.toString(),'decimal',false)}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                          <div style={{textAlign: 'right'}}>
                                            <Typography style={{color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Valor</Typography>
                                            <Divider/>
                                            <Typography style={{color:'#191970', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(tt.valor.toString(),'decimal')}</Typography>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                            )}
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
                :
                null}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  )
};

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  registros: RecebimentosObtidos[],
  open: boolean,
  mensagem: string,
  duracao: number,
  severidade: string,
  acessosok: boolean,
  dialogo: boolean,
}

const srv=new ApiService();
const headers: Iheaders[] = [{titulo: 'Id', alinhamento:'right', coluna: 'id_rcbt', mascara:''}, { titulo: 'Id Interno', alinhamento: 'left', coluna: 'idinterno_rcbt', mascara: '' }, { titulo: 'Situação', alinhamento: 'center', coluna: 'desc_strc', mascara: '' }, { titulo: 'Id Cliente', alinhamento: 'right', coluna: 'id_cli', mascara:'' }, { titulo: 'Cliente', alinhamento: 'left', coluna: 'nome_pessoa', mascara:'' }, { titulo: 'Rede', alinhamento: 'center', coluna: 'nome_rede', mascara:'' }, { titulo: 'Loja', alinhamento: 'right', coluna: 'loja_cli', mascara:'' }, { titulo: 'Valor', alinhamento: 'right', coluna: 'valor_rcbt', mascara:'decimal' }, { titulo: 'Líquido', alinhamento: 'right', coluna: 'liquido', mascara:'decimal' }, { titulo: 'Vencto.', alinhamento: 'center', coluna: 'vencto_rcbt', mascara:'data' }, { titulo: 'Pagto.', alinhamento: 'center', coluna: 'pagto_rcbt', mascara:'data' }, { titulo: 'Atraso', alinhamento: 'right', coluna: 'atraso', mascara:'' }]

class Recebimentos extends Component<PropsType, IState>
{

  private _dadosobt: RetornoAcesso;
  private _roteador: Roteador;
  private _adicao=false;
  private _edicao=false;
  private _exclusao=false;
  private _acessos: number = 0;
  private _parametros: Parametros[] = [];
  private _idrcbtexc: number = 0;

  constructor(props: any)
  {
    super(props);

    this._dadosobt=new RetornoAcesso();

    if (props.location.state)
    {
      if(props.location.state.dadosret)
      {
        this._dadosobt=props.location.state.dadosret;
      }
    }

    if (!this._dadosobt.ok)
    {
      this.props.history.push({pathname: '/'})
    }

    this._roteador=new Roteador(this._dadosobt, '/Recebimentos');
    this._roteador.history=props.history;
    this.state =
    {
      dadoobtido: false,
      registros: [],
      open: false,
      mensagem: '',
      duracao: 2000,
      severidade: '',
      acessosok: false,
      dialogo: false,
    }
    if (this._dadosobt.ok)
    {
      if (this._dadosobt.objac.tipoacesso_perf==='A')
      {
        this._adicao=true;
        this._edicao=true;
        this._exclusao=true;
      }
      else
      {
        let fnrc = this._dadosobt.objac.funcoes.filter(funcao => funcao.id_rcrs===3);
        this._edicao=fnrc.some(funcao => funcao.id_fnrc===8)
        this._adicao=fnrc.some(funcao => funcao.id_fnrc===7)
        this._exclusao=fnrc.some(funcao => funcao.id_fnrc===9)
      }
      this.ObtemDados('Contas/','contas',this.setacontas.bind(this));
      this.ObtemDados('TiposGrupos/','grupos',this.setagrupos.bind(this));
      this.ObtemDados('Parametros/','parâmetros',this.setaparametros.bind(this));
      this.ObtemDados('Clientes/0/0','clientes',this.setaclientes.bind(this));
      this.ObtemRecebimentos();
    }
  }

  ObtemRecebimentos()
  {
    srv.Get('Recebimentos/3,5,6'+(atvini.trim().length>0 ? ',11' : '')+'/'+clipesq.toString()+','+RetornaDataAAAAMMDD(filtvenc?.dtini)+','+RetornaDataAAAAMMDD(filtpgto?.dtini)+(atvini.trim().length>0 ? ','+atvini : '')+'/'+clipesq.toString()+','+RetornaDataAAAAMMDD(filtvenc?.dtfin)+','+RetornaDataAAAAMMDD(filtpgto?.dtfin)+(atvini.trim().length>0 ? ','+atvini : '')+'/D/'+grpini.toString().trim(), '', '', this._dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let dadosret: Resposta = TrataResposta(ddret);
      if (dadosret.ok)
      {
        let listarec=dadosret.retorno;
        this.setState({registros: listarec, dadoobtido: true});
      }
      else
      {
        this.ExibeMensagem('Erro: Erro obtendo dados de recebimentos (' + dadosret.erro + ')', 'error',5000);
      }
    })
    .catch(Error => {this.ExibeMensagem('Erro acessando a API - obtendo dados de recebimentos (' + Error.name + ' - ' + Error.message + ')','error',5000);this.aumentaAcessos();})
  }

  setacontas(registros: any) {contas=registros; this.aumentaAcessos();};
  setagrupos(registros: any) {grupos=registros; this.aumentaAcessos();};
  setaclientes(registros: any) {clientes=RetornaClientes(registros); this.aumentaAcessos();};
  setaparametros(parobt: Parametros[]) {this._parametros = parobt; this.aumentaAcessos();};

  ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
  {
      srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
      .then((ddret: any) => 
      {
      let dadosret:Resposta = TrataResposta(ddret);
      if(dadosret.ok)
      {
          obret(dadosret.retorno)
      }
      else
      {
          this.ExibeMensagem('Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')','error', 6000); 
          this.aumentaAcessos();
      }
      })
      .catch(Error => {this.ExibeMensagem('Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')','error',6000); this.aumentaAcessos();});
  }

  aumentaAcessos()
  {
      this._acessos = this._acessos+1
      if(this._acessos>3)
      {
        AlteraPadroesRecebimento(this._parametros);
        grupos = grupos.filter(grp => grp.id_tpgr===ParametrosDiversos.idgrfin);
        this.setState({acessosok: true});
      }
  }

  ExibeMensagem(mensagem: string, severidade: string, duracao:number)
  {
    this.setState({open: true, severidade: severidade, mensagem: mensagem, duracao: duracao})
  }

  FechaMensagem()
  {
    this.setState({open: false});
  }

  RetornaTabela(aux: number, id: number)
  {
    if(aux===997)
    {
      this._roteador.rota(aux, id);
    }
    else if (aux===9970)
    {
      this._idrcbtexc=id;
      this.setState({dialogo:true});
    }
  }

  ExcluiRecebimento(retorno: string)
  {
    this.setState({dialogo: false});
    if (this._idrcbtexc>0 && retorno==='A')
    {
      srv.Delete('Recebimentos/'+(this._idrcbtexc.toString().trim()), '', '', this._dadosobt.objac.token, false)
      .then((ddret: any) => 
      {
        let dadosret:Resposta = TrataResposta(ddret);
        if(dadosret.ok)
        {
          this.ExibeMensagem('Recebimento '+dadosret.retorno.adicional+' excluído com sucesso','info',6000)
          this._idrcbtexc=0;
          this.ObtemRecebimentos();
        }
        else
        {
          this.ExibeMensagem('Erro excluindo recebimento (' + dadosret.erro + ')','error', 6000); 
          this._idrcbtexc=0;
        }
      })
      .catch(Error => {this.ExibeMensagem('Erro acessando a API - excluindo recebimento (' + Error.name + ' - ' + Error.message + ')','error',6000); this._idrcbtexc=0;});
    }
  }

  render()
  {
    if (!this._dadosobt.ok)
    {
      return null
    }
    else
    {
      return (
        <div className="Home">
          <header className="Home-header">
              <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador}/>
          </header>
          <main>
              {this.state.dadoobtido && this.state.acessosok ?
                <div>
                  <AlertDialog aberta={this.state.dialogo} fecha={this.ExcluiRecebimento.bind(this)} titulo={'Exclusão de recebimento'} texto={['Após a confirmação não será possível reverter esta operação','Confirma a operação?']} botoes={[{descricao: 'Confirma', retorno: 'A'},{descricao: 'Cancela', retorno: 'B'}]}/>
                  <AreaFiltros atufiltro={this.ObtemRecebimentos.bind(this)} recebimentos={this.state.registros} dadosobt={this._dadosobt} mensagem={this.ExibeMensagem.bind(this)} />
                  <TabelaBase linhasoriginais={this.state.registros} cab={headers} selecao={false} edicao={this._edicao} adicao={this._adicao} habfiltro={true} onClickEdicao={this.RetornaTabela.bind(this)} titulo={'Recebimentos'} full={false} exclusao={this._exclusao} auxedit={997} auxexclui={9970} classe='filtro' colcnc='desc_strc' cncvlr='CANCELADO' />
                </div>
                :
                <div>
                  <Progresso/>
                </div>
              }
          </main>
          <Toast open = {this.state.open} handleClose = {this.FechaMensagem.bind(this)} severity = {this.state.severidade} duracao= {this.state.duracao}>{this.state.mensagem}</Toast>
        </div>
      );
    }
  }
}

export default withRouter(Recebimentos)
