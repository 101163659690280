import React,{ Component, RefObject, useRef } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Button } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EmailIcon from '@material-ui/icons/Email';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import CampoTexto from '../Componentes/CampoTexto';
import CampoDecimal from '../Componentes/CampoDecimal';
import CampoMascara from '../Componentes/CampoMascara';
import CampoData from '../Componentes/CampoData';
import Selecao from '../Componentes/Selecao';
import { arrayBufferToBase64, VerificaCpfCnpj, formataDecimal, Resposta, ValidaDadosAdicionais, BotaoDialogo, TrataResposta, TabPanel, a11yProps, ChaveApiGoogle, AtualizaDocumentos, AtualizaDadosAdicionais, RetornaValoresDadosAdicionais, RetornaReceitasDespesas, DataSql } from '../Servicos/Utilidades';
import { Pessoa_Documentos } from '../Modelos/Pessoa_Documentos';
import { Endereco_Pessoas } from '../Modelos/Endereco_Pessoas';
import { Dados_Adicionais } from '../Modelos/Dados_Adicionais';
import { Documentos } from '../Modelos/Documentos';
import { Emails } from '../Modelos/Emails';
import { Telefones } from '../Modelos/Telefones';
import { Pessoas } from '../Modelos/Pessoas';
import AlertDialog from '../Componentes/Dialogo';
import Progresso from '../Componentes/Progresso';
import Geocode from "react-geocode";
import EnderecosComp from '../Componentes/Enderecos';
import EmailsTelefones from '../Componentes/EmailsTelefones';
import { StyledAppBar } from '../Componentes/Customizados';
import { Vendedores } from '../Modelos/Vendedores';
import { Tipos_Endereco } from '../Modelos/Tipos_Endereco';
import { Despesas } from '../Modelos/Despesas';

Geocode.setApiKey(ChaveApiGoogle);
Geocode.setLanguage('pr-BR');
Geocode.setRegion('br');
 
const srv=new ApiService();

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%'
    },
    foto: {
      float: 'right',
      widht: '80%',
    },
    divgrid: {
        width: '90%',
        margin: '5% 7% 0'
    },
    divtabs: {
        width: '90%',
        margin: '0% 7% 0'
    },
    gridcont: {
        margin: '5px 0 0 0'
    },
    fotocliente: {
        width: "98%",
        maxHeight: "207px",
        minHeight: "207px",
        margin: "1% 1% 0 0",
        boxShadow: "3px 3px 1px lightblue"
    },
    fotoinput: {
        display: "none"
    },
    griddados: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridcontrato: {
        width: "60%",
        margin: "0 0 0 0"
    },
    gridfoto: {
        width: "20%",
        margin: "0 0 0 0"
    },
    gridtabend: {
        width: "100%",
        margin: "0 0 0 0"
    },
    gridtabemail: {
        width: "50%",
        margin: "0 0 0 0"
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    obsform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white',
        minHeight: '300%'
    },
    padrao: {
        margin: "0% 0%",
        width: "100%",
        padding: '0 0 0 0'
    },
    botao: {
        margin: '5% 5%',
        width: '90%'
    },
    botaopdf: {
        margin: '0 2',
        width: '100%'
    },
    pdfcontrato: {
        width: "98%",
        maxHeight: "400px",
        minHeight: "400px",
        margin: "1% 1% 0 0",
        boxShadow: "2px 2px 5px lightgrey"
    },
    pdfnulo: {
        width: "98%",
        margin: "1% 1% 0 0",
        boxShadow: "2px 2px 5px lightgrey"
    },
  }),
);

let ultcpfcnpj: string = '';
let titulodialogo: string;
let botoesdialogo: BotaoDialogo[] = [];
let textodialogo: string[] = [];
let enddigatu: Endereco_Pessoas = new Endereco_Pessoas();
let indendatu: number = 0;
let emaildigatu: Emails = new Emails();
let fonedigatu: Telefones = new Telefones();
let indemailatu: number = 0;
let indfoneatu: number = 0;

let sted = {
    enderecos: false,
    emails: false,
    telefones: false,
};

function InicializaEdicao() 
{
    sted = {
        enderecos: false,
        emails: false,
        telefones: false,
    };
    enddigatu = new Endereco_Pessoas();
    indendatu = 0;
    emaildigatu = new Emails();
    fonedigatu = new Telefones();
    indemailatu = 0;
    indfoneatu = 0;
}

type PrincipalProps=
{
    dadosobt: RetornoAcesso
    documentos: Documentos[]
    dadosvend: Vendedores
    dadosadicionais: Dados_Adicionais[]
    tiposenderecos: Tipos_Endereco[]
    ufs: []
    despesas: Despesas[]
    mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void
    roteador: (recurso: number, id_edicao: number) => void
}

const PrincipalEdicaoVendedores = ({dadosobt, dadosvend, documentos, dadosadicionais, tiposenderecos, ufs, despesas, mensagem, roteador }: PrincipalProps) =>
{
    const classes = useStyles();
    const [tabValue, settabValue] = React.useState(0);
    const [vendedor, setVendedor] = React.useState(dadosvend);
    const [ddadcdig, setddadcDig] = React.useState(dadosadicionais);
    const [doctodig, setdoctoDig] = React.useState(documentos);
    const [dialogo, setDialog] = React.useState(false);

    const AtuEnderecos = (enderecos: Endereco_Pessoas[]) => {setVendedor({...vendedor, pessoa: {...vendedor.pessoa, enderecos: enderecos}}); sted.enderecos=false};
    const AtuEnder = (ender: Endereco_Pessoas, altdados: boolean) => {enddigatu=ender; if(altdados){sted.enderecos=true}};
    const AtuIndEnder = (indender: number) => {indendatu=indender};
    const AtuEmails = (emails: Emails[]) => {setVendedor({...vendedor, pessoa: {...vendedor.pessoa, emails: emails}}); sted.emails=false};
    const AtuEmail = (email: Emails, altdados: boolean) => {emaildigatu=email; if(altdados){sted.emails=true}};
    const AtuIndEmail = (indemail: number) => {indemailatu=indemail};
    const AtuFones = (fones: Telefones[]) => {setVendedor({...vendedor, pessoa: {...vendedor.pessoa, telefones: fones}}); sted.telefones=false};
    const AtuFone = (fone: Telefones, altdados: boolean) => {fonedigatu=fone; if(altdados){sted.telefones=true}};
    const AtuIndFone = (indfn: number) => {indfoneatu=indfn};
    const AtuMensagem = (msg: string) => {mensagem(msg,'error',6000,false)};

    const fechaDialogo = (retorno: string) => 
    {
        setDialog(false);
        if (retorno==='A')
        {
            salvavendedor();
        }
    }

    let inddoc: number = vendedor.pessoa.documentos.findIndex(doc => doc.id_doc===1 || doc.id_doc===2 );
    if(inddoc<0)
    {
        let docatu: Pessoa_Documentos[] = [];
        docatu.concat(vendedor.pessoa.documentos);
        let novodoc=new Pessoa_Documentos();
        novodoc.id_pessoa=vendedor.pessoa.id_pessoa;
        novodoc.id_doc=1;
        novodoc.valor_psdc='';
        docatu.push(novodoc);
        setVendedor({...vendedor, pessoa: {...vendedor.pessoa, documentos: docatu}});
        inddoc=docatu.length-1;
    }

    const inputFileFoto: RefObject<HTMLInputElement> = useRef(null) ;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (newValue===1 && documentos.length>0 && doctodig.length===0)
        {
            setdoctoDig(documentos);
        }
        if (newValue===3 && dadosadicionais.length>0 && ddadcdig.length===0)
        {
            setddadcDig(dadosadicionais);
        }
        settabValue(newValue);
    };

    const onButtonClickFoto = (event:any) => 
    {
        if(event.ctrlKey)
        {
            setVendedor({...vendedor, pessoa: {...vendedor.pessoa, gravafoto: true, foto_pessoa: undefined}});
        }
        else
        {
            if (inputFileFoto.current)
            {
                inputFileFoto.current.click()
            }
        }
    };

    const onChangeFoto = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    {
        const filereader = new FileReader();
        const doc: any = document.getElementById("filefoto");
        if(doc)
        {
            if(doc.files)
            {
                if(doc.files.length>0)
                {
                    filereader.readAsArrayBuffer(doc.files[0]);
                }
            }
        }
        filereader.onloadend = function()
        {
            let result: any = filereader.result
            setVendedor({...vendedor, pessoa: {...vendedor.pessoa, gravafoto: true, foto_pessoa: arrayBufferToBase64(result)}});
        };
    }

    const onblurCpfCnpj = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
    {
        let docatu: Pessoa_Documentos[] = vendedor.pessoa.documentos;
        if (event.target.value.length===11 && docatu[inddoc].id_doc===1)
        {
            docatu[inddoc].id_doc=2;
            setVendedor({...vendedor, pessoa: {...vendedor.pessoa, documentos: docatu}});
        }
        else if(event.target.value.length===14 && docatu[inddoc].id_doc===2)
        {
            docatu[inddoc].id_doc=1;
            setVendedor({...vendedor, pessoa: {...vendedor.pessoa, documentos: docatu}});
        }
        if(ultcpfcnpj!==vendedor.pessoa.documentos[inddoc].valor_psdc && VerificaCpfCnpj(vendedor.pessoa.documentos[inddoc].valor_psdc))
        {
            srv.Get('Pessoas/'+vendedor.pessoa.documentos[inddoc].valor_psdc+'/'+vendedor.id_vend.toString()+'/C' , '', '', dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (!dadosret.ok)
                {
                    mensagem(dadosret.erro,'error',6000);
                    let cpocpfcnpj = document.getElementById('cnpj_pessoa');
                    if(cpocpfcnpj)
                    {
                        let docatu: Pessoa_Documentos[] = vendedor.pessoa.documentos;
                        docatu[inddoc].valor_psdc=ultcpfcnpj;
                        setVendedor({...vendedor, pessoa: {...vendedor.pessoa, documentos: docatu}});
                        cpocpfcnpj.focus();
                    }
                }
                else
                {
                    if (dadosret.retorno)
                    {
                        if(dadosret.retorno.id_pessoa)
                        {
                            let novapes: Pessoas = dadosret.retorno;
                            dadosadicionais=AtualizaDadosAdicionais(dadosadicionais, novapes.dados_adicionais ? novapes.dados_adicionais : [],'B');
                            documentos=AtualizaDocumentos(documentos, novapes.documentos ? novapes.documentos : []);
                            setVendedor({...vendedor, pessoa: novapes});
                            setddadcDig(dadosadicionais);
                            setdoctoDig(documentos);
                            mensagem('Informações atualizadas a partir de cadastro existente','info',7000);
                        }
                    }
                }
            })
            .catch(Error => mensagem('Erro obtendo dados de pessoas acessando a API (' + Error.name + ' - ' + Error.message + ')','error',7000));
        }
    }

    const onfocusCpfCnpj = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
    {
        ultcpfcnpj=event.target.value;
    }

    const onChangePessoaInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
    {
        setVendedor({...vendedor, pessoa: {...vendedor.pessoa, [event.target.id]: propratu}});
    }

    const onChangePessoaDataInput = (date: any, valor: string | null | undefined) =>
    {
        if (date)
        {
            setVendedor({...vendedor, pessoa: {...vendedor.pessoa, aniversario_pessoa: date.getDate().toString().padStart(2,'0')+(date.getMonth()+1).toString().padStart(2,'0'), nascimento_pessoa: date }});
        }
        else
        {
            setVendedor({...vendedor, pessoa: {...vendedor.pessoa, nascimento_pessoa: date}});
        }
    }

    const onChangeVendedor = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string) =>
    {
        setVendedor({...vendedor, [event.target.id]: parseFloat(formataDecimal(propratu))});
    }

    const onChangeVendedoresSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode, campo?: string | undefined) =>
    {
        if(campo && event.target.value)
        {
            let valor:number = 0;
            valor = typeof event.target.value === 'string' ? parseInt(event.target.value) : typeof event.target.value === 'number' ? event.target.value : 0; 
            if ('id_dsps'.indexOf(campo)!==-1)
            {
                setVendedor({...vendedor, 'id_dsps': valor});
            }
        }
    }
    
    const onChangeDadosAdicionaisSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) =>
    {
        if (event.target.name)
        {
            let valor = typeof event.target.value === 'string' && event.target.value ? parseInt(event.target.value) : 0;
            let novoddadc: Dados_Adicionais[]=[]
            novoddadc=novoddadc.concat(ddadcdig);
            let inddado=parseInt(event.target.name)
            if (inddado>=0 && inddado<novoddadc.length)
            {
                novoddadc[inddado].id_vdad=valor;
                setddadcDig(novoddadc);
            }
        }
    }

    const onChangeDadosAdicionaisInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
    {
        if (event.target.id && typeof propratu==='string')
        {
            let novoddadc: Dados_Adicionais[]=[]
            novoddadc=novoddadc.concat(ddadcdig);
            let inddado=parseInt(event.target.id)
            if (inddado>=0 && inddado<novoddadc.length)
            {
                if (novoddadc[inddado].tipo_dadc==='A')
                {
                    novoddadc[inddado].texto_tbad=propratu;
                }
                else
                {
                    if(novoddadc[inddado].mascara_dadc==='decimal')
                    {
                        novoddadc[inddado].numero_tbad=parseFloat(formataDecimal(propratu))
                    }
                    else
                    {
                        novoddadc[inddado].numero_tbad=parseInt(propratu)
                    }
                }
                setddadcDig(novoddadc);
            }
        }
    }

    const onChangeDocumentosInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
    {
        if (event.target.id && typeof propratu==='string')
        {
            let novodoc: Documentos[]=[]
            novodoc=novodoc.concat(doctodig);
            let inddado=parseInt(event.target.id)
            if (inddado>=0 && inddado<novodoc.length)
            {
                novodoc[inddado].valor_psdc=propratu;
                setdoctoDig(novodoc);
            }
        }
    }

    const onChangeCpfCnpj = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
    {
        let docatu: Pessoa_Documentos[] = vendedor.pessoa.documentos;
        docatu[inddoc].valor_psdc=propratu.toString();
        setVendedor({...vendedor, pessoa: {...vendedor.pessoa, documentos: docatu}});
    }

    const Desiste = () =>
    {
        roteador(11,0);
    }

    const Gravavendedor = () =>
    {
        let errogrv: string = '';
        if (!VerificaCpfCnpj(vendedor.pessoa.documentos[inddoc].valor_psdc))
        {
            errogrv = errogrv +'CPF/CNPJ incorreto \n' ;
        }
        if (vendedor.pessoa.nome_pessoa.trim().length<1 || vendedor.comissao_vend<0)
        {
            errogrv = errogrv + 'Verifique os dados cadastrais \n';
        }
        if (!vendedor.pessoa.enderecos.some(endereco => (!endereco.alterado) || endereco.alterado==='' || endereco.alterado!=='B'))
        {
            errogrv = errogrv + 'Nenhum endereço foi informado \n';
        }
        if (!vendedor.pessoa.emails.some(email => (!email.alterado) || email.alterado==='' || email.alterado!=='B'))
        {
            errogrv = errogrv + 'Nenhum e-mail foi informado \n';
        }
        if (!vendedor.pessoa.telefones.some(telefone => (!telefone.alterado) || telefone.alterado==='' || telefone.alterado!=='B'))
        {
            errogrv = errogrv + 'Nenhum telefone foi informado \n';
        }
        if (documentos.some(doc => doc.id_doc>2 && doc.obrig_doc==='A' && (!doc.valor_psdc || doc.valor_psdc.trim().length<1)))
        {
            errogrv = errogrv + 'É necessário informar todos os documentos obrigatórios \n'
        }
        if (dadosadicionais.some(dado => !ValidaDadosAdicionais(dado)))
        {
            errogrv = errogrv + 'Verifique os dados adicionais \n'
        }
        if (errogrv.length>0)
        {
            mensagem(errogrv,'error',10000);
        }
        else
        {
            textodialogo=[]
            if (sted.enderecos)
            {
                textodialogo.push('Endereços');
            }
            if (sted.emails)
            {
                textodialogo.push('E-mails');
            }
            if (sted.telefones)
            {
                textodialogo.push('Telefones');
            }
            if (textodialogo.length>0)
            {
                botoesdialogo=[{descricao: 'Confirma', retorno: 'A'},{descricao: 'Cancela', retorno: 'B'}]
                titulodialogo='Há informações alteradas e não confirmadas nas seguintes áreas:'
                textodialogo.push('Confirma a operação?');
                setDialog(true)
            }
            else
            {
                salvavendedor();
            }
        }
    }

    const salvavendedor = () =>
    {
        let fngrv: Vendedores = JSON.parse(JSON.stringify(vendedor));
        if (vendedor.pessoa.nascimento_pessoa)
        {
            fngrv.pessoa.nascimento_pessoa=DataSql(vendedor.pessoa.nascimento_pessoa);
        }
        fngrv.pessoa.documentos.forEach((documento,index) => {if((documento.id_doc === 1 || documento.id_doc ===2) && index!==inddoc){documento.valor_psdc=''}})
        doctodig.forEach(docto => 
        {
            if(docto.id_doc>2)
            {
                let inddc=fngrv.pessoa.documentos.findIndex(dct => dct.id_doc===docto.id_doc);
                if (inddc!==-1)
                {
                    fngrv.pessoa.documentos[inddc].valor_psdc=docto.valor_psdc;
                }
                else
                {
                    if(docto.valor_psdc && docto.valor_psdc.trim().length>0)
                    {
                        fngrv.pessoa.documentos.push({id_psdc:0, id_doc:docto.id_doc, id_pessoa: vendedor.pessoa.id_pessoa, valor_psdc:docto.valor_psdc, id_uf:0});
                    }
                }
            }
        })
        fngrv.pessoa.enderecos.forEach(endereco => {if(endereco.alterado==='B') {endereco.endereco.logr_end='';}});
        fngrv.pessoa.emails.forEach(email => {if(email.alterado==='B') {email.endereco_email='';}});
        fngrv.pessoa.telefones.forEach(telefone => {if(telefone.alterado==='B') {telefone.numero_fone='';}});
        ddadcdig.forEach(ddad => 
        {
            let inddc=-1;
            if (ddad.comportamento_dadc==='B')
            {
                inddc=fngrv.pessoa.dados_adicionais.findIndex(dad => dad.id_dadc===ddad.id_dadc);
            }
            else
            {
                inddc=fngrv.dados_adicionais.findIndex(dad => dad.id_dadc===ddad.id_dadc);
            }
            if (inddc!==-1)
            {
                if (ddad.comportamento_dadc==='B')
                {
                    fngrv.pessoa.dados_adicionais[inddc].id_vdad=ddad.id_vdad;
                    fngrv.pessoa.dados_adicionais[inddc].numero_tbad=ddad.numero_tbad;
                    fngrv.pessoa.dados_adicionais[inddc].texto_tbad=ddad.texto_tbad;
                }
                else
                {
                    fngrv.dados_adicionais[inddc].id_vdad=ddad.id_vdad;
                    fngrv.dados_adicionais[inddc].numero_tbad=ddad.numero_tbad;
                    fngrv.dados_adicionais[inddc].texto_tbad=ddad.texto_tbad;
                }
            }
            else
            {
                if((ddad.id_vdad && ddad.id_vdad>0) || ddad.numero_tbad || (ddad.texto_tbad))
                    {
                    if (ddad.comportamento_dadc==='B')
                    {
                        fngrv.pessoa.dados_adicionais.push({id_tbad:0, id_dadc: ddad.id_dadc, id_pessoa: vendedor.pessoa.id_pessoa, id_vdad: ddad.id_vdad, numero_tbad: ddad.numero_tbad, texto_tbad: ddad.texto_tbad, id_cli:0, id_forn:0, id_oper:0, id_vend:0, alterado:''});
                    }
                    else
                    {
                        fngrv.dados_adicionais.push({id_tbad:0, id_dadc: ddad.id_dadc, id_cli: 0, id_vdad: ddad.id_vdad, numero_tbad: ddad.numero_tbad, texto_tbad: ddad.texto_tbad, id_pessoa:0, id_vend: vendedor.id_vend, id_oper:0, id_forn:0, alterado:''});
                    }
                }
            }
        });

        srv.Post('Vendedores/','',JSON.stringify(fngrv),dadosobt.objac.token,false)
        .then((ddret: any) => 
        {
            let dadosret: Resposta = TrataResposta(ddret);
            if (!dadosret.ok)
            {
                mensagem(dadosret.erro,'error',6000)
            }
            else
            {
                mensagem('vendedor: '+dadosret.retorno.id.toString()+' salvo com sucesso','info',10000, true);
            }
        })
        .catch(Error => {mensagem('Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', 'error',6000);})
    }

    return (
        <div>
            <div className={classes.divgrid}>
                <AlertDialog aberta={dialogo} fecha={fechaDialogo} titulo={titulodialogo} texto={textodialogo} botoes={botoesdialogo}/>
                <Grid container spacing={1}>
                    <Grid item className={classes.griddados}>
                        <Grid className={classes.gridcont} container spacing={1}>
                            <Grid item xs={2}>
                                <CampoTexto valor={vendedor.id_vend>0 ? vendedor.id_vend : 0}  id='id_vend' label='Id' placeholder='Id do vendedor' classe={classes.textoform} tipo='number' required={false} habilitado={false} />
                            </Grid>
                            <Grid item xs={3}>
                                <CampoTexto valor={vendedor.pessoa.documentos[inddoc] ? vendedor.pessoa.documentos[inddoc].valor_psdc : ''}  id='cnpj_pessoa' label='CPF/CNPJ' placeholder='CPF ou CNPJ do vendedor' classe={classes.textoform} tipo='text' required={true} habilitado={true} onchange={onChangeCpfCnpj} onblur={onblurCpfCnpj} onfocus={onfocusCpfCnpj} erro={!vendedor.pessoa.documentos[inddoc] || !VerificaCpfCnpj(vendedor.pessoa.documentos[inddoc].valor_psdc)} tamanho={14}/>
                            </Grid>
                            <Grid item xs={5}>
                                <CampoTexto valor={vendedor.pessoa.nome_pessoa}  id='nome_pessoa' label='Nome ou Razão Social' placeholder='Nome ou Razão Social do vendedor' classe={classes.textoform} tipo='text' required={true} habilitado={true} onchange={onChangePessoaInput} erro={!vendedor.pessoa.nome_pessoa || vendedor.pessoa.nome_pessoa.trim().length<1} tamanho={60}/>
                            </Grid>
                            <Grid item xs={2}>
                                <CampoTexto valor={vendedor.pessoa.fantasia_pessoa}  id='fantasia_pessoa' label='Fantasia' placeholder='Nome fantasia' classe={classes.textoform} tipo='text' required={false} habilitado={true} onchange={onChangePessoaInput} tamanho={50}/>
                            </Grid>
                            <Grid item xs={3}>
                                <CampoData valor={vendedor.pessoa.nascimento_pessoa} id='nascimento_pessoa' label='Nascimento' placeholder='Nascimento' classe={classes.textoform} required={false} habilitado={true} onchange={onChangePessoaDataInput} limpadata={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => setVendedor({...vendedor, pessoa: {...vendedor.pessoa, nascimento_pessoa: undefined}})}/>
                            </Grid>
                            <Grid item xs={2}>
                                <CampoMascara valor={vendedor.pessoa.aniversario_pessoa} id='aniversario_pessoa' label='Aniversario' placeholder='Aniversário' classe={classes.textoform} mascara='##/##' required={false} habilitado={true} onchange={onChangePessoaInput}/>
                            </Grid>
                            <Grid item xs={1}>
                                <CampoDecimal valor={vendedor.comissao_vend}  id='comissao_vend' label='Comissão' placeholder='% comissão' classe={classes.textoform} tipo='text' required={false} habilitado={true} onchange={onChangeVendedor} erro={vendedor.comissao_vend<0}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Selecao valor={vendedor.id_dsps}  nome='id_dsps' label='Despesa' required={true} habilitado={true} onchange={onChangeVendedoresSelect} conteudo={despesas} nulo={[0,'Indefinida']} classe={classes.textoform} />
                            </Grid>
                            <Grid item xs={12}>
                                <CampoTexto valor={vendedor.pessoa.obs_pessoa} id='obs_pessoa' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={true} onchange={onChangePessoaInput} multi={true} qtdlinhas={2} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.gridfoto}>
                        <input type='file' id='filefoto' accept='image/*' ref={inputFileFoto} className={classes.fotoinput} onChange={event => {onChangeFoto(event)}}/> 
                        <img id='foto_pessoa' alt='fotovendedor' src={vendedor.pessoa.foto_pessoa && vendedor.pessoa.foto_pessoa.length>0 ? 'data:image/jpeg;base64, ' + vendedor.pessoa.foto_pessoa : 'Imagens/maquinafotografica.png'}  className={classes.fotocliente} onClick={event => onButtonClickFoto(event)} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.divtabs}>
                <StyledAppBar position="static">
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        variant='scrollable'
                        scrollButtons="auto"
                        aria-label="scrollable prevent tabs example"
                    >
                        <Tab label={'Endereços'} icon={<HomeIcon />} aria-label="enderecos" {...a11yProps(0)} />
                        <Tab label={'Documentos'} icon={<AssignmentIndIcon/>} aria-label="documentos" {...a11yProps(1)} />
                        <Tab label={'E-mails/Telefones'} icon={<EmailIcon />} aria-label="emails" {...a11yProps(2)} />
                        <Tab label={'Adicionais'} icon={<PostAddIcon />} aria-label="adicionais" {...a11yProps(3)} />
                    </Tabs>
                </StyledAppBar>
                <TabPanel value={tabValue} index={0}>
                    <EnderecosComp ender={enddigatu} indender={indendatu} enderecos={vendedor.pessoa.enderecos} retindender={AtuIndEnder} retenderecos={AtuEnderecos} retender={AtuEnder} retmsg={AtuMensagem} acessos={true} ufs={ufs} tipos={tiposenderecos} nomepessoa={vendedor.pessoa.fantasia_pessoa ? vendedor.pessoa.fantasia_pessoa : ''} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={1}>
                        <Grid item className={classes.griddados}>
                            <Grid container spacing={1}>
                            {doctodig.map((doct: Documentos, index) =>
                                doct.id_doc>2
                                ?
                                <Grid item key={index+1} xs={3}>
                                    <CampoTexto valor={doct.valor_psdc} id={index.toString()} label={doct.desc_doc} placeholder={doct.desc_doc} classe={classes.textoform} tipo='text' required={doct.obrig_doc==='A'} habilitado={true} onchange={onChangeDocumentosInput} erro={(!doct.valor_psdc || doct.valor_psdc.length<1) && doct.obrig_doc==='A'} tamanho={40}/>
                                </Grid>
                                :
                                null
                                )
                            }
                            </Grid>
                        </Grid>
                        </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <EmailsTelefones indem={indemailatu} indfn={indfoneatu} email={emaildigatu} fone={fonedigatu} emails={vendedor.pessoa.emails} fones={vendedor.pessoa.telefones} retemail={AtuEmail} retemails={AtuEmails} retindemail={AtuIndEmail} retfone={AtuFone} retfones={AtuFones} retindfone={AtuIndFone} retmsg={AtuMensagem} acessos={true}/>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <Grid container spacing={1}>
                        <Grid item className={classes.gridtabend}>
                            <Grid container spacing={1}>
                                {ddadcdig.map((ddadc: Dados_Adicionais, index) =>
                                    <Grid item key={index+1} xs={3}>
                                    {
                                        ddadc.tipo_dadc === 'C'
                                        ?
                                        <Selecao valor={!ddadc.id_vdad ? 0 : ddadc.id_vdad} nome={index.toString()} label={ddadc.desc_dadc} required={ddadc.opcional_dadc !=='A'} habilitado={true} onchange={onChangeDadosAdicionaisSelect} conteudo={RetornaValoresDadosAdicionais(ddadc.valores)} nulo={[0,'Indefinido']} classe={classes.textoform} erro={!ValidaDadosAdicionais(ddadc)}/>
                                        :
                                        ddadc.tipo_dadc === 'B' 
                                        ?
                                        ddadc.mascara_dadc==='decimal'
                                        ?
                                        <CampoDecimal valor={ddadc.numero_tbad ? ddadc.numero_tbad : 0}  id={index.toString()} label={ddadc.desc_dadc} placeholder={ddadc.desc_dadc} classe={classes.textoform} tipo='text' required={false} habilitado={true} onchange={onChangeDadosAdicionaisInput} erro={!ValidaDadosAdicionais(ddadc)}/>
                                        :
                                        <CampoMascara valor={ddadc.numero_tbad ? ddadc.numero_tbad : 0} id={index.toString()} label={ddadc.desc_dadc} placeholder={ddadc.desc_dadc} classe={classes.textoform} required={false} habilitado={true} mascara={ddadc.mascara_dadc} onchange={onChangeDadosAdicionaisInput} erro={!ValidaDadosAdicionais(ddadc)}/>
                                        :
                                        ddadc.tipo_dadc === 'A'
                                        ?
                                        <CampoTexto valor={ddadc.texto_tbad ? ddadc.texto_tbad : ''} id={index.toString()} label={ddadc.desc_dadc} placeholder={ddadc.desc_dadc} classe={classes.textoform} tipo='text' required={ddadc.opcional_dadc!=='A'} habilitado={true} onchange={onChangeDadosAdicionaisInput} erro={!ValidaDadosAdicionais(ddadc)} tamanho={ddadc.tamanho_dadc && ddadc.tamanho_dadc>0 ? ddadc.tamanho_dadc : 1000}/>
                                        :
                                        null
                                    }
                                    </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>
                <Grid container spacing={1}>
                    <Grid item className={classes.gridtabend}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={Gravavendedor} endIcon={<SaveIcon/>}>Confirma</Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={Desiste} endIcon={<CancelIcon/>}>Desiste</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}


type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
    dadoobtido: boolean,
    openToast: boolean,
    severidadeToast: string,
    mensagemToast: string,
    duracaoToast: number,
    acessosok: boolean,
}

class EdicaoVendedores extends Component<PropsType, IState>
{
    private _dadosobt: RetornoAcesso;
    private _roteador: Roteador;
    private _id_vend: number;
    private _dadosadicionais: Dados_Adicionais[];
    private _documentos: Documentos[];
    private _tiposendereco: Tipos_Endereco[];
    private _despesas: Despesas[];
    private _ufs: [];
    private _vendedor: Vendedores;
    private _dadoobtido: boolean;
    private _acessos: number;
    private _retornamensagem: boolean;
  
  constructor(props: any)
  {
        super(props);
        this._dadosobt=new RetornoAcesso();
        this._id_vend=0;

        if (props.location.state)
        {
            if(props.location.state.dadosret)
            {
                this._dadosobt=props.location.state.dadosret;
            }
            if (props.location.state.id_edicao)
            {
                this._id_vend=props.location.state.id_edicao;
            }
        }

        if (!this._dadosobt.ok)
        {
            this.props.history.push({pathname: '/'})
        }

        this._roteador=new Roteador(this._dadosobt, '/Vendedores');
        this._roteador.history=props.history;
        this._dadosadicionais=[];
        this._documentos=[];
        this._tiposendereco=[];
        this._ufs=[];
        this._despesas=[];
        this._vendedor = new Vendedores();
        this._dadoobtido=false;
        this._acessos=0;
        this._retornamensagem=false;
    
        if (this._id_vend<0 && this._dadosobt.ok)
        {
            this._dadoobtido=true;
        }
        this.state =
        {
            dadoobtido: this._dadoobtido,
            openToast: false,
            severidadeToast: 'success',
            mensagemToast: '',
            duracaoToast: 6000,
            acessosok: false,
        }
        if (this._dadosobt.ok)
        {
            if (this._id_vend>0)
            {
                srv.Get('Vendedores/'+this._id_vend, '', '', this._dadosobt.objac.token, false)
                .then((ddret: any) => 
                {
                    let dadosret: Resposta = TrataResposta(ddret);
                    if (dadosret.ok)
                    {
                        this._vendedor=dadosret.retorno; this.aumentaAcessos();
                    }
                    else
                    {
                        this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo vendedor (' + dadosret.erro + ')' });
                    }
                })
                .catch(Error => this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo vendedor (' + Error.name + ' - ' + Error.message + ')' }));
            }
            else
            {
                this.aumentaAcessos();
            }
            this.ObtemDados('DadosAdicionais/BE','dados adicionais',this.setadadosadicionais.bind(this));
            this.ObtemDados('Documentos/','documentos',this.setadocumentos.bind(this));
            this.ObtemDados('TiposEndereco/','tipos de endereço',this.setatiposendereco.bind(this));
            this.ObtemDados('Despesas/','despesas',this.setadespesas.bind(this));
            this.ObtemDados('Ufs/','UFs',this.setaufs.bind(this));
        }
    }
    
    setadadosadicionais(registros: any) {this._dadosadicionais=registros; this.aumentaAcessos();};
    setadocumentos(registros: any) {this._documentos=registros; this.aumentaAcessos();};
    setatiposendereco(registros: any) {this._tiposendereco=registros; this.aumentaAcessos();};
    setadespesas(registros: any) {this._despesas=RetornaReceitasDespesas(registros,'B'); this.aumentaAcessos();};
    setaufs(registros: any) {this._ufs=registros; this.aumentaAcessos();};

    aumentaAcessos()
    {
        this._acessos = this._acessos+1
        if(this._acessos>5)
        {
            this._documentos = AtualizaDocumentos(this._documentos, this._vendedor.pessoa.documentos);
            this._dadosadicionais = AtualizaDadosAdicionais(this._dadosadicionais, this._vendedor.dados_adicionais, 'E');
            this._dadosadicionais = AtualizaDadosAdicionais(this._dadosadicionais, this._vendedor.pessoa.dados_adicionais, 'B');
            InicializaEdicao();
            this.setState({acessosok: true, dadoobtido: true });
        }
    }
        
    ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
    {
        srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
            let dadosret:Resposta = TrataResposta(ddret);
            if(dadosret.ok)
            {
                obret(dadosret.retorno)
            }
            else
            {
                this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
                this.aumentaAcessos();
            }
        })
        .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
    }

    ExibeMensagem(mensagem: string, severidade: string, duracao:number, retorna:boolean=false)
    {
        this.setState({openToast: true, severidadeToast: severidade, mensagemToast: mensagem, duracaoToast: duracao})
        this._retornamensagem=retorna;
    }

    FechaMensagem()
    {
        this.setState({openToast: false});
        if (this._retornamensagem)
        {
            this._roteador.rota(11,0);
        }
    }

    render()
    {
        if (!this._dadosobt.ok)
        {
            return null
        }
        else
        {
            return (
                <div className="Home">
                    <header className="Home-header">
                    <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador} recurso='Edição de Vendedor'/>
                    </header>
                    <main>
                    { this.state.dadoobtido ?
                        <PrincipalEdicaoVendedores dadosvend={this._vendedor} dadosobt={this._dadosobt} dadosadicionais={this._dadosadicionais} documentos={this._documentos} tiposenderecos={this._tiposendereco} ufs={this._ufs} despesas={this._despesas} mensagem={this.ExibeMensagem.bind(this)} roteador={this._roteador.rota.bind(this._roteador)} />
                        :
                        <Progresso/>
                    }
                    </main>
                    <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
                </div>
            )
        }
    }
}

export default withRouter(EdicaoVendedores)
