import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import './index.css';
import Login from './Paginas/Login';
import Home from './Paginas/Home';
import Clientes from './Paginas/Clientes';
import Fornecedores from './Paginas/Fornecedores';
import EdicaoClientes from './Paginas/EdicaoClientes';
import Recebimentos from './Paginas/Recebimentos';
import EdicaoRecebimentos from './Paginas/EdicaoRecebimentos';
import GeracaoRecebimentos from './Paginas/GeracaoRecebimentos';
import RetornoBancos from './Paginas/RetornoBancos';
import Pagamentos from './Paginas/Pagamentos';
import EdicaoPagamentos from './Paginas/EdicaoPagamentos';
import TiposContrato from './Paginas/TiposContrato';
import GeracaoRemessas from './Paginas/GeracaoRemessas';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import EdicaoFornecedores from './Paginas/EdicaoFornecedores';
import Operadores from './Paginas/Operadores';
import EdicaoOperadores from './Paginas/EdicaoOperadores';
import Vendedores from './Paginas/Vendedores';
import EdicaoVendedores from './Paginas/EdicaoVendedores';
import GeracaoRPS from './Paginas/GeracaoRPS';
import Excecao from './Paginas/Excecao';
import Comissoes from './Paginas/Comissoes';
import CadastroRedes from './Paginas/CadastroRedes';
import ResultadosDetalhados from './Paginas/ResultadosDetalhados';

const theme = createMuiTheme({
    typography: {
      fontSize: 13,
      fontFamily: 'Arial'
    },
  });

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
              <Route path='/Resultados' exact={true} component={ResultadosDetalhados}/>
              <Route path='/Comissoes' exact={true} component={Comissoes}/>
              <Route path='/GeracaoRPS' exact={true} component={GeracaoRPS}/>
              <Route path='/GeracaoRemessas' exact={true} component={GeracaoRemessas}/>
              <Route path='/CadastroRedes' exact={true} component={CadastroRedes}/>
              <Route path='/TiposContratos' exact={true} component={TiposContrato}/>
              <Route path='/RetornoBancos' exact={true} component={RetornoBancos}/>
              <Route path='/GeracaoRecebimentos' exact={true} component={GeracaoRecebimentos}/>
              <Route path='/EdicaoRecebimentos' exact={true} component={EdicaoRecebimentos}/>
              <Route path='/Recebimentos' exact={true} component={Recebimentos}/>
              <Route path='/EdicaoPagamentos' exact={true} component={EdicaoPagamentos}/>
              <Route path='/Pagamentos' exact={true} component={Pagamentos}/>
              <Route path='/EdicaoClientes' exact={true} component={EdicaoClientes}/>
              <Route path='/Clientes' exact={true} component={Clientes}/>
              <Route path='/EdicaoFornecedores' exact={true} component={EdicaoFornecedores}/>
              <Route path='/Fornecedores' exact={true} component={Fornecedores}/>
              <Route path='/EdicaoOperadores' exact={true} component={EdicaoOperadores}/>
              <Route path='/Operadores' exact={true} component={Operadores}/>
              <Route path='/EdicaoVendedores' exact={true} component={EdicaoVendedores}/>
              <Route path='/Vendedores' exact={true} component={Vendedores}/>
              <Route path='/' exact={true} component={Login}/>
              <Route path='/Home' exact={true} component={Home}/>
              <Route path='*' exact={true} component={Excecao}/>
          </Switch>
      </BrowserRouter>
    </ThemeProvider>
      , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();