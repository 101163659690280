import ReactPDF from '@react-pdf/renderer';
import { Clientes } from '../Modelos/Clientes';
import { Contratos } from '../Modelos/Contratos';
import { Tipos_Contrato } from '../Modelos/Tipos_Contrato';
import { formatarCampo, ParametrosDiversos, meses, RetornaStringData, nulltoNumber } from '../Servicos/Utilidades';
import extenso from 'extenso';

type ParPreenchimento = {
    chave: string,
    conteudo: string
}
  
export type DocPDF = 
{
    titulo: string,
    defaultstyle: ReactPDF.Style,
    conteudo: ObjPDF[];
}

export type ObjPDF =
{
    paragrafo?: ParagrafoPDF, imagem?: ImagemPDF, tabela?: DadosTabelaPDF
}

export type ImagemPDF =
{
    style?: ReactPDF.Style, 
    quebra?: boolean,
    src: string
}

export type ParagrafoPDF =
{
    style?: ReactPDF.Style,
    quebra?: boolean,
    texto: {style?: ReactPDF.Style, conteudo: string}[]
}

export type Assinaturas =
{
    titulo: string [],
    signatario: string[],
    cliente: string[],
    testemunha1: string[],
    testemunha2: string[]
}

export type HeaderTabelaPDF =
{
    bordas: {top: boolean, bottom: boolean, left: boolean, right: boolean},
    colunas: {descricao: string, borda?: 'dotted' | 'dashed' | 'solid' | 'double' | 'groove' | 'ridge' | 'inset' | 'outset' | 'none' | 'hidden',  alinhamento: 'left' | 'center' | 'right', familia: string, fonte: number | string, tamanho: number, bordas: {top: number, bottom: number, left: number, right: number}}[]
}
export type LinhasTabelaPDF =
{
    bordas: {top: boolean, bottom: boolean, left: boolean, right: boolean},
    colunas: {descricao: string, borda?: 'dotted' | 'dashed' | 'solid' | 'double' | 'groove' | 'ridge' | 'inset' | 'outset' | 'none' | 'hidden', alinhamento: 'left' | 'center' | 'right', familia: string, fonte: number | string, tamanho: number, formato?: string }[]
}

export type DadosTabelaPDF = 
{
    style?: ReactPDF.Style,
    quebra?: boolean,
    header: HeaderTabelaPDF,
    dados: LinhasTabelaPDF,
    valores: any[]
}

export default class MontaPDF
{
    private _defaultstyle: ReactPDF.Style = {fontFamily: 'Arial', fontSize: 12, margin: 12, textAlign: 'justify'};
    private _defaultfontFamily: string = 'Arial';
    private _defaultfontSize: string | number = 12;
    private _defaultmargin: string | number = 12;
    private _defaulttextAlign: 'left' | 'right' | 'center' | 'justify' = 'justify';

    private ObtemQuebra(linha: string): {novalinha: string, quebra: boolean}
    {
        let novalinha: string=linha;
        let quebra: boolean=false;
        let posquebra=linha.toUpperCase().indexOf('[QUEBRA]')
        if (posquebra>=0)
        {
            quebra=true
            novalinha=(linha.length>(posquebra+8) ? linha.substr(posquebra+8,linha.length-posquebra+8) : '')
        }
        return {novalinha: novalinha, quebra: quebra};
    }
    private ObtemEstilo(linha: string): {novalinha: string, novoestilo?: ReactPDF.Style, novopadrao?: boolean}
    {
        let novoestilo: ReactPDF.Style | undefined = undefined;
        let novalinha: string = linha;
        let padrao: boolean = false;
        let posfinal: number = 6;
        let postyle=linha.toUpperCase().indexOf('[PADRAO')
        if (postyle>=0)
        {
            padrao=true;
            posfinal=7;
        }
        else
        {
            postyle=linha.toUpperCase().indexOf('[STYLE')
        }
        if (postyle !==-1)
        {
            let posfimstyle=linha.indexOf(']');
            if (posfimstyle>(postyle+posfinal))
            {
                let ininova=posfimstyle+1;
                novalinha=(linha.length>(ininova+1) ? linha.substr(ininova,linha.length-ininova) : '')
                let parst: string[] = linha.substr(postyle+posfinal,posfimstyle-(postyle+posfinal)).split(';');
                if (parst.length>0)
                {
                    novoestilo=JSON.parse(JSON.stringify(this._defaultstyle)) as ReactPDF.Style;
                    if(parst[0].trim().length>0)
                    {
                        novoestilo.fontFamily=parst[0].trim()
                        if (padrao)
                        {
                            this._defaultfontFamily=novoestilo.fontFamily;
                        }
                    }
                    if (parst.length>1)
                    {
                        if(parst[1].trim().length>0)
                        {
                            novoestilo.fontSize=(parseInt(parst[1].trim(),10) ? parseInt(parst[1].trim(),10) : parst[1].trim())
                            if (padrao)
                            {
                                this._defaultfontSize = novoestilo.fontSize;
                            }
                        }
                        if (parst.length>2)
                        {
                            if(parst[2].trim().length>0)
                            {
                                novoestilo.margin=(Number(parst[2].trim()) ? Number(parst[2].trim()) : parst[2].trim())
                                if (padrao)
                                {
                                    this._defaultmargin = novoestilo.margin;
                                }
                            }
                            if (parst.length>3)
                            {
                                parst[3]=parst[3].trim()
                                if(parst[3].length>0)
                                {
                                    if(parst[3] === 'left' || parst[3] === 'right' || parst[3] === 'center' || parst[3] === 'justify')
                                    {
                                        novoestilo.textAlign=parst[3];
                                        if (padrao)
                                        {
                                            this._defaulttextAlign = novoestilo.textAlign;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (padrao)
                    {
                        this._defaultstyle={fontFamily: this._defaultfontFamily, fontSize: this._defaultfontSize, margin: this._defaultmargin, textAlign: this._defaulttextAlign}
                    }
                }
            }
        }
        return {novalinha: novalinha, novoestilo: novoestilo, novopadrao: padrao};
    }

    private ObtemImagem(linha: string): {tipo: 'A' | 'B', novalinha: string}
    {
        let novalinha: string=linha;
        let postyle=linha.toUpperCase().indexOf('[IMAGEM]')
        if (postyle !==-1)
        {
            novalinha=(linha.length>(postyle+8) ? linha.substr(postyle+8,linha.length-(postyle+8)) : '')
        }
        return {tipo: postyle===-1 ? 'A' : 'B', novalinha: novalinha};
    }

    private ObtemTabela(linha: string): DadosTabelaPDF | undefined
    {
        let tabret: DadosTabelaPDF={ 
        header: {bordas: {top: true, bottom: true, left: true, right: true}, colunas: []},
        dados: {bordas:{top: true, bottom: true, left: true, right: true}, colunas: []},
        valores: []};

        let retok: boolean = false;

        let poshead=linha.toUpperCase().indexOf('[HEADER')
        if (poshead>=0)
        {
            let posfimhead=linha.indexOf(']');
            if (posfimhead>(poshead+7))
            {
                let parst: string[] = linha.substr(poshead+7,posfimhead-(poshead+7)).split('|');
                let detcol: string[]
                let vlrdado: string;
                if (parst.length>1)
                {
                    retok=true;
                    let idxcol: number;
                    parst.forEach((cln,idc) =>
                    {
                        if (idc<parst.length-1)
                        {
                            detcol=cln.trim().split(';');
                            if (detcol.length>0)
                            {
                                tabret.header.colunas.push({descricao: detcol[0].trim(), borda: 'solid', alinhamento: 'center', familia: this._defaultfontFamily, fonte: this._defaultfontSize, tamanho: 100, bordas: {top: 0, bottom: 0, left: 0, right: 0}});
                                tabret.dados.colunas.push({descricao: 'coluna'+(idc.toString().trim().padStart(2,'0')), borda: 'solid', alinhamento: 'center', familia: this._defaultfontFamily, fonte: this._defaultfontSize, tamanho: 100});
                                idxcol=tabret.header.colunas.length-1;
                                if (detcol.length>1)
                                {
                                    vlrdado=detcol[1].trim();
                                    if (vlrdado==='dotted' || vlrdado==='dashed' || vlrdado==='solid' || vlrdado==='double' || vlrdado==='groove' || vlrdado==='ridge' || vlrdado==='inset' || vlrdado==='outset' || vlrdado==='none' || vlrdado==='hidden')
                                    {
                                        tabret.header.colunas[idxcol].borda=vlrdado;
                                        tabret.dados.colunas[idxcol].borda=vlrdado;
                                    }
                                    if (detcol.length>2)
                                    {
                                        vlrdado=detcol[2].trim();
                                        if (vlrdado==='left' || vlrdado==='center' || vlrdado==='right')
                                        {
                                            tabret.header.colunas[idxcol].alinhamento=vlrdado;
                                        }
                                        if (detcol.length>3)
                                        {
                                            vlrdado=detcol[3].trim();
                                            if (vlrdado==='left' || vlrdado==='center' || vlrdado==='right')
                                            {
                                                tabret.dados.colunas[idxcol].alinhamento=vlrdado;
                                            }
                                            if (detcol.length>4)
                                            {
                                                vlrdado=detcol[4].trim();
                                                if (vlrdado.length>0)
                                                {
                                                    tabret.header.colunas[idxcol].familia=vlrdado;
                                                    tabret.dados.colunas[idxcol].familia=vlrdado;
                                                }
                                                if (detcol.length>5)
                                                {
                                                    vlrdado=detcol[5].trim();
                                                    tabret.header.colunas[idxcol].fonte=parseInt(vlrdado,10) ? parseInt(vlrdado): vlrdado;
                                                    tabret.dados.colunas[idxcol].fonte=parseInt(vlrdado,10) ? parseInt(vlrdado): vlrdado;
                                                    if (detcol.length>6)
                                                    {
                                                        vlrdado=detcol[6].trim();
                                                        if (parseInt(vlrdado,10))
                                                        {
                                                            tabret.header.colunas[idxcol].tamanho=parseInt(vlrdado,10);
                                                            tabret.dados.colunas[idxcol].tamanho=parseInt(vlrdado,10);
                                                        }
                                                        if (detcol.length>7)
                                                        {
                                                            vlrdado=detcol[7].trim();
                                                            if (parseInt(vlrdado,10))
                                                            {
                                                                tabret.header.colunas[idxcol].bordas.top=parseInt(vlrdado,10);
                                                            }
                                                            if (detcol.length>8)
                                                            {
                                                                vlrdado=detcol[8].trim();
                                                                if (parseInt(vlrdado,10))
                                                                {
                                                                    tabret.header.colunas[idxcol].bordas.bottom=parseInt(vlrdado,10);
                                                                }
                                                                if (detcol.length>9)
                                                                {
                                                                    vlrdado=detcol[9].trim();
                                                                    if (parseInt(vlrdado,10))
                                                                    {
                                                                        tabret.header.colunas[idxcol].bordas.left=parseInt(vlrdado,10);
                                                                    }
                                                                    if (detcol.length>10)
                                                                    {
                                                                        vlrdado=detcol[10].trim();
                                                                        if (parseInt(vlrdado,10))
                                                                        {
                                                                            tabret.header.colunas[idxcol].bordas.right=parseInt(vlrdado,10);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else
                        {
                            detcol=cln.trim().split(';');
                            if (detcol.length>0)
                            {
                                tabret.header.bordas.top=(detcol[0].trim()==='0' ? false : true);
                                if (detcol.length>1)
                                {
                                    tabret.header.bordas.bottom=(detcol[1].trim()==='0' ? false : true);
                                    if (detcol.length>2)
                                    {
                                        tabret.header.bordas.left=(detcol[2].trim()==='0' ? false : true);
                                        if (detcol.length>3)
                                        {
                                            tabret.header.bordas.right=(detcol[3].trim()==='0' ? false : true);
                                            if (detcol.length>4)
                                            {
                                                tabret.dados.bordas.top=(detcol[4].trim()==='0' ? false : true);
                                                if (detcol.length>5)
                                                {
                                                    tabret.dados.bordas.bottom=(detcol[5].trim()==='0' ? false : true);
                                                    if (detcol.length>6)
                                                    {
                                                        tabret.dados.bordas.left=(detcol[6].trim()==='0' ? false : true);
                                                        if (detcol.length>7)
                                                        {
                                                          tabret.dados.bordas.right=(detcol[7].trim()==='0' ? false : true);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    })
                }
            }
        }
        return retok ? tabret : undefined;
    }

    private ObtemLinhaTabela(linha: string, qtdcol: number): Object | undefined
    {
        let strret: string='{';
        let retok: boolean = false;

        let poshead=linha.toUpperCase().indexOf('[LINHA')
        if (poshead>=0)
        {
            let posfimhead=linha.indexOf(']');
            if (posfimhead>(poshead+6))
            {
                retok=true;
                let parst: string[] = linha.substr(poshead+6,posfimhead-(poshead+6)).split('|');
                let idxcol=0;
                while (idxcol<parst.length && idxcol<qtdcol)
                {
                    strret=strret + (idxcol===0 ? '' : ', ') + '"coluna'+(idxcol.toString().trim().padStart(2,'0'))+'": "'+parst[idxcol]+'"';
                    idxcol=idxcol+1;
                }
                while (idxcol<qtdcol)
                {
                    strret=strret + (idxcol===0 ? '' : ', ') + '"coluna'+(idxcol.toString().trim().padStart(2,'0'))+'": " "';
                    idxcol=idxcol+1;
                }
                strret=strret+'}';
            }
        }
        return retok ? JSON.parse(strret) : undefined;
    }

    private ObtemAssinaturas(linha: string): {tipodado: string, dado: string[]}
    {
        let retdado: {tipodado: string, dado: string[]} = {tipodado: '', dado: []};
        let idx: number = 0
        let dadosbusca: {tpdado: string , dado: string}[] = [{tpdado: 'A' , dado: 'ASSINATURAS'},{tpdado: 'B' , dado: 'SIGNATARIO'},{tpdado: 'C' , dado: 'T1'},{tpdado: 'D' , dado: 'T2'}]

        while(idx<dadosbusca.length)
        {
            let poshead=linha.toUpperCase().indexOf('['+dadosbusca[idx].dado)
            if (poshead>=0)
            {
                let posfimhead=linha.indexOf(']');
                if (posfimhead>(poshead+dadosbusca[idx].dado.length+1))
                {
                    let parst: string[] = linha.substr(poshead+dadosbusca[idx].dado.length+1,posfimhead-(poshead+dadosbusca[idx].dado.length+1)).split('|');
                    parst.forEach(inf => retdado.dado.push(inf));
                    retdado.tipodado=dadosbusca[idx].tpdado
                    break;
                }
            }
            idx=idx+1;
        }
        return retdado;
    }

    public PreencheDados(dadoscli: Clientes, contrato: Contratos, tipocontr: Tipos_Contrato): DocPDF
    {
        let txtbase: string[] = tipocontr.conteudo_tpct.split(String.fromCharCode(10));
        let retdoc: DocPDF = {titulo: 'Contrato RCKY', defaultstyle: this._defaultstyle, conteudo:[]};
        let linha: string = '';
        let posstr: number = 0;
        let posfimstr: number = 0;
        let valores: ParPreenchimento[] = [];
        let indend: number = Math.max(dadoscli.pessoa.enderecos.findIndex(end => end.padrao_enps==='A'),0);
        let inddoc: number = dadoscli.pessoa.documentos.findIndex(docto => docto.id_doc===1 || docto.id_doc===2);
        let indsign: number = dadoscli.dados_adicionais.findIndex(dadc => dadc.id_dadc===ParametrosDiversos.iddadcsign);
        let indcargosign: number = dadoscli.dados_adicionais.findIndex(dadc => dadc.id_dadc===ParametrosDiversos.iddadccargosign);
        let indrgsign: number = dadoscli.dados_adicionais.findIndex(dadc => dadc.id_dadc===ParametrosDiversos.iddadcrgsign);
        let indcpfsign: number = dadoscli.dados_adicionais.findIndex(dadc => dadc.id_dadc===ParametrosDiversos.iddadccpfsign);
        let indlicuso: number = contrato.srvcont.findIndex(svc => svc.recor_svct==='A');
        let indadesao: number = contrato.srvcont.findIndex(svc => svc.recor_svct==='B');
        let valadesao: number = contrato.srvcont.filter(lcto => lcto.recor_svct==='B').reduce((valtot,lcto) => valtot + lcto.valor_svct, 0);
        let retestilo: {novalinha: string, novoestilo?: ReactPDF.Style, novopadrao?: boolean};
        let linhatipo: {tipo: string, novalinha:string};
        let paragrafo: string
        let tabela: DadosTabelaPDF | undefined;
        let parpdf: ParagrafoPDF;
        let linhatb: Object | undefined;
        let dadosass: {tipodado: string, dado: string[]};
        let assinatura: Assinaturas;
        let strobj: string;
        let flconv: boolean;
        let dtcontr: Date = typeof contrato.dataass_cont==='string' ? new Date(contrato.dataass_cont) : contrato.dataass_cont;
        let retquebra: {novalinha: string, quebra: boolean};
        let quebra: boolean=false;
        let indtab: boolean=false;

        valores.push({chave: '[NUMCONT]', conteudo: dadoscli.id_cli.toString().trim()});
        valores.push({chave: '[LOJA]', conteudo: nulltoNumber(dadoscli.loja_cli).toString().trim()});
        valores.push({chave: '[RAZÃO SOCIAL]', conteudo: dadoscli.pessoa.nome_pessoa.trim()});
        valores.push({chave: '[FANTASIA]', conteudo: dadoscli.pessoa.fantasia_pessoa.toString().trim()});
        valores.push({chave: '[ENDEREÇO]', conteudo: dadoscli.pessoa.enderecos.length>0 ? (dadoscli.pessoa.enderecos[indend].endereco.logr_end.trim()+', '+dadoscli.pessoa.enderecos[indend].numero_end.trim() + (dadoscli.pessoa.enderecos[indend].compl_end.trim().length> 0 ? (' - '+dadoscli.pessoa.enderecos[indend].compl_end.trim()) : '')) : ''});
        valores.push({chave: '[BAIRRO]', conteudo: dadoscli.pessoa.enderecos.length>0 ? (dadoscli.pessoa.enderecos[indend].endereco.bairro_end.trim()) : ''});
        valores.push({chave: '[CIDADE]', conteudo: dadoscli.pessoa.enderecos.length>0 ? (dadoscli.pessoa.enderecos[indend].endereco.cidade.nome_cid.trim()+'/'+dadoscli.pessoa.enderecos[indend].endereco.cidade.uf.sigla_uf) : ''});
        valores.push({chave: '[CNPJ]', conteudo: inddoc>-1 ? formatarCampo(dadoscli.pessoa.documentos[inddoc].valor_psdc,'cpfcnpj') : ''});
        valores.push({chave: '[DATA]', conteudo: dtcontr.getDate().toString().trim()+' de '+meses[dtcontr.getMonth()]+' de '+dtcontr.getFullYear().toString()});
        valores.push({chave: '[LICVAL]', conteudo: indlicuso>-1 ? formatarCampo(contrato.srvcont[indlicuso].valor_svct.toString().trim(), 'decimal', true) : ''});
        valores.push({chave: '[LICEXTENSO]', conteudo: indlicuso>-1 ? extenso(formatarCampo(contrato.srvcont[indlicuso].valor_svct.toString().trim(), 'decimal', true), {mode: 'currency', currency: {type: 'BRL'}}) : ''});
        valores.push({chave: '[ADVAL]', conteudo: indadesao>-1 ? formatarCampo(valadesao.toString().trim(), 'decimal', true) : ''});
        valores.push({chave: '[ADEXTENSO]', conteudo: indadesao>-1 ? extenso(formatarCampo(valadesao.toString().trim(), 'decimal', true), {mode: 'currency', currency: {type: 'BRL'}}) : ''});
        valores.push({chave: '[DIALIC]', conteudo: indadesao>-1 ? contrato.srvcont[indlicuso].dia_svct.toString().trim() : ''});
        valores.push({chave: '[PRILIC]', conteudo: indlicuso>-1 ? RetornaStringData(contrato.srvcont[indlicuso].data_svct, false) : ''});
        valores.push({chave: '[DTINICIO]', conteudo: indadesao>-1 ? RetornaStringData(contrato.vigini_cont, false) : ''});
        
        let idx=0;
        while (idx<txtbase.length)
        {
            linha=txtbase[idx];
            indtab=linha.indexOf('[LINHA')!==-1 || linha.indexOf('[HEADER')!==-1;
            valores.forEach(valor =>
                {
                    posstr=linha.indexOf(valor.chave)
                    while (posstr!==-1)
                    {
                        linha = (posstr===0 ? '' : linha.substr(0,posstr)) + (indtab ? '' : '*$#@') + valor.conteudo + (indtab ? '' : '@#$*') + (linha.length>posstr+valor.chave.length ? linha.substr(posstr+valor.chave.length,linha.length-(posstr+valor.chave.length)) : '');
                        posstr=linha.indexOf(valor.chave)
                    }
                });
            txtbase[idx]=linha
            idx=idx+1
        }

        idx=0;

        while (idx<txtbase.length)
        {
            paragrafo=txtbase[idx];
            retquebra=this.ObtemQuebra(paragrafo);
            linha=retquebra.novalinha;
            quebra=retquebra.quebra;
            retestilo=this.ObtemEstilo(linha);
            if (idx===0 && retestilo.novopadrao)
            {
                retdoc.defaultstyle=this._defaultstyle;
            }
            if (retestilo.novoestilo===undefined || retestilo.novalinha.trim().length>0)
            {
                linha=retestilo.novalinha;
                linhatipo=this.ObtemImagem(linha);
                if (linhatipo.tipo==='B')
                {
                    retdoc.conteudo.push({imagem: {style: retestilo.novoestilo ? retestilo.novoestilo : this._defaultstyle, quebra: quebra, src: linhatipo.novalinha}})
                }
                else
                {
                    tabela=this.ObtemTabela(linha);
                    if (tabela)
                    {
                        tabela.style=retestilo.novoestilo ? retestilo.novoestilo : this._defaultstyle;
                        tabela.quebra=quebra;
                        idx=idx+1;
                        while (idx<txtbase.length)
                        {
                            linhatb=this.ObtemLinhaTabela(txtbase[idx],tabela.dados.colunas.length);
                            if (linhatb)
                            {
                                tabela.valores.push(linhatb);
                                idx=idx+1;
                            }
                            else
                            {
                                break;
                            }
                        }
                        retdoc.conteudo.push({tabela: tabela});
                        idx=idx-1
                    }
                    else
                    {
                        dadosass=this.ObtemAssinaturas(linha)
                        if(dadosass.tipodado.length>0)
                        {
                            assinatura={titulo: [], signatario: [], cliente: [], testemunha1: [], testemunha2: []};
                            while(dadosass.tipodado.length>0)
                            {
                                switch (dadosass.tipodado)
                                {
                                    case 'A':
                                    {
                                        assinatura.titulo=dadosass.dado;
                                        break;
                                    }
                                    case 'B':
                                    {
                                        assinatura.signatario=dadosass.dado;
                                        break;
                                    }
                                    case 'C':
                                    {
                                        assinatura.testemunha1=dadosass.dado;
                                        break;
                                    }
                                    case 'D':
                                    {
                                        assinatura.testemunha2=dadosass.dado;
                                        break;
                                    }
                                }
                                idx=idx+1
                                if (idx<txtbase.length)
                                {
                                    dadosass=this.ObtemAssinaturas(txtbase[idx]);
                                }
                            }
                            idx=idx-1
                            if (assinatura.signatario.length>0)
                            {
                                tabela={
                                    quebra: quebra,
                                    style: retestilo.novoestilo ? retestilo.novoestilo : this._defaultstyle,
                                    header: {bordas: {top: false, bottom: false, left: false, right: false},
                                    colunas: [
                                        {descricao: 'Contratada', borda: 'none',familia: this._defaultfontFamily,  fonte: this._defaultfontSize, alinhamento: 'left', tamanho: 40, bordas:{top: 0, bottom: 0, left: 0, right: 0}},
                                        {descricao: '', borda: 'none',familia: this._defaultfontFamily,  fonte: this._defaultfontSize, alinhamento: 'left', tamanho: 20, bordas:{top: 0, bottom: 0, left: 0, right: 0}},
                                        {descricao: 'Contratante', borda: 'none',familia: this._defaultfontFamily,  fonte: this._defaultfontSize, alinhamento: 'left', tamanho: 40, bordas:{top: 0, bottom: 0, left: 0, right: 0}},
                                    ]},
                                    dados: {bordas: {top: true, bottom: true, left: true, right: true}, colunas: []},
                                    valores: []
                                }
                                if (assinatura.titulo.length>0)
                                {
                                    if(assinatura.titulo[0].trim().length>0)
                                    {
                                        tabela.header.colunas[0].descricao=assinatura.titulo[0].trim();
                                    }
                                    if (assinatura.titulo.length>1)
                                    {
                                        if(assinatura.titulo[1].trim().length>1)
                                        {
                                            tabela.header.colunas[2].descricao=assinatura.titulo[1].trim();
                                        }
                                    }
                                }
                                retdoc.conteudo.push({paragrafo: {style: {margin: '5px 0 0 0'}, texto: [{conteudo:''}]}});
                                retdoc.conteudo.push({tabela: tabela});
                                retdoc.conteudo.push({paragrafo: {style: {margin: '30px 0 0 0'}, texto: [{conteudo:''}]}});
                                if (indsign>=0)
                                {
                                    if (dadoscli.dados_adicionais[indsign].texto_tbad.trim().length>0)
                                    {
                                        assinatura.cliente.push(dadoscli.dados_adicionais[indsign].texto_tbad.trim());
                                    }
                                }
                                if (indcargosign>=0)
                                {
                                    if (dadoscli.dados_adicionais[indcargosign].texto_tbad.trim().length>0)
                                    {
                                        assinatura.cliente.push(dadoscli.dados_adicionais[indcargosign].texto_tbad.trim());
                                    }
                                }
                                if (indrgsign>=0)
                                {
                                    if (dadoscli.dados_adicionais[indrgsign].texto_tbad.trim().length>0)
                                    {
                                        assinatura.cliente.push('RG: '+(dadoscli.dados_adicionais[indrgsign].texto_tbad.trim()));
                                    }
                                }
                                if (indcpfsign>=0)
                                {
                                    if (dadoscli.dados_adicionais[indcpfsign].texto_tbad.trim().length>0)
                                    {
                                        assinatura.cliente.push('CPF: '+(formatarCampo(dadoscli.dados_adicionais[indcpfsign].texto_tbad.trim(),'cpfcnpj')));
                                    }
                                }
                                tabela={
                                    style: retestilo.novoestilo ? retestilo.novoestilo : this._defaultstyle,
                                    header: {bordas: {top: false, bottom: false, left: false, right: false}, 
                                    colunas: [
                                        {descricao: '', borda: 'none',familia: 'Arial',  fonte: 10, alinhamento: 'left', tamanho: 40, bordas:{top: 0, bottom: 1, left: 0, right: 0}},
                                        {descricao: '', borda: 'none',familia: 'Arial',  fonte: 10, alinhamento: 'left', tamanho: 20, bordas:{top: 0, bottom: 0, left: 0, right: 0}},
                                        {descricao: '', borda: 'none',familia: 'Arial',  fonte: 10, alinhamento: 'left', tamanho: 40, bordas:{top: 0, bottom: 1, left: 0, right: 0}}
                                    ]},
                                    dados: {bordas: {top: false, bottom: false, left: false, right: false}, colunas: [
                                        {descricao: 'coluna1', borda: 'none',familia: 'Arial-Bold',  fonte: 10, alinhamento: 'left', tamanho: 40},
                                        {descricao: 'coluna3', borda: 'none',familia: 'Arial-Bold',  fonte: 10, alinhamento: 'left', tamanho: 20},
                                        {descricao: 'coluna2', borda: 'none',familia: 'Arial-Bold',  fonte: 10, alinhamento: 'left', tamanho: 40},
                                    ]},
                                    valores: []
                                }
                                posstr=0;
                                while (posstr<assinatura.signatario.length || posstr<assinatura.cliente.length)
                                {
                                    strobj='{"coluna1": "'+(posstr<assinatura.signatario.length ? assinatura.signatario[posstr].trim() : '')+'", "coluna2": "'+(posstr<assinatura.cliente.length ? assinatura.cliente[posstr].trim() : '')+'", "coluna3": ""}';
                                    tabela.valores.push(JSON.parse(strobj));
                                    posstr=posstr+1
                                }
                                retdoc.conteudo.push({tabela: tabela});
                                if (assinatura.testemunha1.length>0 || assinatura.testemunha2.length>0)
                                {
                                    tabela={
                                        style: retestilo.novoestilo ? retestilo.novoestilo : this._defaultstyle,
                                        header: {bordas: {top: false, bottom: false, left: false, right: false},
                                        colunas: [
                                        {descricao: 'Testemunhas', borda: 'none',familia: this._defaultfontFamily,  fonte: this._defaultfontSize, alinhamento: 'left', tamanho: 40, bordas:{top: 0, bottom: 0, left: 0, right: 0}},
                                        {descricao: '', borda: 'none',familia: this._defaultfontFamily,  fonte: this._defaultfontSize, alinhamento: 'left', tamanho: 20, bordas:{top: 0, bottom: 0, left: 0, right: 0}},
                                        {descricao: '', borda: 'none',familia: this._defaultfontFamily,  fonte: this._defaultfontSize, alinhamento: 'left', tamanho: 40, bordas:{top: 0, bottom: 0, left: 0, right: 0}},
                                        ]},
                                        dados: {bordas: {top: true, bottom: true, left: true, right: true}, colunas: []},
                                        valores: []
                                    }
                                    if (assinatura.titulo.length>2)
                                    {
                                        if(assinatura.titulo[2].trim().length>0)
                                        {
                                        tabela.header.colunas[0].descricao=assinatura.titulo[2].trim();
                                        }
                                    }
                                    retdoc.conteudo.push({paragrafo: {style: {margin: '5px 0 0 0'}, texto: [{conteudo:''}]}});
                                    retdoc.conteudo.push({tabela: tabela});
                                    retdoc.conteudo.push({paragrafo: {style: {margin: '30px 0 0 0'}, texto: [{conteudo:''}]}});
                                    tabela={
                                        style: retestilo.novoestilo ? retestilo.novoestilo : this._defaultstyle,
                                        header: {bordas: {top: false, bottom: false, left: false, right: false}, 
                                        colunas: [
                                        {descricao: '', borda: 'none',familia: 'Arial',  fonte: 10, alinhamento: 'left', tamanho: 40, bordas:{top: 0, bottom: assinatura.testemunha1.length>0 ? 1 : 0, left: 0, right: 0}},
                                        {descricao: '', borda: 'none',familia: 'Arial',  fonte: 10, alinhamento: 'left', tamanho: 20, bordas:{top: 0, bottom: 0, left: 0, right: 0}},
                                        {descricao: '', borda: 'none',familia: 'Arial',  fonte: 10, alinhamento: 'left', tamanho: 40, bordas:{top: 0, bottom: assinatura.testemunha2.length>0 ? 1 : 0, left: 0, right: 0}}
                                        ]},
                                        dados: {bordas: {top: false, bottom: false, left: false, right: false}, colunas: [
                                        {descricao: 'coluna1', borda: 'none',familia: 'Arial-Bold',  fonte: 10, alinhamento: 'left', tamanho: 40},
                                        {descricao: 'coluna3', borda: 'none',familia: 'Arial-Bold',  fonte: 10, alinhamento: 'left', tamanho: 20},
                                        {descricao: 'coluna2', borda: 'none',familia: 'Arial-Bold',  fonte: 10, alinhamento: 'left', tamanho: 40},
                                        ]},
                                        valores: []
                                    }
                                    posstr=0;
                                    while (posstr<assinatura.testemunha1.length || posstr<assinatura.testemunha2.length)
                                    {
                                        strobj='{"coluna1": "'+(posstr<assinatura.testemunha1.length ? assinatura.testemunha1[posstr].trim() : '')+'", "coluna2": "'+(posstr<assinatura.testemunha2.length ? assinatura.testemunha2[posstr].trim() : '')+'", "coluna3": ""}'
                                        tabela.valores.push(JSON.parse(strobj));
                                        posstr=posstr+1
                                    }
                                    retdoc.conteudo.push({tabela: tabela});
                                }
                            }
                        }
                        else
                        {
                            parpdf={style: retestilo.novoestilo ? retestilo.novoestilo : this._defaultstyle, quebra: quebra, texto:[]};
                            posstr=linha.indexOf('*$#@');
                            posfimstr=linha.indexOf('@#$*')
                            flconv=false;
                            while (posstr>=0 && posfimstr>(posstr+4))
                            {
                                flconv=true;
                                if(posstr===0)
                                {
                                    parpdf.texto.push({style: {fontFamily:'Arial-Bold', fontSize: retestilo.novoestilo ? retestilo.novoestilo.fontSize : this._defaultfontSize}, conteudo: linha.substr(posstr+4,posfimstr-(posstr+4))})
                                }
                                else
                                {
                                    parpdf.texto.push({conteudo: linha.substr(0,posstr)});
                                    parpdf.texto.push({style: {fontFamily:'Arial-Bold', fontSize: retestilo.novoestilo ? retestilo.novoestilo.fontSize : this._defaultfontSize}, conteudo: linha.substr(posstr+4,posfimstr-(posstr+4))})
                                }
                                linha = linha.length>(posfimstr+4) ? linha.substr(posfimstr+4,linha.length-posfimstr+4) : '';
                                posstr=linha.indexOf('*$#@');
                                posfimstr=linha.indexOf('@#$*')
                            }
                            if (!flconv || linha.length>0)
                            {
                                parpdf.texto.push({conteudo:linha});
                            }
                            retdoc.conteudo.push({paragrafo: parpdf});
                        }
                    }
                }
            }
            idx=idx+1
        }
        return retdoc;
    }
}
  