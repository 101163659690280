import React, { Component } from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { RetornaDataAAAAMMDD, Resposta, TrataResposta, DifSegundos, formatarCampo, retPeriodoFiltro, achaDescricao, DashProps } from '../Servicos/Utilidades';
import { ApiService } from '../Servicos/ApiService';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Dashboard_Operador } from '../Modelos/Dashboard_Operador';
import TituloDash from '../Componentes/TituloDash';
import Grafico from './Grafico';

const srv = new ApiService();

interface DashPgtoState {
    atutela: boolean,
    erro: string,
    dados: {qttot:number, valortot:number, qtvenc:number, valorvenc:number, qtavenc:number, valoravenc:number, qtpagos:number, valorpagos:number},
    atuagora: boolean
}

class DashPagamentos extends Component<DashProps, DashPgtoState>
{
    private _idinterval: number;
    private _dadosobt: RetornoAcesso;
    private _inicial: Date;
    private _parms: {periodo: number, datini: Date | undefined, datfin: Date |undefined};
    private _dash: Dashboard_Operador;
    
    constructor(props: any)
    {
        super(props);

        this._parms={periodo:0, datini: undefined, datfin: undefined};
        this._dash=this.props.dashoper;
        this._parms=retPeriodoFiltro(achaDescricao(1,this._dash.filtros,'id_tfdb','valores_fdbo'));
        this._dadosobt=props.dadosacesso;
        this._inicial=new Date();
        this._idinterval=-1;
        this.state =
        {
            atutela: false,
            erro: '',
            dados: {qttot: 0, valortot: 0, qtvenc: 0, valorvenc: 0, qtavenc: 0, valoravenc: 0, qtpagos: 0, valorpagos: 0},
            atuagora: false
        }

        if (window)
        {
            this._idinterval=window.setInterval(this.atualizahora.bind(this),1000)
        }
        this.atualizaDados();
    }

    atualizaDash(dashatu: Dashboard_Operador)
    {
        this._dash=dashatu;
        this._parms=retPeriodoFiltro(achaDescricao(1,this._dash.filtros,'id_tfdb','valores_fdbo'));
        this.setState(
        {
            atuagora: true
        })
    }

    atualizaDados()
    {
        srv.Get('Pagamentos/Totais/3/'+RetornaDataAAAAMMDD(this._parms.datini, false)+'/'+RetornaDataAAAAMMDD(this._parms.datfin, false)+'/', '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
            let dadosret: Resposta = TrataResposta(ddret);
            if (dadosret.ok)
            {
                this.setState({atutela: true, erro: this._inicial.toString(), dados: {qttot: dadosret.retorno.qttotal, valortot: dadosret.retorno.valortotal, qtvenc: dadosret.retorno.qtvenc, valorvenc: dadosret.retorno.valorvenc, qtavenc: dadosret.retorno.qtavenc, valoravenc: dadosret.retorno.valoravenc, qtpagos: dadosret.retorno.qtpagos, valorpagos: dadosret.retorno.valorpago}});
            }
            else
            {
                this.setState({atutela: true, erro: 'Erro: Erro obtendo dados de pagamentos (' + dadosret.erro + ')' });
            }
        })
        .catch(Error => this.setState({atutela: true, erro: 'Erro acessando a API - obtendo dados de pagamentos (' + Error.name + ' - ' + Error.message + ')' }))
    }

    atualizahora()
    {
        let dif=DifSegundos(this._inicial, new Date());
        if ((dif>this._dash.intervalo_dsbo && this._dash.intervalo_dsbo>0) || this.state.atuagora)
        {
            this.setState({atuagora: false});
            this._inicial=new Date();
            this.atualizaDados();
        }
    }

    componentWillUnmount() {
        if (this._idinterval>=0)
        {
            window.clearInterval(this._idinterval);
            this._idinterval=-1;
        }
    }

    render()
    {
        if (!this.state.atutela)
        {
            return null
        }
        else
        {
            return (
                <>
                    <Grid style={{ backgroundColor: '#F0FFFF', width: '100%', height: 'AB'.indexOf(this._dash.tamanho_dsbo)!==-1 ? '27%' : this._dash.tamanho_dsbo==='C' ? '22% ' : '15%' }} container spacing={0}>
                        <TituloDash altura={this.props.altura} dash={this._dash} quadro={this.props.quadro} edita={this.props.edita} atualiza={this.atualizaDash.bind(this)} intervalo={{ dtini: this._parms.datini, dtfin: this._parms.datfin }}/>
                    </Grid>
                    <Grid style={{ backgroundColor: '#F0FFFF', width: '100%', height: 'AB'.indexOf(this._dash.tamanho_dsbo)!==-1 ? '73%' : this._dash.tamanho_dsbo==='C' ? '78% ' : '85%' }} container spacing={0}>
                        {this._dash.tamanho_dsbo === 'D'
                            ?
                            <Grafico estilo={{ backgroundColor: '#F0FFFF', width: '100%', height: Math.round(this.props.altura * 0.415).toString().trim() + 'px', boxShadow: "3px 3px 1px lightblue" }} opcoes={{
                                chart: { type: 'column', backgroundColor: '#F0FFFF', height: Math.round(this.props.altura * 0.375).toString().trim() + 'px' },
                                title: '',
                                credits: {enabled: false},
                                legend: { enabled: true },
                                tooltip: { pointFormat: '{point.ybr}' },
                                xAxis: { type: 'category', title: { text: 'Situação' } },
                                yAxis: { min: 0, title: { text: 'Valores em R$' } },
                                series: [
                                    {
                                        showInLegend: false,
                                        data: [
                                            {
                                                name: 'Total',
                                                ybr: formatarCampo(this.state.dados.valortot.toString().trim(),'decimal'),
                                                y: this.state.dados.valortot,
                                                dataLabels: {
                                                    style: { color: '#00BFFF' },
                                                    format: '{point.ybr}',
                                                    enabled: true,
                                                    align: 'center',
                                                    verticalAlign: 'top',
                                                    y: -20
                                                },
                                                color: {
                                                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                                    stops: [
                                                        [0, '#00BFFF'],
                                                        [1, '#B0E2FF']
                                                    ]
                                                }
                                            },
                                            {
                                                name: 'Pagos',
                                                ybr: formatarCampo(this.state.dados.valorpagos.toString().trim(),'decimal'),
                                                y: this.state.dados.valorpagos,
                                                dataLabels: {
                                                    style: { color: '#00CD66' },
                                                    format: '{point.ybr}',
                                                    enabled: true,
                                                    align: 'center',
                                                    verticalAlign: 'top',
                                                    y: -20
                                                },
                                                color: {
                                                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                                    stops: [
                                                        [0, '#00CD66'],
                                                        [1, '#00FF7F']
                                                    ]
                                                }
                                            },
                                            {
                                                name: 'A vencer',
                                                ybr: formatarCampo(this.state.dados.valoravenc.toString().trim(),'decimal'),
                                                y: this.state.dados.valoravenc,
                                                dataLabels: {
                                                    style: { color: '#FFB90F' },
                                                    format: '{point.ybr}',
                                                    enabled: true,
                                                    align: 'center',
                                                    verticalAlign: 'top',
                                                    y: -20
                                                },
                                                color: {
                                                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                                    stops: [
                                                        [0, '#FFB90F'],
                                                        [1, '#FFEC8B']
                                                    ]
                                                }
                                            },
                                            {
                                                name: 'Vencidos',
                                                ybr: formatarCampo(this.state.dados.valorvenc.toString().trim(),'decimal'),
                                                y: this.state.dados.valorvenc,
                                                dataLabels: {
                                                    style: { color: '#FF69B4' },
                                                    format: '{point.ybr}',
                                                    enabled: true,
                                                    align: 'center',
                                                    verticalAlign: 'top',
                                                    y: -20
                                                },
                                                color: {
                                                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                                    stops: [
                                                        [0, '#FF69B4'],
                                                        [1, '#FFC0CB']
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }} />
                            :
                            <>
                                <Grid style={{ maxHeight: Math.round(this.props.altura * 0.25 * 0.40).toString().trim() + 'px', margin: '1px 0 0 0' }} item xs={'AB'.indexOf(this._dash.tamanho_dsbo) !== -1 ? 6 : 12}>
                                    <Card style={{ margin: '0 1% 0 1%', background: 'linear-gradient(to bottom,  #00BFFF 0%, #B0E2FF 100%)', height: '90%' }}>
                                        <Typography style={{ width: '100%', fontSize: '1em' }} align='center'>{'Total'}</Typography>
                                        <Typography style={{ width: '100%', fontSize: this._dash.tamanho_dsbo === 'A' ? '1.4em' : this._dash.tamanho_dsbo === 'B' ? '1.7em' : '2.1em' }} align='center'>{this.state.dados.qttot.toString().trim() + ' - ' + formatarCampo(this.state.dados.valortot.toString().trim(), 'decimal', true)}</Typography>
                                    </Card>
                                </Grid>
                                <Grid style={{ maxHeight: Math.round(this.props.altura * 0.25 * 0.40).toString().trim() + 'px', margin: '1px 0 0 0' }} item xs={'AB'.indexOf(this._dash.tamanho_dsbo) !== -1 ? 6 : 12}>
                                    <Card style={{ margin: '0 1% 0 1%', background: 'linear-gradient(to bottom,  #00CD66 0%, #00FF7F 100%)', height: '90%' }}>
                                        <Typography style={{ width: '100%', fontSize: '1em' }} align='center'>{'Pagos'}</Typography>
                                        <Typography style={{ width: '100%', fontSize: this._dash.tamanho_dsbo === 'A' ? '1.4em' : this._dash.tamanho_dsbo === 'B' ? '1.7em' : '2.1em' }} align='center'>{this.state.dados.qtpagos.toString().trim() + ' - ' + formatarCampo(this.state.dados.valorpagos.toString().trim(), 'decimal', true)}</Typography>
                                    </Card>
                                </Grid><Grid style={{ maxHeight: Math.round(this.props.altura * 0.25 * 0.40).toString().trim() + 'px', margin: '1px 0 0 0' }} item xs={'AB'.indexOf(this._dash.tamanho_dsbo) !== -1 ? 6 : 12}>
                                    <Card style={{ margin: '0 1% 0 1%', background: 'linear-gradient(to bottom,  #FFB90F 0%, #FFEC8B 100%)', height: '90%' }}>
                                        <Typography style={{ width: '100%', fontSize: '1em' }} align='center'>{'A vencer'}</Typography>
                                        <Typography style={{ width: '100%', fontSize: this._dash.tamanho_dsbo === 'A' ? '1.4em' : this._dash.tamanho_dsbo === 'B' ? '1.7em' : '2.1em' }} align='center'>{this.state.dados.qtavenc.toString().trim() + ' - ' + formatarCampo(this.state.dados.valoravenc.toString().trim(), 'decimal', true)}</Typography>
                                    </Card>
                                </Grid>
                                <Grid style={{ maxHeight: Math.round(this.props.altura * 0.25 * 0.40).toString().trim() + 'px', margin: '1px 0 0 0' }} item xs={'AB'.indexOf(this._dash.tamanho_dsbo) !== -1 ? 6 : 12}>
                                    <Card style={{ margin: '0 1% 0 1%', background: 'linear-gradient(to bottom,  #FF0000 0%, #EE5C42 100%)', height: '90%' }}>
                                        <Typography style={{ width: '100%', fontSize: '1em' }} align='center'>{'Vencidos'}</Typography>
                                        <Typography style={{ width: '100%', fontSize: this._dash.tamanho_dsbo === 'A' ? '1.4em' : this._dash.tamanho_dsbo === 'B' ? '1.7em' : '2.1em' }} align='center'>{this.state.dados.qtvenc.toString().trim() + ' - ' + formatarCampo(this.state.dados.valorvenc.toString().trim(), 'decimal', true)}</Typography>
                                    </Card>
                                </Grid>
                            </>}
                    </Grid>
                </>
            );
        }
    }
}

export default DashPagamentos
