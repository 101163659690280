import { RouteComponentProps, withRouter } from 'react-router';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Roteador } from '../Servicos/Roteador';
import { Resposta, TrataResposta, datas, ParametrosDiversos, RetornaDataAAAAMMDD, AlteraPadroesRecebimento, Iheaders, retPeriodoTitulo, arrayBufferToBase64, formatarCampo, RetornaStringData, AlteraPadroesPagamento, DataSql } from '../Servicos/Utilidades';
import ApiService from '../Servicos/ApiService';
import BarraLateral from '../Componentes/Barralateral';
import Toast from '../Componentes/Toast';
import Progresso from '../Componentes/Progresso';
import ProgressoEtiqueta from '../Componentes/ProgressoEtiqueta';
import { makeStyles, Theme, createStyles, Grid, Button, Typography, Box } from '@material-ui/core';
import React, { ChangeEvent, Fragment } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import PageviewIcon from '@material-ui/icons/Pageview';
import { Component } from 'react';
import SelecaoMultipla from '../Componentes/SelecaoMultipla';
import { Parametros } from '../Modelos/Parametros';
import { Situacoes_Recebimentos } from '../Modelos/Situacoes_Recebimentos';
import Periodo from '../Componentes/Periodo';
import TabelaBase from '../Componentes/TabelaBase';
import SelecaoAuto from '../Componentes/SelecaoAuto';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';
import CampoData from '../Componentes/CampoData';
import { DadosTabelaPDF, DocPDF } from '../Servicos/MontaPDF';
import DocumentoPDF from '../Componentes/DocumentoPDF';
import { pdf } from '@react-pdf/renderer';
import { Ocorrencias_Recebimento } from '../Modelos/Ocorrencias_Recebimento';
import { Pagamentos } from '../Modelos/Pagamentos';
import { Detalhes_Pagamento } from '../Modelos/Detalhes_Pagamento';

const srv = new ApiService();

const useStyles = makeStyles((theme: Theme) => createStyles({
    griddados: {
        width: "100%",
        margin: "0 0 0 0"
    },
    gridtab: {
        width: "50%",
        margin: "0 0 0 0"
    },
    divgrid: {
        width: '90%',
        margin: '5% 7% 0'
    },
    divtabs: {
        width: '90%',
        margin: '0% 7% 0'
    },
    boxpanel: {
        width: '104%',
        margin: '0% 0% 0% -2%',
        padding: '0 0 0 0'
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    botao1: {
        margin: '2% 4% 0 1%',
        width: '45%'
        },
    botao2: {
        margin: '2% 0% 0 5%',
        width: '45%'
        },
    tab: {
        minWidth: "50%",
        width: "50%"
    },
    gridcampos: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridfoto: {
        width: "20%",
        margin: "0 0 0 0"
    },
}));

let hoje=new Date();
let mesatu=hoje.getMonth();
let filtvenc: datas = {dtini: undefined, dtfin: undefined};
let filtpgto: datas = {dtini: new Date(hoje.getFullYear() - (mesatu===0 ? 1 : 0), mesatu===0 ? 11 : mesatu -1, 5), dtfin: new Date(hoje.getFullYear(), mesatu, 5)};
let selecionadas: number[] = [];
let totreg = 0;
let interv = 0;
let grvpag:Pagamentos[];
let grvocr:Ocorrencias_Recebimento[];

type PrincipalProps=
{
    dadosobt: RetornoAcesso,
    vendedores: [],
    situacoes: Situacoes_Recebimentos[],
    mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void
}

const PrincipalComissoes = ({dadosobt, vendedores, situacoes, mensagem }: PrincipalProps) =>
{
    const classes = useStyles();
    const [progresso,setaprogresso]=React.useState({processo: false, percentual: 0});
    const [tipoger,setatipogera]=React.useState<number[]>([ParametrosDiversos.sitpago]);
    const [vendsel,setVendsel]=React.useState<number[]>([]);
    const [datapgto,setDatapgto]=React.useState<Date | undefined>(undefined);
    const [registros,setRegistros]=React.useState<any[]>([]);
    const [cliproc,setcliproc]=React.useState('');
    const [operexec,setoperexec]=React.useState('A');

    const srv = new ApiService();

    const AtualizaFiltro = (campo:string, valor: any) =>
    {
        if(campo==='Vencimento')
        {
            filtvenc={dtini: valor.dtini, dtfin: valor.dtfin}
        }
        else if(campo==='Pagamento')
        {
            filtpgto={dtini: valor.dtini, dtfin: valor.dtfin}
        }
    }

    const onChangeMultiSel = (event: React.ChangeEvent<{ value: unknown }>, campo?: string) => 
    {
        if (campo==='tipoger')
        {
            setatipogera(event.target.value as number[]);
        }
    };

    const onChangeVendedores = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined, campo?: string) =>
    {
        if(campo && value)
        {
            if (value.length!==undefined)
            {
                let novovend:number[]=[];   
                value.forEach((vl:any) => novovend.push(vl[campo]));
                setVendsel(novovend);
            }
            else
            {
                setVendsel([value[campo]]);
            }
        }
        else
        {
            if(campo)
            {
                setVendsel([]);
            }
        }
    }

    const onChangeDataPagto = (date: any, valor: string | null | undefined, campo: string | undefined) =>
    {
        setDatapgto(date ? date : undefined);
    }

    const AlteraSelecionadas = (sel: number[]) =>
    {
        selecionadas=sel;
    }

    const AbreFecha = (reg: number) =>
    {
        let copiareg:any[]=[];
        copiareg=copiareg.concat(registros);
        if (reg>=0 && reg<registros.length)
        {
            copiareg[reg].aberta=!copiareg[reg].aberta;
            setRegistros(copiareg);
        }
    }

    const obtemLancamentos = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
    {
        let inivct: string=RetornaDataAAAAMMDD(filtvenc.dtini, false);
        let fimvct: string=RetornaDataAAAAMMDD(filtvenc.dtfin, false);
        let inipgt: string=RetornaDataAAAAMMDD(filtpgto.dtini, false);
        let fimpgt: string=RetornaDataAAAAMMDD(filtpgto.dtfin, false);
        if (fimvct<inivct && fimvct.trim().length>0)
        {
            mensagem('Atenção: verifque o período de vencimento selecionado', 'error', 6000, false);
        }
        else if (fimpgt<inipgt && fimpgt.trim().length>0)
        {
            mensagem('Atenção: verifque o período de pagamento selecionado', 'error', 6000, false);
        }
        else if(tipoger.length<1)
        {
            mensagem('Atenção: escolha ao menos uma situação de recebimento', 'error', 6000, false);
        }
        else
        {
            setoperexec('B');
            srv.Get('Recebimentos/Comissoes/'+(vendsel.length>0 ? '1,' : '')+'2,3,4/'+(vendsel.length>0 ? vendsel.join('-')+',' : '')+inivct+','+inipgt+','+tipoger.join('-')+'/'+(vendsel.length>0 ? vendsel.join('-')+',' : '')+fimvct+','+fimpgt+','+tipoger.join('-'), '', '', dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (dadosret.ok)
                {
                    setRegistros(dadosret.retorno);
                    setoperexec('C');
                }
                else
                {
                    mensagem('Erro obtendo comissões (' + dadosret.erro + ')','error',5000, false);
                    setoperexec('A');
                }
            })
            .catch(Error => {setoperexec('A'); mensagem('Erro obtendo comissões acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false);});
        }
    }

    const GeraPagamentos = () =>
    {

        if (selecionadas.length>0 && registros.length>0)
        {
            if (datapgto)
            {
                setoperexec('D');
                setaprogresso({processo: true, percentual:0});
                grvpag=[];
                grvocr=[];
                GeraComissao(0);
            }
            else
            {
                mensagem('A data para pagamento não foi preenchida','warning',5000, false)
            }
        }
        else
        {
            mensagem('Nenhum registro selecionado para geração de pagamentos de comissão','warning',5000, false)
        }
    }

    const GeraComissao = (indrec: number) =>
    {
        setcliproc((indrec+1).toString()+'/'+totreg.toString()+' - '+registros[indrec].id_vend.toString().trim()+' - '+registros[indrec].nome_vend.trim());
        if (indrec % interv===0 || indrec===totreg-1)
        {
            setaprogresso({processo: true, percentual: Math.trunc(((indrec+1)/totreg)*100)});
        }
        if (selecionadas.some(sel => sel===registros[indrec].id_vend))
        {
            let dtpag: Detalhes_Pagamento[]=[]
            registros[indrec].detalhes.forEach((dt:any) => {
                if(!grvocr.some(ocr => ocr.id_rcbt===dt.id_rcbt))
                {
                    grvocr.push({id_ocrc: 0, id_rcbt: dt.id_rcbt, id_tpor: ParametrosDiversos.idpgtcom, data_ocrc: DataSql(new Date()), id_oper: dadosobt.objac.id_oper, nome_oper: '', tipoarq_ocrc: '', nomearq_ocrc: '', regbanco_ocrc: 0, motbanco_ocrc: '', ocorbanco_ocrc: '', obs_ocrc: 'Registro de comissão gerado pelo MO', alterado: 'A', gravadoc: false, tipodoc_ocrc: ''  })
                }
                dtpag.push({id_dtpg: 0, id_dsps: registros[indrec].id_dsps, valor_dtpg: dt.basecom_dtrc, id_cli: dt.id_cli, id_oper: dadosobt.objac.id_oper, nome_oper: '', id_pgto:0, id_rcta: 0, id_rede: 0, alterado: 'A'})
            });
            let novopag:Pagamentos = new Pagamentos();
            novopag.id_pgto=0;
            novopag.id_forn=registros[indrec].id_forn;
            novopag.id_stpg=ParametrosDiversos.sitpendpagto;
            if (datapgto)
            {
                novopag.vencto_pgto=DataSql(datapgto);
            }
            novopag.valor_pgto=registros[indrec].valor;
            novopag.id_oper=dadosobt.objac.id_oper;
            novopag.detalhes=dtpag;
            novopag.ocorrencias.push({id_ocpg:0, id_pgto: 0, id_oper: dadosobt.objac.id_oper, nome_oper:'', data_ocpg: DataSql(new Date()), id_tpop: ParametrosDiversos.regcompagto, gravadoc: true, tipodoc_ocpg:'B', obs_ocpg: 'Registro gerado pelo MO', alterado:'A'})
            let docpdf: DocPDF = {titulo: 'Comissões '+registros[indrec].id_vend.toString().trim()+' - '+registros[indrec].nome_vend.trim(), defaultstyle: {fontFamily: 'Arial', fontSize: 10, margin: 12, textAlign: 'justify'}, conteudo:[]};
            docpdf.conteudo.push({paragrafo: {style: {backgroundColor: '#00BFFF', color: 'white', fontFamily: 'Arial', fontSize: 16, margin: '0 0 4mm 0', textAlign: 'center'}, quebra: false, texto: [{conteudo: 'Comissões'}]}});
            docpdf.conteudo.push({paragrafo: {style: {color: '#4169E1', fontFamily: 'Arial', fontSize: 12, margin: '0 0 2mm 0', textAlign: 'center'}, quebra: false, texto: [{conteudo: registros[indrec].id_vend.toString().trim()+' - '+registros[indrec].nome_vend.trim()}]}});
            let subtitulo: string = filtvenc.dtini || filtvenc.dtfin ? 'Vencimentos: ' + retPeriodoTitulo(filtvenc) : '';
            subtitulo=(subtitulo.length>0 ? ' ' : '')+(filtpgto.dtini || filtpgto.dtfin ? 'Pagamentos: ' + retPeriodoTitulo(filtpgto) : '');
            docpdf.conteudo.push({paragrafo: {style: {color: '#4169E1', fontFamily: 'Arial', fontSize: 10, margin: '0 0 2mm 0', textAlign: 'center'}, quebra: false, texto: [{conteudo: subtitulo}]}});
            let adctab: DadosTabelaPDF = {
                style: {color: '#4169E1', margin: '0 0 0 0'},
                header: 
                    {bordas: {top: true, bottom: false, left: false, right: false}, 
                    colunas: [
                        {descricao: ' ', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 10, tamanho: 25, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: ' ', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 10, tamanho: 25, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: ' ', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 10, tamanho: 25, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: ' ', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 10, tamanho: 25, bordas: {top: 0, bottom: 0, left: 0, right: 0}}
                    ]},
                dados:
                    {bordas: {top: false, bottom: false, left: false, right: false},
                    colunas: [
                        {descricao: 'coluna01', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 10, tamanho: 25 },
                        {descricao: 'coluna02', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 10, tamanho: 25 },
                        {descricao: 'coluna03', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 10, tamanho: 25 },
                        {descricao: 'coluna04', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 10, tamanho: 25 }
                    ]},
                valores: []};
            let idcl:number=2;
            let stjs:string='{ "coluna01": "CPF/CNPJ: '+formatarCampo(registros[indrec].cpfcnpj,'cpfcnpj')+'"';
            registros[indrec].adicionais.forEach((adc:{descricao: string, valor: string}) => {
                stjs=stjs+(idcl<2 ? ' ' : ', ')+'"coluna'+(idcl.toString().trim().padStart(2,'0'))+'": "'+adc.descricao.trim()+': '+adc.valor.trim()+'"';
                idcl=idcl+1
                if (idcl>4)
                {
                    stjs=stjs+' }';
                    adctab.valores.push(JSON.parse(stjs));
                    stjs='{';
                    idcl=1;
                }
            })
            while(idcl<5 && idcl>1)
            {
                stjs=stjs+(idcl<2 ? ' ' : ', ')+'"coluna'+(idcl.toString().trim().padStart(2,'0'))+'": " "';
                idcl=idcl+1
            }
            if (idcl>1)
            {
                stjs=stjs+' }';
                adctab.valores.push(JSON.parse(stjs));
            }

            docpdf.conteudo.push({tabela: adctab});

            docpdf.conteudo.push({paragrafo: {style: {color: '#4169E1', fontFamily: 'Arial', fontSize: 10, margin: '0 0 1mm 0', textAlign: 'left'}, quebra: false, texto: [{conteudo: ' '}]}});

            adctab = {
                style: {color: '#4169E1', margin: '0 0 0 0'},
                header: 
                    {bordas: {top: true, bottom: false, left: false, right: false}, 
                    colunas: [
                        {descricao: ' ', borda: 'none', alinhamento: 'left', familia: 'Arial', fonte: 10, tamanho: 100, bordas: {top: 0, bottom: 0, left: 0, right: 0}}
                    ]},
                dados:
                    {bordas: {top: false, bottom: false, left: false, right: false},
                    colunas: [
                        {descricao: 'coluna01', borda: 'none', alinhamento: 'left', familia: 'Arial', fonte: 10, tamanho: 100 },
                    ]},
                valores: [{coluna01: ''}]};

            docpdf.conteudo.push({tabela: adctab});

            docpdf.conteudo.push({paragrafo: {style: {color: '#4169E1', fontFamily: 'Arial', fontSize: 10, margin: '0 0 2mm 0', textAlign: 'left'}, quebra: false, texto: [{conteudo: 'Valor total: R$ '+formatarCampo(registros[indrec].valor.toString(),'decimal').trim()}]}});
            if (datapgto)
            {
                docpdf.conteudo.push({paragrafo: {style: {color: '#4169E1', fontFamily: 'Arial', fontSize: 10, margin: '0 0 2mm 0', textAlign: 'left'}, quebra: false, texto: [{conteudo: 'Data de pagamento: '+RetornaStringData(datapgto)}]}});
            }

            adctab = {
                style: {color: '#4169E1', margin: '0 0 0 0'},
                header: 
                    {bordas: {top: true, bottom: false, left: false, right: false}, 
                    colunas: [
                        {descricao: ' ', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 14, tamanho: 100, bordas: {top: 0, bottom: 0, left: 0, right: 0}}
                    ]},
                dados:
                    {bordas: {top: false, bottom: false, left: false, right: false},
                    colunas: [
                        {descricao: 'coluna01', borda: 'none', alinhamento: 'center', familia: 'Arial', fonte: 14, tamanho: 100 },
                    ]},
                valores: [{coluna01: "Detalhes"},{coluna01: ' '}]};

            docpdf.conteudo.push({tabela: adctab});

            docpdf.conteudo.push({paragrafo: {style: {color: '#4169E1', fontFamily: 'Arial', fontSize: 10, margin: '0 0 1mm 0', textAlign: 'left'}, quebra: false, texto: [{conteudo: ' '}]}});

            adctab = {
                style: {color: 'black', margin: '0 0 0 0'},
                header: 
                    {bordas: {top: true, bottom: true, left: true, right: true}, 
                    colunas: [
                        {descricao: 'Id', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 4, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Id interno', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 6, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Id cliente', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 4, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Loja', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 4, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Cliente', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 28, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Fantasia', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 11, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Situação', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 11, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Vencimento', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 8, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Pagamento', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 8, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Valor', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 8, bordas: {top: 0, bottom: 0, left: 0, right: 0}},
                        {descricao: 'Comissão', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 8, bordas: {top: 0, bottom: 0, left: 0, right: 0}}
                    ]},
                dados:
                    {bordas: {top: true, bottom: true, left: true, right: true},
                    colunas: [
                        {descricao: 'id_rcbt', borda: 'solid', alinhamento: 'right', familia: 'Arial', fonte: 6, tamanho: 4},
                        {descricao: 'idinterno_rcbt', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 6},
                        {descricao: 'id_cli', borda: 'solid', alinhamento: 'right', familia: 'Arial', fonte: 6, tamanho: 4},
                        {descricao: 'loja_cli', borda: 'solid', alinhamento: 'right', familia: 'Arial', fonte: 6, tamanho: 4},
                        {descricao: 'nome_cli', borda: 'solid', alinhamento: 'left', familia: 'Arial', fonte: 6, tamanho: 28},
                        {descricao: 'fantasia', borda: 'solid', alinhamento: 'left', familia: 'Arial', fonte: 6, tamanho: 11},
                        {descricao: 'desc_strc', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 11},
                        {descricao: 'vencto_rcbt', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 8, formato: 'data'},
                        {descricao: 'pagto_rcbt', borda: 'solid', alinhamento: 'center', familia: 'Arial', fonte: 6, tamanho: 8, formato: 'data'},
                        {descricao: 'valor_dtrc', borda: 'solid', alinhamento: 'right', familia: 'Arial', fonte: 6, tamanho: 8, formato: 'decimal'},
                        {descricao: 'basecom_dtrc', borda: 'solid', alinhamento: 'right', familia: 'Arial', fonte: 6, tamanho: 8, formato: 'decimal'}
                   ]},
                valores: registros[indrec].detalhes};

            docpdf.conteudo.push({tabela: adctab});

            pdf(DocumentoPDF(docpdf,'landscape')).toBlob()
            .then(result => 
              {
                let reader = new FileReader();
                reader.readAsArrayBuffer(result); 
                reader.onloadend = function() {
                    let base64data: string | ArrayBuffer | null = reader.result;
                    if (base64data && typeof base64data !=='string')
                    {
                        novopag.ocorrencias[0].documento_ocpg=arrayBufferToBase64(base64data);
                        grvpag.push(novopag);
                        ChamaProximo(indrec);
                    }
                    else
                    {
                        grvpag.push(novopag);
                        ChamaProximo(indrec);
                    }
                  }
              })
            .catch(error => console.log(error))
        }
        else
        {
            ChamaProximo(indrec);
        }
    }

    const ChamaProximo = (indrec: number) =>
    {
        if (registros.length>(indrec+1))
        {
            GeraComissao(indrec+1);
        }
        else
        {
            srv.Post('Pagamentos/Comissoes', '', JSON.stringify({pagamentos: grvpag, ocorrencias: grvocr}), dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (dadosret.ok)
                {
                    mensagem('Registros de comissões gerados com sucesso','info',10000, true);
                }
                else
                {
                    setoperexec('A');
                    mensagem('Erro gerando registro de comissões (' + dadosret.erro + ')','error',5000, false);
                }
            })
            .catch(Error => {setoperexec('A'); mensagem('Erro gerando registro de comissões acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false);});
        }
    }

    const head: Iheaders[] = 
    [
        { titulo: 'Id', alinhamento:'right', coluna: 'id_vend', mascara: '' }, 
        { titulo: 'Vendedor', alinhamento: 'left', coluna: 'nome_vend', mascara: '' }, 
        { titulo: 'Fantasia', alinhamento: 'left', coluna: 'fantasia', mascara: '' }, 
        { titulo: 'Id fornecedor', alinhamento: 'right', coluna: 'id_forn', mascara: '' }, 
        { titulo: 'id despesa', alinhamento: 'right', coluna: 'id_dsps', mascara: '' },
        { titulo: 'Comissão', alinhamento: 'right', coluna: 'valor', mascara: 'decimal' },
        { titulo: '#', alinhamento: 'right', coluna: 'flagedicao', mascara: '' }
    ]

    const headdet: Iheaders[] = 
    [
        { titulo: 'Id', alinhamento:'right', coluna: 'id_rcbt', mascara: '' }, 
        { titulo: 'Id interno', alinhamento: 'left', coluna: 'idinterno_rcbt', mascara: '' },
        { titulo: 'Id cliente', alinhamento: 'right', coluna: 'id_cli', mascara: '' }, 
        { titulo: 'Loja', alinhamento: 'center', coluna: 'loja_cli', mascara: ''},
        { titulo: 'Cliente', alinhamento: 'left', coluna: 'nome_cli', mascara: '' }, 
        { titulo: 'Fantasia', alinhamento: 'left', coluna: 'fantasia', mascara: '' }, 
        { titulo: 'Situação', alinhamento: 'center', coluna: 'desc_strc', mascara: '' }, 
        { titulo: 'Vencimento', alinhamento: 'center', coluna: 'vencto_rcbt', mascara: 'data' },
        { titulo: 'Pagamento', alinhamento: 'center', coluna: 'pagto_rcbt', mascara: 'data' },
        { titulo: 'Valor', alinhamento: 'right', coluna: 'valor_dtrc', mascara: 'decimal' },
        { titulo: 'Comissão', alinhamento: 'right', coluna: 'basecom_dtrc', mascara: 'decimal' }
    ]

    return (
        <div>
            <div className={classes.divgrid}>
                <Grid container spacing={1} className={classes.griddados}>
                    <Grid item xs={2}>
                        <SelecaoMultipla habilitado={true} colunas={{id: 'id_strc', desc: 'desc_strc'}} conteudo={situacoes} erro={tipoger.length===0} nome='tipoger' onchange={onChangeMultiSel} label='Situações' classe={classes.textoform} valor={tipoger} />
                    </Grid>
                    <Grid item xs={5}>
                        <Periodo capper='Vencimento' camporet='Vencimento' atualiza={AtualizaFiltro} perpad={0} dataspad={filtvenc ? filtvenc : {dtini: undefined, dtfin: undefined}} tipoper='AB' />
                    </Grid>
                    <Grid item xs={5}>
                        <Periodo capper='Pagamento' camporet='Pagamento' atualiza={AtualizaFiltro} perpad={0} dataspad={filtpgto ? filtpgto : {dtini: undefined, dtfin: undefined}} tipoper='AB' />
                    </Grid>
                    <Grid item xs={5}>
                        <SelecaoAuto valor={vendsel} nome='id_vend' label='Vendedores' classe='' conteudo={vendedores} habilitado={true} colunas={{id: 'id_vend', desc: 'nome_vend'}} onchange={onChangeVendedores} multiplo={true}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoData valor={datapgto} id='datapgto' label='Data de pagamento' placeholder='data de pagamento' classe={classes.textoform} required={true} habilitado={true} onchange={onChangeDataPagto} erro={!datapgto} />
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant="contained" color="primary" disabled={'BD'.indexOf(operexec)!==-1} className={classes.botao1} onClick={(event) => obtemLancamentos(event)} endIcon={<PageviewIcon/>}>Obtem Lançamentos</Button>
                        <Button variant="contained" color="primary" disabled={operexec!=='C'} className={classes.botao2} onClick={GeraPagamentos} endIcon={<SaveIcon/>}>Gera Pagamentos</Button>
                    </Grid>
                    {'BD'.indexOf(operexec)!==-1 ?
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Box minWidth={35} margin='20px 0 0 10px'>
                                    <Typography color='primary'>{operexec==='B' ? 'Obtendo lançamentos' : 'Gerando Pagamentos'}</Typography>
                                </Box>
                            </Grid>
                            {operexec==='B'
                            ?
                                <Grid item xs={8}>
                                    <Progresso tipo='B' />
                                </Grid>
                            :
                                <Fragment>
                                    <Grid item xs={4}>
                                        <Box minWidth={35} margin='20px 0 0 10px'>
                                            <Typography color='primary'>{cliproc}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ProgressoEtiqueta value={progresso.percentual} tipo='B' />
                                    </Grid>
                                </Fragment>
                            }
                        </Grid>
                        :
                        null
                    }
                    <Grid item xs={12}>
                        <TabelaBase linhasoriginais={'CD'.indexOf(operexec)!==-1 ? registros : []} cab={head} selecao={true} edicao={false} adicao={false} habfiltro={false} titulo={'Comissões'} full={true} linhasiniciais={15} exclusao={false} auxedit={1} onSelect={AlteraSelecionadas} cabdet={headdet} coldet='detalhes' coldetst='aberta' onOpen={AbreFecha} tamdet={9} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}  

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  openToast: boolean,
  severidadeToast: string,
  mensagemToast: string,
  duracaoToast: number,
  acessosok: boolean,
}

class Comissoes extends Component<PropsType, IState>
{
    private _dadosobt: RetornoAcesso;
    private _roteador: Roteador;
    private _dadoobtido: boolean;
    private _acessos: number;
    private _situacoes: Situacoes_Recebimentos[];
    private _parametros: Parametros[];
    private _vendedores: [];
    private _retornamensagem: boolean;
    
    constructor(props: any)
    {
        super(props);

        this._dadosobt=new RetornoAcesso();

        if (props.location.state)
        {
            if(props.location.state.dadosret)
            {
                this._dadosobt=props.location.state.dadosret;
            }
        }

        if (!this._dadosobt.ok)
        {
            this.props.history.push({pathname: '/'})
        }

        this._roteador=new Roteador(this._dadosobt, '/GeracaoRecebimentos');
        this._roteador.history=props.history;
        this._dadoobtido=false;
        this._situacoes = [];
        this._vendedores = [];
        this._parametros = [];
        this._retornamensagem=false;
    
        this._acessos=0;
    
        if (this._dadosobt.ok)
        {
            this._dadoobtido=true;
        }
        this.state =
        {
            dadoobtido: this._dadoobtido,
            openToast: false,
            severidadeToast: 'success',
            mensagemToast: '',
            duracaoToast: 6000,
            acessosok: false,
        }
        if (this._dadosobt.ok)
        {
            this.ObtemDados('Vendedores/','vendedores',this.setavendedores.bind(this));
            this.ObtemDados('Situacoesrecebimentos/','situações de recebimentos',this.setasituacoes.bind(this));
            this.ObtemDados('Parametros/','parâmetros',this.setaparametros.bind(this));
        }
    }
    
    setasituacoes(registros: any) {this._situacoes=registros; this.aumentaAcessos();};
    setavendedores(registros: any) {this._vendedores=registros; this.aumentaAcessos();}
    setaparametros(registros: Parametros[]) {this._parametros=registros; this.aumentaAcessos();};

    aumentaAcessos()
    {
        this._acessos = this._acessos+1
        if(this._acessos>2)
        {
            AlteraPadroesRecebimento(this._parametros);
            AlteraPadroesPagamento(this._parametros);
            this.setState({acessosok: true});
        }
    }
        
    ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
    {
        srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
        let dadosret:Resposta = TrataResposta(ddret);
        if(dadosret.ok)
        {
            obret(dadosret.retorno)
        }
        else
        {
            this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
            this.aumentaAcessos();
        }
        })
        .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
    }

    ExibeMensagem(mensagem: string, severidade: string, duracao:number, retorna:boolean=false)
    {
        this.setState({openToast: true, severidadeToast: severidade, mensagemToast: mensagem, duracaoToast: duracao})
        this._retornamensagem=retorna;
    }

    FechaMensagem()
    {
        this.setState({openToast: false});
        if (this._retornamensagem)
        {
          this._roteador.rota(0,0);
        }
    }

    render()
    {
        if (!this._dadosobt.ok)
        {
        return null
        }
        else
        {
            return (
            <div className="Home">
                <header className="Home-header">
                <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador} recurso='Comissões'/>
                </header>
                <main>
                { this.state.acessosok ?
                    <PrincipalComissoes dadosobt={this._dadosobt} situacoes={this._situacoes} vendedores={this._vendedores} mensagem={this.ExibeMensagem.bind(this)}/>
                    :
                    <Progresso/>
                }
                </main>
                <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
            </div>
            )
        }
    }
}

export default withRouter(Comissoes)
