import React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    campotexto: {
      margin: "2% 10%",
      width: "80%"
    },
  })
);


type TextProps=
{
  id: string,
  label: string,
  placeholder: string,
  required: boolean,
  classe: string,
  valor: string | number | Date,
  habilitado: boolean,
  tipo: string,
  onchange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, filtro:string | number | Date, campo?: string | undefined) =>void,
  onblur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => any,
  onfocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => any,
  erro?: boolean,
  multi?: boolean,
  tamanho?: number
  qtdlinhas?: number
}

export default function CampoTexto({id, label, placeholder, tipo, required, classe, valor, habilitado, onchange, onblur, onfocus, erro, multi, tamanho, qtdlinhas }: TextProps) {

  if (tipo==='date')
  {
    if(valor)
    {
      valor=valor.toString().slice(0,10);
    }
  }
  
  const classes = useStyles();

  return (
      <TextField
        style = {{boxShadow: "3px 3px 1px lightblue"}}
        error = {erro === undefined ? false : erro}
        className={classe ? classe : classes.campotexto}
        required={required}
        id={id}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        type={tipo}
        value={valor}
        disabled={!habilitado}
        multiline={multi}
        rows={multi && qtdlinhas ? qtdlinhas : 1}
        onFocus= { onfocus ? event => onfocus(event) : undefined }
        onBlur={ onblur ? event => onblur(event) : undefined }
        onChange={onchange ? event => {onchange(event, tipo==='number' ? parseInt(event.target.value) : tamanho && tamanho>0 ? event.target.value.substr(0,tamanho) : event.target.value, id)} : undefined}
      />
  );
}