import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Grid, IconButton } from '@material-ui/core';
import StorageIcon from '@material-ui/icons/Storage';
import { DadosConexao } from '../Servicos/Utilidades';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%"
    },
  }),
);

export default function BarraInicial({ddcon}: {ddcon?: DadosConexao}) {
  const classes = useStyles();
  const [exibecon, setExibecon] = React.useState(false);

  return (
    <div className={classes.root}>
      <AppBar position="static" color={ddcon ? (ddcon.homologa ? 'secondary' : 'primary') : 'primary'} >
        <Toolbar variant="dense">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h6" color="inherit" style={{margin: '1% 0 0 0'}}>
                RCKY - Master Office 1.0 (24f27a)
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant='h6' color='inherit' style={{margin: '1% 0 0 0'}}>
                {exibecon && ddcon ? (ddcon.ok ? ddcon.catalogo : ddcon.erro ) : ''}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton style={{textAlign: 'right'}} onClick={() => setExibecon(!exibecon)}>
                <StorageIcon color={ddcon ? (ddcon.homologa ? 'secondary' : 'primary') : 'primary'}/>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}