import { Pessoa_Documentos } from "./Pessoa_Documentos";
import { Tabelas_Dados_Adicionais } from "./Tabelas_Dados_Adicionais";
import { Endereco_Pessoas } from "./Endereco_Pessoas";
import { Emails } from "./Emails";
import { Telefones } from "./Telefones";

export class Pessoas
{
    id_pessoa: number = 0;
    nome_pessoa: string = '';
    obs_pessoa: string = '';
    fantasia_pessoa: string = '';
    nascimento_pessoa?: Date = undefined
    aniversario_pessoa: string = '';
    datacad_pessoa: Date = new Date()
    natureza_pessoa: string = '';
    gravafoto: boolean = false;
    foto_pessoa?: Uint32Array = undefined;
    enderecos: Endereco_Pessoas[] = [];
    emails: Emails[] = [];
    telefones: Telefones[] = [];
    documentos: Pessoa_Documentos[] = [];
    dados_adicionais: Tabelas_Dados_Adicionais[] = [];
}