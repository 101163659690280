import { RouteComponentProps, withRouter } from 'react-router';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Roteador } from '../Servicos/Roteador';
import { Resposta, TrataResposta, AchaFuncoes, TabPanel, BotaoDialogo, a11yProps, formataDecimal, Iheaders, RetornaClientes, RetornaStringData, RetornaMotivosBanco, RetornaOcorrenciaBanco, RetornaDataString, ObjPdfImg, achaDescricao, DataSql, formatarCampo, nulltoNumber } from '../Servicos/Utilidades';
import ApiService from '../Servicos/ApiService';
import { Recebimentos } from '../Modelos/Recebimentos';
import BarraLateral from '../Componentes/Barralateral';
import Toast from '../Componentes/Toast';
import Progresso from '../Componentes/Progresso';
import { makeStyles, Theme, createStyles, Grid, Tabs, Tab, Button, Typography, Card, CardContent } from '@material-ui/core';
import { Detalhes_Recebimento } from '../Modelos/Detalhes_Recebimento';
import { Ocorrencias_Recebimento } from '../Modelos/Ocorrencias_Recebimento';
import AlertDialog from '../Componentes/Dialogo';
import SelecaoAuto from '../Componentes/SelecaoAuto';
import React, { ChangeEvent } from 'react';
import CampoTexto from '../Componentes/CampoTexto';
import CancelIcon from '@material-ui/icons/Cancel';
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { Component } from 'react';
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@material-ui/lab/Autocomplete';
import CampoData from '../Componentes/CampoData';
import Selecao from '../Componentes/Selecao';
import CampoDecimal from '../Componentes/CampoDecimal';
import TabelaBase from '../Componentes/TabelaBase';
import { Tipos_Ocorrencia_Recebimento } from '../Modelos/Tipos_Ocorrencia_Recebimento';
import ExibePdfImg from '../Componentes/ExibePdfImg';
import { StyledAppBar } from '../Componentes/Customizados';
import { Contas } from '../Modelos/Contas';
import { Situacoes_Recebimentos } from '../Modelos/Situacoes_Recebimentos';
import { Receitas } from '../Modelos/Receitas';
import { Despesas } from '../Modelos/Despesas';
import { Vendedores } from '../Modelos/Vendedores';

const srv = new ApiService();

const useStyles = makeStyles((theme: Theme) => createStyles({
    griddados: {
      width: "100%",
      margin: "0 0 0 0"
    },
    gridtab: {
      width: "50%",
      margin: "0 0 0 0"
    },
    divgrid: {
        width: '90%',
        margin: '5% 7% 0'
    },
    divtabs: {
        width: '90%',
        margin: '0% 7% 0'
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    textodet: {
      margin: '0 0 0 0',
      width: '100',
      textAlign: 'right'
    },
    botao: {
        margin: '8px 1% 0 1%',
        width: '98%'
      },
    tab: {
        minWidth: "50%",
        width: "50%"
    },
    gridcampos: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridfoto: {
        width: "20%",
        margin: "0 0 0 0"
    },
}));

const RetornaReceitasDespesas = (rcds:[],tipo: string):[] =>
{
    let ret:any=[];
    rcds.forEach((rd:any) => 
        {
            if (tipo==='A')
            {
                ret.push({id_rcta: rd.id_rcta, desc_rcta: rd.desc_grrc+'-'+rd.desc_sgrc+'-'+rd.desc_rcta});
            }
            else
            {
                ret.push({id_dsps: rd.id_dsps, desc_dsps: rd.desc_grds+'-'+rd.desc_sgds+'-'+rd.desc_dsps});
            }
        })
    return ret;
}

let sted = 
{
    detalhes: false,
    ocorrencias: false
};
  
function InicializaEdicao() 
{
    sted = {
        detalhes: false,
        ocorrencias: false
    };
}

type PrincipalProps=
{
    dadosobt: RetornoAcesso
    dadosrec: Recebimentos
    clientes: {id_cli: number, nome_cli: string}[];
    contas: Contas[];
    situacoes: Situacoes_Recebimentos[];
    receitas: Receitas[];
    despesas: Despesas[];
    ocorrencias: Tipos_Ocorrencia_Recebimento[];
    vendedores: Vendedores[];
    mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void
    roteador: (recurso: number, id_edicao: number) => void
}

let titulodialogo: string;
let botoesdialogo: BotaoDialogo[] = [];
let textodialogo: string[] = [];

const PrincipalEdicaoRecebimentos = ({dadosobt, dadosrec, clientes, contas, situacoes, receitas, despesas, ocorrencias, vendedores, mensagem, roteador }: PrincipalProps) =>
{
    const classes = useStyles();
    const [tabValue, settabValue] = React.useState(0);
    const [recebimento,setRecebimento] = React.useState(dadosrec);
    const [detdig, setdetDig] = React.useState(new Detalhes_Recebimento());
    const [inddet, setindDet] = React.useState(0);
    const [ocordig, setocorDig] = React.useState(new Ocorrencias_Recebimento());
    const [indocor, setindOcor] = React.useState(0);
    const [dialogo, setDialog] = React.useState(false);

    const acessos = {
        altbas: AchaFuncoes(dadosobt.objac, 25),
        altavc: AchaFuncoes(dadosobt.objac, 26),
        altbxa: AchaFuncoes(dadosobt.objac, 10),
        incdet: AchaFuncoes(dadosobt.objac, 27),
        altdet: AchaFuncoes(dadosobt.objac, 28),
        excdet: AchaFuncoes(dadosobt.objac, 29),
        incocor: AchaFuncoes(dadosobt.objac, 30),
        altocor: AchaFuncoes(dadosobt.objac, 31),
        excocor: AchaFuncoes(dadosobt.objac, 32),
    }

    const fechaDialogo = (retorno: string) => 
    {
        setDialog(false);
        if (retorno==='A')
        {
            salvaRecebimento();
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        settabValue(newValue);
    };

    const onChangeRecebimentosAutoComplete = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined, campo?: string) =>
    {
        if(campo && value &&value[campo])
        {
            setRecebimento({...recebimento, [campo]: value[campo]});
        }
        else
        {
            if(campo)
            {
                setRecebimento({...recebimento, [campo]: 0})
            }
        }
    }

    const onChangeRecebimentoSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode, campo?: string | undefined) =>
    {
      if(campo && event.target.value)
      {
        let valor:number = 0;
        valor = typeof event.target.value === 'string' ? parseInt(event.target.value) : typeof event.target.value === 'number' ? event.target.value : 0; 
        if ('id_strc, id_cta'.indexOf(campo)!==-1)
        {
          if ('id_strc'.indexOf(campo)!==-1 && achaDescricao(valor,situacoes,'id_strc','comportamento_strc')!=='B')
          {
            setRecebimento({...recebimento, id_strc: valor, pagto_rcbt: undefined});
          }
          else
          {
            setRecebimento({...recebimento, [campo]: valor});
          }
        }
        else
        {
            if ('id_rcta, id_dsps, id_vend'.indexOf(campo)!==-1)
            {
              if ('id_rcta'.indexOf(campo)!==-1 && valor>0)
              {
                setdetDig({...detdig, id_rcta: valor, id_dsps: 0});
              }
              else if ('id_dsps'.indexOf(campo)!==-1 && valor>0)
              {
                setdetDig({...detdig, id_dsps: valor, id_rcta: 0})
              }
              else
              {
                setdetDig({...detdig, [campo]: valor});
              }
              sted.detalhes=true;
            }
            else
            {
                if ('id_tpor'.indexOf(campo)!==-1)
                {
                    setocorDig({...ocordig, [campo]: valor})
                    sted.ocorrencias=true;
                }
            }
        }
      }
    }
  
    const onChangeRecebimentoDataInput = (date: any, valor: string | null | undefined, campo: string | undefined) =>
    {
      if (date && campo)
      {
        if ('vencto_rcbt,pagto_rcbt,datadesc_rcbt'.indexOf(campo)!==-1)
        {
          setRecebimento({...recebimento, [campo]: date });
        }
        else
        {
          if ('data_ocrc'.indexOf(campo)!==-1)
          {
            setocorDig({...ocordig, [campo]: date});
            sted.ocorrencias=true;
          }
        }
      }
    }
  
    const onChangeRecebimentoInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date, campo?: string | undefined) =>
    {
        if (campo)
        {
            if ('idinterno_rcbt,autorizacao_rcbt,nfs_rcbt,rps_rcbt,carteira_rcbt,numban_rcbt'.indexOf(campo)!==-1)
            {
                setRecebimento({...recebimento, [campo]: propratu});
            }
            else if ('obs_ocrc'.indexOf(campo)!==-1)
            {
                setocorDig({...ocordig, [campo]: propratu});
                sted.ocorrencias=true;
            }
            else if ('rps_dtrc, loja_dtrc'.indexOf(campo)!==-1)
            {
                setdetDig({...detdig, [campo]: propratu});
                sted.detalhes=true;
            }
        }
    }
  
    const onChangeRecebimentoDecimalInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string, campo?: string | undefined) =>
    {
      if (campo)
      {
          if ('valor_rcbt,desconto_rcbt,descapl_rcbt,despesas_rcbt,multarec_rcbt,jurosrec_rcbt,multa_rcbt,jurosdia_rcbt'.indexOf(campo)!==-1)
          {
            setRecebimento({...recebimento, [campo]: parseFloat(formataDecimal(propratu))});
          }
          else
          {
            if ('valor_dtrc,basecom_dtrc'.indexOf(campo)!==-1)
            {
              setdetDig({...detdig, [campo]: parseFloat(formataDecimal(propratu))});
              sted.detalhes=true;
            }
          }
      }
    }
  
    const limpaData = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, campo: string | undefined) =>
    {
      if (campo)
      {
        setRecebimento({...recebimento, [campo]: undefined});
      }
    }
  
    const adicionaDetalhe = () =>
    {
      if (detdig.valor_dtrc>0 && (detdig.id_rcta!==0 || detdig.id_dsps!==0) && detdig.basecom_dtrc>=0 && detdig.basecom_dtrc<=detdig.valor_dtrc && (detdig.id_vend>0 || detdig.basecom_dtrc===0))
      {
        let detnovos: Detalhes_Recebimento[]=[];
        detnovos=detnovos.concat(recebimento.detalhes);
        if (inddet===0)
        {
          detnovos.push(detdig);
          detnovos[detnovos.length-1].alterado='A';
          detnovos[detnovos.length-1].id_oper=dadosobt.objac.id_oper;
          detnovos[detnovos.length-1].nome_oper=dadosobt.nome;
          detnovos[detnovos.length-1].id_rcbt=recebimento.id_rcbt;
        }
        else
        {
          detnovos[inddet-1]=detdig;
          detnovos[inddet-1].alterado='A';
          detnovos[inddet-1].id_oper=dadosobt.objac.id_oper;
          detnovos[inddet-1].nome_oper=dadosobt.nome;
        }
        setRecebimento({...recebimento, detalhes: detnovos});
        setdetDig(new Detalhes_Recebimento());
        setindDet(0);
        sted.detalhes=false;
      }
      else
      {
        mensagem('Existem dados incorretos. Verifique, por gentileza','error',6000);
      }
    }

    const editaDetalhe = (val1: number, val2: number) =>
    {
      if(val1===1)
      {
        if(val2<=recebimento.detalhes.length && val2>0)
        {
          setdetDig(recebimento.detalhes[val2-1]);
          setindDet(val2);
        }
        else
        {
          setdetDig(new Detalhes_Recebimento());
          setindDet(0);
        }
      }
      else
      {
        if(val1===2)
        {
          if(val2<=recebimento.detalhes.length && val2>0)
          {
            let detnovos: Detalhes_Recebimento[]=[]
            detnovos=detnovos.concat(recebimento.detalhes);
            detnovos[val2-1].alterado='B';
            detnovos[val2-1].valor_dtrc=-1;
            setRecebimento({...recebimento, detalhes: detnovos});
            if (val2===inddet)
            {
              setdetDig(new Detalhes_Recebimento());
              setindDet(0);
            }
          }
        }
      }
    }

    const adicionaOcorrencia = () =>
    {
      if (ocordig.data_ocrc && ocordig.id_tpor && ocordig.id_tpor>0)
      {
        let ocornovos: Ocorrencias_Recebimento[]=[];
        ocornovos=ocornovos.concat(recebimento.ocorrencias);
        if (indocor===0)
        {
          ocornovos.push(ocordig);
          ocornovos[ocornovos.length-1].data_ocrc=new Date();
          ocornovos[ocornovos.length-1].alterado='A';
          ocornovos[ocornovos.length-1].id_oper=dadosobt.objac.id_oper;
          ocornovos[ocornovos.length-1].nome_oper=dadosobt.nome;
          ocornovos[ocornovos.length-1].id_rcbt=recebimento.id_rcbt;
        }
        else
        {
          ocornovos[indocor-1]=ocordig;
          ocornovos[indocor-1].alterado='A';
          ocornovos[indocor-1].id_oper=dadosobt.objac.id_oper;
          ocornovos[indocor-1].nome_oper=dadosobt.nome;
        }
        setRecebimento({...recebimento, ocorrencias: ocornovos});
        setocorDig(new Ocorrencias_Recebimento());
        setindOcor(0);
        sted.ocorrencias=false;
      }
      else
      {
        mensagem('Existem dados incorretos. Verifique, por gentileza','error',6000);
      }
    }

    const editaOcorrencia = (val1: number, val2: number) =>
    {
      if(val1===1)
      {
        if(val2<=recebimento.ocorrencias.length && val2>0)
        {
          setocorDig(recebimento.ocorrencias[val2-1]);
          setindOcor(val2);
        }
        else
        {
          setocorDig(new Ocorrencias_Recebimento());
          setindOcor(0);
        }
      }
      else
      {
        if(val1===2)
        {
          if(val2<=recebimento.ocorrencias.length && val2>0)
          {
            let ocornovos: Ocorrencias_Recebimento[]=[]
            ocornovos=ocornovos.concat(recebimento.ocorrencias);
            ocornovos[val2-1].alterado='B';
            ocornovos[val2-1].id_tpor=0;
            setRecebimento({...recebimento, ocorrencias: ocornovos});
            if (val2===indocor)
            {
              setocorDig(new Ocorrencias_Recebimento());
              setindOcor(0);
            }
          }
        }
      }
    }

    const atualizaPdfImgRecebimento = (objrec: ObjPdfImg) =>
    {
      setocorDig({...ocordig, gravadoc: true, tipodoc_ocrc: objrec.tipo, documento_ocrc: objrec.conteudo } )
      sted.ocorrencias=true;
    }

    const hddet: Iheaders[] = [
        {titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, 
        { titulo: 'Valor', alinhamento: 'right', coluna: 'valor_dtrc', mascara: 'decimal' }, 
        { titulo: 'Rec./Desp.', alinhamento: 'left', coluna: 'desc_rcds', mascara: '' }, 
        { titulo: 'Comissão', alinhamento: 'right', coluna: 'basecom_dtrc', mascara: 'decimal' }, 
        { titulo: 'Vendedor', alinhamento: 'left', coluna: 'nome_vend', mascara: '' },
        { titulo: 'Operador', alinhamento: 'left', coluna: 'nome_oper', mascara: '' },
        { titulo: 'Loja', alinhamento: 'center', coluna: 'loja_dtrc', mascara: '' },
        { titulo: 'RPS', alinhamento: 'left', coluna: 'rps_dtrc', mascara: '' },
        { titulo: 'Contrato', alinhamento: 'center', coluna: 'id_svct', mascara: '' }, 
        { titulo: 'Lançamento', alinhamento: 'center', coluna: 'id_svcl', mascara: '' }]

    const RetornaDetalhes = () =>
    {
        let detret: {id: number; valor_dtrc: number; desc_rcds: string, id_svct: number, id_svcl: number, loja_dtrc: number, basecom_dtrc: number, nome_vend:string, rps_dtrc: string, nome_oper: string}[] = [];
        recebimento.detalhes.forEach((dtrc, index) => 
        {
            if (dtrc.alterado!=='B')
            {
                detret.push({
                    id: index+1, 
                    valor_dtrc: dtrc.valor_dtrc, 
                    desc_rcds: ((dtrc.id_rcta && dtrc.id_rcta>0) ? achaDescricao(dtrc.id_rcta, receitas, 'id_rcta', 'desc_rcta') : achaDescricao(dtrc.id_dsps, despesas, 'id_dsps', 'desc_dsps')),
                    id_svct: dtrc.id_svct,
                    id_svcl: dtrc.id_svcl,
                    basecom_dtrc: dtrc.basecom_dtrc,
                    loja_dtrc: dtrc.loja_dtrc,
                    rps_dtrc: dtrc.rps_dtrc,
                    nome_vend: achaDescricao(dtrc.id_vend, vendedores, 'id_vend', 'nome_vend'),
                    nome_oper: dtrc.nome_oper
                })
            }
        })
        return detret;
    }

    const hdoco: Iheaders[] = [
        {titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, 
        { titulo: 'Data/Hora', alinhamento: 'center', coluna: 'data_ocrc', mascara: '' }, 
        { titulo: 'Ocorrência', alinhamento: 'left', coluna: 'desc_tpor', mascara: '' }, 
        { titulo: 'Operador', alinhamento: 'left', coluna: 'nome_oper', mascara: '' },
        { titulo: 'Observações', alinhamento: 'left', coluna: 'obs_ocrc', mascara: ''},
        { titulo: 'Arquivo', alinhamento: 'left', coluna: 'nomearq_ocrc', mascara: '' },
        { titulo: 'Registro', alinhamento: 'right', coluna: 'regbanco_ocrc', mascara: '' },
        { titulo: 'Código', alinhamento: 'center', coluna: 'ocorbanco_ocrc', mascara: '' },
        { titulo: 'Descrição', alinhamento: 'left', coluna: 'descocorbanco_ocrc', mascara: '' },
        { titulo: 'Motivo', alinhamento: 'center', coluna: 'motbanco_ocrc', dica: 'descmotbanco_ocrc', mascara: '' },
        { titulo: '=', alinhamento:'right', coluna: 'imgpdf', mascara: '' },
        { titulo: '*',alinhamento: 'center', coluna: 'flagedicao', mascara: ''}
    ]

    const RetornaOcorrencias = () =>
    {
      let bco = parseInt(achaDescricao(recebimento.id_cta,contas,'id_cta','banco_cta'))
      let ocoret: {id: number, data_ocrc: string, desc_tpor: string, obs_ocrc: string, nome_oper: string, nomearq_ocrc: string, regbanco_ocrc: number, ocorbanco_ocrc: string, descocorbanco_ocrc: string, motbanco_ocrc: string, descmotbanco_ocrc: string, imgpdf:ObjPdfImg, flagedicao: string}[] = [];
      let tpocr: string;

      recebimento.ocorrencias.forEach((ocor, index) => 
      {
        if (ocor.alterado!=='B')
        {
          tpocr=achaDescricao(ocor.id_tpor, ocorrencias, 'id_tpor', 'tipo_tpor');
          ocoret.push({
            id: index+1,
            data_ocrc: RetornaStringData(ocor.data_ocrc, true),
            desc_tpor: achaDescricao(ocor.id_tpor, ocorrencias, 'id_tpor', 'desc_tpor'),
            nome_oper: ocor.nome_oper,
            obs_ocrc: ocor.obs_ocrc,
            nomearq_ocrc: ocor.nomearq_ocrc,
            regbanco_ocrc: ocor.regbanco_ocrc,
            ocorbanco_ocrc: ocor.ocorbanco_ocrc,
            descocorbanco_ocrc: RetornaOcorrenciaBanco(ocor.ocorbanco_ocrc, ocor.tipoarq_ocrc, bco),
            motbanco_ocrc: ocor.motbanco_ocrc,
            descmotbanco_ocrc: RetornaMotivosBanco(ocor.motbanco_ocrc, bco, ocor.ocorbanco_ocrc),
            imgpdf: {tipo: ocor.tipodoc_ocrc, conteudo: ocor.documento_ocrc},
            flagedicao: tpocr==='A' || (tpocr==='C' && acessos.altavc) ? 'D' : 'C'
          })
        }
      })
      return ocoret;
    }

    const Verificadetalhes = (props: {tipo: string}) =>
    {
      const {tipo} = props;
      let tottit: number;
      let totdet: number;
      if(tipo==='A')
      {
        totdet = nulltoNumber(parseFloat(recebimento.detalhes.reduce((acum, det) => acum + (det.id_rcta && det.id_rcta!==0 && (!det.alterado || det.alterado!=='B') ? det.valor_dtrc : 0), 0).toFixed(2)));
        tottit = nulltoNumber(parseFloat((nulltoNumber(recebimento.valor_rcbt)+nulltoNumber(recebimento.jurosrec_rcbt)+nulltoNumber(recebimento.multarec_rcbt)).toFixed(2)));
      }
      else
      {
        totdet = nulltoNumber(parseFloat(recebimento.detalhes.reduce((acum, det) => acum + (det.id_dsps && det.id_dsps!==0 && (!det.alterado || det.alterado!=='B') ? det.valor_dtrc : 0), 0).toFixed(2)));
        tottit = nulltoNumber(parseFloat((nulltoNumber(recebimento.descapl_rcbt)+nulltoNumber(recebimento.despesas_rcbt)).toFixed(2)));
      }

      return(
        <>
          <Grid container spacing={1} style={{width: '100%'}}>
            <Grid item xs={4}>
              <Card style={{ borderRadius: 3, border: 0, color: 'white', width: '100%', height: 55, padding: '0 0 0 0', margin: '5px 0 0 0', boxShadow: '0 3px 5px 2px lightblue'}} variant="outlined">
                <CardContent style={{ margin: '5px 5px 5px 5px', width: '100%', padding: '0 0 0 0'}}>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }}>Título</Typography>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '18px', padding: '0 0 0 0', margin: '0 0 0 0', width: '90%', textAlign: 'right' }}>{formatarCampo(tottit.toString(),'decimal',true)}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card style={{ borderRadius: 3, border: 0, color: 'white', width: '100%', height: 55, padding: '0 0 0 0', margin: '5px 0 0 0', boxShadow: '0 3px 5px 2px lightblue'}} variant="outlined">
                <CardContent style={{ margin: '5px 5px 5px 5px', width: '100%', padding: '0 0 0 0'}}>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }}>Detalhes</Typography>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '18px', padding: '0 0 0 0', margin: '0 0 0 0', width: '90%', textAlign: 'right' }}>{formatarCampo(totdet.toString(),'decimal',true)}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card style={{ borderRadius: 3, border: 0, color: 'white', width: '100%', height: 55, padding: '0 0 0 0', margin: '5px 0 0 0', boxShadow: '0 3px 5px 2px lightblue'}} variant="outlined">
                <CardContent style={{ margin: '5px 5px 5px 5px', width: '100%', padding: '0 0 0 0'}}>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }}>Diferença</Typography>
                  <Typography style={{ color: totdet === tottit ? 'blue' : 'red', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '18px', padding: '0 0 0 0', margin: '0 0 0 0', width: '90%', textAlign: 'right' }}>{formatarCampo((tottit-totdet).toString(),'decimal',true)}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }
    
    const Desiste = () =>
    {
      roteador(3,0);
    }
  
    const GravaRecebimento = () =>
    {
      let errogrv: string = '';
      if (!recebimento.id_cli || recebimento.id_cli===0)
      {
        errogrv = errogrv +'É necessário informar o cliente \n' ;
      }
      if (!recebimento.id_strc || recebimento.id_strc===0 || (!recebimento.pagto_rcbt && achaDescricao(recebimento.id_strc, situacoes, 'id_strc', 'comportamento_strc') ==='B') || recebimento.valor_rcbt<0 || recebimento.descapl_rcbt>recebimento.valor_rcbt || recebimento.descapl_rcbt>recebimento.valor_rcbt || recebimento.desconto_rcbt<0 || recebimento.descapl_rcbt<0 || recebimento.multa_rcbt<0 || recebimento.jurosrec_rcbt<0 || recebimento.despesas_rcbt<0 || recebimento.multarec_rcbt<0 || recebimento.jurosdia_rcbt<0)
      {
        errogrv = errogrv + 'Verifique os dados do recebimento \n';
      }
      let totrec = nulltoNumber(parseFloat(recebimento.detalhes.reduce((acum, det) => acum + (det.id_rcta && det.id_rcta!==0 && (!det.alterado || det.alterado!=='B') ? det.valor_dtrc : 0), 0).toFixed(2)));
      let totdsp = nulltoNumber(parseFloat(recebimento.detalhes.reduce((acum, det) => acum + (det.id_dsps && det.id_dsps!==0 && (!det.alterado || det.alterado!=='B') ? det.valor_dtrc : 0), 0).toFixed(2)));
      if (totrec!==nulltoNumber(parseFloat((nulltoNumber(recebimento.valor_rcbt)+nulltoNumber(recebimento.jurosrec_rcbt)+nulltoNumber(recebimento.multarec_rcbt)).toFixed(2))))
      {
        errogrv = errogrv + 'Soma das receitas (aba detalhes) deve ser igual à soma do valor, juros e multa recebidos \n';
      }
      if (totdsp!==nulltoNumber(parseFloat((nulltoNumber(recebimento.descapl_rcbt)+nulltoNumber(recebimento.despesas_rcbt)).toFixed(2))))
      {
        errogrv = errogrv + 'Soma das despesas (aba detalhes) deve ser igual à soma do valor do desconto aplicado e das despesas lançadas';
      }

      if (errogrv.length>0)
      {
        mensagem(errogrv,'error',10000);
      }
      else
      {
        textodialogo=[]
        if (sted.detalhes)
        {
          textodialogo.push('Detalhes');
        }
        if (sted.ocorrencias)
        {
          textodialogo.push('Ocorrências');
        }
        if (textodialogo.length>0)
        {
          botoesdialogo=[{descricao: 'Confirma', retorno: 'A'},{descricao: 'Cancela', retorno: 'B'}]
          titulodialogo='Há informações alteradas e não confirmadas nas seguintes áreas:'
          textodialogo.push('Confirma a operação?');
          setDialog(true)
        }
        else
        {
          salvaRecebimento();
        }
      }
    }
  
    const salvaRecebimento = () =>
    {
      let rcgrv: Recebimentos = JSON.parse(JSON.stringify(recebimento));
      if (recebimento.datadesc_rcbt)
      {
        rcgrv.datadesc_rcbt=DataSql(recebimento.datadesc_rcbt);
      }
      if (recebimento.pagto_rcbt)
      {
        rcgrv.pagto_rcbt=DataSql(recebimento.pagto_rcbt);
      }
      rcgrv.vencto_rcbt=DataSql(recebimento.vencto_rcbt);
      rcgrv.id_oper=dadosobt.objac.id_oper;
      rcgrv.detalhes=[];
      recebimento.detalhes.forEach(det =>
      {
        if (det.alterado && (det.alterado==='B' || det.alterado==='A'))
        {
          rcgrv.detalhes.push(det);
        }
      });
      rcgrv.ocorrencias=[];
      recebimento.ocorrencias.forEach(ocor =>
      {
        if (ocor.alterado && (ocor.alterado==='B' || ocor.alterado==='A'))
        {
          ocor.data_ocrc=DataSql(ocor.data_ocrc);
          rcgrv.ocorrencias.push(ocor);
        }
      });

      srv.Post('Recebimentos/','',JSON.stringify(rcgrv),dadosobt.objac.token,false)
      .then((ddret: any) => {
        let dadosret: Resposta = TrataResposta(ddret);
        if (!dadosret.ok)
        {
          mensagem(dadosret.erro,'error',6000)
        }
        else
        {
          mensagem('Recebimento: '+dadosret.retorno.id.toString()+' id interno: '+dadosret.retorno.adicional+' salvo com sucesso','info',8000,true);
        }
      })
      .catch(Error => 
      {
        mensagem('Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', 'error',6000);
      })
    }

    return (
        <div>
            <div className={classes.divgrid}>
                <AlertDialog aberta={dialogo} fecha={fechaDialogo} titulo={titulodialogo} texto={textodialogo} botoes={botoesdialogo}/>
                <Grid container spacing={1} className={classes.griddados}>
                    <Grid item xs={2}>
                        <CampoTexto valor={recebimento.id_rcbt>0 ? recebimento.id_rcbt : 0} id='id_rcbt' label='Id' placeholder='Id' classe={classes.textoform} tipo='number' required={false} habilitado={false} onchange={undefined}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoTexto valor={recebimento.idinterno_rcbt ? recebimento.idinterno_rcbt : ''}  id='idinterno_rcbt' label='Id interno' placeholder='Id interno' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altbas} onchange={onChangeRecebimentoInput} tamanho={10} />
                    </Grid>
                    <Grid item xs={8}>
                        <SelecaoAuto valor={recebimento.id_cli} nome='id_cli' label='Cliente' classe={classes.textoform} conteudo={clientes} habilitado={acessos.altavc} colunas={{id: 'id_cli', desc: 'nome_cli'}} erro={!recebimento.id_cli || recebimento.id_cli<1} onchange={onChangeRecebimentosAutoComplete}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Selecao valor={recebimento.id_strc ? recebimento.id_strc : 0 }  nome='id_strc' label='Situação' required={true} habilitado={acessos.altavc} onchange={onChangeRecebimentoSelect} conteudo={situacoes} nulo={[0,'Indefinida']} classe={classes.textoform} erro={!recebimento.id_strc || recebimento.id_strc<1} />
                    </Grid>
                    <Grid item xs={2}>
                        <CampoData valor={recebimento.vencto_rcbt} id='vencto_rcbt' label='Vencimento' placeholder='Vencimento' classe={classes.textoform} required={true} habilitado={acessos.altbas || acessos.altavc} onchange={onChangeRecebimentoDataInput} erro={!recebimento.vencto_rcbt} />
                    </Grid>
                    <Grid item xs={2}>
                        <CampoData valor={recebimento.pagto_rcbt} id='pagto_rcbt' label='Pagamento' placeholder='Pagamento' classe={classes.textoform} required={false} habilitado={acessos.altbxa && achaDescricao(recebimento.id_strc, situacoes, 'id_strc', 'comportamento_strc')==="B"} onchange={onChangeRecebimentoDataInput} limpadata={limpaData} erro={!recebimento.pagto_rcbt && achaDescricao(recebimento.id_strc, situacoes, 'id_strc', 'comportamento_strc')==="B"}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={recebimento.valor_rcbt}  id='valor_rcbt' label='Valor' placeholder='Valor' classe={classes.textoform} tipo='text' required={true} habilitado={acessos.altavc} onchange={onChangeRecebimentoDecimalInput} erro={recebimento.valor_rcbt<=0}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={recebimento.desconto_rcbt}  id='desconto_rcbt' label='Desconto' placeholder='Desconto' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoDecimalInput} erro={recebimento.desconto_rcbt<0 || recebimento.desconto_rcbt>recebimento.valor_rcbt}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoData valor={recebimento.datadesc_rcbt} id='datadesc_rcbt' label='Desconto até' placeholder='Desconto até' classe={classes.textoform} required={false} habilitado={acessos.altbas || acessos.altavc} onchange={onChangeRecebimentoDataInput} limpadata={limpaData}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={recebimento.descapl_rcbt}  id='descapl_rcbt' label='Desconto aplicado' placeholder='Desc. aplicado' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoDecimalInput} erro={recebimento.descapl_rcbt<0 || recebimento.descapl_rcbt>recebimento.valor_rcbt}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={recebimento.multarec_rcbt}  id='multarec_rcbt' label='Multa recebida' placeholder='Multa recebida' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoDecimalInput} erro={recebimento.multarec_rcbt<0}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={recebimento.jurosrec_rcbt}  id='jurosrec_rcbt' label='Juros recebidos' placeholder='Juros recebidos' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoDecimalInput} erro={recebimento.jurosrec_rcbt<0}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoDecimal valor={recebimento.despesas_rcbt}  id='despesas_rcbt' label='Despesas' placeholder='Despesas' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoDecimalInput} erro={recebimento.despesas_rcbt<0}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoDecimal valor={recebimento.multa_rcbt}  id='multa_rcbt' label='Valor da multa ' placeholder='Valor da multa' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoDecimalInput} erro={recebimento.multa_rcbt<0}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoDecimal valor={recebimento.jurosdia_rcbt}  id='jurosdia_rcbt' label='Juros por dia' placeholder='Juros por dia' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoDecimalInput} erro={recebimento.jurosdia_rcbt<0}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoTexto valor={recebimento.autorizacao_rcbt ? recebimento.autorizacao_rcbt : ''}  id='autorizacao_rcbt' label='Autorização' placeholder='Autorização' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altbas} onchange={onChangeRecebimentoInput} tamanho={20} />
                    </Grid>
                    <Grid item xs={2}>
                        <CampoTexto valor={recebimento.nfs_rcbt ? recebimento.nfs_rcbt : ''}  id='nfs_rcbt' label='NFs' placeholder='NFs' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altbas} onchange={onChangeRecebimentoInput} tamanho={15}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoTexto valor={recebimento.rps_rcbt ? recebimento.rps_rcbt : ''}  id='rps_rcbt' label='RPS' placeholder='RPS' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altbas} onchange={onChangeRecebimentoInput} tamanho={15}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Selecao valor={recebimento.id_cta ? recebimento.id_cta : 0 }  nome='id_cta' label='Conta' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoSelect} conteudo={contas} nulo={[0,'Indefinida']} classe={classes.textoform} />
                    </Grid>
                    <Grid item xs={2}>
                        <CampoTexto valor={recebimento.carteira_rcbt ? recebimento.carteira_rcbt : ''}  id='carteira_rcbt' label='Carteira' placeholder='Carteira' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoInput} tamanho={6}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoTexto valor={recebimento.numban_rcbt ? recebimento.numban_rcbt : ''}  id='numban_rcbt' label='Nº bancário' placeholder='Nº bancário' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altavc} onchange={onChangeRecebimentoInput} tamanho={20}/>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.divtabs}>
                <StyledAppBar position="static">
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        variant='scrollable'
                        scrollButtons="auto"
                        aria-label="scrollable prevent tabs example"
                    >
                        <Tab classes={{root: classes.tab}} label={'Detalhes'} icon={<DescriptionIcon />} aria-label="detalhes" {...a11yProps(0)} />
                        <Tab classes={{root: classes.tab}} label={'Ocorrências'} icon={<ListIcon/>} aria-label="ocorrencias" {...a11yProps(1)} />
                    </Tabs>
                </StyledAppBar>
                <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={1} className={classes.griddados}>
                        <Grid item xs={2}>
                            <CampoDecimal valor={detdig.valor_dtrc}  id='valor_dtrc' label='Valor' placeholder='Valor' classe={classes.textoform} tipo='text' required={true} habilitado={(acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)} onchange={onChangeRecebimentoDecimalInput} erro={detdig.valor_dtrc<=0}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Selecao valor={detdig.id_rcta ? detdig.id_rcta : 0}  nome='id_rcta' label='Receita' required={true} habilitado={((acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)) && (!detdig.id_dsps || detdig.id_dsps===0)} onchange={onChangeRecebimentoSelect} conteudo={receitas} nulo={[0,'Indefinida']} classe={classes.textoform} erro={(!detdig.id_rcta || detdig.id_rcta<1) && (!detdig.id_dsps || detdig.id_dsps<1)} />
                        </Grid>
                        <Grid item xs={2}>
                            <Selecao valor={detdig.id_dsps ? detdig.id_dsps : 0}  nome='id_dsps' label='Despesa' required={true} habilitado={((acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)) && (!detdig.id_rcta || detdig.id_rcta===0)} onchange={onChangeRecebimentoSelect} conteudo={despesas} nulo={[0,'Indefinida']} classe={classes.textoform} erro={(!detdig.id_rcta || detdig.id_rcta<1) && (!detdig.id_dsps || detdig.id_dsps<1)} />
                        </Grid>
                        <Grid item xs={1}>
                            <CampoDecimal valor={detdig.basecom_dtrc}  id='basecom_dtrc' label='Base comissão' placeholder='Base comissão' classe={classes.textoform} tipo='text' required={false} habilitado={(acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)} onchange={onChangeRecebimentoDecimalInput} erro={detdig.basecom_dtrc<0 || detdig.basecom_dtrc>detdig.valor_dtrc}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Selecao valor={detdig.id_vend ? detdig.id_vend : 0}  nome='id_vend' label='Vendedor' required={false} habilitado={(acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)} onchange={onChangeRecebimentoSelect} conteudo={vendedores} nulo={[0,'Indefinido']} classe={classes.textoform} erro={detdig.id_vend===0 && detdig.basecom_dtrc>0} />
                        </Grid>
                        <Grid item xs={1}>
                          <CampoTexto valor={detdig.loja_dtrc}  id='loja_dtrc' label='Loja' placeholder='Código da loja' classe={classes.textoform} tipo='number' required={false} habilitado={((acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)) && acessos.altavc} onchange={onChangeRecebimentoInput} />
                        </Grid>
                        <Grid item xs={1}>
                          <CampoTexto valor={detdig.rps_dtrc ? detdig.rps_dtrc : ''}  id='rps_dtrc' label='RPS' placeholder='RPS' classe={classes.textoform} tipo='text' required={false} habilitado={(acessos.incdet && inddet===0) || (acessos.altdet && inddet>0)} onchange={onChangeRecebimentoInput} tamanho={15}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="contained" color="primary" disabled={(!acessos.altdet && inddet>0) || (!acessos.incdet && inddet===0)} className={classes.botao} onClick={adicionaDetalhe} endIcon={<VerticalAlignBottomIcon/>}>Adiciona</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TabelaBase linhasoriginais={RetornaDetalhes()} cab={hddet} selecao={false} edicao={acessos.altdet} adicao={acessos.incdet} habfiltro={false} onClickEdicao={editaDetalhe} titulo={''} full={true} linhasiniciais={5} exclusao={acessos.excdet} auxedit={1} auxexclui={2} />
                        </Grid>
                        <Grid style={{margin: '-4% 0 0 0'}} item xs={5}>
                          <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: '90px', padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '0 3px 5px 2px lightblue'}} variant="outlined">
                              <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                  <Typography style={{color:'darkblue', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Receitas</Typography>
                                  <Verificadetalhes tipo='A'/>
                              </CardContent>
                          </Card>
                        </Grid>
                        <Grid style={{margin: '-4% 0 0 0'}} item xs={5}>
                          <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 90, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '0 3px 5px 2px rgba(0, 105, 255, .3)'}} variant="outlined">
                              <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                  <Typography style={{color:'darkblue', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Despesas</Typography>
                                  <Verificadetalhes tipo='B'/>
                              </CardContent>
                          </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                <Grid container spacing={1}>
                    <Grid item className={classes.gridcampos}>
                        <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <CampoData valor={RetornaDataString(ocordig.data_ocrc)} id='data_ocrc' label='Data' placeholder='Data' classe={classes.textoform} required={true} habilitado={acessos.altocor && indocor>0} onchange={onChangeRecebimentoDataInput} hora={true}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Selecao valor={ocordig.id_tpor}  nome='id_tpor' label='Ocorrência' required={true} habilitado={(acessos.altocor && indocor>0) || (acessos.incocor && indocor===0)} onchange={onChangeRecebimentoSelect} conteudo={ ocorrencias.filter((ocor: Tipos_Ocorrencia_Recebimento) => ocor.tipo_tpor && ocor.tipo_tpor!=='B') } nulo={[0,'Indefinida']} classe={classes.textoform} erro={!ocordig.id_tpor || ocordig.id_tpor<1}/>
                        </Grid>
                        <Grid item xs={6}>
                            <CampoTexto valor={ocordig.obs_ocrc} id='obs_ocrc' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={(acessos.altocor && indocor>0) || (acessos.incocor && indocor===0)} onchange={onChangeRecebimentoInput}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" disabled={!((acessos.altocor && indocor>0) || (acessos.incocor && indocor===0))} className={classes.botao} onClick={adicionaOcorrencia} endIcon={<VerticalAlignBottomIcon/>}>Adiciona</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TabelaBase linhasoriginais={RetornaOcorrencias()} cab={hdoco} selecao={false} edicao={acessos.altocor} adicao={acessos.altocor} habfiltro={false} onClickEdicao={editaOcorrencia} titulo={''} full={true} linhasiniciais={5} exclusao={acessos.excocor} auxedit={1} auxexclui={2} />
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.gridfoto}>
                        <ExibePdfImg id='documento_ocrc' exibicao={false} objeto={{tipo: ocordig.tipodoc_ocrc, conteudo: ocordig.documento_ocrc}} tpaceito='C'  retorno={atualizaPdfImgRecebimento} altera={(acessos.altocor && indocor>0) || (acessos.incocor && indocor===0)}/>
                    </Grid>
                    </Grid>
                </TabPanel>
                <Grid container spacing={1} className={classes.griddados}>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={GravaRecebimento} endIcon={<SaveIcon/>}>Confirma</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={Desiste} endIcon={<CancelIcon/>}>Desiste</Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}  

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  openToast: boolean,
  severidadeToast: string,
  mensagemToast: string,
  duracaoToast: number,
  acessosok: boolean,
}

class EdicaoRecebimentos extends Component<PropsType, IState>
{

    private _dadosobt: RetornoAcesso;
    private _roteador: Roteador;
    private _id_rcbt: number;
    private _dadoobtido: boolean;
    private _acessos: number;
    private _retornamensagem: boolean;
    private _clientes: {id_cli: number, nome_cli: string} [];
    private _recebimento: Recebimentos;
    private _contas: Contas[];
    private _situacoes: Situacoes_Recebimentos[];
    private _receitas: Receitas[];
    private _despesas: Despesas[];
    private _ocorrencias: Tipos_Ocorrencia_Recebimento[];
    private _vendedores: Vendedores[];
    
    constructor(props: any)
    {
        super(props);

        this._dadosobt=new RetornoAcesso();
        this._id_rcbt=0;

        if (props.location.state)
        {
            if(props.location.state.dadosret)
            {
                this._dadosobt=props.location.state.dadosret;
            }
            if (props.location.state.id_edicao)
            {
                this._id_rcbt=props.location.state.id_edicao;
            }
        }

        if (!this._dadosobt.ok)
        {
            this.props.history.push({pathname: '/'})
        }

        this._roteador=new Roteador(this._dadosobt, '/Recebimentos');
        this._roteador.history=props.history;
        this._dadoobtido=false;
        this._ocorrencias= [];
        this._situacoes=[];
        this._vendedores=[];
        this._clientes = [];
        this._receitas = [];
        this._despesas = [];
        this._recebimento =  new Recebimentos();
        this._contas = [];
    
        this._acessos=0;
        this._retornamensagem=false;
    
        if (this._id_rcbt<0 && this._dadosobt.ok)
        {
            this._dadoobtido=true;
        }
            this.state =
            {
                dadoobtido: this._dadoobtido,
                openToast: false,
                severidadeToast: 'success',
                mensagemToast: '',
                duracaoToast: 6000,
                acessosok: false,
            }
        if (this._dadosobt.ok)
        {
            if (this._id_rcbt>0)
            {
                srv.Get('Recebimentos/'+this._id_rcbt, '', '', this._dadosobt.objac.token, false)
                .then((ddret: any) => 
                {
                    let dadosret: Resposta = TrataResposta(ddret);
                    if (dadosret.ok)
                    {
                        this._recebimento=dadosret.retorno; 
                        this.aumentaAcessos(); 
                        this.setState({dadoobtido: true});
                    }
                    else
                    {
                        this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo recebimento (' + dadosret.erro + ')' });
                    }
                })
                .catch(Error => this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo recebimento (' + Error.name + ' - ' + Error.message + ')' }));
            }
            else
            {
                this.aumentaAcessos();
            }
            this.ObtemDados('Clientes/0/0','clientes',this.setaclientes.bind(this));
            this.ObtemDados('Contas/','contas',this.setacontas.bind(this));
            this.ObtemDados('Receitas/','receitas',this.setareceitas.bind(this));
            this.ObtemDados('Despesas/','despesas',this.setadespesas.bind(this));
            this.ObtemDados('SituacoesRecebimentos/','situações de recebimentos',this.setasituacoes.bind(this));
            this.ObtemDados('TiposOcorrenciaRecebimento/','tipos de ocorrência',this.setaocorrencias.bind(this));
            this.ObtemDados('Vendedores/','vendedores',this.setavendedores.bind(this));
        }
    }
    
    setaclientes(registros: any) {this._clientes=RetornaClientes(registros); this.aumentaAcessos();};
    setacontas(registros: any) {this._contas=registros; this.aumentaAcessos();};
    setareceitas(registros: any) {this._receitas=RetornaReceitasDespesas(registros,'A'); this.aumentaAcessos();};
    setadespesas(registros: any) {this._despesas=RetornaReceitasDespesas(registros,'B'); this.aumentaAcessos();};
    setaocorrencias(registros: any) {this._ocorrencias=registros; this.aumentaAcessos();};
    setasituacoes(registros: any) {this._situacoes=registros; this.aumentaAcessos();};
    setavendedores(registros: any) {this._vendedores=registros; this.aumentaAcessos();};

    aumentaAcessos()
    {
        this._acessos = this._acessos+1
        if(this._acessos>7)
        {
            InicializaEdicao();
            this.setState({acessosok: true});
        }
    }
        
    ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
    {
        srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
        let dadosret:Resposta = TrataResposta(ddret);
        if(dadosret.ok)
        {
            obret(dadosret.retorno)
        }
        else
        {
            this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
            this.aumentaAcessos();
        }
        })
        .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
    }

    ExibeMensagem(mensagem: string, severidade: string, duracao:number, retorna:boolean=false)
    {
        this.setState({openToast: true, severidadeToast: severidade, mensagemToast: mensagem, duracaoToast: duracao})
        this._retornamensagem=retorna;
    }

    FechaMensagem()
    {
        this.setState({openToast: false});
        if (this._retornamensagem)
        {
            this._roteador.rota(3,0);
        }
    }

    render()
    {
        if (!this._dadosobt.ok)
        {
        return null
        }
        else
        {
            return (
            <div className="Home">
                <header className="Home-header">
                <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador} recurso='Edição de Recebimento'/>
                </header>
                <main>
                { this.state.acessosok ?
                    <PrincipalEdicaoRecebimentos dadosrec={this._recebimento} dadosobt={this._dadosobt} clientes={this._clientes} contas={this._contas} situacoes={this._situacoes} ocorrencias={this._ocorrencias} receitas={this._receitas} despesas={this._despesas} vendedores={this._vendedores} mensagem={this.ExibeMensagem.bind(this)} roteador={this._roteador.rota.bind(this._roteador)} />
                    :
                    <Progresso/>
                }
                </main>
                <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
            </div>
            )
        }
    }
}

export default withRouter(EdicaoRecebimentos)
