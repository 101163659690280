import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    camposelecao: {
      margin: "2% 10%",
      width: "80%"
    },
  })
);

type SelecaoProps=
{
  nome: string,
  label: string,
  required: boolean,
  classe: string,
  valor: any,
  habilitado: boolean,
  conteudo: any [],
  onchange?: ((event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode, campo?: string | undefined) => void) | undefined,
  nulo: [number | string,string],
  erro?: boolean,
  tamletra?: string,
  altura?: string
}

export default function Selecao({nome, label, required, classe, valor, habilitado, conteudo, onchange, nulo, erro, tamletra, altura }: SelecaoProps) {

  const classes = useStyles();
  let contok=false;
  let chaves: string[] = ['',''];

  if (conteudo)
  {
    if (conteudo[0])
    {
      chaves = Object.keys(conteudo[0]);
      if (chaves.length>1)
      {
        if (typeof chaves[0]==='string' && typeof chaves[1]==='string')
        {
          contok=true;
        }
      }
    }
  }

  return (
    <FormControl className={classe ? classe : classes.camposelecao}>
        <InputLabel style = {{fontSize: tamletra ? tamletra : undefined}} variant='outlined' required={required} htmlFor="outlined-age-native-simple">{label}</InputLabel>
        <Select
        style = {{fontSize: tamletra ? tamletra : undefined, maxHeight: altura ? altura : undefined, boxShadow: "3px 3px 1px lightblue"}}
        id={nome}
        name={nome}
        error={erro}
        native
        value={valor.toString()}
        onChange={onchange ? (event, child) => onchange(event, child, nome) : undefined}
        label={label}
        required={required}
        variant='outlined'
        disabled={!habilitado}
        >
        <option key={0} aria-label={nulo[1]} value={nulo[0]}>{nulo[1]}</option>
        {contok ? conteudo.map((cont) => <option key={cont[chaves[0]]} value={cont[chaves[0]]}>{cont[chaves[1]]}</option>) : null}
        </Select>
    </FormControl>
  );
}