export class Contatos_Cliente
{
    id_ctcl: number = 0;
    id_occl: number = 0;
    id_cli: number = 0;
    data_ctcl: Date = new Date();
    obs_ctcl: string = '';
    id_oper: number = 0;
    nome_oper: string = '';
    gravadoc: boolean = false;
    documento_ctcl?: Uint32Array = undefined;
    tipodoc_ctcl: string = '';
    alterado: string = ''
}