import { Valores_Grupos } from "./Valores_Grupos";

export class Tipos_Grupos
{
    id_tpgr: number = 0;
    desc_tpgr: string = '';
    valores: Valores_Grupos[] = [];
    id_vlgr: number = 0;
    alterado: string = '';
}
