export class ApiService<T>
{
    private _producao: boolean = true;
    private _somei: boolean = false;
    private _urlAuth= this._producao ? this._somei ? 'http://api.masteroffice.inf.br:9011/api'  : 'http://api.masteroffice.inf.br:9001/api' : 'http://localhost:8000/api';
    private _urlBase= this._producao ? this._somei ? 'http://api.masteroffice.inf.br:9012/api'  : 'http://api.masteroffice.inf.br:9002/api' : 'http://localhost:5000/api';
    private _headers={'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
                     'Access-Control-Allow-Origin': '*', 
                     'Access-Control-Allow-Credentials': 'true', 
                     'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                     'Content-Type': 'application/json',
                     'Authorization': ''
                    };
 
    Post(parametro: string, content: string, body: string, bearer: string, aut: boolean): Promise<any>
    {
        if (content)
        {
            this._headers["Content-Type"]=content;
        }
        if (bearer)
        {
            this._headers["Authorization"]='Bearer '+bearer;
        }
        return fetch(`${aut ? this._urlAuth : this._urlBase}/${parametro}`, {method: 'POST', headers: this._headers, body})
        .then(res => 
            {
                if(!res.ok) 
                { 
                    throw new Error(res.statusText)
                } 
                return res.json();
            });
    }

    Get(parametro: string, content: string, body: string, bearer: string, aut: boolean, urlesp: string='', noheaders: boolean=false): Promise<any>
    {
        if (content)
        {
            this._headers["Content-Type"]=content;
        }
        if (bearer)
        {
            this._headers["Authorization"]='Bearer '+bearer;
        }
        return fetch(urlesp ? urlesp : `${aut ? this._urlAuth : this._urlBase}/${parametro}`, noheaders ?  {method: 'GET'} : {method: 'GET', headers: this._headers})
        .then(res => 
            {
                if(!res.ok) 
                { 
                    throw new Error(res.statusText)
                } 
                return res.json()
            });
    }

    Put(parametro: string, content: string, body: string, bearer: string, aut: boolean): Promise<any>
    {
        if (content)
        {
            this._headers["Content-Type"]=content;
        }
        if (bearer)
        {
            this._headers["Authorization"]='Bearer '+bearer;
        }
        return fetch(`${aut ? this._urlAuth : this._urlBase}/${parametro}`, {method: 'PUT', headers: this._headers, body})
        .then(res => 
            {
                if(!res.ok) 
                { 
                    throw new Error(res.statusText)
                } 
                return res.json();
            });
    }

    Delete(parametro: string, content: string, body: string, bearer: string, aut: boolean): Promise<any>
    {
        if (content)
        {
            this._headers["Content-Type"]=content;
        }
        if (bearer)
        {
            this._headers["Authorization"]='Bearer '+bearer;
        }
        return fetch(`${aut ? this._urlAuth : this._urlBase}/${parametro}`, {method: 'DELETE', headers: this._headers, body})
        .then(res => 
            {
                if(!res.ok) 
                { 
                    throw new Error(res.statusText)
                } 
                return res.json();
            });
    }
}

export default ApiService;