import React, { Component } from 'react';
import BarraInicial from '../Componentes/Barrainicial';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import ApiService from '../Servicos/ApiService';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SolicitaAcesso, RetornoAcesso, SolicitaResetSenha } from '../Modelos/ObjetosDiversos';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Toast from '../Componentes/Toast';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import { Roteador } from '../Servicos/Roteador';
import CampoTexto from '../Componentes/CampoTexto';
import { Criptografia, DadosConexao, Resposta, TrataResposta, urlbase, ValidaEmail } from '../Servicos/Utilidades';
import { Grid } from '@material-ui/core';
import Progresso from '../Componentes/Progresso';

const srv= new ApiService();
const useStyles = makeStyles((theme: Theme) =>
createStyles(
  {
    botaologin: {
      margin: "0 10% 5% 10% ",
      width: "80%"
    },
    cartaologin: {
      float: "right",
      width: "35%",
      margin: "40px 40px"
    },
    rckylogo: {
      width: "40%",
      margin: "40px 40px",
      float: "left",
      boxShadow: "10px 10px 5px lightblue"
    }
  }
));

type RenderProps=
{
  clck: () => void,
  esqueci: () => void,
  onChangetexto: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, valor: string | number | Date) => void,
  usuario: string,
  senha: string,
  esqueceu: boolean,
  enviando: boolean
}

const RenderSytle = ({esqueci, clck, onChangetexto, usuario, senha, esqueceu, enviando}: RenderProps) =>
{
  const classes = useStyles();

  return (
    <div>
      <img src='Imagens/logorcky.png' className={classes.rckylogo} alt="logo rcky" />
      <Card className={classes.cartaologin} variant="outlined">
        <CardContent>
          <CampoTexto valor={usuario} id='usuario' label='Usuário' placeholder='Usuário' tipo='text' required={false} classe='' habilitado={true} onchange={ onChangetexto }></CampoTexto>
          {esqueceu ? null : 
            <CampoTexto valor={senha} id='senha' label='Senha' placeholder='' tipo='password' required={true} classe='' habilitado={true} onchange={ onChangetexto }></CampoTexto>
          }
        </CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Button onClick={clck} className={classes.botaologin} variant="contained" color="primary" endIcon={<VpnKeyIcon/>}>{esqueceu ? 'Retorna ao Login' : 'Login'}</Button>
          </Grid>
          <Grid item xs={6}>
            {enviando ?
              <Progresso/>
              :
              <Button onClick={esqueci} className={classes.botaologin} variant="contained" color="primary" endIcon={<ContactSupportIcon/>}>{esqueceu ? 'Reiniciar Senha' : 'Esqueci minha senha'}</Button>
            }
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  open: boolean,
  mensagem: string,
  duracao: number,
  severidade: string,
  usuario: string,
  senha: string,
  esqueceu: boolean,
  enviando: boolean,
  dadosok: boolean
}

class Login extends Component<PropsType, IState>
{

  public roteador: Roteador;
  private _docdiv: HTMLElement | null = null;
  private _dadosconexao?: DadosConexao;

  constructor(props: PropsType)
  {
    super(props);

    this.state =
    {
      open: false,
      mensagem: '',
      duracao: 3000,
      severidade: 'success',
      usuario: '',
      senha: '',
      esqueceu: false,
      enviando: false,
      dadosok: false,
    }

    this.roteador=new Roteador(new RetornoAcesso(),'/');
    this.roteador.history=props.history;

    if (!this._dadosconexao || !this._dadosconexao.ok)
    {
      const srv = new ApiService();
      srv.Get('Servidor/Conexao','', '', '', true)
      .then((ddret: any) => 
      {
          let dadosret: Resposta = TrataResposta(ddret);
          if (dadosret.ok)
          {
            this._dadosconexao=dadosret.retorno;
            this.setState({dadosok: true});
          }
          else
          {
            this._dadosconexao={ok: false, catalogo: '', erro: dadosret.erro, homologa: false}
            this.setState({dadosok: true});
          }
      })
      .catch(Error => {this._dadosconexao={ok: false, catalogo: '', erro: 'Erro acessando a API - obtendo dados de conexao (' + Error.name + ' - ' + Error.message + ')', homologa: false}; this.setState({dadosok: true});});
    }
    else
    {
      this.setState({dadosok: true});
    }
  }

  TeclaPressionada(e: KeyboardEvent)
  {
    if (e.key==='Enter' && !e.altKey && !e.ctrlKey && !e.shiftKey)
    {
      if (this.state.esqueceu)
      {
        this.Esqueci();
      }
      else
      {
        this.Click();
      }
    }
  }

  FechaMensagem()
  {
    this.setState({open: false});
  }

  SetaEstado(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, valor: string | number | Date)
  {
    if (event.target.id==='usuario')
    {
      this.setState({usuario: valor.toString()})
    }
    else if(event.target.id==='senha')
    {
      this.setState({senha: valor.toString()})
    }
  }

  Esqueci()
  {
    if (this.state.esqueceu)
    {
      if (this.state.usuario)
      {
        this.setState({enviando: true});
        srv.Post('Operadores/operador', '', '"'+this.state.usuario.trim()+'"', '', true)
          .then((ddret: any) => {

            let dadosret: Resposta = TrataResposta(ddret);

            if (!dadosret.ok)
            {
              this.setState({open: true, mensagem: dadosret.erro, duracao: 3000, severidade: 'error', enviando: false});
              document.querySelector<HTMLInputElement>("#usuario")?.focus();
            }
            else
            {
              let idoper: string = dadosret.retorno.id_oper.toString().trim();
              let nome: string = dadosret.retorno.pessoa.nome_pessoa.trim();
              if(dadosret.retorno.pessoa.emails && dadosret.retorno.pessoa.emails.length>0 && ValidaEmail(dadosret.retorno.pessoa.emails[0].endereco_email.trim()))
              {
                let email: string = dadosret.retorno.pessoa.emails[0].endereco_email.trim();
                let emaildisp=email.substr(0,2)+'*****'+email.substr(email.length-2,2);
                srv.Get('Servidor/Datahora', '', '', '', true)
                .then((ddret: any) => {
                  let dadosret: Resposta = TrataResposta(ddret);
      
                  if (!dadosret.ok)
                  {
                    this.setState({open: true, mensagem: dadosret.erro, duracao: 3000, severidade: 'error', enviando: false});
                    document.querySelector<HTMLInputElement>("#usuario")?.focus();
                  }
                  else
                  {
                    let crip=Criptografia.Encripta('ResetSenha|'+idoper+'|'+dadosret.retorno.datahora.trim());
                    let solres = new SolicitaResetSenha();
                    solres.nome = nome;
                    solres.email = email;
                    solres.link = urlbase+'/'+crip;
                    srv.Post('Servidor/reset', '', JSON.stringify(solres), '', true)
                    .then((ddret: any) => {
          
                      let dadosret: Resposta = TrataResposta(ddret);
          
                      if (!dadosret.ok)
                      {
                        this.setState({open: true, mensagem: dadosret.erro, duracao: 3000, severidade: 'error', enviando: false});
                        document.querySelector<HTMLInputElement>("#usuario")?.focus();
                      }
                      else
                      {
                        this.setState({open: true, mensagem: 'Email enviado com sucesso para o endereço '+emaildisp, duracao: 3000, severidade: 'success', usuario: '', senha: '', esqueceu: false, enviando: false});
                      }
                    })
                    .catch(Error => 
                    {
                      this.setState({open: true, mensagem: 'Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', duracao: 3000, severidade: 'error', enviando: false})
                      document.querySelector<HTMLInputElement>("#usuario")?.focus();
                    });
                  }
                })
                .catch(Error => 
                {
                  this.setState({open: true, mensagem: 'Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', duracao: 3000, severidade: 'error', enviando: false})
                  document.querySelector<HTMLInputElement>("#usuario")?.focus();
                });
              }
              else
              {
                this.setState({open: true, mensagem: 'O usuário não possui e-mails cadastrados', duracao: 3000, severidade: 'error', enviando: false})
                document.querySelector<HTMLInputElement>("#usuario")?.focus();
              }
            }
          })
          .catch(Error => 
          {
            this.setState({open: true, mensagem: 'Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', duracao: 3000, severidade: 'error', enviando: false})
            document.querySelector<HTMLInputElement>("#usuario")?.focus();
          });
      }
      else
      {
        this.setState({open: true, mensagem: 'É preciso informar o usuário', duracao: 3000, severidade: 'error', enviando: false})
        document.querySelector<HTMLInputElement>("#usuario")?.focus();
      }
    }
    else
    {
      this.setState({esqueceu: true});
    }
  }

  Click()
  {
    if (this.state.esqueceu)
    {
      this.setState({esqueceu: false});
    }
    else
    {
      if (this.state.usuario)
      {
        if(this.state.senha)
        {
          let solac = new SolicitaAcesso(0, this.state.usuario, this.state.senha);
    
          srv.Post('Operadores/login/', '', JSON.stringify(solac), '', true)
            .then((ddret: any) => {
  
              let dadosret: Resposta = TrataResposta(ddret);
  
              if (!dadosret.ok)
              {
                this.setState({open: true, mensagem: dadosret.erro, duracao: 3000, severidade: 'error'});
                if (dadosret.retorno.erro==='Senha não confere')
                {
                  document.querySelector<HTMLInputElement>("#senha")?.focus();
                }
                else
                {
                  document.querySelector<HTMLInputElement>("#usuario")?.focus();
                }
              }
              else
              {
                  this.roteador.dadosacesso=dadosret.retorno;
                  this.roteador.dadosacesso.dadosconexao=this._dadosconexao;
                  this.roteador.rota(0);
              }
            })
            .catch(Error => 
            {
              this.setState({open: true, mensagem: 'Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', duracao: 3000, severidade: 'error'})
              document.querySelector<HTMLInputElement>("#usuario")?.focus();
            }
          );
        }
        else
        {
          this.setState({open: true, mensagem: 'É preciso informar a senha', duracao: 3000, severidade: 'error'})
          document.querySelector<HTMLInputElement>("#senha")?.focus();
        }
      }
      else
      {
        this.setState({open: true, mensagem: 'É preciso informar o usuário', duracao: 3000, severidade: 'error'})
        document.querySelector<HTMLInputElement>("#usuario")?.focus();
      }
    }
  }

  componentDidMount()
  {
    this._docdiv=document.getElementById('divlogin');
    if (this._docdiv)
    {
      this._docdiv.addEventListener('keypress', this.TeclaPressionada.bind(this));
    }
    document.querySelector<HTMLInputElement>("#usuario")?.focus();
  }

  componentWillUnmount() 
  {
    if (this._docdiv)
    {
      this._docdiv.removeEventListener('keypress',this.TeclaPressionada.bind(this));
    }
  }


  render()
  {
    document.title='RCKY - Master Office'

    return (
      <div id='divlogin'>
        {this.state.dadosok ?
          <React.Fragment>
            <header>
              <BarraInicial ddcon={this._dadosconexao ? this._dadosconexao : undefined}/>
            </header>
            <main>
              <RenderSytle esqueci={this.Esqueci.bind(this)} clck={this.Click.bind(this)} onChangetexto={this.SetaEstado.bind(this)} usuario={this.state.usuario} senha={this.state.senha} esqueceu={this.state.esqueceu} enviando={this.state.enviando} />
              <Toast open = {this.state.open} handleClose = {this.FechaMensagem.bind(this)} severity = {this.state.severidade} duracao= {this.state.duracao}>{this.state.mensagem}</Toast>
            </main>
          </React.Fragment>
          :
          <Progresso />
        }
      </div>
    );
  }
}

export default withRouter(Login)