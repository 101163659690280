import { AppBar, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledAppBar = withStyles({
    root: {
        background: 'linear-gradient(to bottom,  #0099FF 0%, #0000FF 100%)', borderRadius: '7px'},
    },
  )(AppBar)

export const StyledMenuItem = withStyles((theme) => ({
    root: {
       backgroundColor: '#E6F2FA',
       '&:focus': {
        backgroundColor: '#E6F2FA'},
       '&:hover': {
        backgroundColor: 'darkblue',
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);  