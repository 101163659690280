import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ListIcon from '@material-ui/icons/List';
import LastPageIcon from '@material-ui/icons/LastPage';
import Input from '@material-ui/core/Input';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ImageIcon from '@material-ui/icons/Image';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import CropDinIcon from '@material-ui/icons/CropDin';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CampoTexto from './CampoTexto';
import { Iheaders, formatarCampo, RetornaUrlPdfImg } from '../Servicos/Utilidades';
import { Avatar, Button, Collapse, Grid, Tooltip } from '@material-ui/core';
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';

type Order = 'asc' | 'desc' ;

const calcperc = (bscalc: number[], vlref: number, indbs: number, indord: string, indbase: string): string =>
{
  let vlret: string = '0.00';
  let ind = indbs>=0 ? indbs : indbase.indexOf(indord);
  if (ind>=0 && ind<bscalc.length)
  {
    vlret=((vlref/bscalc[ind])*100).toFixed(2);
  }
  return vlret;
}

interface HeadCell {
  disablePadding: boolean;
  id: number;
  label: string;
  numeric: boolean;
}

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>,
  numSelected: number,
  onRequestSort: (event: React.MouseEvent<unknown>, property: number) => void,
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
  order: Order,
  orderBy: number,
  rowCount: number,
  cab: HeadCell[],
  selecao: boolean,
  edicao: boolean,
  exclusao: boolean,
  detalhe: boolean,
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { classes, onSelectAllClick, numSelected, order, orderBy, rowCount, cab, onRequestSort, selecao, edicao, exclusao, detalhe } = props;
  const createSortHandler = (property: number) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={1}>
        {selecao
          ?
          <TableCell padding="checkbox">
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'selecione todos os registros' }}
            />
          </TableCell>
          : 
          null
        }
        {detalhe 
          ?
          <TableCell>
            <ListIcon />
          </TableCell> 
          : null
        }
        {cab.map((headCell,index) => (
          <TableCell
            key={index+1}
            align={'center'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label==='+'
            ?
            headCell.label
            :
            headCell.label==='='
            ?
            <CropOriginalIcon />
            :
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'ordem desencendente' : 'ordem ascendente'}
                </span>
              ) : null}
            </TableSortLabel>
            }
          </TableCell>
        ))}
        {edicao ? 
        <TableCell className={classes.icones} padding="default" size='small'>
          <EditTwoToneIcon/>
        </TableCell> : null}
        {exclusao ? 
        <TableCell className={classes.icones} padding="default" size='small'>
          <DeleteTwoToneIcon/>
        </TableCell> : null}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:{
      color: 'blue',
      background: 'linear-gradient(to bottom, #E0F2FA 0%, #98F5FF 100%)'
    },
    normal:{
      color: 'blue',
      background: 'linear-gradient(to bottom, #E0F2FA 0%, #FFFFFF 100%)'
    },
    title: {
      margin: '1% 0% 1% 0%',
    },
    botao: {
      margin: '1% 0% 1% 0%',
      width: '100%',
    },
    filtro: {
      margin: "1% 0% 1% 0%",
      width: "100%",
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number,
  onchangeFiltro?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, filtro:string | number | Date) =>void,
  exportaExcel?: () => void,
  valorfiltro: string,
  titulo: string,
  cortit?: string
}

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {

  const classes = useToolbarStyles();
  const { numSelected, onchangeFiltro, valorfiltro, titulo, exportaExcel} = props;

  return (
    <Toolbar className={clsx(classes.root, numSelected > 0 ? classes.highlight : classes.normal)}>
      <Grid container spacing={1}>
        <Grid item xs={onchangeFiltro ? 3 : 7}>
          <Typography style={{color: props.cortit? props.cortit : 'blue'}} className={classes.title} variant="h6" id="tableTitle" component="div" >
            {titulo}
          </Typography>
          {numSelected > 0 ?
            <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
              {numSelected+ ' selecionado' + (numSelected > 1 ? 's' : '') }
            </Typography>
          : null}
        </Grid>
        <Grid item xs={onchangeFiltro ? 6 : 2}>
          {onchangeFiltro 
            ? 
            <CampoTexto valor={valorfiltro} classe={classes.filtro} id="filtro" label="Pesquisa" placeholder="Pesquisa" required={false} tipo='text' habilitado={true} onchange={onchangeFiltro} onblur={undefined} /> 
            :
            null}
        </Grid>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={2}>
          {exportaExcel 
            ?
            <Button className={classes.botao} variant='contained' color='primary' endIcon={<ViewComfyIcon />} onClick={exportaExcel}>Exporta</Button>
            :
            null
          }
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      flex: '1 1 10%',
      display: 'flex',
      padding: '0 0 0 0',
      margin: '0 0 0 0',
      width: '100%',
      justifyContent: 'left',
      textAlign: 'left',
    },
    root: {
      margin: '4% 7%',
      width: '90%',
    },
    filtro: {
      margin: '1% 7%',
      width: '90%',
    },
    full: {
      margin: '0 0 0 0',
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      boxShadow: "3px 3px 1px lightblue"
    },
    tooltip: {
      background: 'blue'
    },
    table: {
      minWidth: '60%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    paginacao: {
      display: 'inline-block',
      margin: '0 0 0 0',
      width: '100%',
      padding: '0 0 0 0',
    },
    mudapagina: {
      display: 'inline-block',
      margin: '0 0',
      width: '100%',
    },
    pagina: {
      width: '20%'
    },
    edicao: {
      margin: '0 0 0 0',
      padding: '0 0 0 0',
      cursor: 'pointer'
    },
    adicao: {
      margin: '0 0 0 0',
      justifyItems: 'right',
      justifySelf: 'right'
    },
    icones: {
      width: '1%',
      margin: 0
    },
    tableTextdesab: {
      "$hover &": {
        color: "white"
      },
      "$hover:hover &": {
        color: "black"
      }
    },
    tableRowopt: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#BFEFFF"
      },
      "&$hover": {
        backgroundColor: "#BFEFFF",
      },
      "&$hover:hover": {
        backgroundColor: "#00BFFF"
      }
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#C1EBFA"
      },
      "&$hover:hover": {
        backgroundColor: "#DCF4FC"
      }
    },
    tableRowdesab: {
      "&$hover": {
        backgroundColor: "red"
      },
      "&$hover:hover": {
        backgroundColor: "pink"
      }
    },
    tableRowCancel: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#F15050"
      },
      "&$hover": {
        backgroundColor: "#FC8383",
      },
      "&$hover:hover": {
        backgroundColor: "#F97474"
      }
    },
    tableRowalert: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#FBC9B1"
      },
      "&$hover": {
        backgroundColor: "#FCEEA9",
      },
      "&$hover:hover": {
        backgroundColor: "#F9E68C"
      }
    },
    tableRowtotal: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#7FFFD4"
      },
      "&$hover": {
        backgroundColor: "#C1FFC1",
      },
      "&$hover:hover": {
        backgroundColor: "#54FF9F"
      }
    },
    corestabela: {
      "&$selected, &$selected:hover": {
        backgroundColor: "red"
      },
      "&$hover": {
        backgroundColor: "blue",
      },
      "&$hover:hover": {
        backgroundColor: "green"
      }
    },
    tableCell: {
      "$selected &": {
        color: "yellow"
      }
    },
    selected: {},
    hover: {}
  }),
);

const GarantePagina = (pagina: number, lntotais: number, linhasporpagina:number):number =>
{
  if (pagina<0)
  {
    pagina=0;
  }
  if (pagina>Math.max(0, Math.ceil(lntotais / linhasporpagina)-1))
  {
    pagina=Math.max(0, Math.ceil(lntotais / linhasporpagina)-1);
  }
  return pagina;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: any, newPage: number) => void;
  btultpag: string;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage, btultpag } = props;

  const cpopagina=document.querySelector<HTMLInputElement>("#pagina");
  if (cpopagina)
  {
    cpopagina.value=(page+1).toString();
  }

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const MudaPagina = (event: React.ChangeEvent<HTMLInputElement>) =>
  {
    let pagina=1;
    if (event.target.value)
    {
      pagina=parseInt(event.target.value);
    }
    pagina=GarantePagina(pagina-1, count, rowsPerPage)+1;
    onChangePage(event, pagina-1)
  }

  const clickinvisivel = (event: React.MouseEvent<HTMLButtonElement>) =>
  {
    handleLastPageButtonClick(event);
  }

  return (
    <div className={classes.paginacao}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primeira página"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="página anterior">
        <KeyboardArrowLeft />
      </IconButton>
      <Input id="pagina" value={page+1} className={classes.pagina} type="number" onChange={MudaPagina} ></Input>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="próxima página"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        <LastPageIcon />
      </IconButton>
      <button hidden={true} id={btultpag} onClick={clickinvisivel}/>
    </div>
  );
}

function RetornaDetalhes({aberta, cabdet, contdet, tamdet, onClck, linpr, baseclperc, colindpr, baseindpr}: {aberta: boolean, cabdet: Iheaders[], contdet: any[], tamdet: number, onClck?: (lnpr:number, lndt: number) => void, linpr: number, baseclperc?: number[], colindpr?: string, baseindpr?: string})
{
  return(
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0, width: '100%' }} colSpan={tamdet}>
        <Collapse style={{ width: '100%'}} in={aberta} timeout="auto" unmountOnExit>
          <Paper elevation={1} style={{backgroundColor: '#F0FFFF', boxShadow: "3px 3px 1px lightblue"}}>
            <Typography color='primary' style={{margin:'0 0 0 1%', fontSize: '1.2em'}}>
              Detalhes
            </Typography>
            <Table  size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                {cabdet.map((cbd,idxcbd) => 
                {
                  return(
                    <TableCell key={idxcbd+1} align={cbd.alinhamento}>{cbd.titulo}</TableCell>
                  )
                })}
                </TableRow>
              </TableHead>
              <TableBody>
                {contdet.map((lndet, inddet) => {
                    return (
                      <TableRow key={inddet+1} onClick={onClck ? (event) => onClck(linpr, inddet) : undefined} style={{cursor: onClck ? 'pointer' : undefined}} >
                      {cabdet.map((cbd, idxcbd) =>
                        {
                          return(
                            <TableCell key={((inddet+1)*1000)+idxcbd+1} align={cbd.alinhamento} component="th" scope="row">
                              {cbd.dica ?
                                <Tooltip title={lndet[cbd.dica]} placement='top'><p>{formatarCampo(cbd.calculo && baseclperc ? calcperc(baseclperc, lndet[cbd.calculo.colbase], (colindpr && baseindpr ? -1 : 0), colindpr ? lndet[colindpr] : '', baseindpr ? baseindpr : '') : lndet[cbd.coluna],cbd.mascara)}</p></Tooltip>
                                :
                                formatarCampo(cbd.calculo && baseclperc ? calcperc(baseclperc, lndet[cbd.calculo.colbase], (colindpr && baseindpr ? -1 : 0), (colindpr ? lndet[colindpr] : ''), (baseindpr ? baseindpr : '')) : cbd.coluna==='*index*' ? (inddet+1).toString().trim() : lndet[cbd.coluna],cbd.mascara)
                              }
                            </TableCell>
                          )
                        } 
                      )}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </Paper>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

interface EnhancedTableProps{
    linhasoriginais: any,
    cab: Iheaders[],
    selecao: boolean,
    adicao: boolean,
    edicao: boolean,
    exclusao: boolean,
    habfiltro: boolean,
    onClickEdicao?: (opcao: number, idcli: number) => void,
    onClickDet?: (linhapr: number, linhadet: number) => void,
    titulo: string,
    cortit?: string,
    full: boolean,
    auxedit?: number,
    auxexclui?: number,
    linhasiniciais?: number
    habilitadense?: boolean,
    semespaco?: boolean
    onSelect?: (selecionadas: number[]) => void,
    classe?: 'filtro',
    btultpg?: string,
    aposRender?: () => void,
    ultpag?: boolean,
    onOpen?: (aberta: number) => void,
    cabdet?: Iheaders[],
    coldet?: string,
    coldetst?: string,
    tamdet?: number,
    colcnc?: string,
    cncvlr?: string,
    linhatot?: string [],
    baseclperc?: number[],
    colbsind?: string,
    basind?: string,
    colbsclprdet?: string,
    colbsinddt?: string,
    basinddt?: string,
}

let linhas:any[]=[];

export default function TabelaBase(props: EnhancedTableProps) {

  const {linhasoriginais, cab, selecao, edicao, adicao, habfiltro, onClickEdicao, onClickDet, titulo, cortit, full, auxedit, auxexclui, exclusao, linhasiniciais, habilitadense, semespaco, classe, onSelect, btultpg, aposRender, ultpag, onOpen, cabdet, coldet, coldetst, tamdet, colcnc, cncvlr, linhatot, baseclperc, colbsind, basind, colbsclprdet, colbsinddt, basinddt} = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState(0);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(linhasiniciais ? linhasiniciais : 10);
  const [filtro, setFiltro] = React.useState('');

  if (!filtro)
  {
    linhas=linhasoriginais;
  }

  const exportaExcel = () =>
  {
    const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(linhasoriginais);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, titulo + fileExtension);
  }

  const [page, setPage] = React.useState(ultpag ? Math.max(0, Math.ceil(linhas.length / (linhasiniciais ? linhasiniciais : 10)) - 1) : 0);

  React.useEffect(() => 
  {
    if (aposRender)
    {
      aposRender();
    }
  }, [linhasoriginais, aposRender])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: number) => {
    const isDesc = orderBy === property && order === 'asc';
    linhas.sort((a: any,b: any) => 
      {
        let nret: number=0
        if (a[cab[property].coluna] > b[cab[property].coluna])
        {
          nret=1;
        }
        if (a[cab[property].coluna] < b[cab[property].coluna])
        {
          nret=-1;
        }
        if(isDesc)
        {
          nret=nret*-1;
        }
        return nret;
      }
    )
    setOrder(isDesc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = linhas.filter((ln:any) => colds===-1 || ln[cab[colds].coluna]!=='D').map((n: any) => n[cab[0].coluna]);
      if(onSelect){onSelect(newSelecteds)};
      setSelected(newSelecteds);
      return;
    }
    if(onSelect){onSelect([])};
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown> | ChangeEvent<HTMLInputElement>, idrow: number) => {

    let selok:boolean=true;

    if (colds!==-1)
    {
      let indlin:number = linhas.findIndex(ln => ln[cab[0].coluna]===idrow)

      if (indlin>=0 && linhas[indlin][cab[colds].coluna] === "D")
      {
        selok=false;
      }
    }

    if (!selecao || !selok)
    {
      return;
    }

    const selectedIndex = selected.indexOf(idrow);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, idrow);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    if(onSelect){onSelect(newSelected)};
    setSelected(newSelected);
  };

  const EditClick = (event: React.MouseEvent<unknown>, aux:number, id: number) =>
  {
    if (onClickEdicao)
    {
      onClickEdicao(aux, id)
    }
  }

  const handleFiltro = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, filtro:string | number | Date) => 
    {
      if (filtro)
      {
        linhas=linhasoriginais.filter((linha:any) => {return cab.some((celula) => 
          {
            return linha[celula.coluna] ? linha[celula.coluna].toString().toLowerCase().search(filtro.toString().toLowerCase())!==-1 : false;
          })});
      }
      setPage(0);
      setFiltro(filtro.toString());
    }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (idrow: number) => selected.indexOf(idrow) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, linhas.length - page * rowsPerPage);

  let cabec: HeadCell[]=[];
  let coled: number = -1;
  let colds: number = -1;
  cab.forEach((colcab, index) => 
    {
      if(colcab.titulo!=='*' && colcab.titulo!=='#')
      {
        cabec.push({ id: index, numeric: false, disablePadding: true, label: colcab.titulo })
      }
      else
      {
        if(colcab.titulo==='*')
        {
          coled=index;
        }
        else
        {
          colds=index;
        }
      }
    });

  return (
    <div className={classe ? classes[classe] : full ? classes.full : classes.root}>
      <Paper className={classes.paper}>
        {titulo ? <EnhancedTableToolbar numSelected={selected.length} onchangeFiltro={habfiltro ? handleFiltro : undefined} valorfiltro={filtro} titulo={titulo} exportaExcel={linhasoriginais.length>0 ? exportaExcel : undefined} cortit={cortit} /> : null}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={linhas.filter((ln:any) => colds===-1 || ln[cab[colds].coluna]!=='D').length}
              cab={cabec}
              selecao={selecao}
              edicao={edicao}
              exclusao={exclusao}
              detalhe={coldet && cabdet && coldetst ? true : false}
            />
            <TableBody>
              {linhas.slice(GarantePagina(page,linhas.length,rowsPerPage) * rowsPerPage, GarantePagina(page,linhas.length,rowsPerPage) * rowsPerPage + rowsPerPage)
                .map((row: any, indexrow: any) => {
                  const isItemSelected = isSelected(row[cab[0].coluna]);
                  const labelId = `enhanced-table-checkbox-${indexrow}`;
                  return (
                    <React.Fragment key={indexrow+1}>
                    <TableRow
                      hover
                      onClick={coldetst && coldet && cabdet ? undefined : (event) => handleClick(event, row[cab[0].coluna])}
                      role={selecao && (colds===-1 || row[cab[colds].coluna]!=='D') ? "checkbox" : undefined}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={indexrow+1}
                      selected={isItemSelected}
                      classes={{ selected: classes.selected, hover: classes.hover }}
                      className={colds===-1 || row[cab[colds].coluna]==='' ? (colcnc && cncvlr && row[colcnc]===cncvlr ? classes.tableRowCancel : coldet && coldetst && cabdet ? classes.tableRowopt : classes.tableRow) : row[cab[colds].coluna]==='D' ? classes.tableRowdesab : classes.tableRowalert}
                    >
                      {selecao 
                        ? 
                        <TableCell padding="checkbox">
                          <Checkbox
                            color='primary'
                            disabled={(colds!==-1 && row[cab[colds].coluna]==='D')}
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row[cab[0].coluna])}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell> 
                        : 
                        null
                      }
                      {coldet && coldetst && cabdet && onOpen
                        ?
                          <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => onOpen((page*rowsPerPage)+indexrow)}>
                              {row[coldetst] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                        :
                        null
                      }
                      {cab.map((celula,index) => 
                          '*#'.indexOf(celula.titulo)===-1 ?
                            <TableCell className={colds!==-1 && row[cab[colds].coluna]==='D' ? classes.tableTextdesab : undefined} key={index+1} component={'th'} id={celula.titulo} scope={'row'} padding={'default'} align={celula.alinhamento}>
                              {celula.titulo==='='
                              ?
                                celula.mascara==='A'
                                ?
                                  <Avatar style={{margin: '0 40%'}} alt='imgtable' src={row[celula.coluna] && row[celula.coluna].length>0 ? 'data:image/jpeg;base64, ' + row[celula.coluna] : 'Imagens/maquinafotografica.png'} ></Avatar>
                                :
                                <IconButton color='primary' aria-label="expand row" size="small" disabled={'AB'.indexOf(row[celula.coluna].tipo)===-1 || !row[celula.coluna].tipo} onClick={() => window.open(RetornaUrlPdfImg(row[celula.coluna].conteudo, row[celula.coluna].tipo))}>
                                  {row[celula.coluna].tipo==='A' ? <ImageIcon/> : row[celula.coluna].tipo==='B' ? <PictureAsPdfIcon/> : <CropDinIcon/>}
                                </IconButton>
                              :
                              celula.dica ?
                                <Tooltip title={row[celula.dica]} placement='top'><Typography variant='body2' >{formatarCampo(celula.calculo && baseclperc ? calcperc(baseclperc, row[celula.calculo.colbase], (colbsind && basind ? -1 : 0), colbsind ? row[colbsind] : '', basind ? basind : '') : row[celula.coluna],celula.mascara)}</Typography></Tooltip>
                                :
                                <Typography variant='body2' >{formatarCampo(celula.calculo && baseclperc ?  calcperc(baseclperc, row[celula.calculo.colbase], (colbsind && basind ? -1 : 0), colbsind ? row[colbsind] : '', basind ? basind : '') : celula.coluna==='*index*' ? (linhas.indexOf(row)+1).toString().trim() : row[celula.coluna],celula.mascara)}</Typography>
                              }
                            </TableCell>
                          :
                          null
                      )}
                      {edicao && auxedit ? 
                      <TableCell padding="default">
                        <IconButton color="primary" className={classes.edicao} disabled={coled>=0 && (row[cab[coled].coluna]==='A' || row[cab[coled].coluna]==='C')} onClick={(event) => EditClick(event, auxedit, cab[0].coluna==='*index*' ? linhas.indexOf(row)+1 : row[cab[0].coluna])}><EditTwoToneIcon/></IconButton>
                      </TableCell> : null}
                      {exclusao && auxexclui ? 
                      <TableCell padding="default">
                        <IconButton color="primary" className={classes.edicao} disabled={coled>=0 && (row[cab[coled].coluna]==='B' || row[cab[coled].coluna]==='C')} onClick={(event) => EditClick(event, auxexclui, cab[0].coluna==='*index*' ? linhas.indexOf(row)+1 : row[cab[0].coluna])}><DeleteTwoToneIcon/></IconButton>
                      </TableCell> : null}
                    </TableRow>
                    {cabdet && coldet && coldetst
                      ?
                      <RetornaDetalhes aberta={row[coldetst]} cabdet={cabdet} contdet={row[coldet]} tamdet={tamdet ? tamdet : cab.length} onClck={onClickDet} linpr={(page*rowsPerPage)+indexrow} baseclperc={colbsclprdet ? [row[colbsclprdet]] : undefined} baseindpr={basinddt} colindpr={colbsinddt} />
                      :
                      null
                    }
                    </React.Fragment>
                  );
                })}
              {linhatot && linhatot.length<=cab.length
                ?
                <TableRow hover classes={{ selected: classes.selected, hover: classes.hover }} className={classes.tableRowtotal}>
                  {selecao 
                    ? 
                    <TableCell padding="checkbox">
                    </TableCell> 
                    : 
                    null
                  }
                  {coldet && coldetst && cabdet && onOpen
                    ?
                      <TableCell>
                      </TableCell>
                    :
                    null
                  }
                  {cab.map((celula,index) => 
                    '*#'.indexOf(celula.titulo)===-1 ?
                      <TableCell key={index+1} component={'th'} id={celula.titulo} scope={'row'} padding={'default'} align={celula.alinhamento}>
                        <Typography variant='body2' >{linhatot[index]}</Typography>
                      </TableCell>
                    :
                    null
                  )}
                </TableRow>
                :
                null
              }
              {emptyRows + (linhatot ? 1 : 0) > 0 && !semespaco && (
                <TableRow style={{ height: (dense ? 33 : 53) * (emptyRows + (linhatot ? 1 : 0)) }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        classes={{spacer: classes.spacer}}
        labelRowsPerPage={"Linhas por página"}
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        count={linhas.length}
        component='div'
        rowsPerPage={rowsPerPage}
        page={GarantePagina(page, linhas.length, rowsPerPage)}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>`${from}-${to} de ${count}`}
        ActionsComponent={(subProps) => <TablePaginationActions {...subProps} btultpag= {btultpg ? btultpg : 'ultpagtab'} />}
      />
    </Paper>
    <Grid container spacing={1}>
      <Grid item xs={11}>
        { habilitadense ?
        <FormControlLabel
          control={<Switch color={"primary"} checked={dense} onChange={handleChangeDense} />}
          label="Linhas estreitas"
        />
        : null
        }
      </Grid>
      <Grid item xs={1}>
        {adicao && auxedit ? <Fab className={classes.adicao} color="primary" aria-label="add" onClick={(event) => EditClick(event,auxedit,-1)}>
          <AddIcon />
        </Fab> : null}
      </Grid>
    </Grid>
    </div>
  );
}