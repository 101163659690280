import { Filtros_Dashboard_Operador } from '../Modelos/Filtros_Dashboard_Operador';

export class Dashboard_Operador
{
    public id_dsbo:number = 0;
    public id_dsb:number = 0;
    public id_oper:number = 0;
    public posicao_dsbo:string = '';
    public tamanho_dsbo:string = '';
    public nome_dsbo:string = '';
    public intervalo_dsbo:number = 0;
    public filtros:Filtros_Dashboard_Operador[] = [];
}