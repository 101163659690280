import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Endereco_Pessoas } from '../Modelos/Endereco_Pessoas';
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import CampoMascara from '../Componentes/CampoMascara';
import CampoTexto from '../Componentes/CampoTexto';
import Selecao from '../Componentes/Selecao';
import { Ufs } from '../Modelos/Ufs';
import { Tipos_Endereco } from '../Modelos/Tipos_Endereco';
import ApiService from '../Servicos/ApiService';
import { achavalor, CenterMap, ClicaBotao, Iheaders } from '../Servicos/Utilidades';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import TabelaBase from '../Componentes/TabelaBase';
import MapaGoogle from '../Componentes/MapaGoogle';

const srv=new ApiService();
const useStyles = makeStyles((theme: Theme) => createStyles({
    griddados: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridmap: {
        width: "20%",
        margin: "0 0 0 0"
    },
    gridend: {
      width: "100%",
      margin: "0 0 0 0"
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    padrao: {
        margin: "0% 0%",
        width: "100%",
        padding: '0 0 0 0'
    },
    botao: {
        margin: '5% 5%',
        width: '90%'
    }
}));

let botcli: string = '';  

type EndProps=
{
    ender: Endereco_Pessoas,
    indender: number,
    enderecos: Endereco_Pessoas[],
    retenderecos: (ends: Endereco_Pessoas[]) => void,
    retender: (end: Endereco_Pessoas, altdados: boolean) => void,
    retindender: (indender: number) => void;
    retmsg: (msgerro: string) => void,
    acessos: boolean,
    ufs: Ufs[],
    tipos: Tipos_Endereco[],
    nomepessoa? : string,
    icone?: string

}

export default function TabEnderecos({ender, indender, enderecos, retenderecos, retender, retindender, retmsg, acessos, ufs, tipos, nomepessoa, icone}: EndProps) {

    const classes = useStyles();
    const [enddig, setendDig] = React.useState(ender);
    const [indend, setindEnd] = React.useState(indender);

    const AtualizaEndereco = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {name?: string | undefined; value: unknown;}>, valor:string | number | Date | React.ReactNode, campo?: string | undefined) => 
    {
        if (campo)
        {
            let zrlat:boolean = true;
            let novenddig: Endereco_Pessoas = JSON.parse(JSON.stringify(enddig));

            if(campo==='cep_end' && typeof valor==='string')
            {
              valor=valor.toString().replace('-','');
            }

            if ((campo==='cep_end' || campo==='logr_end' || campo==='bairro_end') && typeof valor==='string')
            {
                novenddig.endereco[campo]=valor;
            }
            else if ((campo==='numero_end' || campo==='compl_end') && typeof valor==='string')
            {
                if (campo==='compl_end')
                {
                  zrlat=false;
                }
                novenddig[campo]=valor as string
            }
            else if ((campo==='nome_cid' || campo==='codibge_cid') && typeof valor==='string')
            {
                novenddig.endereco.cidade[campo]=valor
            }
            else if (campo==='id_tpen' && event.target.value)
            {
                novenddig.id_tpen=typeof event.target.value === 'string' ? parseInt(event.target.value) : typeof event.target.value==='number' ? event.target.value : 0;
                zrlat=false;
            }
            else if (campo==='id_uf' && event.target.value)
            {
                novenddig.endereco.cidade.uf.id_uf=typeof event.target.value === 'string' ? parseInt(event.target.value) : typeof event.target.value==='number' ? event.target.value : 0;
            }
            if (zrlat)
            {
              novenddig.latitude_enps=0;
              novenddig.longitude_enps=0;
            }
            retender(novenddig,true);
            setendDig(novenddig);
        }
    }

    const AtuEnderecoDigitado = (edrc: Endereco_Pessoas, indedrc: number) =>
    {
        retender(edrc,false);
        retindender(indedrc);
        setendDig(edrc);
        setindEnd(indedrc);
    }

    const adicionaEndereco = () =>
    {
      if (enddig.endereco.cep_end && enddig.endereco.logr_end && enddig.numero_end && enddig.endereco.bairro_end && enddig.endereco.cidade.nome_cid && enddig.endereco.cidade.codibge_cid && enddig.endereco.cidade.uf.id_uf && enddig.id_tpen)
      {
        let endnovos: Endereco_Pessoas[]=[];
        endnovos=endnovos.concat(enderecos);
        if (enddig.padrao_enps==='A')
        {
          endnovos.forEach((endnovo: Endereco_Pessoas, index: number) => 
          {
            if ((indend===0 || indend-1!==index) && endnovo.padrao_enps==='A')
            {
              endnovo.padrao_enps='B';
              endnovo.alterado='A';
            };
          });
        }
        if (indend===0)
        {
          endnovos.push(enddig);
          endnovos[endnovos.length-1].alterado='A'
          botcli='ultpagtab'
        }
        else
        {
          endnovos[indend-1]=enddig;
          endnovos[indend-1].alterado='A';
        }
        if (!endnovos.some((endnovo: Endereco_Pessoas) => endnovo.padrao_enps==='A'))
        {
          endnovos[0].padrao_enps='A';
          endnovos[0].alterado='A'
        }
        retenderecos(endnovos);
        AtuEnderecoDigitado(new Endereco_Pessoas(),0);
      }
      else
      {
        retmsg('Preencha todos os campos obrigatórios, por gentileza');
      }
    }

    const editaEndereco = (val1: number, val2: number) =>
    {
      if(val1===1)
      {
        if(val2<=enderecos.length && val2>0)
        {
            AtuEnderecoDigitado(enderecos[val2-1],val2);
        }
        else
        {
            AtuEnderecoDigitado(new Endereco_Pessoas(),0);
        }
      }
      else
      {
        if(val1===2)
        {
          if(val2<=enderecos.length && val2>0)
          {
            let endnovos: Endereco_Pessoas[]=[];
            endnovos=endnovos.concat(enderecos);
            endnovos[val2-1].alterado='B';
            retenderecos(endnovos);
            if (val2===indend)
            {
                AtuEnderecoDigitado(new Endereco_Pessoas(),0);
            }
          }
        }
      }
    }

    const onblurCep = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
    {
      if (enddig.endereco && enddig.endereco.cep_end && enddig.endereco.cep_end.trim().length===8)
      {
        srv.Get('','','','',false,`http://viacep.com.br/ws/${enddig.endereco.cep_end}/json`,true)
        .then(res => 
            {
                let resposta: any = res;
                if (resposta.erro)
                {
                    retmsg('Cep não localizado')
                }
                else
                {
                    let iduf:number=achavalor(resposta.uf,ufs,'sigla_uf','id_uf',0) as number;
                    let novenddig: Endereco_Pessoas = JSON.parse(JSON.stringify(enddig));
                    novenddig.endereco.logr_end=resposta.logradouro;
                    novenddig.endereco.bairro_end=resposta.bairro;
                    novenddig.endereco.cidade.nome_cid=resposta.localidade;
                    novenddig.endereco.cidade.codibge_cid=resposta.ibge;
                    novenddig.endereco.cidade.uf.id_uf=iduf;
                    novenddig.endereco.cidade.uf.sigla_uf=resposta.uf;
                    novenddig.latitude_enps=0;
                    novenddig.longitude_enps=0;
                    AtuEnderecoDigitado(novenddig,indend);
                }
            }
        )
        .catch(Error => retmsg('Não foi possível obter o endereço a partir do cep (' + Error.name + ' - ' + Error.message + ')'));
      }
    }
  
    const AposRender = () =>
    {
      if(botcli)
      {
        ClicaBotao(botcli);
        botcli = '';
      }
    }

    const hdend: Iheaders[] = [{titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, {titulo: 'Cep', alinhamento:'left', coluna: 'cep_end', mascara: 'cep' }, { titulo: 'Endereço', alinhamento: 'left', coluna: 'logr_end', mascara: '' }, { titulo: 'Número', alinhamento: 'left', coluna: 'numero_end', mascara: '' }, { titulo: 'Cidade', alinhamento: 'left', coluna: 'nome_cid', mascara: '' }, { titulo: 'UF', alinhamento: 'center', coluna: 'sigla_uf', mascara: '' }]

    const RetornaEnderecos = () =>
    {
      let endtab: {id: number; cep_end: string; logr_end: string; numero_end: string; nome_cid: string; sigla_uf: string; }[] = [];
      enderecos.forEach((endereco: Endereco_Pessoas, index) => 
      {
        if (endereco.alterado!=='B')
        {
          endtab.push({id: index+1, cep_end: endereco.endereco.cep_end, logr_end: endereco.endereco.logr_end, numero_end: endereco.numero_end, nome_cid: endereco.endereco.cidade.nome_cid, sigla_uf: endereco.endereco.cidade.uf.sigla_uf })
        }
      })
      return endtab;
    }

    const atuGeo = (latlong: CenterMap) =>
    {
      let novenddig: Endereco_Pessoas = JSON.parse(JSON.stringify(enddig));
      novenddig.latitude_enps=latlong.lat;
      novenddig.longitude_enps=latlong.lng;
      AtuEnderecoDigitado(novenddig,indend);
    }

    return (
      <Grid container spacing={1}>
        <Grid item className={classes.griddados}>
          <Grid item className={classes.gridend}>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                  <CampoMascara valor={enddig.endereco.cep_end} id='cep_end' label='Cep' placeholder='Cep' classe={classes.textoform} mascara='#####-###' required={true} habilitado={acessos} onchange={AtualizaEndereco} erro={enddig.endereco.cep_end.length<8}  onblur={onblurCep}/>
                </Grid>
                <Grid item xs={4}>
                  <CampoTexto valor={enddig.endereco.logr_end} id='logr_end' label='Endereço' placeholder='Endereço' classe={classes.textoform} tipo='text' required={true} habilitado={acessos} onchange={AtualizaEndereco} erro={!enddig.endereco.logr_end}  tamanho={200}/>
                </Grid>
                <Grid item xs={3}>
                  <CampoTexto valor={enddig.numero_end} id='numero_end' label='Número' placeholder='Número' classe={classes.textoform} tipo='text' required={true} habilitado={acessos} erro={!enddig.numero_end} onchange={AtualizaEndereco} tamanho={50}/>
                </Grid>
                <Grid item xs={3}>
                  <CampoTexto valor={enddig.compl_end} id='compl_end' label='Complemento' placeholder='Complemento' classe={classes.textoform} tipo='text' required={false} habilitado={acessos} onchange={AtualizaEndereco} tamanho={50}/>
                </Grid>
                <Grid item xs={2}>
                  <CampoTexto valor={enddig.endereco.bairro_end} id='bairro_end' label='Bairro' placeholder='Bairro' classe={classes.textoform} tipo='text' required={true} habilitado={acessos} onchange={AtualizaEndereco} erro={!enddig.endereco.bairro_end} tamanho={50}/>
                </Grid>
                <Grid item xs={2}>
                  <CampoTexto valor={enddig.endereco.cidade.nome_cid} id='nome_cid' label='Cidade' placeholder='Cidade' classe={classes.textoform} tipo='text' required={true} habilitado={acessos} onchange={AtualizaEndereco} erro={!enddig.endereco.cidade.nome_cid} tamanho={60}/>
                </Grid>
                <Grid item xs={2}>
                  <CampoTexto valor={enddig.endereco.cidade.codibge_cid} id='codibge_cid' label='IBGE' placeholder='IBGE' classe={classes.textoform} tipo='text' required={true} habilitado={acessos} onchange={AtualizaEndereco} erro={!enddig.endereco.cidade.codibge_cid} tamanho={7} />
                </Grid>
                <Grid item xs={1}>
                  <Selecao valor={enddig.endereco.cidade.uf.id_uf}  nome='id_uf' label='UF' required={true} habilitado={acessos} onchange={AtualizaEndereco} conteudo={ufs} nulo={[0,'']} classe={classes.textoform} erro={!enddig.endereco.cidade.uf.id_uf}/>
                </Grid>
                <Grid item xs={2}>
                  <Selecao valor={enddig.id_tpen}  nome='id_tpen' label='Tipo' required={true} habilitado={acessos} onchange={AtualizaEndereco} conteudo={tipos} nulo={[0,'']} classe={classes.textoform} erro={!enddig.id_tpen}/>
                </Grid>
                <Grid item xs={1}>
                  <FormControlLabel
                    value="padrao"
                    control={<Checkbox className={classes.padrao} id='padrao_enps' disabled={!acessos} checked={enddig.padrao_enps==='A'} onChange={(event, checked:boolean) => {setendDig({...enddig, 'padrao_enps': checked ? 'A' : 'B'})}} color="primary" />}
                    label="Padrão"
                    labelPlacement="bottom"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" color="primary" disabled={!acessos} className={classes.botao} onClick={adicionaEndereco} endIcon={<VerticalAlignBottomIcon/>}>Adiciona</Button>
                </Grid>
                <Grid item xs={12}>
                  <TabelaBase linhasoriginais={RetornaEnderecos()} cab={hdend} selecao={false} edicao={acessos} adicao={acessos} habfiltro={false} onClickEdicao={editaEndereco} titulo={''} full={true} linhasiniciais={5} exclusao={acessos} auxedit={1} auxexclui={2} ultpag={true} aposRender={AposRender} />
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.gridmap}>
          <Grid item xs={12}>
            <MapaGoogle endereco={enddig} zoom={18} titulo={nomepessoa ? nomepessoa : ''} retgeo={atuGeo} icone={icone ? icone : undefined}/>
          </Grid>
        </Grid>
      </Grid>
    )
}
