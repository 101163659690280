import React, { ChangeEvent, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Card, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { achaDescricao, achavalor, DataParaString, datas, nulltoNumber, posicoesdash, Resposta, retPeriodoFiltro, retTamanhos, TrataResposta } from '../Servicos/Utilidades';
import ApiService from '../Servicos/ApiService';
import Progresso from './Progresso';
import CampoTexto from './CampoTexto';
import { Filtros_Dashboard_Operador } from '../Modelos/Filtros_Dashboard_Operador';
import { Tipos_Filtros_Dashboard } from '../Modelos/Tipos_Filtros_Dashboard';
import { Dashboard_Operador } from '../Modelos/Dashboard_Operador';
import { Dashboard } from '../Modelos/Dashboard';
import Periodo from './Periodo';
import Selecao from './Selecao';
import SelecaoAuto from './SelecaoAuto';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => createStyles({
    textoform: {
        margin: "0% 1% 0% 1%",
        width: "98%",
        background: 'white'
    }
}));

type FiltrosProps =
{
    aberta:boolean, 
    fecha: (botaoretorno: string, dash: Dashboard_Operador) => void, 
    reset: () => boolean,
    dash: Dashboard[],
    dashoper: Dashboard_Operador,
    tipos: Tipos_Filtros_Dashboard[], 
    clientes: {id_cli: number, nome_cli: string}[], 
    fornecedores: {id_forn: number, nome_forn: string} [], 
    vazios: number[],
    exclusao?: boolean,
    dadosacesso: RetornoAcesso
}

export default function Indicador({aberta, fecha, dash, tipos, clientes, fornecedores, vazios, dashoper, dadosacesso, reset, exclusao}: FiltrosProps)
{
    const srv= new ApiService();

    const classes = useStyles();
    
    const [erro, setErro] = React.useState('');
    const [novodash, setDash] = React.useState<Dashboard_Operador>(dashoper);
    if (reset() && aberta)
    {
        setDash(dashoper);
        setErro('');
    }

    const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, botaoretorno: string) => 
    {
        fecha(botaoretorno, novodash);
    };

    const confirma = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, tpconf: string) =>
    {
        if (novodash.id_dsb===0 || novodash.nome_dsbo.trim().length===0 || novodash.posicao_dsbo.trim().length<1 || novodash.tamanho_dsbo.trim().length<1 || novodash.intervalo_dsbo<0)
        {
            setErro('Existem dados incorretos. Verifique, por gentileza');
        }
        else
        {
            if(tpconf==='A')
            {
                let retdash: Dashboard_Operador = JSON.parse(JSON.stringify(novodash));
                setErro('****')
                if (exclusao)
                {
                    retdash.id_dsb=0;
                }
                srv.Post('Dashboard','',JSON.stringify(retdash),dadosacesso.objac.token, false)
                .then((ddret: any) => {
                    let dadosret: Resposta = TrataResposta(ddret);
                    if (!dadosret.ok)
                    {
                        setErro(dadosret.erro);
                    }
                    else
                    {
                        if (retdash.id_dsbo===0)
                        {
                            retdash.id_dsbo=dadosret.retorno.id;
                        }
                        fecha(exclusao ? 'D' : 'A',retdash);
                    }
                })
                .catch(Error => 
                {
                    setErro('Erro acessando a API salvando indicador (' + Error.name + ' - ' + Error.message + ')');
                })
            }
            else
            {
                fecha(exclusao ? 'E' : 'B',novodash)
            }
        }
    }

    const atudashFornCli = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined, campo?: string) =>
    {
        let vlrret: number=0;
        if(campo)
        {
            if(value && value['id_forn'])
            {
                vlrret=value['id_forn'];
            }
            else if(value && value['id_cli'])
            {
                vlrret=value['id_cli'];
            }

            let ind: number = nulltoNumber(parseInt(campo));
            if (ind>0 && ind<=novodash.filtros.length)
            {
                let atufiltros: Filtros_Dashboard_Operador[] = [];
                atufiltros = atufiltros.concat(...novodash.filtros);
                atufiltros[ind-1].valores_fdbo = vlrret.toString().trim();

                console.log('indicador: ', novodash);

                setDash({...novodash, filtros: atufiltros});
            }
        }
    }

    const atudashPeriodo = (campo: string, valor: datas, periodo?: number) =>
    {
        let ind: number = nulltoNumber(parseInt(campo));
        if (ind>0 && ind<=novodash.filtros.length)
        {
            if (valor)
            {
                let atufiltros: Filtros_Dashboard_Operador[] = [];
                atufiltros = atufiltros.concat(...novodash.filtros);
                atufiltros[ind-1].valores_fdbo = (periodo ? periodo.toString().trim() : '0') + '|' + DataParaString(valor.dtini) + '|' + DataParaString(valor.dtfin);
                setDash({...novodash, filtros: atufiltros});
            }
        }
    }

    const selecionaDash = (event: React.ChangeEvent<{name?: string; value: unknown;}>, child: React.ReactNode, campo?: string) => 
    {
        if (campo)
        {
            if (campo==='id_dsb')
            {
                let novodsb: number = typeof event.target.value === 'string' ? nulltoNumber(parseInt(event.target.value)) : typeof event.target.value === 'number' ? event.target.value : 0;
                if (novodsb!==novodash.id_dsb)
                {
                    let inddash: number = achavalor(novodsb, dash, 'id_dsb','*index*',0) as number;
                    if (inddash>=0)
                    {
                        let atufiltros: Filtros_Dashboard_Operador[] = [];
                        dash[inddash].filtros.forEach(flt => {
                            atufiltros.push({id_fdbo:0, id_tfdb: flt.id_tfdb, id_dsbo: dashoper.id_dsbo, valores_fdbo:''})
                        })
                        setDash({...novodash, id_dsb: novodsb, nome_dsbo: dash[inddash].desc_dsb, intervalo_dsbo: dash[inddash].intervalo_dsb, filtros: atufiltros});
                    }
                    else
                    {
                        setDash({...novodash, id_dsb: novodsb});
                    }
                }
            }
            else if((campo==='posicao_dsbo' || campo==='tamanho_dsbo') && typeof event.target.value==='string')
            {
                setDash({...novodash, [campo]: event.target.value});
            }
        }
    }

    const retPeriodo = (flop: Filtros_Dashboard_Operador, indice: number, descricao: string): ReactNode =>
    {
        let parms: {periodo: number, datini: Date | undefined, datfin: Date |undefined} = retPeriodoFiltro(flop.valores_fdbo);
        return (
            <div style={{margin: '2% 0 0 0'}}>
                <Periodo key={indice + 1} capper={descricao} camporet={(indice + 1).toString().trim()} atualiza={atudashPeriodo} perpad={parms.periodo} dataspad={{ dtini: parms.datini, dtfin: parms.datfin }} tipoper='ABC' habilitado={!exclusao} />
            </div>
        )
    }
    const retFornCli = (flop: Filtros_Dashboard_Operador, indice: number, descricao: string, tipo: string) =>
    {
        return (
            <div style={{margin: '2% 0 0 0'}}>
                <SelecaoAuto valor={nulltoNumber(parseInt(flop.valores_fdbo.trim()))} nome={(indice + 1).toString().trim()} label={descricao} classe={classes.textoform} conteudo={tipo==='A' ? fornecedores : clientes} habilitado={!exclusao} colunas={tipo==='A' ? {id: 'id_forn', desc: 'nome_forn'} : {id: 'id_cli', desc: 'nome_cli'}} onchange={atudashFornCli} ident={tipo==='A' ? 'id_forn' : 'id_cli'} />
            </div>
        )
    }

    const retFiltro = (flop: Filtros_Dashboard_Operador, indice: number): ReactNode =>
    {
        let tpflt: number = achavalor(flop.id_tfdb, tipos, 'id_tfdb', '*index*', 0) as number
        let retorno: ReactNode=null;

        if (tpflt>=0)
        {
            switch (tipos[tpflt].chave_tfdb)
            {
                case 'A':
                {
                    retorno=retPeriodo(flop, indice, tipos[tpflt].desc_tfdb);
                    break;
                }
                case 'B' || 'C':
                {
                    retorno=retFornCli(flop, indice, tipos[tpflt].desc_tfdb, tipos[tpflt].chave_tfdb==='B' ? 'A' : 'B');
                    break;
                }
            }
        }

        return retorno;
    }

    return (
        <div>
            <Dialog
                open={aberta}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{alignSelf: 'center', color: '#00BFFF'}} id="alert-dialog-title">{'Dados Indicador'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Selecao valor={novodash.id_dsb}  nome='id_dsb' label='Indicador' required={!exclusao} habilitado={novodash.id_dsbo===0 && !exclusao} onchange={selecionaDash} conteudo={ dash } nulo={[0,'Indefinida']} classe={classes.textoform} erro={!novodash.id_dsb || novodash.id_dsb===0}/>
                        </Grid>
                        <Grid style={{margin: '1% 0 0 0'}} item xs={12}>
                            <CampoTexto valor={novodash.nome_dsbo} id='nome_dsbo' label='Título do indicador' placeholder='' tipo='Título do indicador' required={!exclusao} classe={classes.textoform} habilitado={!exclusao} onchange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, filtro: string | number | Date, campo?: string) => setDash({...novodash, nome_dsbo: filtro as string})} erro={novodash.nome_dsbo.trim().length<1}></CampoTexto>
                        </Grid>
                        <Grid style={{margin: '1% 0 0 0'}} item xs={3}>
                            <Selecao valor={novodash.posicao_dsbo} nome='posicao_dsbo' label='Posição' required={!exclusao} habilitado={false} onchange={selecionaDash} conteudo={ posicoesdash } nulo={[' ','Indefinida']} classe={classes.textoform} erro={!novodash.posicao_dsbo || novodash.posicao_dsbo.trim().length<1}/>
                        </Grid>
                        <Grid style={{margin: '1% 0 0 0'}} item xs={6}>
                            <Selecao valor={novodash.tamanho_dsbo}  nome='tamanho_dsbo' label='Tamanho' required={!exclusao} habilitado={novodash.id_dsbo===0 && !exclusao} onchange={selecionaDash} conteudo={ retTamanhos(achaDescricao(novodash.id_dsb, dash, 'id_dsb','apresentacao_dsb'),novodash.posicao_dsbo,vazios) } nulo={[' ','Indefinido']} classe={classes.textoform} erro={!novodash.tamanho_dsbo || novodash.tamanho_dsbo.trim().length<1}/>
                        </Grid>
                        <Grid style={{margin: '1% 0 0 0'}} item xs={3}>
                            <CampoTexto valor={novodash.intervalo_dsbo}  id='intervalo_dsbo' label='Intervalo' placeholder='Intervalo (segundos)' classe={classes.textoform} tipo='number' required={!exclusao} habilitado={!exclusao} onchange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, filtro: string | number | Date, campo?: string) => setDash({...novodash, intervalo_dsbo: filtro as number})} erro={novodash.intervalo_dsbo===undefined || novodash.intervalo_dsbo<0}/>
                        </Grid>
                        <Card style={{width: '98%', margin: '0 1% 0 1%', padding: '2% 0 2% 0', boxShadow: "3px 3px 1px lightblue"}}>
                            <Typography style={{width:'100%', margin: '2% 0 2% 0', color: '#00BFFF'}} variant='h5' align='center' >Filtros</Typography>
                            {novodash.filtros.map((flt,idx) =>
                                {
                                    return (
                                        <Grid key={(idx+1)*1000} item xs={12}>
                                            {retFiltro(flt, idx)}
                                        </Grid>
                                    )
                                }
                            )}
                        </Card>
                        {erro.length>0 ?
                            <Grid item xs={12}>
                                {erro==='****' ?
                                    <Box minWidth={35} margin='20px 0 0 10px'>
                                        <Typography align='center' color='primary'>Aguarde....</Typography>
                                        <Progresso/>
                                    </Box>
                                    :
                                    <Box minWidth={35} margin='20px 0 0 10px'>
                                        <Typography color='secondary'>{erro}</Typography>
                                    </Box>
                                }
                            </Grid>
                        :
                            null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {dashoper.id_dsbo===0 || exclusao ?
                        <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => confirma(event, 'A')} color="primary">
                            Confirma
                        </Button>
                        :
                        <>
                            <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => confirma(event, 'A')} color="primary">
                                Confirma
                            </Button>
                            <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => confirma(event, 'B')} color="primary">
                                Altera Sessão
                            </Button>
                        </>
                    }
                    <Button onClick={event => handleClose(event, 'C')} color="primary">
                        Desiste
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
