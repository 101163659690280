import { RouteComponentProps, withRouter } from 'react-router';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Roteador } from '../Servicos/Roteador';
import { Resposta, TrataResposta, ParametrosDiversos, AlteraTiposGeracao, AlteraPadroesRecebimento, Iheaders, DataSql, RetornaMotivosBanco, ConsomeRetornoBancos, RetornaOcorrenciaBanco, DifDias, formatarCampo } from '../Servicos/Utilidades';
import ApiService from '../Servicos/ApiService';
import { Recebimentos } from '../Modelos/Recebimentos';
import BarraLateral from '../Componentes/Barralateral';
import Toast from '../Componentes/Toast';
import Progresso from '../Componentes/Progresso';
import ProgressoEtiqueta from '../Componentes/ProgressoEtiqueta';
import { makeStyles, Theme, createStyles, Grid, Button, Typography, Box, Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Divider, Tooltip } from '@material-ui/core';
import { Detalhes_Recebimento } from '../Modelos/Detalhes_Recebimento';
import React, { RefObject, useRef } from 'react';
import PageviewIcon from '@material-ui/icons/Pageview';
import InputIcon from '@material-ui/icons/Input';
import { Component } from 'react';
import { Contas } from '../Modelos/Contas';
import { Parametros } from '../Modelos/Parametros';
import TabelaBase from '../Componentes/TabelaBase';
import { Ocorrencias_Recebimento } from '../Modelos/Ocorrencias_Recebimento';
import { Feriados } from '../Modelos/Feriados';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Servicos_Clientes } from '../Modelos/Servicos_Clientes';

const srv = new ApiService();

const useStyles = makeStyles((theme: Theme) => createStyles({
    griddados: {
      width: "100%",
      margin: "0 0 0 0"
    },
    gridtab: {
      width: "100%",
      margin: "0 0 0 0"
    },
    divgrid: {
        width: '90%',
        margin: '5% 7% 0'
    },
    divtabs: {
        width: '90%',
        margin: '0% 7% 0'
    },
    boxpanel: {
        width: '104%',
        margin: '0% 0% 0% -2%',
        padding: '0 0 0 0'
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    botao: {
        margin: '0 0 0 0',
        width: '100%'
    },
    tab: {
        minWidth: "50%",
        width: "50%"
    },
    gridcampos: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridfoto: {
        width: "20%",
        margin: "0 0 0 0"
    },
    letratotais: {
        padding: '0 0 0 0',
        margin: '0 0 0 0',
        fontSize: '15px',
        backgroundColor: 'FFFFFF',
        color: '00FF00'
    },
    cartao: {
        padding: '0 0 0 0',
        margin: '0 0 0 0',
        width: '20%'
    }
}));

type PrincipalProps=
{
    dadosobt: RetornoAcesso
    contas: Contas[];
    clientes: { 
        id_cli: number, 
        cpfcnpj: string, 
        id_pessoa: number, 
        nome_pessoa: string, 
        fantasia_pessoa: string, 
        id_rede: number, 
        nome_rede: string,
        loja_cli: number}[];
    mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void
}

type Tabrec=
{
    indice: number,
    id_interno: string,
    id_rcbt: number,
    numban_rcbt: string,
    vencto_rcbt?: Date,
    dataocor: Date,
    codocor: string,
    descocor: string,
    motivos: string,
    descmot: string,
    valortit: number,
    valorrec: number,
    acrescalc: number,
    acresrec: number,
    desccalc: number,
    descrec: number,
    despesas: number,
    diferenca: number,
    id_cli: number,
    nome_pessoa: string,
    fantasia_pessoa: string,
    nome_rede: string,
    loja_cli: number,
    flagedicao: string,
    baixa: boolean,
    protesto: boolean, 
    confirmada: boolean,
    gravado: boolean,
    recebimento?: Recebimentos
}

let procret = new ConsomeRetornoBancos([]);
let tabelarec: Tabrec[] = [];
let tabfinal: Tabrec[] = [];
let regsel: number[] = [];
let nomearq: string = '';

const PrincipalRetornoBancos = ({dadosobt, contas, clientes, mensagem }: PrincipalProps) =>
{
    const classes = useStyles();
    const inputFileTxt: RefObject<HTMLInputElement> = useRef(null);

    const [operexec,setoperexec]=React.useState('A');
    const [regproc,setregproc]=React.useState('');
    const [perc,setperc]=React.useState(0);
    const [totais, setTotais]=React.useState<{qtde: number, valor: number, valorpago?: number, label: string}[]>([]);

    const onButtonClickTxt = (event: React.MouseEvent<HTMLButtonElement | HTMLImageElement | HTMLIFrameElement, MouseEvent>) => 
    {
        if (inputFileTxt.current)
        {
            inputFileTxt.current.value='';
            inputFileTxt.current.click()
        }
    };

    const Atusel = (selecionadas: number[]) =>
    {
        regsel=selecionadas;
        if (regsel.length>0)
        {
            if (operexec==='D')
            {
                setoperexec('E');
            }
        }
        else
        {
            if (operexec==='E')
            {
                setoperexec('D');
            }
        }
    }

    const onChangeTxt = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    {
      const filereader = new FileReader();
      const doc: any = document.getElementById(event.target.id);
      if(doc)
      {
        if(doc.files)
        {
          if(doc.files.length>0)
          {
            const arquivo = doc.files[0];
            nomearq=doc.files[0].name;
            filereader.readAsText(arquivo);
          }
          else
          {
              mensagem('Nenhum arquivo selecionado','info',6000);
          }
        }
      };
  
      filereader.onloadend = function()
      {
        let result: any = filereader.result
        setoperexec('B');
        procret.LeRegistros(result, nomearq, contas);
        if (procret.ok)
        {
            if(procret.registros.length<1)
            {
                mensagem('Atenção: Arquivo retorno sem nenhum registro','info',4000);
                setoperexec('A');
                setregproc('');
            }
            else
            {
                tabelarec=[];
                setTotais(procret.totais);
                setoperexec('C');
                Leregistro(0);
            }
        }
        else
        {
            mensagem('Erro lendo arquivo de retorno - '+procret.erro,'error',5000);
            setoperexec('A');
            setregproc('');
        }
      };
    };

    const Leregistro = (ind:number) =>
    {
        setregproc(procret.registros[ind].numban+' ('+(ind+1).toString().trim()+'/'+procret.registros.length.toString().trim()+')');
        setperc(Math.trunc(((ind+1)/procret.registros.length)*100));
        srv.Get('Recebimentos/'+procret.registros[ind].numban+'/numban','', '', dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
            let dadosret: Resposta = TrataResposta(ddret);
            if (dadosret.ok)
            {
                const recenc: Recebimentos = dadosret.retorno;
                const difdias=DifDias(recenc.vencto_rcbt, procret.registros[ind].dataocorrencia, true);
                let idcli=clientes.findIndex(cli => cli.id_cli===recenc.id_cli);
                let novorec: Tabrec = {
                    indice: ind,
                    id_rcbt: recenc.id_rcbt,
                    id_interno: procret.registros[ind].idinterno, 
                    numban_rcbt: procret.registros[ind].numban,
                    vencto_rcbt: recenc.vencto_rcbt,
                    dataocor: procret.registros[ind].dataocorrencia,
                    codocor: procret.registros[ind].ocorrencia,
                    descocor: RetornaOcorrenciaBanco(procret.registros[ind].ocorrencia,'2',procret.banco),
                    motivos: procret.registros[ind].motivos,
                    descmot: RetornaMotivosBanco(procret.registros[ind].motivos,procret.banco,procret.registros[ind].ocorrencia),
                    valortit: recenc.valor_rcbt,
                    valorrec: procret.registros[ind].valorpago,
                    acrescalc: procret.registros[ind].baixa ? (recenc.jurosdia_rcbt*difdias)+(difdias>0 ? recenc.multa_rcbt :  0) : 0,
                    acresrec: procret.registros[ind].juros+procret.registros[ind].multa,
                    desccalc: DifDias(recenc.datadesc_rcbt,procret.registros[ind].dataocorrencia,true)>0 ? 0 : recenc.desconto_rcbt,
                    descrec: procret.registros[ind].desconto,
                    despesas: procret.registros[ind].despesas,
                    diferenca: 0,
                    id_cli: recenc.id_cli,
                    nome_pessoa: idcli>=0 ? clientes[idcli].nome_pessoa : '',
                    fantasia_pessoa: idcli>=0 ? (clientes[idcli].fantasia_pessoa.trim().length>0 ? clientes[idcli].fantasia_pessoa : clientes[idcli].nome_pessoa) : '',
                    nome_rede: idcli>=0 ? clientes[idcli].nome_rede : '',
                    loja_cli: idcli>=0 ? clientes[idcli].loja_cli : 0,
                    flagedicao: procret.registros[ind].baixa && recenc.id_strc===ParametrosDiversos.sitpago ? 'D' : '',
                    baixa: procret.registros[ind].baixa,
                    protesto: procret.registros[ind].protestado,
                    confirmada: procret.registros[ind].confirmada,
                    gravado: false,
                    recebimento: recenc
                };
                if (procret.registros[ind].baixa && recenc.id_strc!==ParametrosDiversos.sitpago)
                {
                    novorec.diferenca=((novorec.valortit+novorec.acrescalc)-novorec.desccalc)-novorec.valorrec;
                }
                if (novorec.flagedicao!=='D')
                {
                    if (recenc.ocorrencias.some(ocr => ocr.nomearq_ocrc===procret.nomearq && ocr.ocorbanco_ocrc===novorec.codocor))
                    {
                        novorec.flagedicao='C'
                    }
                }
                tabelarec.push(novorec);
            }
            else
            {
                tabelarec.push({
                    indice: ind,
                    id_rcbt: 0,
                    id_interno: procret.registros[ind].idinterno, 
                    numban_rcbt: procret.registros[ind].numban,
                    vencto_rcbt: undefined,
                    dataocor: procret.registros[ind].dataocorrencia,
                    codocor: procret.registros[ind].ocorrencia,
                    descocor: RetornaOcorrenciaBanco(procret.registros[ind].ocorrencia,'2',procret.banco),
                    motivos: procret.registros[ind].motivos,
                    descmot: RetornaMotivosBanco(procret.registros[ind].motivos,procret.banco,procret.registros[ind].ocorrencia),
                    valortit: procret.registros[ind].valor,
                    valorrec: procret.registros[ind].valorpago,
                    acrescalc: 0,
                    acresrec: procret.registros[ind].juros+procret.registros[ind].multa,
                    desccalc: 0,
                    descrec: procret.registros[ind].desconto,
                    despesas: 0,
                    diferenca: 0,
                    id_cli: 0,
                    nome_pessoa: '',
                    fantasia_pessoa: '',
                    nome_rede: '',
                    loja_cli: 0,
                    flagedicao: 'D',
                    baixa: false,
                    protesto: false,
                    confirmada: false,
                    gravado: false
                })
            }
            ProximoRegistro(ind);
        })
        .catch(Error => {
            mensagem('Erro obtendo dados de recebimentos - nº banco: '+procret.registros[ind].numban+' acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false)
            setoperexec('A');
            setregproc('');
        });
    }

    const ProximoRegistro = (ind:number) =>
    {
        if (ind+1<procret.registros.length)
        {
            Leregistro(ind+1);
        }
        else
        {
            tabfinal=[];
            tabfinal=tabfinal.concat(...tabelarec);
            setoperexec('D');
            setregproc('');
            setperc(0);
        }
    }

    const ProcessaArquivo = () =>
    {
        if(regsel.length>0)
        {
            setoperexec('F');
            GravaRegistro(0);
        }
    }

    const GravaRegistro = (ind: number) =>
    {
        let rggrv=tabelarec[regsel[ind]];
        let receb: Recebimentos | undefined = rggrv.recebimento;
        let novaocor: Ocorrencias_Recebimento;
        let novodet: Detalhes_Recebimento;
        let novolanc: Servicos_Clientes;        
        setregproc(rggrv.numban_rcbt+' ('+(ind+1).toString().trim()+'/'+regsel.length.toString().trim()+')');
        setperc(Math.trunc(((ind+1)/regsel.length)*100));
        if(receb && !rggrv.gravado && rggrv.flagedicao!=='D')
        {
            srv.Get('Recebimentos/'+(receb.id_rcbt.toString().trim()),'', '', dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (dadosret.ok)
                {
                    receb=dadosret.retorno;
                    if (receb)
                    {
                        if (rggrv.baixa)
                        {
                            let multarec=0;
                            let jurosrec=0;
                            let descconc=0;
                            receb.pagto_rcbt=DataSql(rggrv.dataocor);
                            receb.id_strc=ParametrosDiversos.sitpago;
                            if (rggrv.acresrec>0)
                            {
                                if(rggrv.acresrec>receb.multa_rcbt)
                                {
                                    multarec=receb.multa_rcbt;
                                    rggrv.acresrec=rggrv.acresrec-receb.multa_rcbt;
                                }
                                else
                                {
                                    multarec=rggrv.acresrec
                                    rggrv.acresrec=0;
                                }
                            }
                            if (rggrv.acresrec>0)
                            {
                                jurosrec=rggrv.acresrec;
                            }
                            if (rggrv.descrec>0)
                            {
                                descconc=rggrv.descrec;
                            }
                            receb.jurosrec_rcbt=jurosrec;
                            receb.multarec_rcbt=multarec;
                            receb.descapl_rcbt=descconc;
                            if (jurosrec>0)
                            {
                                novodet=new Detalhes_Recebimento();
                                novodet.id_rcbt=receb.id_rcbt;
                                novodet.id_dtrc=0;
                                novodet.id_rcta=ParametrosDiversos.recjuros;
                                novodet.id_oper=dadosobt.objac.id_oper;
                                novodet.valor_dtrc=jurosrec;
                                receb.detalhes.push(novodet);
                            }
                            if (multarec>0)
                            {
                                novodet=new Detalhes_Recebimento();
                                novodet.id_rcbt=receb.id_rcbt;
                                novodet.id_dtrc=0;
                                novodet.id_rcta=ParametrosDiversos.recmulta;
                                novodet.id_oper=dadosobt.objac.id_oper;
                                novodet.valor_dtrc=multarec;
                                receb.detalhes.push(novodet);
                            }
                            if (descconc>0)
                            {
                                novodet=new Detalhes_Recebimento();
                                novodet.id_rcbt=receb.id_rcbt;
                                novodet.id_dtrc=0;
                                novodet.id_dsps=ParametrosDiversos.despdesc;
                                novodet.id_oper=dadosobt.objac.id_oper;
                                novodet.valor_dtrc=descconc;
                                receb.detalhes.push(novodet);
                            }
                            if (rggrv.diferenca>ParametrosDiversos.difmin)
                            {
                                novolanc = new Servicos_Clientes();
                                novolanc.id_cli=receb.id_cli;
                                novolanc.id_serv=ParametrosDiversos.iddifrec;
                                novolanc.data_svcl=DataSql(rggrv.dataocor);
                                novolanc.valor_svcl=rggrv.diferenca;
                                novolanc.obs_svcl='Valor pago a menor no título id: '+receb.id_rcbt.toString().trim()+ ' id interno: '+receb.idinterno_rcbt.trim();
                            }
                        }
                        else if (rggrv.protesto)
                        {
                            receb.id_strc=ParametrosDiversos.sitprot;
                        }
                        else if (rggrv.confirmada && (receb.id_strc===ParametrosDiversos.sitpadrao || receb.id_strc===ParametrosDiversos.sitremessa))
                        {
                            receb.id_strc=ParametrosDiversos.sitremconf;
                        }
                        novaocor=new Ocorrencias_Recebimento();
                        novaocor.id_rcbt=rggrv.id_rcbt;
                        novaocor.id_ocrc=0;
                        novaocor.id_tpor=ParametrosDiversos.idretorno;
                        novaocor.data_ocrc=DataSql(new Date());
                        novaocor.nomearq_ocrc=procret.nomearq;
                        novaocor.tipoarq_ocrc='2';
                        novaocor.regbanco_ocrc=rggrv.indice+2;
                        novaocor.ocorbanco_ocrc=rggrv.codocor;
                        novaocor.motbanco_ocrc=rggrv.motivos;
                        novaocor.id_oper=dadosobt.objac.id_oper;
                        receb.ocorrencias.push(novaocor);
            
                        srv.Post('Recebimentos/','',JSON.stringify(receb),dadosobt.objac.token,false)
                        .then((ddret: any) => 
                        {
                          let dadosret: Resposta = TrataResposta(ddret);
                          if (!dadosret.ok)
                          {
                            mensagem(dadosret.erro,'error',6000)
                          }
                          else
                          {
                            if (novolanc)
                            {
                                srv.Post('Recebimentos/ServicosClientes','',JSON.stringify(novolanc),dadosobt.objac.token,false)
                                .then((ddret: any) => {
                                  let dadosret: Resposta = TrataResposta(ddret);
                                  if (!dadosret.ok)
                                  {
                                    mensagem(dadosret.erro,'error',6000)
                                  }
                                  else
                                  {
                                    GravaProximo(ind);
                                  }
                                })
                                .catch(Error => 
                                {
                                  mensagem('Erro acessando a API gravando diferença de recebimento (' + Error.name + ' - ' + Error.message + ')', 'error',6000);
                                })
                            }
                            else
                            {
                                GravaProximo(ind)
                            }
                          }
                        })
                        .catch(Error => 
                        {
                          mensagem('Erro acessando a API atualizando recebimento (' + Error.name + ' - ' + Error.message + ')', 'error',6000);
                        })
                    }
                }
                else
                {
                    mensagem(dadosret.erro,'error',6000)
                }
            })
            .catch(Error => 
            {
                mensagem('Erro acessando a API obtendo recebimento durante a baixa (' + Error.name + ' - ' + Error.message + ')', 'error',6000);
            })
        }
    }

    const GravaProximo = (ind: number) =>
    {
        if (ind+1<regsel.length)
        {
            GravaRegistro(ind+1);
        }
        else
        {
            mensagem('Arquivo '+procret.nomearq+' processado com sucesso','info',6000,true);
        }
    }

    const hdtabrec: Iheaders[] = 

    [
        { titulo: 'Registro', alinhamento:'right', coluna: 'indice', mascara: '' }, 
        { titulo: 'Id interno', alinhamento: 'center', coluna: 'id_interno', mascara: '' },
        { titulo: 'Nº bancário', alinhamento: 'center', coluna: 'numban_rcbt', mascara: '' },
        { titulo: 'Id cliente', alinhamento: 'right', coluna: 'id_cli', mascara: '' }, 
        { titulo: 'Cliente', alinhamento: 'left', coluna: 'fantasia_pessoa', mascara: ''},
        { titulo: 'Rede', alinhamento: 'left', coluna: 'nome_rede', mascara: '' },
        { titulo: 'Loja', alinhamento: 'right', coluna: 'loja_cli', mascara: '' }, 
        { titulo: 'Vencimento', alinhamento: 'center', coluna: 'vencto_rcbt', mascara: 'data' },
        { titulo: 'Data ocorência', alinhamento: 'center', coluna: 'dataocor', mascara: 'data' },
        { titulo: 'Código ocorência', alinhamento: 'center', coluna: 'codocor', mascara: '' },
        { titulo: 'Ocorência', alinhamento: 'left', coluna: 'descocor', mascara: '' },
        { titulo: 'Código motivo', alinhamento: 'center', coluna: 'motivos', dica: 'descmot', mascara: '' },
        { titulo: 'Valor título', alinhamento: 'right', coluna: 'valortit', mascara: 'decimal' },
        { titulo: 'Desconto calculado', alinhamento: 'right', coluna: 'desccalc', mascara: 'decimal' },
        { titulo: 'Acréscimo calculado', alinhamento: 'right', coluna: 'acrescalc', mascara: 'decimal'},
        { titulo: 'Valor rec./proc.', alinhamento: 'right', coluna: 'valorrec', mascara: 'decimal' },
        { titulo: 'Desconto aplicado', alinhamento: 'right', coluna: 'descrec', mascara: 'decimal' },
        { titulo: 'Acréscimo aplicado', alinhamento: 'right', coluna: 'acresrec', mascara: 'decimal'},
        { titulo: 'Diferença', alinhamento: 'right', coluna: 'diferenca', mascara: 'decimal'},
        { titulo: '#', alinhamento: 'right', coluna: 'flagedicao', mascara: '' }
    ]

    return (
        <div>
            <div className={classes.divgrid}>
                <Grid container spacing={1} className={classes.griddados}>
                    <Grid item xs={3}>
                        <Button variant="contained" color="primary" disabled={operexec.indexOf('BC')!==-1} className={classes.botao} onClick={(event) => onButtonClickTxt(event)} endIcon={<PageviewIcon/>}>Abre Arquivo Retorno</Button>
                        <input type='file' hidden={true} id='filepdfimg' accept={'*'} ref={inputFileTxt} onChange={onChangeTxt}/>
                    </Grid>
                    <Grid item xs={operexec==='B' ? 1 : 2}>
                        <Box minWidth={35} margin='10px 0 0 10px'>
                            <Typography color='primary'>{operexec==='B' ? 'Lendo arquivo' : operexec==='C' ? 'Obtendo registros' : operexec==='D' ? ' ' : operexec==='F' ? 'Processando retorno' : ' '}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={operexec==='B' ? 1 : 2}>
                        <Box minWidth={35} margin='10px 0 0 10px'>
                            <Typography color='primary'>{regproc}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={operexec==='B' ? 4 : 2}>
                        {operexec==='B'
                        ?
                        <Progresso tipo='B'/>
                        :
                        'FC'.indexOf(operexec)!==-1
                        ?
                        <ProgressoEtiqueta value={perc} tipo='B' />
                        :
                        null
                        }   
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="primary" disabled={'E'.indexOf(operexec)===-1} className={classes.botao} onClick={ProcessaArquivo} endIcon={<InputIcon/>}>Processa Registros</Button>
                    </Grid>
                    { totais.length>0 ?  
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                            <Grid container spacing={1} className={classes.griddados}>
                                <Grid item xs={4}>
                                    <Typography color='primary' variant='h6'>Totais</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography color='primary' align='center' variant='h6'>{nomearq}</Typography>
                                </Grid>
                            </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2} className={classes.griddados}>
                                    {totais.map((total, index) => (
                                        <Grid item xs={3} key={index+1}>
                                            <Card style={{borderRadius: 3, border: 0, color: 'white', width: '100%', height: 80, padding: '0 0 0 0', margin: '0 0 0 0', boxShadow: '0 3px 5px 2px rgba(0, 105, 255, .3)'}} variant="outlined">
                                                <CardContent style={{margin: '5px 5px 5px 5px', padding: '0 0 0 0'}}>
                                                    <Tooltip title={total.label}>
                                                        <Typography noWrap={true} style={{color:'darkblue', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{total.label}</Typography>
                                                    </Tooltip>
                                                    <Divider/>
                                                    <Grid container spacing={2} className={classes.griddados}>
                                                        <Grid item xs={total.valorpago ? 2 : 4}>
                                                            <Typography style={{color:'#0011FF', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0' }} >Qtde.</Typography>
                                                            <Divider/>
                                                            <Typography style={{color:'#FF0000', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(total.qtde.toString(),'decimal',false)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={total.valorpago ? 5 : 8}>
                                                            <div style={{textAlign: 'right'}}>
                                                                <Typography style={{color:'#0011FF', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Valor títulos</Typography>
                                                                <Divider/>
                                                                <Typography style={{color:'#FF0000', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(total.valor.toString(),'decimal')}</Typography>
                                                            </div>
                                                        </Grid>
                                                        {total.valorpago 
                                                            ?
                                                            <Grid item xs={5}>
                                                                <div style={{textAlign: 'right'}}>
                                                                    <Typography style={{color:'#0011FF', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '12px', padding: '0 0 0 0', margin: '0 0 0 0'}}>Valor creditado</Typography>
                                                                    <Divider/>
                                                                    <Typography style={{color:'#FF0000', fontFamily: 'Arial', fontWeight: 'bold', fontStyle: 'normal', fontSize: '14px', padding: '0 0 0 0', margin: '0 0 0 0'}}>{formatarCampo(total.valorpago.toString(),'decimal')}</Typography>
                                                                </div>
                                                            </Grid>
                                                            :
                                                            null
                                                        }
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid> :
                    null}
                </Grid>
                  <Grid container spacing={1} className={classes.gridtab}>
                    <Grid item xs={12}>
                        <TabelaBase linhasoriginais={tabfinal} cab={hdtabrec} selecao={true} edicao={false} adicao={false} habfiltro={false} onClickEdicao={(val1, val2) => {}} titulo={'Recebimentos'} full={true} linhasiniciais={10} exclusao={false} auxedit={1} auxexclui={2} onSelect={Atusel} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}  

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  openToast: boolean,
  severidadeToast: string,
  mensagemToast: string,
  duracaoToast: number,
  acessosok: boolean,
}

class RetornoBancos extends Component<PropsType, IState>
{
    private _dadosobt: RetornoAcesso;
    private _roteador: Roteador;
    private _dadoobtido: boolean;
    private _acessos: number;
    private _contas: Contas[];
    private _clientes: [];
    private _parametros: Parametros[];
    private _retornamensagem: boolean;
    
    constructor(props: any)
    {
        super(props);

        this._dadosobt=new RetornoAcesso();

        if (props.location.state)
        {
            if(props.location.state.dadosret)
            {
                this._dadosobt=props.location.state.dadosret;
            }
        }

        if (!this._dadosobt.ok)
        {
            this.props.history.push({pathname: '/'})
        }

        this._roteador=new Roteador(this._dadosobt, '/GeracaoRecebimentos');
        this._roteador.history=props.history;
        this._dadoobtido=false;
        this._contas = [];
        this._clientes = [];
        this._parametros = [];
        this._retornamensagem=false;
    
        this._acessos=0;
    
        if (this._dadosobt.ok)
        {
            this._dadoobtido=true;
        }
            this.state =
            {
                dadoobtido: this._dadoobtido,
                openToast: false,
                severidadeToast: 'success',
                mensagemToast: '',
                duracaoToast: 6000,
                acessosok: false,
            }
        if (this._dadosobt.ok)
        {
            this.ObtemDados('Clientes/0/0','clientes',this.setaclientes.bind(this));
            this.ObtemDados('Contas/','contas',this.setacontas.bind(this));
            this.ObtemDados('Parametros/','parâmetros',this.setaparametros.bind(this));
            this.ObtemDados('Feriados/','feriados',this.setaferiados.bind(this));
        }
    }
    
    setaclientes(registros: any) {this._clientes=registros; this.aumentaAcessos();};
    setacontas(registros: any) {this._contas=registros; this.aumentaAcessos();};
    setaparametros(parobt: Parametros[]) 
    {
        this._parametros = parobt;
        this.aumentaAcessos();
    };
    setaferiados(registros: Feriados[]) {ParametrosDiversos.feriados=registros; this.aumentaAcessos();};

    aumentaAcessos()
    {
        this._acessos = this._acessos+1
        if(this._acessos>3)
        {
            AlteraTiposGeracao(this._parametros);
            AlteraPadroesRecebimento(this._parametros);
            tabelarec = [];
            tabfinal = [];
            regsel = [];
            this.setState({acessosok: true});
        }
    }
        
    ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
    {
        srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
        let dadosret:Resposta = TrataResposta(ddret);
        if(dadosret.ok)
        {
            obret(dadosret.retorno)
        }
        else
        {
            this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
            this.aumentaAcessos();
        }
        })
        .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
    }

    ExibeMensagem(mensagem: string, severidade: string, duracao:number, retorna:boolean=false)
    {
        this.setState({openToast: true, severidadeToast: severidade, mensagemToast: mensagem, duracaoToast: duracao})
        this._retornamensagem=retorna;
    }

    FechaMensagem()
    {
        this.setState({openToast: false});
        if (this._retornamensagem)
        {
          this._roteador.rota(0,0);
        }
    }

    render()
    {
        if (!this._dadosobt.ok)
        {
        return null
        }
        else
        {
            return (
            <div className="Home">
                <header className="Home-header">
                <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador} recurso='Retorno de Bancos'/>
                </header>
                <main>
                { this.state.acessosok ?
                    <PrincipalRetornoBancos dadosobt={this._dadosobt} contas={this._contas} clientes={this._clientes} mensagem={this.ExibeMensagem.bind(this)}/>
                    :
                    <Progresso/>
                }
                </main>
                <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
            </div>
            )
        }
    }
}

export default withRouter(RetornoBancos)
