import React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { RetornaMascara } from '../Servicos/Utilidades';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    campotexto: {
      margin: "0% 0%",
      width: "100%",
      background: 'white'
    },
  })
);

type DecimalProps=
{
  id: string,
  label: string,
  placeholder: string,
  required: boolean,
  classe: string,
  valor: string | number,
  habilitado: boolean,
  mascara: string,
  onchange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, retorno:string, campo?: string | undefined) =>void,
  onblur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => any,
  erro?: boolean
}

export default function CampoMascara({id, label, placeholder, mascara, required, classe, valor, habilitado, onchange, onblur, erro }: DecimalProps) {

  const classes = useStyles();

  return (
    <NumberFormat
      style = {{boxShadow: "3px 3px 1px lightblue"}}
      error = {erro === undefined ? false : erro}
      className={classe ? classe : classes.campotexto}
      required={required}
      label={label}
      placeholder={placeholder}
      name={id}
      id={id}
      customInput={TextField}
      format={RetornaMascara(mascara, valor.toString())}
      type='text'
      variant="outlined"
      value={valor}
      disabled={!habilitado}
      onBlur={ onblur ? event => onblur(event) : undefined }
      onChange={onchange ? event => {onchange(event, event.target.value, id)} : undefined}
      thousandSeparator={false}
      decimalSeparator={true}
      isNumericString
      prefix={''}
    />
  );
}