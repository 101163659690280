import {Tabelas_Dados_Adicionais} from '../Modelos/Tabelas_Dados_Adicionais';
import {Pessoas} from '../Modelos/Pessoas';

export class Vendedores
{
    public id_vend: number = 0;
    public pessoa: Pessoas = new Pessoas();
    public comissao_vend: number = 0;
    public id_dsps: number = 0;
    public dados_adicionais: Tabelas_Dados_Adicionais[] = [];
}