import {Detalhes_Recebimento} from '../Modelos/Detalhes_Recebimento';
import {Ocorrencias_Recebimento} from '../Modelos/Ocorrencias_Recebimento';
 
export class Recebimentos
{
    id_rcbt: number=0;
    idinterno_rcbt: string='';
    vencto_rcbt: Date=new Date();
    valor_rcbt: number=0;
    jurosrec_rcbt: number=0;
    multarec_rcbt: number=0;
    descapl_rcbt: number=0;
    despesas_rcbt: number=0;
    desconto_rcbt: number=0;
    datadesc_rcbt?: Date=undefined;
    jurosdia_rcbt: number=0;
    multa_rcbt: number=0;
    pagto_rcbt?: Date=undefined;
    id_cli: number=0;
    nfs_rcbt: string='';
    rps_rcbt: string='';
    id_cta: number=0;
    carteira_rcbt: string='';
    numban_rcbt: string='';
    autorizacao_rcbt: string='';
    id_strc: number=0;
    detalhes: Detalhes_Recebimento[] = [];
    ocorrencias: Ocorrencias_Recebimento[] = [];
    id_oper: number=0;
}