export class Ocorrencias_Pagamento
{
    id_ocpg: number=0;
    id_tpop: number=0;
    id_pgto: number=0;
    data_ocpg: Date = new Date();
    id_oper: number=0;
    nome_oper: string='';
    obs_ocpg: string='';
    gravadoc: boolean=false;
    tipodoc_ocpg: string='';
    documento_ocpg?: Uint32Array=undefined;
    alterado: string='';
}