import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@material-ui/core';
import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone';

type GraficoProps = 
{
  estilo?: React.CSSProperties
  retorno?: () => void
  opcoes: any
}


function Grafico({opcoes, estilo, retorno}: GraficoProps) {

  return (
    <Card style={estilo}>
      <HighchartsReact highcharts={Highcharts} options={opcoes} />
      {retorno ?
        <ReplayTwoToneIcon onClick={retorno} style={{width: '6%', cursor: 'pointer', alignSelf: 'left', margin: '0 0 0 10%'}} color='primary' fontSize='small' />
      :
        null
      }
    </Card>
  )
}

export default Grafico