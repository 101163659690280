import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Checkbox, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
        margin: "0 0 0 0",
        width: "100%",
        background: '#F0FFFF'
    },
  }),
);

const ITEM_HEIGHT = 148;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: '#F0FFFF',
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type SelmultProps=
{
  nome: string,
  label: string,
  classe: string,
  valor: string[] | number[],
  habilitado: boolean,
  conteudo: any [],
  colunas: {id: string, desc: string},
  erro?: boolean,
  onchange: (event: React.ChangeEvent<{ value: unknown }>, campo?: string) => void
}

export default function SelecaoMultipla({nome, label, classe, valor, habilitado, conteudo, colunas, erro, onchange}: SelmultProps) {

  const classes = useStyles();

  const retornaRender = (selected: any): string =>
  {
    let retstr='';
    selected.forEach((elsel:any) => {
        let indc =conteudo.findIndex(vlr => vlr[colunas.id]===elsel)
        if (indc!==-1)
        {
            retstr = retstr + (retstr.length>0 ? ', ' : '') + conteudo[indc][colunas.desc] ;
        }
    })
    return retstr;
  }

  return (
    <div>
      <FormControl className={classe ? classe : classes.formControl}>
        <InputLabel variant="outlined">{label}</InputLabel>
        <Select
        style = {{boxShadow: "3px 3px 1px lightblue"}}
        error={erro}
        disabled={!habilitado}
        label={label}
        variant='outlined'
        id={nome}
        multiple
        value={valor}
        onChange={(event) => onchange(event, nome)}
        renderValue={(selected) => (retornaRender(selected))}
        MenuProps={MenuProps}
        >
        {conteudo.map((vlr, idx) => {
          return(
              <MenuItem style={{borderBottom: idx+1<conteudo.length ? '2px solid blue' : ''}} key={vlr[colunas.id]} value={vlr[colunas.id]}>
                <Checkbox color='primary' checked={valor.findIndex((vlver: string | number) => vlver === vlr[colunas.id]) > -1} />
                <ListItemText primaryTypographyProps={{ style: { color: '#000080' } }} primary={vlr[colunas.desc]} />
              </MenuItem>
          )
        })}
        </Select>
    </FormControl>
  </div>)
}
