import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { retPeriodoTitulo, datas } from '../Servicos/Utilidades';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import { Dashboard_Operador } from '../Modelos/Dashboard_Operador';

type GraficoProps = 
{
  altura: number,
  dash: Dashboard_Operador,
  quadro: {numero: number, posicao: number, vazios: number[]},
  edita: (dashoper: Dashboard_Operador, vazios: number[], atudash: (atudash: Dashboard_Operador) => void, exclusao:boolean) => void,
  atualiza: (dashatu: Dashboard_Operador) => void,
  intervalo: datas,
  subtitulo?: string
}

function TituloDash({altura, dash, quadro, edita, atualiza, intervalo, subtitulo}: GraficoProps) {

  let linhas: {alt: number, largura: string, tamletra: string, margem: string, largicon: string, texto: string}[]=[];
  let perstr: string = retPeriodoTitulo(intervalo);
  let alttit: number = Math.round(altura*(dash.tamanho_dsbo==='C' ? 0.1 : 0.05));

  if (dash.tamanho_dsbo==='D')
  {
    if(subtitulo)
    {
      linhas.push({alt: Math.round(alttit*0.70), largura: '84%', tamletra: Math.round(alttit*0.65).toString().trim()+'px', margem: '0 0 0 8%', largicon: '3%', texto: dash.nome_dsbo.trim()+(perstr.length>0 ? ' - '+perstr : '')});
      linhas.push({alt: Math.round(alttit*0.30), largura: '100%', tamletra: Math.round(alttit*0.25).toString().trim()+'px', margem: '0 0 0 0%', largicon: '', texto: subtitulo});
    }
    else
    {
      linhas.push({alt: alttit, largura: '84%', tamletra: Math.round(alttit*0.75).toString().trim()+'px', margem: '0 0 0 8%', largicon: '3%', texto: dash.nome_dsbo.trim()+(perstr.length>0 ? ' - '+perstr : '')});
    }
  }
  else if (dash.tamanho_dsbo==='C')
  {
    if(subtitulo)
    {
      linhas.push({alt: Math.round(alttit*0.35), largura: '72%', tamletra: Math.round(alttit*0.25).toString().trim()+'px', margem: '0 0 0 14%', largicon: '6%', texto: dash.nome_dsbo.trim()});
      linhas.push({alt: Math.round(alttit*0.35), largura: '100%', tamletra: Math.round(alttit*0.25).toString().trim()+'px', margem: '0 0 0 0%', largicon: '', texto: perstr});
      linhas.push({alt: Math.round(alttit*0.30), largura: '100%', tamletra: Math.round(alttit*0.15).toString().trim()+'px', margem: '0 0 0 0%', largicon: '', texto: subtitulo});
    }
    else
    {
      linhas.push({alt: Math.round(alttit*0.40), largura: '72%', tamletra: Math.round(alttit*0.30).toString().trim()+'px', margem: '0 0 0 14%', largicon: '6%', texto: dash.nome_dsbo.trim()});
      linhas.push({alt: Math.round(alttit*0.40), largura: '100%', tamletra: Math.round(alttit*0.30).toString().trim()+'px', margem: '0 0 0 0%', largicon: '', texto: perstr});
    }
  }
  else if (dash.tamanho_dsbo==='B')
  {
    if(subtitulo)
    {
      linhas.push({alt: Math.round(alttit*0.70), largura: '84%', tamletra: Math.round(alttit*0.50).toString().trim()+'px', margem: '0 0 0 8%', largicon: '3%', texto: dash.nome_dsbo.trim()+(perstr.length>0 ? ' - '+perstr : '')});
      linhas.push({alt: Math.round(alttit*0.30), largura: '100%', tamletra: Math.round(alttit*0.30).toString().trim()+'px', margem: '0 0 0 0%', largicon: '', texto: subtitulo});
    }
    else
    {
      linhas.push({alt: Math.round(alttit*0.80), largura: '84%', tamletra: Math.round(alttit*0.70).toString().trim()+'px', margem: '0 0 0 8%', largicon: '3%', texto: dash.nome_dsbo.trim()+(perstr.length>0 ? ' - '+perstr : '')});
    }
  }
  else if (dash.tamanho_dsbo==='A')
  {
    if(subtitulo)
    {
      linhas.push({alt: Math.round(alttit*0.70), largura: '72%', tamletra: Math.round(alttit*0.50).toString().trim()+'px', margem: '0 0 0 14%', largicon: '6%', texto: dash.nome_dsbo.trim()+(perstr.length>0 ? ' - '+perstr : '')});
      linhas.push({alt: Math.round(alttit*0.30), largura: '100%', tamletra: Math.round(alttit*0.30).toString().trim()+'px', margem: '0 0 0 0%', largicon: '', texto: subtitulo});
    }
    else
    {
      linhas.push({alt: Math.round(alttit*0.80), largura: '72%', tamletra: Math.round(alttit*0.40).toString().trim()+'px', margem: '0 0 0 14%', largicon: '6%', texto: dash.nome_dsbo.trim()+(perstr.length>0 ? ' - '+perstr : '')});
    }
  }
  
  return (
    <Grid style={{backgroundColor: '#F0FFFF', maxHeight: alttit.toString().trim()+'px'}} item xs={12}>
      {linhas.map((linha,idx) => {
        return(
          <div key = {(idx+1)*10000}>
            <Typography key={idx+1} style={{width: linha.largura, maxHeight: Math.round(linha.alt).toString().trim()+'%', fontSize: linha.tamletra, display: 'inline-block', margin: linha.margem }} align='center'>{linha.texto}</Typography>
            {idx===0 ?
              <>
                <SettingsTwoToneIcon key={(idx+1)*100} onClick={() => { edita(dash, quadro.vazios, atualiza, false); } } style={{ width: linha.largicon, maxHeight: linha.alt.toString().trim() + 'px', cursor: 'pointer', alignSelf: 'right' }} color='primary' fontSize='small' />
                <CancelTwoToneIcon key={(idx+1)*1000} onClick={() => { edita(dash, quadro.vazios, atualiza, true); } } style={{ width: linha.largicon, maxHeight: linha.alt.toString().trim() + 'px', cursor: 'pointer', alignSelf: 'right', margin: '0 2% 0 0' }} color='primary' fontSize='small' />
              </>
                  :
            null}
          </div>
        )})
      }
    </Grid>
    )
}

export default TituloDash