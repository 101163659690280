import {Detalhes_Pagamento} from './Detalhes_Pagamento';
import {Ocorrencias_Pagamento} from './Ocorrencias_Pagamento';
 
export class Pagamentos
{
    id_pgto: number=0;
    vencto_pgto: Date=new Date();
    valor_pgto: number=0;
    juros_pgto: number=0;
    multa_pgto: number=0;
    datadesc_pgto?: Date=undefined;
    desconto_pgto: number=0;
    pagto_pgto?: Date=undefined;
    id_forn: number=0;
    id_frpg: number=0;
    id_cta: number=0;
    id_stpg: number=0;
    detalhes: Detalhes_Pagamento[] = [];
    ocorrencias: Ocorrencias_Pagamento[] = [];
    id_oper: number=0;
}