import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { RetornaDataAAAAMMDD, Resposta, TrataResposta, DifSegundos, formatarCampo, retPeriodoFiltro, achaDescricao, DashProps, nulltoNumber } from '../Servicos/Utilidades';
import { ApiService } from '../Servicos/ApiService';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Dashboard_Operador } from '../Modelos/Dashboard_Operador';
import TituloDash from '../Componentes/TituloDash';
import Grafico from './Grafico';

const srv = new ApiService();

interface DashPgtoState {
    atutela: boolean,
    erro: string,
    dados: { mesrec: string, mesdsp: string, despesas: number, receitas: number}[],
    atuagora: boolean,
}

class DashResultados extends Component<DashProps, DashPgtoState>
{
    private _idinterval: number;
    private _dadosobt: RetornoAcesso;
    private _inicial: Date;
    private _parms: {periodo: number, datini: Date | undefined, datfin: Date |undefined};
    private _dash: Dashboard_Operador;
    
    constructor(props: any)
    {
        super(props);

        this._parms={periodo:0, datini: undefined, datfin: undefined};
        this._dash=this.props.dashoper;
        this._parms=retPeriodoFiltro(achaDescricao(1,this._dash.filtros,'id_tfdb','valores_fdbo'));
        this._dadosobt=props.dadosacesso;
        this._inicial=new Date();
        this._idinterval=-1;
        this.state =
        {
            atutela: false,
            erro: '',
            dados: [],
            atuagora: false,
        }

        if (window)
        {
            this._idinterval=window.setInterval(this.atualizahora.bind(this),1000)
        }
        this.atualizaDados();
    }

    atualizaDash(dashatu: Dashboard_Operador)
    {
        console.log('atualizaDash resultados ', new Date().toString());
        this._dash=dashatu;
        this._parms=retPeriodoFiltro(achaDescricao(1,this._dash.filtros,'id_tfdb','valores_fdbo'));
        this.setState(
        {
            atuagora: true
        })
    }

    atualizaDados()
    {
        srv.Get('Pagamentos/Resultados/'+RetornaDataAAAAMMDD(this._parms.datini, false)+'/'+RetornaDataAAAAMMDD(this._parms.datfin, false), '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
            let dadosret: Resposta = TrataResposta(ddret);
            if (dadosret.ok)
            {
                this.setState({atutela: true, erro: '', dados: dadosret.retorno });
            }
            else
            {
                this.setState({atutela: true, erro: 'Erro: Erro obtendo dados de resultados (' + dadosret.erro + ')' });
            }
        })
        .catch(Error => this.setState({atutela: true, erro: 'Erro acessando a API - obtendo dados de resultados (' + Error.name + ' - ' + Error.message + ')' }))
    }

    atualizahora()
    {
        let dif=DifSegundos(this._inicial, new Date());
        if ((dif>this._dash.intervalo_dsbo && this._dash.intervalo_dsbo>0) || this.state.atuagora)
        {
            this.setState({atuagora: false});
            this._inicial=new Date();
            this.atualizaDados();
        }
    }

    componentWillUnmount() {
        if (this._idinterval>=0)
        {
            window.clearInterval(this._idinterval);
            this._idinterval=-1;
        }
    }

    retornax()
    {
        let rsac: number = 0;
        let retx: {name: string, color: {linearGradient: { x1: number, x2: number, y1: number, y2: number}, stops: [number, string][]}, data: {y: number, valorbr: string}[]}[]=
        [
            {name: 'Receitas', color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, '#00CD66'],
                    [1, '#00FF7F']
                ]},data:[]},
            {name: 'Despesas', color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, '#FF0000'],
                    [1, '#EE5C42']
                ]},data:[]},
            {name: 'Resultado', color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, '#00BFFF'],
                    [1, '#B0E2FF']
                ]},data:[]},
            {name: 'Resultado Acumulado', color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, '#0000FF'],
                    [1, '#00E2FF']
                ]},data:[]},
        ];

        this.state.dados.forEach(dd => 
        {
            rsac = rsac + (nulltoNumber(dd.receitas) -  nulltoNumber(dd.despesas));
            retx[3].data.push({y: rsac, valorbr: formatarCampo((rsac/1000).toString(),'decimal',true)});
            retx[2].data.push({y: nulltoNumber(dd.receitas)-nulltoNumber(dd.despesas), valorbr: dd.despesas ? formatarCampo(((nulltoNumber(dd.receitas)-nulltoNumber(dd.despesas))/1000).toString(),'decimal',true) : '0,00'});
            retx[1].data.push({y: dd.despesas ? dd.despesas : 0, valorbr: dd.despesas ? formatarCampo((dd.despesas/1000).toString(),'decimal',true) : '0,00'});
            retx[0].data.push({y: dd.receitas ? dd.receitas : 0, valorbr: dd.receitas ? formatarCampo((dd.receitas/1000).toString(),'decimal',true) : '0,00'});
        });
        return retx;
    }

    retornay()
    {
        let rety: string[]=[]
        this.state.dados.forEach(dd => rety.push(dd.mesrec && dd.mesrec.length>0 ? dd.mesrec.substr(4,2)+'/'+dd.mesrec.substr(0,4) : dd.mesdsp.substr(4,2)+'/'+dd.mesdsp.substr(0,4)));
        return rety;
    }

    render()
    {
        if (!this.state.atutela)
        {
            return null
        }
        else
        {
            return (
                <>
                    <Grid style={{ backgroundColor: '#F0FFFF', width: '100%', height: this._dash.tamanho_dsbo === 'C' ? '22% ' : '15%' }} container spacing={0}>
                        <TituloDash altura={this.props.altura} dash={this._dash} quadro={this.props.quadro} edita={this.props.edita} atualiza={this.atualizaDash.bind(this)} intervalo={{ dtini: this._parms.datini, dtfin: this._parms.datfin }} />
                    </Grid>
                    <Grid style={{ backgroundColor: '#F0FFFF', width: '100%', height: this._dash.tamanho_dsbo === 'C' ? '78% ' : '85%' }} container spacing={1}>
                        <Grid item xs={12}>
                            <Grafico estilo={{ backgroundColor: '#F0FFFF', width: '100%', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.415 : 0.38)).toString().trim() + 'px', boxShadow: "3px 3px 1px lightblue" }} opcoes={{
                                chart: { type: 'spline', backgroundColor: '#F0FFFF', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.405 : 0.375)).toString().trim() + 'px' },
                                credits: { enabled: false },
                                title: { text: '' },
                                xAxis: { categories: this.retornay()},
                                yAxis: { title: { text: 'Valores em Milhares de R$' } },
                                legend: { enabled: true, labelFormat: '{name}'},
                                tooltip: { pointFormat: '{point.valorbr}' },
                                plotOptions: {series: {dataLabels: {format: '{point.valorbr}', enabled: true}}},
                                series: this.retornax()
                            }} />
                        </Grid>
                    </Grid>
                </>
            );
        }
    }
}

export default DashResultados
