export class Servicos_Contratos
{
    id_svct: number = 0;
    id_cont: number = 0;
    id_serv: number = 0;
    recor_svct: string = ' ';
    valor_svct: number = 0;
    obs_svct: string = '';
    basecom_svct: number=0;
    dia_svct: number = 0;
    id_dtrc: number =0;
    data_svct?: Date = undefined;
    alterado: string = '';
}