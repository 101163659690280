import React, { RefObject, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { RetornoAcesso, SolicitaAcesso } from '../Modelos/ObjetosDiversos';
import { arrayBufferToBase64, Resposta, TrataResposta } from '../Servicos/Utilidades';
import ApiService from '../Servicos/ApiService';
import Progresso from './Progresso';
import CampoTexto from './CampoTexto';

export default function DadosUsuario({aberta, fecha, dadosacesso, idoper}: {aberta:boolean, fecha: (botaoretorno: string, foto?: Uint32Array | undefined) => void, dadosacesso: RetornoAcesso, idoper: number})
{
    const srv= new ApiService();
    
    const [foto, setFoto] = React.useState(dadosacesso ? dadosacesso.foto : undefined);
    const [senha, setSenha] = React.useState('');
    const [novasenha, setNovasenha] = React.useState('');
    const [conferesenha, setConferesenha] = React.useState('');
    const [erro, setErro] = React.useState('');

    const inputFileFoto: RefObject<HTMLInputElement> = useRef(null) ;

    const handleClose = ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, botaoretorno: string) => 
    {
        fecha('C');
    });

    const onChangeFoto = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    {
        const filereader = new FileReader();
        const doc: any = document.getElementById("filefotouser");
        if(doc)
        {
            if(doc.files)
            {
                if(doc.files.length>0)
                {
                    filereader.readAsArrayBuffer(doc.files[0]);
                }
            }
        }
        filereader.onloadend = function()
        {
            setFoto(arrayBufferToBase64(filereader.result as ArrayBuffer));
        };
    }

    const onButtonClickFoto = (event:any) => 
    {
        if(event.ctrlKey)
        {
            setFoto(undefined);
        }
        else
        {
            if (inputFileFoto.current)
            {
                inputFileFoto.current.click()
            }
        }
    };

    const onChangetexto = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date, campo?: string) =>
    {
        if (campo)
        {
            if (campo==='senha')
            {
                setSenha(propratu.toString());
            }
            else if(campo==='novasenha')
            {
                setNovasenha(propratu.toString());
            }
            else if(campo==='conferesenha')
            {
                setConferesenha(propratu.toString());
            }
        }
    }

    const confirma = () =>
    {
        setErro('****');
        if (idoper===0)
        {
            let grvfoto = new RetornoAcesso();
            grvfoto.id_pessoa=dadosacesso.id_pessoa;
            grvfoto.foto=foto;
            srv.Post('Pessoas/foto','',JSON.stringify(grvfoto),dadosacesso.objac.token,false)
            .then((ddret: any) => {
                let dadosret: Resposta = TrataResposta(ddret);
                if (!dadosret.ok)
                {
                    setErro(dadosret.erro);
                }
                else
                {
                    fecha('A',foto);
                }
            })
            .catch(Error => 
            {
                setErro('Erro acessando a API gravando foto (' + Error.name + ' - ' + Error.message + ')');
            })
        }
        else
        {
            if ((!senha || senha.length===0) && dadosacesso.ok)
            {
                setErro('Informe a senha atual');
                document.querySelector<HTMLInputElement>("#senha")?.focus();
            }
            else if(!novasenha || novasenha.length===0)
            {
                setErro('Informe a nova senha');
                document.querySelector<HTMLInputElement>("#novasenha")?.focus();
            }
            else if(!conferesenha || conferesenha.length===0)
            {
                setErro('Repita a nova senha');
                document.querySelector<HTMLInputElement>("#conferesenha")?.focus();
            }
            else if(novasenha!==conferesenha)
            {
                setErro('A nova senha repetida não confere com a informada');
                document.querySelector<HTMLInputElement>("#novasenha")?.focus();
            }
            else
            {
                let grvacesso = new SolicitaAcesso(idoper,'',senha);
                grvacesso.reset=!dadosacesso.ok;
                grvacesso.novasenha_oper=novasenha;
                srv.Post(dadosacesso.ok ? 'Operadores/senha' : 'Servidor/Senha','',JSON.stringify(grvacesso),dadosacesso.ok ? dadosacesso.objac.token : '', !dadosacesso.ok)
                .then((ddret: any) => {
                    let dadosret: Resposta = TrataResposta(ddret);
                    if (!dadosret.ok)
                    {
                        setErro(dadosret.erro);
                    }
                    else
                    {
                        fecha('B');
                    }
                })
                .catch(Error => 
                {
                    setErro('Erro acessando a API alterando senha (' + Error.name + ' - ' + Error.message + ')');
                })
            }
        }
    }

    return (
        <div>
        <Dialog
            open={aberta}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{alignSelf: 'center'}} id="alert-dialog-title">{idoper===0 ? 'Alterar Foto' : dadosacesso.ok ? 'Alterar Senha' : 'Reinicializar Senha'}</DialogTitle>
            <DialogContent>
                <Grid style={{maxWidth: '300px', minWidth: '300px'}} container spacing={1}>
                    {idoper===0 ?
                        <Grid item xs={12}>
                            <input style={{display: 'none'}} type='file' id='filefotouser' accept='image/*' ref={inputFileFoto} onChange={event => onChangeFoto(event)}/>
                            <Tooltip title={'Clique na foto para alterar ou Ctrl+clique para remover'} placement='top' >
                                <img style={{width: '67%', maxHeight: "200px", minHeight: "200px", margin: '0 16% 0 17%', boxShadow: "2px 2px 5px lightblue"}} id='foto_pessoa' alt='fotousuario' src={foto ? 'data:image/jpeg;base64, ' + foto : 'Imagens/maquinafotografica.png'} onClick={event => onButtonClickFoto(event)} />
                            </Tooltip>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            {dadosacesso.ok?
                                <CampoTexto valor={senha} id='senha' label='Senha atual' placeholder='' tipo='password' required={true} classe='' habilitado={true} onchange={ onChangetexto } erro={!senha || senha.length===0} ></CampoTexto>
                            :
                                <Typography align='center' color='primary'>Usuario: {dadosacesso.nome}</Typography>
                            }
                            <CampoTexto valor={novasenha} id='novasenha' label='Nova senha' placeholder='' tipo='password' required={true} classe='' habilitado={true} onchange={ onChangetexto } erro={!novasenha || novasenha.length===0}></CampoTexto>
                            <CampoTexto valor={conferesenha} id='conferesenha' label='Repita a nova senha' placeholder='' tipo='password' required={true} classe='' habilitado={true} onchange={ onChangetexto } erro={!conferesenha || (novasenha!==undefined && conferesenha!==novasenha)}></CampoTexto>
                        </Grid>
                    }
                    {
                        erro.length>0 ?
                            <Grid item xs={12}>
                                {erro==='****' ?
                                    <Box minWidth={35} margin='20px 0 0 10px'>
                                        <Typography align='center' color='primary'>Aguarde....</Typography>
                                        <Progresso/>
                                    </Box>
                                    :
                                    <Box minWidth={35} margin='20px 0 0 10px'>
                                        <Typography color='secondary'>{erro}</Typography>
                                    </Box>
                                }
                            </Grid>
                        :
                            null
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={confirma} color="primary">
                    Confirma
                </Button>
                <Button onClick={event => handleClose(event, 'B')} color="primary">
                    Desiste
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}