export class Detalhes_Recebimento
{
    id_dtrc: number=0;
    id_rcbt: number=0;
    valor_dtrc: number=0;
    id_svct: number=0;
    id_svcl: number=0;
    id_rcta: number=0;
    id_dsps: number=0;
    basecom_dtrc: number=0;
    id_vend: number=0;
    id_oper: number=0;
    nome_oper: string='';
    id_rede: number=0;
    loja_dtrc: number=0;
    rps_dtrc: string='';
    alterado: string='';
}