import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { RetornaDataAAAAMMDD, Resposta, TrataResposta, DifSegundos, formatarCampo, retPeriodoFiltro, achaDescricao, DashProps, nulltoNumber, retornaCor } from '../Servicos/Utilidades';
import { ApiService } from '../Servicos/ApiService';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Dashboard_Operador } from '../Modelos/Dashboard_Operador';
import TituloDash from '../Componentes/TituloDash';
import Grafico from './Grafico';

const srv = new ApiService();

interface DashPgtoState {
    atutela: boolean,
    erro: string,
    dados: {id:number, desc:string, valores:number, registros:number, subgrupos: {id:number, desc:string, valores:number, registros:number, despesas: {id: number, desc: string, valores: number, registros: number}[]}[]}[],
    gruposel: number,
    subgruposel: number,
    atuagora: boolean,
    grafex: number
}

class DashDespesas extends Component<DashProps, DashPgtoState>
{
    private _idinterval: number;
    private _dadosobt: RetornoAcesso;
    private _inicial: Date;
    private _parms: {periodo: number, datini: Date | undefined, datfin: Date |undefined};
    private _fornecedor: number;
    private _dash: Dashboard_Operador;
    private _subtitulo: string;
    
    constructor(props: any)
    {
        super(props);

        this._parms={periodo:0, datini: undefined, datfin: undefined};
        this._dash=this.props.dashoper;
        this._parms=retPeriodoFiltro(achaDescricao(1,this._dash.filtros,'id_tfdb','valores_fdbo'));
        this._fornecedor=nulltoNumber(parseInt(achaDescricao(2,this._dash.filtros,'id_tfdb','valores_fdbo')));
        this._dadosobt=props.dadosacesso;
        this._inicial=new Date();
        this._idinterval=-1;
        this._subtitulo='';
        this.state =
        {
            atutela: false,
            erro: '',
            dados: [],
            gruposel: 0,
            subgruposel: 0,
            atuagora: false,
            grafex: 1
        }

        if (window)
        {
            this._idinterval=window.setInterval(this.atualizahora.bind(this),1000)
        }
        this.atualizaDados();
    }

    atualizaDash(dashatu: Dashboard_Operador)
    {
        this._dash=dashatu;
        this._parms=retPeriodoFiltro(achaDescricao(1,this._dash.filtros,'id_tfdb','valores_fdbo'));
        this._fornecedor=nulltoNumber(parseInt(achaDescricao(2,this._dash.filtros,'id_tfdb','valores_fdbo')));
        this.setState(
        {
            atuagora: true
        })
    }

    atualizaDados()
    {
        srv.Get('Pagamentos/Despesas/'+RetornaDataAAAAMMDD(this._parms.datini, false)+'/'+RetornaDataAAAAMMDD(this._parms.datfin, false)+'/'+nulltoNumber(this._fornecedor).toString().trim(), '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
            let dadosret: Resposta = TrataResposta(ddret);
            if (dadosret.ok)
            {
                this._subtitulo='';
                if (this._fornecedor>0)
                {
                    let gddados: {id:number, desc:string, valores:number, registros:number, subgrupos: {id:number, desc:string, valores:number, registros:number, despesas: {id: number, desc: string, valores: number, registros: number}[]}[]}[]=dadosret.retorno;
                    srv.Get('Fornecedores/'+(this._fornecedor.toString().trim()), '', '', this._dadosobt.objac.token, false)
                    .then((ddret: any) => 
                    {
                        let dadosret: Resposta = TrataResposta(ddret);
                        if (dadosret.ok)
                        {
                            this._subtitulo=(dadosret.retorno.pessoa.fantasia_pessoa && dadosret.retorno.pessoa.fantasia_pessoa.trim().length>0 ? dadosret.retorno.pessoa.fantasia_pessoa.trim() : (dadosret.retorno.pessoa.nome_pessoa ? dadosret.retorno.pessoa.nome_pessoa : 'Fornecedor: '+this._fornecedor.toString().trim()));
                            this.setState({atutela: true, erro: '', dados: gddados });                          
                        }
                        else
                        {
                            this._subtitulo='Fornecedor: '+this._fornecedor.toString().trim();
                            this.setState({atutela: true, erro: '', dados: gddados });
                        }
                    })
                    .catch(Error => {this._subtitulo='Fornecedor: '+this._fornecedor.toString().trim(); this.setState({atutela: true, erro: '', dados: gddados})});
                }
                else
                {
                    this.setState({atutela: true, erro: '', dados: dadosret.retorno });
                }
            }
            else
            {
                this.setState({atutela: true, erro: 'Erro: Erro obtendo dados de pagamentos (' + dadosret.erro + ')' });
            }
        })
        .catch(Error => this.setState({atutela: true, erro: 'Erro acessando a API - obtendo dados de pagamentos (' + Error.name + ' - ' + Error.message + ')' }))
    }

    retornaGrafico()
    {
        if (this.state.grafex>1)
        {
            this.setState({grafex: this.state.grafex-1});
        }
    }

    selecionaFatia(event:any)
    {
        if(event && event.point && event.point.index!==undefined && event.point.tipo!==undefined)
        {
            if (event.point.tipo==='A')
            {
                this.setState({gruposel: event.point.index, subgruposel: 0, grafex: this.state.grafex+1});
            }
            else if (event.point.tipo==='B')
            {
                this.setState({subgruposel: event.point.index, grafex: this.state.grafex+1});
            }
        }
    }

    retornaFatias(dtbases: {id:number, desc:string, valores:number, registros:number}[], tipo:string): any[]
    {
        let retdtpie:any[] = []
        dtbases.forEach((dtbas,idx) => 
        {
            retdtpie.push({events: {click: this.selecionaFatia.bind(this)}, y: dtbas.valores, tipo: tipo, name: dtbas.desc, color: retornaCor(idx), selected: idx===(tipo==='A' ? this.state.gruposel : tipo==='B' ? this.state.subgruposel : -1), sliced: idx===(tipo==='A' ? this.state.gruposel : tipo==='B' ? this.state.subgruposel : -1), valorbr: formatarCampo(dtbas.valores.toString().trim(),'decimal',true)+' ('+dtbas.registros.toString().trim()+')', optname: formatarCampo((dtbas.valores/1000).toString().trim(),'decimal',true)+'k' })
        })
        return retdtpie;
    }

    atualizahora()
    {
        let dif=DifSegundos(this._inicial, new Date());
        if ((dif>this._dash.intervalo_dsbo && this._dash.intervalo_dsbo>0) || this.state.atuagora)
        {
            this.setState({atuagora: false});
            this._inicial=new Date();
            this.atualizaDados();
        }
    }

    componentWillUnmount() {
        if (this._idinterval>=0)
        {
            window.clearInterval(this._idinterval);
            this._idinterval=-1;
        }
    }

    render()
    {
        if (!this.state.atutela)
        {
            return null
        }
        else
        {
            return (
                <>
                    <Grid style={{ backgroundColor: '#F0FFFF', width: '100%', height: this._dash.tamanho_dsbo === 'C' ? '22% ' : '15%' }} container spacing={0}>
                        <TituloDash altura={this.props.altura} dash={this._dash} quadro={this.props.quadro} edita={this.props.edita} atualiza={this.atualizaDash.bind(this)} intervalo={{ dtini: this._parms.datini, dtfin: this._parms.datfin }} subtitulo={this._subtitulo.length>0 ? this._subtitulo : undefined} />
                    </Grid>
                    <Grid style={{ backgroundColor: '#F0FFFF', width: '100%', height: this._dash.tamanho_dsbo === 'C' ? '78% ' : '85%' }} container spacing={1}>
                        <>
                            {this._dash.tamanho_dsbo==='D' || this.state.grafex===1 ?
                                <Grid item xs={this._dash.tamanho_dsbo==='D' ? 4 : 12}>
                                    <Grafico estilo={{ backgroundColor: '#F0FFFF', width: '100%', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.415 : 0.38)).toString().trim() + 'px', boxShadow: "3px 3px 1px lightblue" }} opcoes={{
                                        chart: { type: 'pie', backgroundColor: '#F0FFFF', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.405 : 0.375)).toString().trim() + 'px' },
                                        credits: { enabled: false },
                                        title: { text: '' },
                                        legend: { enabled: true, labelFormat: '{name}', title: { text: 'Grupos' } },
                                        tooltip: { pointFormat: '{point.valorbr} - {point.percentage:.2f}%' },
                                        plotOptions: {
                                            pie: {
                                                size: '80%',
                                                allowPointSelect: false,
                                                cursor: 'pointer',
                                                dataLabels: {
                                                    distance: 20,
                                                    alignTo: 'connectors',
                                                    enabled: true,
                                                    format: '{point.optname}',
                                                    style: {
                                                        fontSize: '10px',
                                                        color: 'darkblue'
                                                    },
                                                }
                                            }
                                        },
                                        series: [
                                            {
                                                showInLegend: true,
                                                data: this.retornaFatias(this.state.dados, 'A')
                                            }
                                        ]
                                    }} />

                                </Grid>
                            :
                            null}
                            {(this._dash.tamanho_dsbo==='D' || this.state.grafex===2) && this.state.gruposel >= 0 && this.state.gruposel < this.state.dados.length ?
                                <Grid item xs={this._dash.tamanho_dsbo==='D' ? 4 : 12}>
                                    <Grafico estilo={{ backgroundColor: '#F0FFFF', width: '100%', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.415 : 0.38)).toString().trim() + 'px', boxShadow: "3px 3px 1px lightblue" }} retorno={this._dash.tamanho_dsbo==='C' ? this.retornaGrafico.bind(this) : undefined} opcoes={{
                                        chart: { type: 'pie', backgroundColor: '#F0FFFF', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.405 : 0.35)).toString().trim() + 'px' },
                                        credits: { enabled: false },
                                        title: { text: '' },
                                        legend: { enabled: true, labelFormat: '{name}', title: { text: this.state.dados[this.state.gruposel].desc } },
                                        tooltip: { pointFormat: '{point.valorbr} - {point.percentage:.2f}%' },
                                        plotOptions: {
                                            pie: {
                                                size: '80%',
                                                allowPointSelect: false,
                                                cursor: 'pointer',
                                                dataLabels: {
                                                    distance: 20,
                                                    alignTo: 'connectors',
                                                    enabled: true,
                                                    format: '{point.optname}',
                                                    style: {
                                                        fontSize: '10px',
                                                        color: 'darkblue'
                                                    },
                                                }
                                            }
                                        },
                                        series: [
                                            {
                                                showInLegend: true,
                                                name: 'Subgrupos',
                                                data: this.retornaFatias(this.state.dados[this.state.gruposel].subgrupos, 'B')
                                            }
                                        ]
                                    }} />
                                </Grid>
                            :
                                null
                            }
                            {(this._dash.tamanho_dsbo==='D' || this.state.grafex===3) && this.state.gruposel >= 0 && this.state.gruposel < this.state.dados.length && this.state.subgruposel >= 0 && this.state.subgruposel < this.state.dados[this.state.gruposel].subgrupos.length ?
                                <Grid item xs={this._dash.tamanho_dsbo==='D' ? 4 : 12}>
                                    <Grafico estilo={{ backgroundColor: '#F0FFFF', width: '100%', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.415 : 0.38)).toString().trim() + 'px', boxShadow: "3px 3px 1px lightblue" }} retorno={this._dash.tamanho_dsbo==='C' ? this.retornaGrafico.bind(this) : undefined} opcoes={{
                                        chart: { type: 'pie', backgroundColor: '#F0FFFF', height: Math.round(this.props.altura * (this._dash.tamanho_dsbo==='D' ? 0.405 : 0.35)).toString().trim() + 'px' },
                                        credits: { enabled: false },
                                        title: { text: '' },
                                        legend: { enabled: true, labelFormat: '{name}', title: { text: this.state.dados[this.state.gruposel].subgrupos[this.state.subgruposel].desc } },
                                        tooltip: { pointFormat: '{point.valorbr} - {point.percentage:.2f}%' },
                                        plotOptions: {
                                            pie: {
                                                size: '80%',
                                                allowPointSelect: false,
                                                cursor: 'pointer',
                                                dataLabels: {
                                                    distance: 20,
                                                    alignTo: 'connectors',
                                                    enabled: true,
                                                    format: '{point.optname}',
                                                    style: {
                                                        fontSize: '10px',
                                                        color: 'darkblue'
                                                    },
                                                }
                                            }
                                        },
                                        series: [
                                            {
                                                showInLegend: true,
                                                name: 'Despesas',
                                                data: this.retornaFatias(this.state.dados[this.state.gruposel].subgrupos[this.state.subgruposel].despesas, 'C')
                                            }
                                        ]
                                    }} />
                                </Grid>
                            :
                                null
                            }
                        </>
                    </Grid>
                </>
            );
        }
    }
}

export default DashDespesas
