import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { Quadro } from '../Componentes/Quadro';
import { Dashboard_Operador } from '../Modelos/Dashboard_Operador';

type DashboardProps = 
{
    altura: number
    dashboard: Dashboard_Operador[]
    qtquadros: number
    conteudoiten: (quadro: number,  posicao: number, vazios: number[], dashoper?: Dashboard_Operador) => ReactNode
    retornaredesenha: (retredes: (novodash: Dashboard_Operador[]) =>void) => void
    corfundo?: string
    borda?: string
}

export default function Dashboard({altura, dashboard, qtquadros, conteudoiten, retornaredesenha, corfundo, borda }: DashboardProps) {

    const posicoes:string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const atuquadros = ():{conteudo: {posicao: number, tamanho: string, dash: Dashboard_Operador}[]}[] =>
    {
        let qdros: {conteudo: {posicao: number, tamanho: string, dash: Dashboard_Operador}[]}[]=[];
        let numquadro: number;
        let posini: number;
        dashboard=dashboard.sort(function(ita, itb) {if(ita.posicao_dsbo>itb.posicao_dsbo) {return 1} else {return -1}});
        dashboard.forEach(dsh =>
            {
                posini=(posicoes.indexOf(dsh.posicao_dsbo))+1;
                if (posini>0)
                {
                    numquadro=Math.ceil(posini/4);
                    while (qdros.length<numquadro)
                    {
                        qdros.push({conteudo: []});
                    }
                    qdros[numquadro-1].conteudo.push({posicao: posini-((numquadro-1)*4), tamanho: dsh.tamanho_dsbo, dash: dsh })
                }
            });
        while (qdros.length<qtquadros)
        {
            qdros.push({conteudo: []});
        }
        return qdros
    }

    const [quadros, setQuadros] = React.useState(atuquadros());

    const redesenha = (novodash: Dashboard_Operador[]) =>
    {
        dashboard=novodash;
        setQuadros(atuquadros());
    }

    retornaredesenha(redesenha);

    return (
        <div style={{margin: '5% 3% 0 6%', width: '91%', height: altura.toString().trim()+'px'}}>
            <Grid container spacing={0}>
                {quadros.map((qdr, idq) =>
                    {
                        return (
                            <Quadro key={idq+1} itensdash={qdr.conteudo} altura={Math.round(altura/2)} ordem={idq+1} conteudoiten={conteudoiten} corfundo='#F0FFFF'/>
                        )
                    })}
            </Grid>
        </div>
    );
}