import { RouteComponentProps, withRouter } from 'react-router';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Roteador } from '../Servicos/Roteador';
import { Resposta, TrataResposta, datas, RetornaPeriodos, achaDescricao, RetornaValoresGrupos, ProximoVencimento, ParametrosDiversos, AlteraTiposGeracao, RetornaDataAAAAMMDD, achavalor, RetornaStringData, AlteraPadroesRecebimento, NumeroBanco, CriaAutorizacao, Iheaders, GeraHeader, GeraTrailler, GeraDetalhe, DataSql, RetornaDataString, RetornaClientes, nulltoNumber } from '../Servicos/Utilidades';
import ApiService from '../Servicos/ApiService';
import { Recebimentos } from '../Modelos/Recebimentos';
import BarraLateral from '../Componentes/Barralateral';
import Toast from '../Componentes/Toast';
import Progresso from '../Componentes/Progresso';
import ProgressoEtiqueta from '../Componentes/ProgressoEtiqueta';
import { makeStyles, Theme, createStyles, Grid, Button, FormControlLabel, Typography, Box } from '@material-ui/core';
import { Detalhes_Recebimento } from '../Modelos/Detalhes_Recebimento';
import React, { ChangeEvent } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import PageviewIcon from '@material-ui/icons/Pageview';
import { Component } from 'react';
import Selecao from '../Componentes/Selecao';
import SelecaoMultipla from '../Componentes/SelecaoMultipla';
import { Tipos_Grupos } from '../Modelos/Tipos_Grupos';
import { Contas } from '../Modelos/Contas';
import { Clientes } from '../Modelos/Clientes';
import { Parametros } from '../Modelos/Parametros';
import { Redes } from '../Modelos/Redes';
import { Situacoes_Recebimentos } from '../Modelos/Situacoes_Recebimentos';
import { Servicos } from '../Modelos/Servicos';
import Periodo from '../Componentes/Periodo';
import FileSaver from 'file-saver';
import Switch from '@material-ui/core/Switch';
import { Tabelas_Dados_Adicionais } from '../Modelos/Tabelas_Dados_Adicionais';
import TabelaBase from '../Componentes/TabelaBase';
import { Ocorrencias_Recebimento } from '../Modelos/Ocorrencias_Recebimento';
import SelecaoAuto from '../Componentes/SelecaoAuto';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';
import { Servicos_Contratos } from '../Modelos/Servicos_Contratos';

const srv = new ApiService();

const useStyles = makeStyles((theme: Theme) => createStyles({
    griddados: {
      width: "100%",
      margin: "0 0 0 0"
    },
    gridtab: {
      width: "50%",
      margin: "0 0 0 0"
    },
    divgrid: {
        width: '90%',
        margin: '5% 7% 0'
    },
    divtabs: {
        width: '90%',
        margin: '0% 7% 0'
    },
    boxpanel: {
        width: '104%',
        margin: '0% 0% 0% -2%',
        padding: '0 0 0 0'
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    botao1: {
        margin: '2% 5% 0 -8%',
        width: '49%'
      },
    botao2: {
        margin: '2% 0 0 5%',
        width: '49%'
    },
    tab: {
        minWidth: "50%",
        width: "50%"
    },
    gridcampos: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridfoto: {
        width: "20%",
        margin: "0 0 0 0"
    },
}));

let filtvenc: datas | undefined = RetornaPeriodos(9);
let datainicio: Date = filtvenc?.dtini ? filtvenc.dtini :new Date();
let datafim: Date = filtvenc?.dtfin ? filtvenc.dtfin :new Date();
let cliobt: any[] = [];
let recebimentos: Recebimentos[] = [];
let tabelarec: Tabrec[] = [];
let selecionadas: number[] = [];
let remessa: string[] = [];
let arqrem: string = '';
let hoje=new Date();
let totreg = 0;
let interv = 0;
let indcta = -1;

type PrincipalProps=
{
    dadosobt: RetornoAcesso
    contas: Contas[];
    redes: Redes[];
    grupo: Tipos_Grupos;
    clientes: {id_cli: number, nome_cli: string} [];
    situacoes: Situacoes_Recebimentos[];
    servicos: Servicos[];
    mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void
}

type Tabrec=
{
    indice: number,
    id_interno: string,
    vencto_rcbt: Date,
    valor_rcbt: number,
    id_cli: number,
    nome_pessoa: string,
    fantasia_pessoa: string,
    nome_rede: string,
    loja_cli: number,
    flagedicao: string
}

const PrincipalGeracaoRecebimentos = ({dadosobt, contas, grupo, redes, clientes, situacoes, servicos, mensagem }: PrincipalProps) =>
{
    const classes = useStyles();
    const [rede,setrede]=React.useState(0);
    const [conta,setconta]=React.useState(0);
    const [grp,setgrp]=React.useState(0);
    const [agrcnpj,setagrcnpj]=React.useState(false);
    const [geraremessa,setageraremessa]=React.useState(false);
    const [progresso,setaprogresso]=React.useState({processo: false, percentual: 0});
    const [tipoger,setatipogera]=React.useState<string[]>([]);
    const [cliproc,setcliproc]=React.useState('');
    const [operexec,setoperexec]=React.useState('A');
    const [clisel,setClisel]=React.useState<number[]>([]);

    const srv = new ApiService();

    const AtualizaFiltro = (campo:string, valor: any) =>
    {
      if(campo==='Vencimento')
      {
        filtvenc={dtini: valor.dtini, dtfin: valor.dtfin}
      }
    }
    
    const onChangeRecebimentoSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode, campo?: string | undefined) =>
    {
      if(campo && event.target.value)
      {
        let valor:number = 0;
        valor = typeof event.target.value === 'string' ? parseInt(event.target.value) : typeof event.target.value === 'number' ? event.target.value : 0; 
        if (campo==='id_tpgr')
        {
            setgrp(valor)
        }
        else if(campo==='id_cta')
        {
            setconta(valor)
            setageraremessa(valor!==0);
        }
        else if(campo==='id_rede')
        {
            setrede(valor)
        }
      }
    }

    const onChangeClientes = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined, campo?: string) =>
    {
        if(campo && value)
        {
            if (value.length!==undefined)
            {
                let novocli:number[]=[];   
                value.forEach((vl:any) => novocli.push(vl[campo]));
                setClisel(novocli);
            }
            else
            {
                setClisel([value[campo]]);
            }
        }
        else
        {
            if(campo)
            {
                setClisel([]);
            }
        }
    }

    const onChangeMultiSel = (event: React.ChangeEvent<{ value: unknown }>, campo?: string) => {
        if (campo==='tipoger')
        {
            setatipogera(event.target.value as string[]);
        }
    };

    const AlteraSelecionadas = (sel: number[]) =>
    {
        selecionadas=sel;
    }

    const obtemLancamentos = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
    {
        if (filtvenc?.dtini && filtvenc?.dtfin && filtvenc.dtfin>=filtvenc.dtini)
        {
            hoje=new Date();
            datainicio=filtvenc.dtini;
            datafim=filtvenc.dtfin;
            srv.Get('Clientes/'+grp.toString()+'/'+rede.toString()+'/Enderecos', '', '', dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (dadosret.ok)
                {
                    cliobt=dadosret.retorno;
                    if (clisel.length>0)
                    {
                        cliobt=cliobt.filter(cl => clisel.indexOf(cl.id_cli)!==-1)
                    }
                    recebimentos=[];
                    tabelarec=[];
                    totreg = cliobt.length;
                    interv = Math.max(Math.ceil(totreg/100),1);
                    setoperexec('B');
                    setaprogresso({processo: true, percentual:0});
                    if (cliobt.length>0)
                    {
                        obtemCliente(cliobt[0].id_cli,0);
                    }
                }
                else
                {
                    mensagem('Erro: Erro obtendo dados de clientes (' + dadosret.erro + ')','error',5000, false);
                }
            })
            .catch(Error => mensagem('Erro obtendo dados de clientes acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false));
        }
        else
        {
            mensagem('Atenção: verifque o período selecionado', 'error', 6000, false);
        }
    }

    function obtemCliente(idcli: number, indcli: number)
    {
        if (indcli % interv===0 || indcli===totreg-1)
        {
            setaprogresso({processo: true, percentual: Math.trunc(((indcli+1)/totreg)*100)});
        }
        srv.Get('Clientes/'+idcli, '', '', dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
            let dadosret: Resposta = TrataResposta(ddret);
            if (dadosret.ok)
            {
                geraRecebimento(dadosret.retorno, indcli);
            }
            else
            {
                mensagem('Erro: Erro obtendo dados de clientes (' + dadosret.erro + ')','error',5000, false);
            }
        })
        .catch(Error => mensagem('Erro obtendo dados de clientes acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false));
    }

    const geraRecebimento = (cliente: Clientes, indcli: number ) =>
    {
        let ok = true;
        let cliagr=cliente.id_cli;
        let indrcger=-1;
        let adserv='A';
        if (agrcnpj)
        {
            let cnpj=achaDescricao(cliagr,cliobt,'id_cli','cpfcnpj');
            if(cnpj.length===14)
            {
                if(cnpj.substr(8,4)!=='0001')
                {
                    let retcli=achavalor(cnpj.substr(0,8)+'0001',cliobt,'cpfcnpj','id_cli',12)
                    if(typeof retcli==='number')
                    {
                        cliagr=retcli;
                    }
                }
                indrcger=recebimentos.findIndex(rec => rec.id_cli===cliagr && (rec.carteira_rcbt==='******' || rec.carteira_rcbt==='agrupa'));
                if(indrcger>=0)
                {
                    adserv='B'
                }
            }
        }
        let regrec: Recebimentos[] = [];
        cliente.contratos.forEach(cont => 
            {
                if ('AC'.indexOf(cont.situacao_cont)!==-1)
                {
                    cont.srvcont.forEach(srvc => 
                        {
                            if(srvc.recor_svct==='A' && tipoger.indexOf(srvc.recor_svct)!==-1 && cont.situacao_cont==='A')
                            {
                                let prxvc=ProximoVencimento(srvc.dia_svct, RetornaDataAAAAMMDD(hoje)>RetornaDataAAAAMMDD(datainicio) ? hoje : datainicio);
                                if (prxvc)
                                {
                                    if(RetornaDataAAAAMMDD(prxvc)>=RetornaDataAAAAMMDD(datainicio) && RetornaDataAAAAMMDD(prxvc)<=RetornaDataAAAAMMDD(datafim))
                                    {
                                        let adcreg=true;
                                        if (indrcger>=0)
                                        {
                                            if(RetornaDataAAAAMMDD(recebimentos[indrcger].vencto_rcbt)===RetornaDataAAAAMMDD(prxvc))
                                            {
                                                recebimentos[indrcger].valor_rcbt = recebimentos[indrcger].valor_rcbt + srvc.valor_svct;
                                                recebimentos[indrcger].carteira_rcbt='agrupa';
                                                let novodet=new Detalhes_Recebimento();
                                                novodet.id_svct=srvc.id_svct;
                                                novodet.valor_dtrc=srvc.valor_svct;
                                                novodet.id_vend=cont.id_vend;
                                                novodet.basecom_dtrc=srvc.basecom_svct;
                                                novodet.id_rcta=achavalor(srvc.id_serv,servicos,'id_serv','id_rcta',0) as number;
                                                novodet.id_rede=cliente.id_rede;
                                                novodet.loja_dtrc=cliente.loja_cli;
                                                if(!recebimentos[indrcger].autorizacao_rcbt || recebimentos[indrcger].autorizacao_rcbt==='')
                                                {
                                                    let id_dadc=achavalor(srvc.id_serv,servicos,'id_serv','id_dadc',0) as number;
                                                    if (id_dadc>0)
                                                    {
                                                        recebimentos[indrcger].autorizacao_rcbt='*'+id_dadc.toString();
                                                    }
                                                }
                                                recebimentos[indrcger].detalhes.push(novodet);
                                                adcreg=false;
                                            }
                                        }
                                        if(adcreg)
                                        {
                                            let novorec=new Recebimentos();
                                            novorec.valor_rcbt=srvc.valor_svct;
                                            novorec.vencto_rcbt=prxvc;
                                            novorec.id_cli=cliagr;
                                            novorec.carteira_rcbt='******';
                                            novorec.detalhes.push(new Detalhes_Recebimento());
                                            novorec.detalhes[0].id_svct=srvc.id_svct;
                                            novorec.detalhes[0].valor_dtrc=srvc.valor_svct;
                                            novorec.detalhes[0].id_vend=cont.id_vend;
                                            novorec.detalhes[0].basecom_dtrc=srvc.basecom_svct;
                                            novorec.detalhes[0].id_rcta=achavalor(srvc.id_serv,servicos,'id_serv','id_rcta',0) as number;
                                            novorec.detalhes[0].id_rede=cliente.id_rede;
                                            novorec.detalhes[0].loja_dtrc=cliente.loja_cli;
                                            let id_dadc=achavalor(srvc.id_serv,servicos,'id_serv','id_dadc',0) as number;
                                            if (id_dadc>0)
                                            {
                                                novorec.autorizacao_rcbt='*'+id_dadc.toString();
                                            }
                                            regrec.push(novorec);
                                        }
                                    }
                                }
                            }
                            else if(srvc.recor_svct==='B' && tipoger.indexOf(srvc.recor_svct)!==-1 && (!srvc.id_dtrc || srvc.id_dtrc===0) && srvc.data_svct && RetornaDataAAAAMMDD(srvc.data_svct)>=RetornaDataAAAAMMDD(datainicio) && RetornaDataAAAAMMDD(srvc.data_svct)<=RetornaDataAAAAMMDD(datafim))
                            {
                                let svador: Servicos_Contratos[]=cont.srvcont.filter(srvctft => srvctft.recor_svct==='B').sort(function (el1, el2) {return RetornaDataAAAAMMDD(el1.data_svct)>RetornaDataAAAAMMDD(el2.data_svct) ? 1 : -1})
                                let novorec=new Recebimentos();
                                novorec.valor_rcbt=srvc.valor_svct;
                                novorec.vencto_rcbt=RetornaDataString(srvc.data_svct);
                                novorec.id_cli=cliagr;
                                novorec.id_oper=svador.findIndex(sc => sc.id_svct===srvc.id_svct)+1;
                                novorec.id_strc=cont.id_cont;
                                novorec.carteira_rcbt='BBBBBB';
                                novorec.detalhes.push(new Detalhes_Recebimento());
                                novorec.detalhes[0].id_svct=srvc.id_svct;
                                novorec.detalhes[0].valor_dtrc=srvc.valor_svct;
                                novorec.detalhes[0].id_vend=cont.id_vend;
                                novorec.detalhes[0].basecom_dtrc=srvc.basecom_svct;
                                novorec.detalhes[0].id_rcta=achavalor(srvc.id_serv,servicos,'id_serv','id_rcta',0) as number;
                                novorec.detalhes[0].id_rede=cliente.id_rede;
                                novorec.detalhes[0].loja_dtrc=cliente.loja_cli;
                                regrec.push(novorec);
                            }
                        })
    
                }
            }
        )

        if (tipoger.indexOf('C')!==-1)
        {
            cliente.servicos.forEach(srvc => 
                {
                    if(!srvc.id_dtrc || srvc.id_dtrc===0)
                    {
                        if (adserv==='A')
                        {
                            let indrgrc = regrec.findIndex(rec => (rec.carteira_rcbt==='******' || rec.carteira_rcbt==='agrupa'));
                            if(indrgrc!==-1)
                            {
                                regrec[indrgrc].valor_rcbt = regrec[indrgrc].valor_rcbt+srvc.valor_svcl;
                            }
                            else
                            {
                                let novorec=new Recebimentos();
                                novorec.valor_rcbt=srvc.valor_svcl;
                                novorec.vencto_rcbt=RetornaDataAAAAMMDD(srvc.data_svcl)>RetornaDataAAAAMMDD(hoje) ? RetornaDataString(srvc.data_svcl) : datainicio;
                                novorec.id_cli=cliente.id_cli;
                                novorec.carteira_rcbt='CCCCCC';
                                if (srvc.obs_svcl.trim().length>=10 && srvc.obs_svcl.trim().substr(8,1)==='/')
                                {
                                    novorec.idinterno_rcbt=srvc.obs_svcl.trim().substring(0,10);
                                }
                                novorec.id_oper=0;
                                novorec.id_strc=srvc.id_svcl;
                                regrec.push(novorec);
                                indrgrc=regrec.length-1;
                            }
                            regrec[indrgrc].detalhes.push(new Detalhes_Recebimento());
                            let inddtrc=regrec[indrgrc].detalhes.length-1;
                            regrec[indrgrc].detalhes[inddtrc].id_svcl=srvc.id_svcl;
                            regrec[indrgrc].detalhes[inddtrc].id_vend=srvc.id_vend;
                            regrec[indrgrc].detalhes[inddtrc].valor_dtrc=srvc.valor_svcl;
                            regrec[indrgrc].detalhes[inddtrc].basecom_dtrc=srvc.basecom_svcl;
                            regrec[indrgrc].detalhes[inddtrc].id_rcta=achavalor(srvc.id_serv,servicos,'id_serv','id_rcta',0) as number;
                        }
                        else
                        {
                            recebimentos[indrcger].valor_rcbt = recebimentos[indrcger].valor_rcbt + srvc.valor_svcl;
                            let novodet=new Detalhes_Recebimento();
                            novodet.id_svcl=srvc.id_svcl;
                            novodet.valor_dtrc=srvc.valor_svcl;
                            novodet.id_vend=srvc.id_vend;
                            novodet.basecom_dtrc=srvc.basecom_svcl;
                            novodet.id_rcta=achavalor(srvc.id_serv,servicos,'id_serv','id_rcta',0) as number;
                            novodet.id_rede=cliente.id_rede;
                            novodet.loja_dtrc=cliente.loja_cli;
                            recebimentos[indrcger].detalhes.push(novodet);
                        }
                    }
                })
        }
        
        recebimentos.push(...regrec);

        if (ok)
        {
            if (indcli+1<cliobt.length)
            {
                setcliproc(cliente.id_cli+'-'+cliente.pessoa.nome_pessoa);
                obtemCliente(cliobt[indcli+1].id_cli, indcli+1);
            }
            else
            {
                if(recebimentos.length>0)
                {
                    totreg = recebimentos.length;
                    interv= Math.ceil(totreg/100);
                    setoperexec('C');
                    setaprogresso({processo: true, percentual:0});
                    VerificaRecebimentos(0);
                }
                else
                {
                    mensagem('Nenhum recebimento gerado para os filtros especificados','warning',6000,false);
                    setoperexec('A');
                    setaprogresso({processo: false, percentual:0});
                }
            }
        }
    }

    const VerificaRecebimentos = (indrec: number) =>
    {
        setcliproc((indrec+1).toString()+'/'+totreg.toString()+' - '+recebimentos[indrec].id_cli.toString()+' - '+RetornaStringData(recebimentos[indrec].vencto_rcbt)+' - '+recebimentos[indrec].valor_rcbt.toString());
        if (indrec % interv===0 || indrec===totreg-1)
        {
            setaprogresso({processo: true, percentual: Math.trunc(((indrec+1)/totreg)*100)});
        }
        let totcont=recebimentos[indrec].detalhes.reduce((acum, det) => acum + (det.id_svct && det.id_svct>0 ? 1 : 0), 0);
        if (totcont>0)
        {
            let contpesq=0
            if(totcont===1)
            {
                let inddetct=recebimentos[indrec].detalhes.findIndex(det => det.id_svct && det.id_svct>0);
                if(inddetct>=0)
                {
                    contpesq=recebimentos[indrec].detalhes[inddetct].id_svct;
                }
            }
            srv.Get('Recebimentos/'+recebimentos[indrec].id_cli.toString()+'/'+RetornaDataAAAAMMDD(recebimentos[indrec].vencto_rcbt)+'/'+contpesq.toString(),'', '', dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (dadosret.ok)
                {
                    let recexist: Recebimentos = dadosret.retorno;
                    if(recexist.id_rcbt>0)
                    {
                        if ((!recexist.jurosrec_rcbt || recexist.jurosrec_rcbt===0) && (!recexist.multarec_rcbt || recexist.multarec_rcbt===0) && !recexist.pagto_rcbt && 'CA'.indexOf(achaDescricao(recexist.id_strc, situacoes,'id_strc','comportamento_strc'))!==-1)
                        {
                            let novosdets: Detalhes_Recebimento[] = [];
                            let nvvalor=0;
                            recexist.detalhes.forEach(dtex => {
                                let acdet = recebimentos[indrec].detalhes.findIndex(dtnovo => dtex.id_svct && dtex.id_svct>0 ? dtnovo.id_svct===dtex.id_svct : dtex.id_svcl && dtex.id_svcl>0 ? dtnovo.id_svcl===dtex.id_svcl : false)
                                if (acdet===-1)
                                {
                                    nvvalor=nvvalor+dtex.valor_dtrc;
                                    novosdets.push(dtex);
                                }
                                else
                                {
                                    recebimentos[indrec].detalhes[acdet].id_dtrc=dtex.id_dtrc;
                                }
                            })
                            nvvalor=recebimentos[indrec].detalhes.reduce((acum, det) => acum + det.valor_dtrc, nvvalor);
                            recebimentos[indrec].id_rcbt=recexist.id_rcbt;
                            recebimentos[indrec].valor_rcbt=nvvalor;
                            recebimentos[indrec].detalhes.push(...novosdets);
                        }
                        else
                        {
                            recebimentos[indrec].carteira_rcbt='XXXXXX';
                        }
                        recebimentos[indrec].idinterno_rcbt=recexist.idinterno_rcbt;
                        recebimentos[indrec].numban_rcbt=recexist.numban_rcbt;
                    }
                    FinalizaRecebimentos(indrec);
                }
                else
                {
                    mensagem('Erro obtendo dados de recebimentos - cliente: '+recebimentos[indrec].id_cli+' (' + dadosret.erro + ')','error',5000, false);
                    setoperexec('A');
                    setaprogresso({processo: false, percentual:0});
                }
            })
            .catch(Error => {
                mensagem('Erro obtendo dados de recebimentos - cliente: '+recebimentos[indrec].id_cli+' acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false)
                setoperexec('A');
                setaprogresso({processo: false, percentual:0});
            });
        }
        else
        {
            FinalizaRecebimentos(indrec);
        }
    }

    const FinalizaRecebimentos = (indrec:number) =>
    {
        if (recebimentos[indrec].carteira_rcbt!=='XXXXXX')
        {
            if (recebimentos[indrec].id_rcbt===0)
            {
                if (recebimentos[indrec].carteira_rcbt && '******,agrupa'.indexOf(recebimentos[indrec].carteira_rcbt)!==-1)
                {
                    recebimentos[indrec].idinterno_rcbt=recebimentos[indrec].vencto_rcbt.getFullYear().toString().padStart(4,'0').substr(2,2)+achavalor(recebimentos[indrec].id_cli,cliobt,'id_cli','loja_cli',0).toString().padStart(6,'0')+'/'+('ABCDEFGHIJLM'.substr(recebimentos[indrec].vencto_rcbt.getMonth(),1));
                }
                else
                {
                    if (recebimentos[indrec].carteira_rcbt && recebimentos[indrec].carteira_rcbt==='BBBBBB')
                    {
                        recebimentos[indrec].idinterno_rcbt=(70000000+recebimentos[indrec].id_strc).toString().trim()+'/'+'AABCDEFJHIJLM'.substr((nulltoNumber(recebimentos[indrec].id_oper)>0 && nulltoNumber(recebimentos[indrec].id_oper)<13 ? nulltoNumber(recebimentos[indrec].id_oper) : 0),1);
                        recebimentos[indrec].id_oper=0;
                        recebimentos[indrec].id_strc=0;
                    }
                    else if(recebimentos[indrec].carteira_rcbt!=='CCCCCC' || recebimentos[indrec].idinterno_rcbt.trim().length<1)
                    {
                        recebimentos[indrec].idinterno_rcbt=(80000000+recebimentos[indrec].id_strc).toString().trim()+'/'+'ABCDEFJHIJLM'.substr(nulltoNumber(recebimentos[indrec].id_oper),1)
                        recebimentos[indrec].id_oper=0;
                        recebimentos[indrec].id_strc=0;
                    }
                }
            }
            if (conta && conta>0)
            {
                let bco: number=parseInt(achavalor(conta,contas,'id_cta','banco_cta',0) as string);
                let agnc: string=(achavalor(conta,contas,'id_cta','agencia_cta',0) as string).trim();
                let cta: string=(achavalor(conta,contas,'id_cta','conta_cta',0) as string).trim();
                let crtr: string=(achavalor(conta,contas,'id_cta','carteira_cta',0) as string).trim();
                recebimentos[indrec].numban_rcbt=NumeroBanco(bco,agnc,cta,crtr,recebimentos[indrec].idinterno_rcbt);
                recebimentos[indrec].id_cta=conta;
                recebimentos[indrec].carteira_rcbt=achavalor(conta,contas,'id_cta','carteira_cta',0) as string;
            }
            else
            {
                recebimentos[indrec].numban_rcbt='';
                recebimentos[indrec].id_cta=0;
                recebimentos[indrec].carteira_rcbt='';
            }
            recebimentos[indrec].multa_rcbt=Math.round((recebimentos[indrec].valor_rcbt*ParametrosDiversos.percmulta))/100;
            recebimentos[indrec].jurosdia_rcbt=Math.round((recebimentos[indrec].valor_rcbt*ParametrosDiversos.percjuros))/100;
            recebimentos[indrec].id_strc=ParametrosDiversos.sitpadrao;
            if(recebimentos[indrec].autorizacao_rcbt && recebimentos[indrec].autorizacao_rcbt.substr(0,1)==='*' && recebimentos[indrec].autorizacao_rcbt.length>1)
            {
                let iddadc=parseInt(recebimentos[indrec].autorizacao_rcbt.substr(1,recebimentos[indrec].autorizacao_rcbt.length-1).trim());
                if(iddadc>0)
                {
                    srv.Get('DadosAdicionais/'+recebimentos[indrec].id_cli.toString()+'/ID_CLI','', '', dadosobt.objac.token, false)
                    .then((ddret: any) => 
                    {
                        let dadosret: Resposta = TrataResposta(ddret);
                        if (dadosret.ok)
                        {
                            let tbadc: Tabelas_Dados_Adicionais[] = dadosret.retorno;
                            let autor=achaDescricao(iddadc,tbadc,'id_dadc','texto_tbad');
                            if(autor && autor.length>0)
                            {
                                recebimentos[indrec].autorizacao_rcbt=CriaAutorizacao(autor,recebimentos[indrec].vencto_rcbt);
                                AcionaProximoRecebimento(indrec);
                            }
                            else
                            {
                                AcionaProximoRecebimento(indrec);
                            }
                        }
                        else
                        {
                            mensagem('Erro obtendo dados adicionais - cliente: '+recebimentos[indrec].id_cli+' (' + dadosret.erro + ')','error',5000, false);
                            setoperexec('A');
                            setaprogresso({processo: false, percentual:0});
                        }
                    })
                    .catch(Error => {
                        mensagem('Erro obtendo dados adicionais - cliente: '+recebimentos[indrec].id_cli+' acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false)
                        setoperexec('A');
                        setaprogresso({processo: false, percentual:0});
                    });
                }
                else
                {
                    AcionaProximoRecebimento(indrec);
                }
            }
            else
            {
                AcionaProximoRecebimento(indrec);
            }
        }
        else
        {
            AcionaProximoRecebimento(indrec);
        }
    }

    const AcionaProximoRecebimento = (indrec: number) =>
    {
        let indcli=achavalor(recebimentos[indrec].id_cli,cliobt,'id_cli','*index*',0) as number;
        tabelarec.push({
            indice: indrec, 
            id_interno: recebimentos[indrec].idinterno_rcbt, 
            vencto_rcbt: recebimentos[indrec].vencto_rcbt,
            valor_rcbt: recebimentos[indrec].valor_rcbt,
            id_cli: recebimentos[indrec].id_cli,
            nome_pessoa: indcli>=0 ? cliobt[indcli].nome_pessoa : '',
            fantasia_pessoa: indcli>=0 ? cliobt[indcli].fantasia_pessoa : '',
            nome_rede: indcli>=0 ? cliobt[indcli].nome_rede : '',
            loja_cli: indcli>=0 ? cliobt[indcli].loja_cli : 0,
            flagedicao: recebimentos[indrec].carteira_rcbt==='XXXXXX' ? 'D' : (recebimentos[indrec].id_rcbt && recebimentos[indrec].id_rcbt !== 0) ? 'C' : ''
        })
        if(recebimentos.length>indrec+1)
        {
            VerificaRecebimentos(indrec+1);
        }
        else
        {
            selecionadas=[];
            setoperexec('D');
            setaprogresso({processo: false, percentual:0});
        }
    }

    const IniciaGravacao = () =>
    {
        if (selecionadas.length>0 && recebimentos.length>0)
        {
            hoje=new Date();
            setoperexec('E');
            setaprogresso({processo: true, percentual:0});
            if(geraremessa && conta && conta>0)
            {
                remessa=[];
                indcta=contas.findIndex(cta => cta.id_cta===conta);
                if (indcta>=0)
                {
                    arqrem=(contas[indcta].banco_cta==='237' ? 'CB' : 'RM')+(hoje.getDate().toString().padStart(2,'0'))+((hoje.getMonth()+1).toString().padStart(2,'0'))+'01';

                    remessa.push(GeraHeader(parseInt(contas[indcta].banco_cta), contas[indcta].identcob_cta, contas[indcta].nome_cta, contas[indcta].seqrem_cta))
                }
            }
            GravaRecebimento(0);
        }
        else
        {
            mensagem('Nenhum registro selecionado para geração de recebimentos','warning',5000, false)
        }
    }

    const GravaRecebimento = (indrec: number) =>
    {
        setcliproc((indrec+1).toString()+'/'+totreg.toString()+' - '+recebimentos[indrec].id_cli.toString()+' - '+RetornaStringData(recebimentos[indrec].vencto_rcbt)+' - '+recebimentos[indrec].valor_rcbt.toString());
        if (indrec % interv===0 || indrec===totreg-1)
        {
            setaprogresso({processo: true, percentual: Math.trunc(((indrec+1)/totreg)*100)});
        }
        if (selecionadas.some(sel => sel===indrec))
        {
            let clrec=cliobt.findIndex(clr => clr.id_cli===recebimentos[indrec].id_cli);
            if(clrec>=0)
            {
                if(geraremessa && conta && conta>0 && indcta>=0)
                {
                    remessa.push(GeraDetalhe(parseInt(contas[indcta].banco_cta),contas[indcta].identben_cta,recebimentos[indrec],cliobt[clrec],remessa.length+1));
                    let ocor=new Ocorrencias_Recebimento()
                    ocor.id_tpor=ParametrosDiversos.idremessa;
                    ocor.id_rcbt=recebimentos[indrec].id_rcbt;
                    ocor.data_ocrc=DataSql(hoje);
                    ocor.id_oper=dadosobt.objac.id_oper;
                    ocor.nomearq_ocrc=arqrem;
                    ocor.tipoarq_ocrc='1';
                    ocor.regbanco_ocrc=remessa.length;
                    ocor.ocorbanco_ocrc='01';
                    recebimentos[indrec].ocorrencias.push(ocor);
                    recebimentos[indrec].id_strc=ParametrosDiversos.sitremessa;
                }
                recebimentos[indrec].id_oper=dadosobt.objac.id_oper;
                srv.Post('Recebimentos/','',JSON.stringify(recebimentos[indrec]),dadosobt.objac.token,false)
                .then((ddret: any) => 
                {
                    let dadosret: Resposta = TrataResposta(ddret);
                    if (!dadosret.ok)
                    {
                        mensagem('Erro gravando recebimento (' + dadosret.erro + ')','error',6000);
                    }
                    else
                    {
                        ChamaProximo(indrec);
                    }
                }).catch(Error => mensagem('Erro gravando recebimento acessando a API (' + Error.name + ' - ' + Error.message + ')', 'error',6000));
            }
        }
        else
        {
            ChamaProximo(indrec);
        }
    }

    const ChamaProximo = (indrec: number) =>
    {
        if (recebimentos.length>(indrec+1))
        {
            GravaRecebimento(indrec+1);
        }
        else
        {
            if (geraremessa && conta && conta>0 && indcta>=0)
            {
                srv.Put('Contas/','',JSON.stringify({id_cta: contas[indcta].id_cta, seqrem_cta: (contas[indcta].seqrem_cta ? contas[indcta].seqrem_cta as number :  0)+1}),dadosobt.objac.token,false)
                .then((ddret: any) => {
                  let dadosret: Resposta = TrataResposta(ddret);
                  if (!dadosret.ok)
                  {
                    mensagem('Erro gravando sequencia de remessa (' + dadosret.erro + ')','error',6000);
                  }
                  else
                  {
                    remessa.push(GeraTrailler(parseInt(contas[indcta].banco_cta),remessa.length+1))
                    downloadarquivo();
                    mensagem('Final de processamento\nSequencia de remessa atualizada','info',10000,true);
                  }
                }).catch(Error => mensagem('Erro acessando a API - atualizando sequencia de remessa (' + Error.name + ' - ' + Error.message + ')', 'error',6000));
            }
            else
            {
                mensagem('Final de processamento','info',10000,true);
            }
        }
    }

    const hdtabrec: Iheaders[] = 
    [
        { titulo: 'Nº', alinhamento:'right', coluna: 'indice', mascara: '' }, 
        { titulo: 'Id interno', alinhamento: 'left', coluna: 'id_interno', mascara: '' },
        { titulo: 'Vencimento', alinhamento: 'center', coluna: 'vencto_rcbt', mascara: 'data' },
        { titulo: 'Valor', alinhamento: 'right', coluna: 'valor_rcbt', mascara: 'decimal' },
        { titulo: 'Id cliente', alinhamento: 'right', coluna: 'id_cli', mascara: '' }, 
        { titulo: 'Cliente', alinhamento: 'left', coluna: 'nome_pessoa', mascara: '' }, 
        { titulo: 'Fantasia', alinhamento: 'left', coluna: 'fantasia_pessoa', mascara: '' }, 
        { titulo: 'Rede', alinhamento: 'left', coluna: 'nome_rede', mascara: '' },
        { titulo: 'Loja', alinhamento: 'right', coluna: 'loja_cli', mascara: '' }, 
        { titulo: '#', alinhamento: 'right', coluna: 'flagedicao', mascara: '' }
    ]
 
    const downloadarquivo = () =>
    {
        var blob = new Blob(remessa, {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob,arqrem);        
    }
  
    return (
        <div>
            <div className={classes.divgrid}>
                <Grid container spacing={1} className={classes.griddados}>
                    {grupo.id_tpgr>0
                    ?
                        <Grid item xs={2}>
                            <Selecao valor={grp}  nome='id_tpgr' label={grupo.desc_tpgr} required={false} habilitado={true} onchange={onChangeRecebimentoSelect} conteudo={RetornaValoresGrupos(grupo.valores)} nulo={[0,'Indefinido']} classe={classes.textoform} />
                        </Grid>
                    :
                    null
                    }
                    <Grid item xs={grupo.id_tpgr>0 ? 2 : 3}>
                        <Selecao valor={rede}  nome='id_rede' label='Rede' required={false} habilitado={true} onchange={onChangeRecebimentoSelect} conteudo={redes} nulo={[0,'Indefinida']} classe={classes.textoform} />
                    </Grid>
                    <Grid item xs={grupo.id_tpgr>0 ? 2 : 3}>
                        <SelecaoMultipla habilitado={true} colunas={{id: 'id', desc: 'desc'}} conteudo={ParametrosDiversos.tiposgeracao} erro={tipoger.length===0} nome='tipoger' onchange={onChangeMultiSel} label='Tipo geração' classe={classes.textoform} valor={tipoger} />
                    </Grid>
                    <Grid item xs={6}>
                        <Periodo capper='Vencimento' camporet='Vencimento' atualiza={AtualizaFiltro} perpad={9} dataspad={filtvenc ? filtvenc : {dtini: undefined, dtfin: undefined}} tipoper='AC' />
                    </Grid>
                    <Grid item xs={3}>
                        <SelecaoAuto valor={clisel} nome='id_cli' label='Clientes' classe='' conteudo={clientes} habilitado={true} colunas={{id: 'id_cli', desc: 'nome_cli'}} onchange={onChangeClientes} multiplo={true}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Selecao valor={conta}  nome='id_cta' label='Conta' required={false} habilitado={true} onchange={onChangeRecebimentoSelect} conteudo={contas} nulo={[0,'Indefinida']} classe={classes.textoform} />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            style={{margin: '-5px 5% 0 1%'}}
                            control={<Switch color={"primary"} checked={agrcnpj} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {setagrcnpj(checked)}} />}
                            label="Agrupar p/ CNPJ"
                        />
                        <FormControlLabel
                            style={{margin: '-10px 5% 0 1%'}}
                            control={<Switch color={"primary"} disabled={conta===0} checked={geraremessa} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {setageraremessa(checked)}} />}
                            label="Gera remessa"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" disabled={'BCE'.indexOf(operexec)!==-1} className={classes.botao1} onClick={(event) => obtemLancamentos(event)} endIcon={<PageviewIcon/>}>Obtem Lançamentos</Button>
                        <Button variant="contained" color="primary" disabled={operexec!=='D'} className={classes.botao2} onClick={IniciaGravacao} endIcon={<SaveIcon/>}>Gera Recebimentos</Button>
                    </Grid>
                    {progresso.processo ?
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Box minWidth={35} margin='20px 0 0 10px'>
                                    <Typography color='primary'>{operexec==='B' ? 'Obtendo lançamentos' : operexec==='C' ? 'Ajustando recebimentos' : operexec==='E' ? 'Gravando recebimentos' : ''}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box minWidth={35} margin='20px 0 0 10px'>
                                    <Typography color='primary'>{cliproc}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <ProgressoEtiqueta value={progresso.percentual} tipo='B' />
                            </Grid>
                        </Grid>
                        :
                        null
                    }
                    <Grid item xs={12}>
                        <TabelaBase linhasoriginais={'DE'.indexOf(operexec)!==-1 ? tabelarec : []} cab={hdtabrec} selecao={true} edicao={false} adicao={false} habfiltro={false} onClickEdicao={(val1, val2) => {}} titulo={'Recebimentos'} full={true} linhasiniciais={15} exclusao={false} auxedit={1} auxexclui={2} onSelect={AlteraSelecionadas} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}  

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  openToast: boolean,
  severidadeToast: string,
  mensagemToast: string,
  duracaoToast: number,
  acessosok: boolean,
}

class GeracaoRecebimentos extends Component<PropsType, IState>
{
    private _dadosobt: RetornoAcesso;
    private _roteador: Roteador;
    private _dadoobtido: boolean;
    private _acessos: number;
    private _contas: Contas[];
    private _grupos: Tipos_Grupos[];
    private _redes: Redes[];
    private _parametros: Parametros[];
    private _situacoes: Situacoes_Recebimentos[];
    private _servicos: Servicos[];
    private _retornamensagem: boolean;
    private _clientes: {id_cli: number, nome_cli: string} [];
    
    constructor(props: any)
    {
        super(props);

        this._dadosobt=new RetornoAcesso();

        if (props.location.state)
        {
            if(props.location.state.dadosret)
            {
                this._dadosobt=props.location.state.dadosret;
            }
        }

        if (!this._dadosobt.ok)
        {
            this.props.history.push({pathname: '/'})
        }

        this._roteador=new Roteador(this._dadosobt, '/GeracaoRecebimentos');
        this._roteador.history=props.history;
        this._dadoobtido=false;
        this._contas = [];
        this._clientes = [];
        this._grupos = [];
        this._redes = [];
        this._situacoes = [];
        this._parametros = [];
        this._servicos = [];
        this._retornamensagem=false;
    
        this._acessos=0;
    
        if (this._dadosobt.ok)
        {
            this._dadoobtido=true;
        }
            this.state =
            {
                dadoobtido: this._dadoobtido,
                openToast: false,
                severidadeToast: 'success',
                mensagemToast: '',
                duracaoToast: 6000,
                acessosok: false,
            }
        if (this._dadosobt.ok)
        {
            this.ObtemDados('Contas/','contas',this.setacontas.bind(this));
            this.ObtemDados('TiposGrupos/','grupos',this.setagrupos.bind(this));
            this.ObtemDados('Redes/','redes',this.setaredes.bind(this));
            this.ObtemDados('Situacoesrecebimentos/','situações de recebimentos',this.setasituacoes.bind(this));
            this.ObtemDados('Servicos/','serviços',this.setaservicos.bind(this));
            this.ObtemDados('Parametros/','parâmetros',this.setaparametros.bind(this));
            this.ObtemDados('Clientes/0/0','clientes',this.setaclientes.bind(this));
        }
    }
    
    setacontas(registros: any) {this._contas=registros; this.aumentaAcessos();};
    setagrupos(registros: any) {this._grupos=registros; this.aumentaAcessos();};
    setaredes(registros: any) {this._redes=registros; this.aumentaAcessos();};
    setasituacoes(registros: any) {this._situacoes=registros; this.aumentaAcessos();};
    setaservicos(registros: any) {this._servicos=registros; this.aumentaAcessos();}
    setaclientes(registros: any) {this._clientes=RetornaClientes(registros); this.aumentaAcessos();};
    setaparametros(parobt: Parametros[]) 
    {
        this._parametros = parobt;
        this.aumentaAcessos();
    };

    aumentaAcessos()
    {
        this._acessos = this._acessos+1
        if(this._acessos>6)
        {
            AlteraTiposGeracao(this._parametros);
            AlteraPadroesRecebimento(this._parametros);
            this._grupos = this._grupos.filter(grp => grp.id_tpgr===ParametrosDiversos.idgrfin);
            this.setState({acessosok: true});
        }
    }
        
    ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
    {
        srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
        let dadosret:Resposta = TrataResposta(ddret);
        if(dadosret.ok)
        {
            obret(dadosret.retorno)
        }
        else
        {
            this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
            this.aumentaAcessos();
        }
        })
        .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
    }

    ExibeMensagem(mensagem: string, severidade: string, duracao:number, retorna:boolean=false)
    {
        this.setState({openToast: true, severidadeToast: severidade, mensagemToast: mensagem, duracaoToast: duracao})
        this._retornamensagem=retorna;
    }

    FechaMensagem()
    {
        this.setState({openToast: false});
        if (this._retornamensagem)
        {
          this._roteador.rota(0,0);
        }
    }

    render()
    {
        if (!this._dadosobt.ok)
        {
        return null
        }
        else
        {
            return (
            <div className="Home">
                <header className="Home-header">
                <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador} recurso='Geração de Recebimentos'/>
                </header>
                <main>
                { this.state.acessosok ?
                    <PrincipalGeracaoRecebimentos dadosobt={this._dadosobt} grupo={this._grupos.length>0 ? this._grupos[0] : new Tipos_Grupos()} contas={this._contas} redes={this._redes} situacoes={this._situacoes} clientes={this._clientes} servicos={this._servicos} mensagem={this.ExibeMensagem.bind(this)}/>
                    :
                    <Progresso/>
                }
                </main>
                <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
            </div>
            )
        }
    }
}

export default withRouter(GeracaoRecebimentos)
