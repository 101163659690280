import React,{ Component, ReactNode } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import { Roteador } from '../Servicos/Roteador';
import { achavalor, DifSegundos, Modulo11, RegistraFontes, Resposta, RetiraAcentos, RetornaClientes, RetornaFornecedores, TrataResposta } from '../Servicos/Utilidades';
import { Tooltip, Typography } from '@material-ui/core';
import Dashboard from '../Componentes/Dashboard';
import { Dashboard_Operador } from '../Modelos/Dashboard_Operador';
import { Dashboard as Dash } from '../Modelos/Dashboard';
import DashPagamentos from '../Componentes/DashPagamentos';
import DashDespesas from '../Componentes/DashDespesas';
import DashResultados from '../Componentes/DashResultados';
import DashInadimplencia from '../Componentes/DashInadimplencia';
import Indicador from '../Componentes/Indicador';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Tipos_Filtros_Dashboard } from '../Modelos/Tipos_Filtros_Dashboard';
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import ReactPlayer from 'react-player';

RegistraFontes();

const srv = new ApiService();
const percutil: number = 0.87;
const posicoes: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

type PropsType = RouteComponentProps;

interface IState {
  fimniver: boolean,
  altind: string,
  qtquadros: number,
  openToast: boolean,
  severidadeToast: string,
  mensagemToast: string,
  duracaoToast: number,
  acessosok: boolean,
}

class Home extends Component<PropsType, IState>
{
  [x: string]: any;
  private _dadosobt: RetornoAcesso;
  private _inicial: Date;
  private _idinterval: number;
  private _altura: number;
  private _acessos: number;
  private _clientes: {id_cli: number, nome_cli: string} [];
  private _fornecedores: {id_forn: number, nome_forn: string}[];
  private _tiposfiltros: Tipos_Filtros_Dashboard[];
  private _dash: Dash[];
  private _resetind: boolean;
  private _atudash: ((atudash: Dashboard_Operador) => void) | undefined;
  private _redesenha: ((novodash: Dashboard_Operador[]) => void) | undefined;
  public _dashedit: Dashboard_Operador;
  private _vazios: number[];
  private _segundos: number

  public roteador: Roteador;


  constructor(props: any)
  {

    super(props);

    this._dadosobt=new RetornoAcesso();
    this._inicial=new Date();
    this._idinterval=-1;
    this._acessos=0;
    this._clientes=[];
    this._fornecedores = [];
    this._tiposfiltros = [];
    this._dash = [];
    this._atudash = undefined;
    this._redesenha = undefined;
    this._dashedit = new Dashboard_Operador();
    this._vazios = [];
    this._resetind = false;
    this._segundos = 0;

    if (props.location.state)
    {
      if(props.location.state.dadosret)
      {
        this._dadosobt=props.location.state.dadosret;
      }
    }
    if (!this._dadosobt.ok)
    {
      this.props.history.push({pathname: '/'})
    }
    this.roteador=new Roteador(this._dadosobt, '/Home');
    this.roteador.history=props.history;
    this.state =
    {
      fimniver: false,
      altind: 'A',
      qtquadros: 4,
      openToast: false,
      severidadeToast: '',
      mensagemToast: '',
      duracaoToast: 0,
      acessosok: false
    }
    this._altura=600
    if (window)
    {
      if (window.innerHeight)
      {
          this._altura=(window.innerHeight);
      }
      if (this._dadosobt.aniversario)
      {
        this._idinterval=window.setInterval(this.atualizahora.bind(this),1000)
      }
    }
    this._altura=Math.round(this._altura*percutil);
    this.ObtemDados('Clientes/0/0','clientes',this.setaclientes.bind(this));
    this.ObtemDados('Fornecedores','fornecedores',this.setafornecedores.bind(this));
    this.ObtemDados('Dashboard','indicadores',this.setadash.bind(this));
    this.ObtemDados('Dashboard/TiposFiltros','tipos de filtros de indicadores',this.setafiltros.bind(this));
    //let mgr = new MigracaoDados(this._dadosobt.objac.token);
    //mgr.migraend(0);
  }
  
  setaclientes(registros: any) {this._clientes=RetornaClientes(registros); this.aumentaAcessos();};
  setafornecedores(registros: any) {this._fornecedores=RetornaFornecedores(registros); this.aumentaAcessos();};
  setafiltros(registros: any) {this._tiposfiltros=registros; this.aumentaAcessos();};
  setadash(registros: Dash[]) {this._dash=registros.filter(dsh => this._dadosobt.objac.funcoes.some(fnc => fnc.id_rcrs===dsh.id_rcrs)); this.aumentaAcessos();};

  aumentaAcessos()
  {
    this._acessos = this._acessos+1
    if(this._acessos>14)
    {
      this.setState({acessosok: true });
    }
  }

  ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
  {
    srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let dadosret:Resposta = TrataResposta(ddret);
      if(dadosret.ok)
      {
        obret(dadosret.retorno)
      }
      else
      {
        this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
        this.aumentaAcessos();
      }
    })
    .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
  }

  editadash(dsedit: Dashboard_Operador, vazios: number [], atudash: (atudash: Dashboard_Operador) => void, exclusao: boolean)
  {
    this._dashedit=dsedit;
    this._vazios=vazios;
    this._resetind=true;
    this.setState({altind: exclusao ? 'C' : 'B'});
    this._atudash = atudash;
  }

  incluidash(quadro: number, posicao: number, vazios: number [])
  {
    let posrel: number=((quadro-1)*4)+posicao
    if (posrel>0 && posrel<=posicoes.length)
    {
      this._dashedit=new Dashboard_Operador();
      this._dashedit.posicao_dsbo=posicoes.substr(posrel-1,1)
      this._dashedit.id_oper=this._dadosobt.objac.id_oper;
      this._vazios=vazios;
      this._resetind=true;
      this.setState({altind: 'B'});
    }
  }

  excluidash(dsexclui: Dashboard_Operador)
  {
    let novodash = this._dadosobt.dashboard.filter(dash => dash.id_dsbo!==dsexclui.id_dsbo)
    this._dadosobt.dashboard=novodash;
    this.roteador.dadosacesso.dashboard=this._dadosobt.dashboard;
    if (this._redesenha)
    {
      this._redesenha(novodash);
    }
    this._dashedit=new Dashboard_Operador();
    this.setState({ altind: 'A'}); 
  }

  resetIndicador():boolean
  {
    let retorno:boolean = this._resetind;
    this._resetind=false;
    return retorno;
  }

  retornoindicador(botaoretorno: string, dash: Dashboard_Operador) 
  { 
    let novo: boolean = false
    if ('AB'.indexOf(botaoretorno)!==-1)
    {
      let inddsbo: number = achavalor(dash.id_dsbo,this._dadosobt.dashboard,'id_dsbo','*index*',0) as number;
      if (inddsbo>=0)
      {
        this._dadosobt.dashboard[inddsbo]=dash;
      }
      else
      {
        novo=true;
        this._dadosobt.dashboard.push(dash);
      }
      this.roteador.dadosacesso.dashboard=this._dadosobt.dashboard;
      if (this._atudash && !novo)
      {
        this._atudash(dash);
      }
      else if(this._redesenha)
      {
        this._redesenha(this._dadosobt.dashboard);
      }
      this._dashedit=dash;
      this.setState({ altind: 'A'}); 
    }
    else if('DE'.indexOf(botaoretorno)!==-1)
    {
      this.excluidash(dash);
    }
    else
    {
      this.setState({altind: 'A'});
    }
  } 

  obtemredesenha(retredes: (novodash: Dashboard_Operador[]) => void)
  {
    this._redesenha=retredes;
  }

  retornacomponente(quadro: number,  posicao: number, vazios: number[], dashoper?: Dashboard_Operador): ReactNode
  {
    let retorno: ReactNode =
        (<Tooltip title='Clique aqui para adicionar um indicador ao dashboard'>
          <AddBoxTwoToneIcon style={{width: '100%', alignSelf:'center', margin: Math.round(this._altura*0.25*0.4).toString().trim()+'px 0 0 0', cursor: 'pointer', color: '#B0E2FF' }} onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => this.incluidash(quadro, posicao, vazios)} />
        </Tooltip>);
    if (dashoper)
    {
      switch (dashoper.id_dsb)
      {
        case 1:
        {
          retorno=<DashPagamentos dadosacesso={this._dadosobt} altura={this._altura} quadro={{numero: quadro, posicao: posicao, vazios: vazios}} dashoper={dashoper} edita={this.editadash.bind(this)}/>
          break;
        }
        case 2:
        {
          retorno=<DashDespesas dadosacesso={this._dadosobt} altura={this._altura} quadro={{numero: quadro, posicao: posicao, vazios: vazios}} dashoper={dashoper} edita={this.editadash.bind(this)}/>
          break;
        }
        case 3:
        {
          retorno=<DashInadimplencia dadosacesso={this._dadosobt} altura={this._altura} quadro={{numero: quadro, posicao: posicao, vazios: vazios}} dashoper={dashoper} edita={this.editadash.bind(this)}/>
          break;
        }
        case 4:
        {
          retorno=<DashResultados dadosacesso={this._dadosobt} altura={this._altura} quadro={{numero: quadro, posicao: posicao, vazios: vazios}} dashoper={dashoper} edita={this.editadash.bind(this)}/>
          break;
        }
        default:
        {
          retorno=(<Tooltip title='Indicador não identificado - Clique aqui para adicionar um indicador válido'>
            <HelpTwoToneIcon style={{width: '100%', alignSelf:'center', margin: Math.round(this._altura*0.25*0.4).toString().trim()+'px 0 0 0', cursor: 'pointer', color: '#B0E2FF' }} onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => this.incluidash(quadro, posicao, vazios)} />
          </Tooltip>);
        }
      }
    }
    return retorno;
  }

  atualizahora()
  {
    let dif=DifSegundos(this._inicial, new Date());
    this._segundos=dif;
    if (dif>120 && this._idinterval>=0)
    {
      window.clearInterval(this._idinterval);
      this.setState({fimniver: true});
      this._idinterval=-1;
    }
  }

  FechaMensagem()
  {
      this.setState({openToast: false});
  }

  componentWillUnmount() {
    if (this._idinterval>=0)
    {
      window.clearInterval(this._idinterval);
      this._idinterval=-1;
    }
  }

  render()
  {
    if (!this._dadosobt.ok)
    {
      return null
    }
    else
    {
      return (
        <div className="Home">
          <header className="Home-header">
            <BarraLateral dadosacesso={this._dadosobt} botaoclick={this.roteador.rota.bind(this.roteador)} clasrot={this.roteador}/>
          </header>
          <main>
              {this._dadosobt.aniversario && !this.state.fimniver ?
                <div style={{margin: '5% 0 0 0'}}>
                  <Typography align='center' color='secondary' variant='h1'>{'Feliz aniversário, ' + (this._dadosobt.fantasia ? this._dadosobt.fantasia : this._dadosobt.nome)}</Typography>
                  <ReactPlayer
                    style={{margin: '5% 30% 0 30%'}}
                    controls={true}
                    playing={true}
                    url={this._dadosobt.objac.id_oper===6 ? 'Videos\\mickey.mp4' : 'Videos\\nivis.mp4'}
                    width='40%'
                    height='40%'
                    onEnded={() => this.setState({fimniver: true})}
                  />
                </div>
              :
                <React.Fragment>
                  <Indicador aberta={this.state.altind !=='A'} fecha={this.retornoindicador.bind(this)} tipos={this._tiposfiltros} dash={this._dash} clientes={this._clientes} fornecedores={this._fornecedores} dashoper={this._dashedit} dadosacesso={this._dadosobt} vazios={this._vazios} reset={this.resetIndicador.bind(this)} exclusao={this.state.altind==='C'} />
                  <Dashboard altura={this._altura} dashboard={this._dadosobt.dashboard} qtquadros={this.state.qtquadros} conteudoiten={this.retornacomponente.bind(this)} retornaredesenha={this.obtemredesenha.bind(this)} />
                </ React.Fragment>
              }
          </main>
          <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
        </div>
      );
    }
  }
}

export default withRouter(Home)
