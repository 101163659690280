import {Pessoas} from '../Modelos/Pessoas';
import {Tabelas_Dados_Adicionais} from '../Modelos/Tabelas_Dados_Adicionais';
import { Operadores_Funcoes_Recursos } from './Operadores_Funcoes_Recursos';
import { Dashboard_Operador } from './Dashboard_Operador';

export class Operadores
{
    id_oper: number = 0;
    login_oper: string = '';
    pessoa: Pessoas = new Pessoas();
    id_perf: number = 0;
    senha_oper: string = '';
    inativo_oper: string = '';
    datacad_oper: Date = new Date();
    dados_adicionais: Tabelas_Dados_Adicionais[] = [];
    funcoes_recursos: Operadores_Funcoes_Recursos[] = [];
    dashboards: Dashboard_Operador[] = [];
    gravasenha: boolean = true;
}