import { DadosConexao } from '../Servicos/Utilidades';
import { Dashboard_Operador } from './Dashboard_Operador';
import {Funcoes_Recursos} from './FuncoesRecursos';
import {Recursos} from './Recursos';

export class VerificaResetSenha
{
    datahora: string ='';
    id_oper: number = 0;
}

export class SolicitaResetSenha
{
    nome: string = '';
    email: string = '';
    link: string = '';
}

export class SolicitaAcesso
{
    id_oper: number = 0
    login_oper: string = ''
    senha_oper: string = ''
    novasenha_oper: string = ''
    reset: boolean = false

    constructor (idoper: number, loginoper: string, senhaoper: string)
    {
        this.id_oper=idoper;
        this.login_oper=loginoper;
        this.senha_oper=senhaoper;
    }

}
export class RetornoAcesso
{
    aniversario: boolean = false;
    nome: string = '';
    id_pessoa: number = 0;
    fantasia: string = '';
    foto?: Uint32Array = undefined;
    ok: boolean = false;
    erro: string = '';
    menu: MenuAcesso[] = [];
    dashboard: Dashboard_Operador[] = []; 
    objac: ObjetoAcesso = new ObjetoAcesso();
    dadosconexao?: DadosConexao;
}
export class ObjetoAcesso
{
    id_oper: number = 0;
    datahora: Date = new Date();
    tipoacesso_perf: string = '';
    funcoes: Funcoes_Recursos[] = [];
    token = '';
    valid: Date = new Date();
    roteador: any;
}
export class MenuAcesso
{
    id_grrc: number = 0;
    desc_grrc: string = '';
    recursos: Recursos[] = [];
}

export class TitulosCobranca
{
    id_rcbt: number = 0;
    loja: string = '';
    id: string = '';
    vencimento: string = '';
    valor: string = '';
    linha: string = '';
}

export class EmailCobranca
{
    id_cli: number = 0;
    id_oper: number = 0;
    titulos: TitulosCobranca[] = [];
}

export class Pontualidade
{
    idinterno_rcbt: string = '';
    vencto_rcbt: Date = new Date();
    pagto_rcbt: Date = new Date();
}

export class TitulosAbertos
{
    id_rcbt: number = 0;
    idinterno_rcbt: string = '';
    vencto_rcbt: Date = new Date();
    valor_rcbt: number = 0;
    jurosdia_rcbt: number = 0;
    multa_rcbt: number = 0;
    desconto_rcbt: number = 0;
    datadesc_rcbt: Date = new Date();
}

export class DetalheResultado
{
    docto: string = '';
    clifor: number = 0;
    nome: string = '';
    vencimento?: Date = undefined;
    pagamento?: Date = undefined;
    valor: number = 0;
    idrd: number = 0;
    operador: number = 0;
    nomeoper: string = '';
    flcli: string = '';
    cliloja: number = 0;
}
export class Resultado
{
    flagrd: string = '';
    idrd: number = 0;
    descricao: string = '';
    valortot: number = 0;
    detalhes: DetalheResultado[] = [];
    aberta: boolean = false;
}
export class SubgrupoResultado
{
    flagrd: string = '';
    idsg: number = 0;
    descricao: string = '';
    valortot: number = 0;
    resultados: Resultado[] = [];
}
export class GrupoResultado
{
    flagrd: string = '';
    idgr: number = 0;
    descricao: string = '';
    valortot: number = 0;
    subgrupos: SubgrupoResultado[] = [];
    aberta: boolean = false;
}

