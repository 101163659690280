import { RetornoAcesso } from '../Modelos/ObjetosDiversos';

export class Roteador
{
    private _ultrecurso: string;
    public dadosacesso: RetornoAcesso;
    public history: any;

    constructor(acesso: RetornoAcesso, recchama: string)
    {
        this._ultrecurso=recchama;
        this.dadosacesso=acesso
    }

    rota(recurso: number, id_edicao: number = 0)
    {
        let dadosret=this.dadosacesso;
        switch (recurso)
        {
            case 0:
                this.history.push({pathname: '/Home', state: {dadosret}})
                this._ultrecurso='/Home';
                break;
            case 1:
                this.history.push({pathname: '/Operadores', state: {dadosret}})
                this._ultrecurso='/Operadores';
                break;
            case 2:
                this.history.push({pathname: '/Clientes', state: {dadosret}})
                this._ultrecurso='/Clientes';
                break;
            case 3:
                this.history.push({pathname: '/Recebimentos', state: {dadosret}})
                this._ultrecurso='/Recebimentos';
                break;
            case 4:
                this.history.push({pathname: '/Pagamentos', state: {dadosret}})
                this._ultrecurso='/Pagamentos';
                break;
            case 5:
                this.history.push({pathname: '/GeracaoRecebimentos', state: {dadosret}})
                this._ultrecurso='/GeracaoRecebimentos';
                break;
            case 6:
                this.history.push({pathname: '/RetornoBancos', state: {dadosret}})
                this._ultrecurso='/RetornoBancos';
                break;
            case 7:
                this.history.push({pathname: '/TiposContratos', state: {dadosret}})
                this._ultrecurso='/TiposContratos';
                break;
            case 8:
                this.history.push({pathname: '/GeracaoRemessas', state: {dadosret}})
                this._ultrecurso='/GeracaoRemessas';
                break;
            case 9:
                this.history.push({pathname: '/Fornecedores', state: {dadosret}})
                this._ultrecurso='/Fornecedores';
                break;
            case 10:
                this.history.push({pathname: '/GeracaoRPS', state: {dadosret}})
                this._ultrecurso='/GeracaoRPS';
                break;
            case 11:
                this.history.push({pathname: '/Vendedores', state: {dadosret}})
                this._ultrecurso='/Vendedores';
                break;
            case 12:
                this.history.push({pathname: '/Comissoes', state: {dadosret}})
                this._ultrecurso='/Comissoes';
                break;
            case 13:
                this.history.push({pathname: '/CadastroRedes', state: {dadosret}})
                this._ultrecurso='/CadastroRedes';
                break;
            case 14:
                this.history.push({pathname: '/Resultados', state: {dadosret}})
                this._ultrecurso='/Resultados';
                break;
            case 993:
                this.history.push({pathname: '/EdicaoVendedores', state: {dadosret, id_edicao}})
                this._ultrecurso='/EdicaoVendedores';
                break;
            case 994:
                this.history.push({pathname: '/EdicaoOperadores', state: {dadosret, id_edicao}})
                this._ultrecurso='/EdicaoOperadores';
                break;
            case 995:
                this.history.push({pathname: '/EdicaoPagamentos', state: {dadosret, id_edicao}})
                this._ultrecurso='/EdicaoPagamentos';
                break;
            case 996:
                this.history.push({pathname: '/EdicaoFornecedores', state: {dadosret, id_edicao}})
                this._ultrecurso='/EdicaoFornecedores';
                break;
            case 997:
                this.history.push({pathname: '/EdicaoRecebimentos', state: {dadosret, id_edicao}})
                this._ultrecurso='/EdicaoRecebimentos';
                break;
            case 998:
                this.history.push({pathname: '/EdicaoClientes', state: {dadosret, id_edicao}})
                this._ultrecurso='/EdicaoClientes';
                break;
            case 999:
                dadosret = new RetornoAcesso();
                if (this._ultrecurso)
                {
                    this.history.push({pathname: this._ultrecurso, state: {dadosret}});
                }
                this.history.push({pathname: '/'});
                this._ultrecurso=''
                break;
        }
    }
}

