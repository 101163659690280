import React,{ Component, RefObject, useRef } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Button, FormControlLabel, Switch, Accordion, AccordionSummary, Typography, AccordionDetails, Checkbox, Tooltip } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EmailIcon from '@material-ui/icons/Email';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CampoTexto from '../Componentes/CampoTexto';
import CampoDecimal from '../Componentes/CampoDecimal';
import CampoMascara from '../Componentes/CampoMascara';
import Selecao from '../Componentes/Selecao';
import { arrayBufferToBase64, VerificaCpfCnpj, formataDecimal, Resposta, ValidaDadosAdicionais, BotaoDialogo, TrataResposta, TabPanel, a11yProps, ChaveApiGoogle, AtualizaDadosAdicionais, AtualizaDocumentos, RetornaValoresDadosAdicionais, achavalor, VerificaCpf, DataSql } from '../Servicos/Utilidades';
import { Pessoa_Documentos } from '../Modelos/Pessoa_Documentos';
import { Endereco_Pessoas } from '../Modelos/Endereco_Pessoas';
import { Dados_Adicionais } from '../Modelos/Dados_Adicionais';
import { Documentos } from '../Modelos/Documentos';
import { Emails } from '../Modelos/Emails';
import { Telefones } from '../Modelos/Telefones';
import { Pessoas } from '../Modelos/Pessoas';
import AlertDialog from '../Componentes/Dialogo';
import Progresso from '../Componentes/Progresso';
import Geocode from "react-geocode";
import EnderecosComp from '../Componentes/Enderecos';
import { Operadores } from '../Modelos/Operadores';
import EmailsTelefones from '../Componentes/EmailsTelefones';
import { StyledAppBar } from '../Componentes/Customizados';
import { Recursos } from '../Modelos/Recursos';
import { Tipos_Endereco } from '../Modelos/Tipos_Endereco';
import { Ufs } from '../Modelos/Ufs';
import { Perfis } from '../Modelos/Perfis';
import { Grupos_Recursos } from '../Modelos/Grupos_Recursos';
import { Funcoes_Recursos } from '../Modelos/FuncoesRecursos';
import { Operadores_Funcoes_Recursos } from '../Modelos/Operadores_Funcoes_Recursos';
import CampoData from '../Componentes/CampoData';

Geocode.setApiKey(ChaveApiGoogle);
Geocode.setLanguage('pr-BR');
Geocode.setRegion('br');
 
const srv=new ApiService();

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%'
    },
    foto: {
      float: 'right',
      widht: '80%',
    },
    divgrid: {
        width: '90%',
        margin: '5% 7% 0'
    },
    divtabs: {
        width: '90%',
        margin: '0% 7% 0'
    },
    gridcont: {
        margin: '5px 0 0 0'
    },
    fotocliente: {
        width: "98%",
        maxHeight: "207px",
        minHeight: "207px",
        margin: "1% 1% 0 0",
        boxShadow: "3px 3px 1px lightblue"
    },
    fotoinput: {
        display: "none"
    },
    griddados: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridcontrato: {
        width: "60%",
        margin: "0 0 0 0"
    },
    gridfoto: {
        width: "20%",
        margin: "0 0 0 0"
    },
    gridtabend: {
        width: "100%",
        margin: "0 0 0 0"
    },
    gridtabemail: {
        width: "50%",
        margin: "0 0 0 0"
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    obsform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white',
        minHeight: '300%'
    },
    padrao: {
        margin: "0% 0%",
        width: "100%",
        padding: '0 0 0 0'
    },
    botao: {
        margin: '5% 5%',
        width: '90%'
    },
    botaopdf: {
        margin: '0 2',
        width: '100%'
    },
    pdfcontrato: {
        width: "98%",
        maxHeight: "400px",
        minHeight: "400px",
        margin: "1% 1% 0 0",
        boxShadow: "2px 2px 5px lightgrey"
    },
    pdfnulo: {
        width: "98%",
        margin: "1% 1% 0 0",
        boxShadow: "2px 2px 5px lightgrey"
    },
  }),
);

let ultcpfcnpj: string = '';
let ultlogin: string = '';
let titulodialogo: string;
let botoesdialogo: BotaoDialogo[] = [];
let textodialogo: string[] = [];
let enddigatu: Endereco_Pessoas = new Endereco_Pessoas();
let indendatu: number = 0;
let emaildigatu: Emails = new Emails();
let fonedigatu: Telefones = new Telefones();
let indemailatu: number = 0;
let indfoneatu: number = 0;

let sted = {
    enderecos: false,
    emails: false,
    telefones: false,
};

function InicializaEdicao() 
{
    sted = {
        enderecos: false,
        emails: false,
        telefones: false,
    };
    enddigatu = new Endereco_Pessoas();
    indendatu = 0;
    emaildigatu = new Emails();
    fonedigatu = new Telefones();
    indemailatu = 0;
    indfoneatu = 0;
}

const AchaFuncaorecurso = (props: {operador: Operadores, chave: number, funcrec: Funcoes_Recursos, perfis: Perfis[], funcmuda: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined}) =>
{   
    const {operador, chave, funcrec, perfis, funcmuda} = props;
    let retval='C';
    let accesperf='Perfil';
    let rgrn = operador.funcoes_recursos.findIndex(fnrc => fnrc.id_fnrc===funcrec.id_fnrc);
    let corperf= 'blue';
    if (rgrn>=0)
    {
        retval=operador.funcoes_recursos[rgrn].acesso_opfr;
    }
    if (retval==='C')
    {
        rgrn = perfis.findIndex(prf => prf.id_perf===operador.id_perf);
        if (rgrn>=0)
        {
            let fnpr = perfis[rgrn].funcoes.findIndex(fp => fp===funcrec.id_fnrc);
            if(fnpr>=0)
            {
                corperf='lightgreen';
                accesperf='Acesso permitido pelo perfil';
            }
            else
            {
                corperf='pink';
                accesperf='Acesso não permitido pelo perfil';
            }
        }
    }
    return(
        <Grid item key={chave} xs={12}>
        <FormControlLabel 
            control={
                <Tooltip title={retval==='A' ? 'Acesso Permitido' : retval==='B' ? 'Acesso não permitido' : accesperf}>
                    <Checkbox style={{color: retval==='A' ? 'green' : retval==='B' ? 'red' : corperf}} 
                              onChange={funcmuda}
                              checked={retval === 'A'} 
                              name={funcrec.id_fnrc.toString()} 
                              indeterminate={retval==='C'}/>
                </Tooltip>}
            label={funcrec.desc_fnrc} />
        </Grid>
    )
}

type PrincipalProps=
{
    dadosobt: RetornoAcesso
    dadosoper: Operadores
    documentos: Documentos[]
    dadosadicionais: Dados_Adicionais[]
    tiposenderecos: Tipos_Endereco []
    ufs: Ufs []
    recursos: Grupos_Recursos[]
    perfis: Perfis[]
    mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void
    roteador: (recurso: number, id_edicao: number) => void
}

const PrincipalEdicaoOperadores = ({dadosobt, dadosoper, documentos, dadosadicionais, tiposenderecos, ufs, recursos, perfis, mensagem, roteador }: PrincipalProps) =>
{
    const classes = useStyles();
    const [tabValue, settabValue] = React.useState(0);
    const [operador,setOperador] = React.useState(dadosoper);
    const [confsenha,setConfsenha] = React.useState(dadosoper.senha_oper);
    const [ddadcdig, setddadcDig] = React.useState(dadosadicionais);
    const [doctodig, setdoctoDig] = React.useState(documentos);
    const [dialogo, setDialog] = React.useState(false);

    const AtuEnderecos = (enderecos: Endereco_Pessoas[]) => {setOperador({...operador, pessoa: {...operador.pessoa, enderecos: enderecos}}); sted.enderecos=false};
    const AtuEnder = (ender: Endereco_Pessoas, altdados: boolean) => {enddigatu=ender; if(altdados){sted.enderecos=true}};
    const AtuIndEnder = (indender: number) => {indendatu=indender};
    const AtuEmails = (emails: Emails[]) => {setOperador({...operador, pessoa: {...operador.pessoa, emails: emails}}); sted.emails=false};
    const AtuEmail = (email: Emails, altdados: boolean) => {emaildigatu=email; if(altdados){sted.emails=true}};
    const AtuIndEmail = (indemail: number) => {indemailatu=indemail};
    const AtuFones = (fones: Telefones[]) => {setOperador({...operador, pessoa: {...operador.pessoa, telefones: fones}}); sted.telefones=false};
    const AtuFone = (fone: Telefones, altdados: boolean) => {fonedigatu=fone; if(altdados){sted.telefones=true}};
    const AtuIndFone = (indfn: number) => {indfoneatu=indfn};
    const AtuMensagem = (msg: string) => {mensagem(msg,'error',6000,false)};

    const fechaDialogo = (retorno: string) => 
    {
        setDialog(false);
        if (retorno==='A')
        {
            salvaOperador();
        }
    }

    let inddoc: number = operador.pessoa.documentos.findIndex(doc => doc.id_doc===2 );
    if(inddoc<0)
    {
        let docatu: Pessoa_Documentos[] = [];
        docatu.concat(operador.pessoa.documentos);
        let novodoc=new Pessoa_Documentos();
        novodoc.id_pessoa=operador.pessoa.id_pessoa;
        novodoc.id_doc=2;
        novodoc.valor_psdc='';
        docatu.push(novodoc);
        setOperador({...operador, pessoa: {...operador.pessoa, documentos: docatu}});
        inddoc=docatu.length-1;
    }

    const inputFileFoto: RefObject<HTMLInputElement> = useRef(null) ;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (newValue===1 && documentos.length>0 && doctodig.length===0)
        {
            setdoctoDig(documentos);
        }
        if (newValue===3 && dadosadicionais.length>0 && ddadcdig.length===0)
        {
            setddadcDig(dadosadicionais);
        }
        settabValue(newValue);
    };

    const onButtonClickFoto = (event:any) => 
    {
        if(event.ctrlKey)
        {
            setOperador({...operador, pessoa: {...operador.pessoa, gravafoto: true, foto_pessoa: undefined}});
        }
        else
        {
            if (inputFileFoto.current)
            {
                inputFileFoto.current.click()
            }
        }
    };

    const onChangeFoto = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    {
        const filereader = new FileReader();
        const doc: any = document.getElementById("filefoto");
        if(doc)
        {
            if(doc.files)
            {
                if(doc.files.length>0)
                {
                    filereader.readAsArrayBuffer(doc.files[0]);
                }
            }
        }
        filereader.onloadend = function()
        {
            let result: any = filereader.result
            setOperador({...operador, pessoa: {...operador.pessoa, gravafoto: true, foto_pessoa: arrayBufferToBase64(result)}});
        };
    }

    const onblurCpfCnpj = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
    {
        let docatu: Pessoa_Documentos[] = operador.pessoa.documentos;
        if (event.target.value.length===11 && docatu[inddoc].id_doc===1)
        {
            docatu[inddoc].id_doc=2;
            setOperador({...operador, pessoa: {...operador.pessoa, documentos: docatu}});
        }
        else if(event.target.value.length===14 && docatu[inddoc].id_doc===2)
        {
            docatu[inddoc].id_doc=1;
            setOperador({...operador, pessoa: {...operador.pessoa, documentos: docatu}});
        }
        if(ultcpfcnpj!==operador.pessoa.documentos[inddoc].valor_psdc && VerificaCpfCnpj(operador.pessoa.documentos[inddoc].valor_psdc))
        {
            srv.Get('Pessoas/'+operador.pessoa.documentos[inddoc].valor_psdc+'/'+operador.id_oper.toString()+'/A' , '', '', dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (!dadosret.ok)
                {
                    mensagem(dadosret.erro,'error',6000);
                    let cpocpfcnpj = document.getElementById('cnpj_pessoa');
                    if(cpocpfcnpj)
                    {
                        let docatu: Pessoa_Documentos[] = operador.pessoa.documentos;
                        docatu[inddoc].valor_psdc=ultcpfcnpj;
                        setOperador({...operador, pessoa: {...operador.pessoa, documentos: docatu}});
                        cpocpfcnpj.focus();
                    }
                }
                else
                {
                    if (dadosret.retorno)
                    {
                        if(dadosret.retorno.id_pessoa)
                        {
                            let novapes: Pessoas = dadosret.retorno;
                            dadosadicionais=AtualizaDadosAdicionais(dadosadicionais, novapes.dados_adicionais ? novapes.dados_adicionais : [],'B');
                            documentos=AtualizaDocumentos(documentos, novapes.documentos ? novapes.documentos : []);
                            setOperador({...operador, pessoa: novapes});
                            setddadcDig(dadosadicionais);
                            setdoctoDig(documentos);
                            mensagem('Informações atualizadas a partir de cadastro existente','info',7000);
                        }
                    }
                }
            })
            .catch(Error => mensagem('Erro obtendo dados de pessoas acessando a API (' + Error.name + ' - ' + Error.message + ')','error',7000));
        }
    }

    const onfocusCpfCnpj = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
    {
        ultcpfcnpj=event.target.value;
    }

    const retornaLogin = () =>
    {
        let cpologin = document.getElementById('login_oper');
        if(cpologin)
        {
            setOperador({...operador, login_oper: ultlogin});
            cpologin.focus();
        }
    }

    const onblurLogin = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
    {
        if(ultlogin!==operador.login_oper)
        {
            srv.Get('Operadores/'+operador.login_oper.trim()+'/login' , '', '', dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (!dadosret.ok)
                {
                    mensagem(dadosret.erro,'error',6000);
                    retornaLogin();
                }
                else
                {
                    if (dadosret.retorno)
                    {
                        if((dadosret.retorno.id || dadosret.retorno.id===0) && (dadosret.retorno.adicional || dadosret.retorno.adicional.length===0))
                        {
                            if (dadosret.retorno.id!==operador.id_oper && dadosret.retorno.id!==0)
                            {
                                mensagem('Atenção: Login atualmente em uso (operador: '+dadosret.retorno.adicional+')','error',6000);
                                retornaLogin();
                            }
                        }
                        else
                        {
                            mensagem('Atenção: Erro verificando login no banco de dados','error',6000);
                            retornaLogin();
                        }
                    }
                    else
                    {
                        mensagem('Atenção: Erro verificando login no banco de dados','error',6000);
                        retornaLogin();
                    }
                }
            })
            .catch(Error => {mensagem('Erro obtendo dados de operadores acessando a API (' + Error.name + ' - ' + Error.message + ')','error',7000); retornaLogin();});
        }
    }

    const onfocusLogin = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
    {
        ultlogin=event.target.value;
    }

    const onChangePessoaInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
    {
        setOperador({...operador, pessoa: {...operador.pessoa, [event.target.id]: propratu}});
    }
    const onChangePessoaDataInput = (date: any, valor: string | null | undefined) =>
    {
      if (date)
      {
        setOperador({...operador, pessoa: {...operador.pessoa, aniversario_pessoa: date.getDate().toString().padStart(2,'0')+(date.getMonth()+1).toString().padStart(2,'0'), nascimento_pessoa: date }});
      }
      else
      {
        setOperador({...operador, pessoa: {...operador.pessoa, nascimento_pessoa: date}});
      }
    }

    const onChangeOperador = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode, campo?:string) =>
    {
        if (campo)
        {
            if (typeof event.target.value==='string' || typeof event.target.value==='number')
            {
                if (campo==='inativo_oper' || campo==='senha_oper' || campo==='login_oper')
                {
                    setOperador({...operador, [campo]: event.target.value});
                }
                else if (campo==='id_perf')
                {
                    setOperador({...operador, id_perf: typeof event.target.value==='string' ? parseInt(event.target.value.toString()) : event.target.value});
                }
                else if (campo==='confsenha')
                {
                    setConfsenha(event.target.value.toString());
                }
            }
        }
    }
  
    const onChangeDadosAdicionaisSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) =>
    {
        if (event.target.name)
        {
            let valor = typeof event.target.value === 'string' && event.target.value ? parseInt(event.target.value) : 0;
            let novoddadc: Dados_Adicionais[]=[]
            novoddadc=novoddadc.concat(ddadcdig);
            let inddado=parseInt(event.target.name)
            if (inddado>=0 && inddado<novoddadc.length)
            {
                novoddadc[inddado].id_vdad=valor;
                setddadcDig(novoddadc);
            }
        }
    }

    const onChangeDadosAdicionaisInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
    {
        if (event.target.id && typeof propratu==='string')
        {
            let novoddadc: Dados_Adicionais[]=[]
            novoddadc=novoddadc.concat(ddadcdig);
            let inddado=parseInt(event.target.id)
            if (inddado>=0 && inddado<novoddadc.length)
            {
                if (novoddadc[inddado].tipo_dadc==='A')
                {
                    novoddadc[inddado].texto_tbad=propratu;
                }
                else
                {
                    if(novoddadc[inddado].mascara_dadc==='decimal')
                    {
                        novoddadc[inddado].numero_tbad=parseFloat(formataDecimal(propratu))
                    }
                    else
                    {
                        novoddadc[inddado].numero_tbad=parseInt(propratu)
                    }
                }
                setddadcDig(novoddadc);
            }
        }
    }

    const onChangeDocumentosInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
    {
        if (event.target.id && typeof propratu==='string')
        {
            let novodoc: Documentos[]=[]
            novodoc=novodoc.concat(doctodig);
            let inddado=parseInt(event.target.id)
            if (inddado>=0 && inddado<novodoc.length)
            {
                novodoc[inddado].valor_psdc=propratu;
                setdoctoDig(novodoc);
            }
        }
    }

    const onChangeCpfCnpj = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
    {
        let docatu: Pessoa_Documentos[] = operador.pessoa.documentos;
        docatu[inddoc].valor_psdc=propratu.toString();
        setOperador({...operador, pessoa: {...operador.pessoa, documentos: docatu}});
    }

    const Desiste = () =>
    {
        roteador(1,0);
    }

    const mudaFuncao = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
    {
        if(event)
        {
            if(event.target)
            {
                if(event.target.name)
                {
                    let fnalt=parseInt(event.target.name);
                    if (fnalt)
                    {
                        let retval='C';
                        let rgrn = operador.funcoes_recursos.findIndex(fnrc => fnrc.id_fnrc===fnalt);
                        if (rgrn>=0)
                        {
                            retval=operador.funcoes_recursos[rgrn].acesso_opfr;
                        }
                        let novafun:Operadores_Funcoes_Recursos[]=[]
                        novafun=novafun.concat(operador.funcoes_recursos);
                        if(retval==='A')
                        {
                            novafun[rgrn].acesso_opfr='B';
                        }
                        else if(retval==='B')
                        {
                            novafun[rgrn].id_fnrc=0;
                        }
                        else if(rgrn>=0)
                        {
                            novafun[rgrn].acesso_opfr='A';
                        }
                        else
                        {
                            let novoref = new Operadores_Funcoes_Recursos();
                            novoref.id_opfr=0;
                            novoref.id_oper=operador.id_oper;
                            novoref.id_fnrc=fnalt;
                            novoref.acesso_opfr='A';
                            novafun=novafun.concat(novoref);
                        }
                        setOperador({...operador, funcoes_recursos: novafun})
                    }
                }
            }
        }
    }

    const GravaOperador = () =>
    {
        let errogrv: string = '';
        if (!VerificaCpfCnpj(operador.pessoa.documentos[inddoc].valor_psdc))
        {
            errogrv = errogrv +'CPF/CNPJ incorreto \n' ;
        }
        if (operador.pessoa.nome_pessoa.trim().length<1 || operador.inativo_oper.trim().length<0)
        {
            errogrv = errogrv + 'Verifique os dados cadastrais \n';
        }
        if (!operador.pessoa.enderecos.some(endereco => (!endereco.alterado) || endereco.alterado==='' || endereco.alterado!=='B'))
        {
            errogrv = errogrv + 'Nenhum endereço foi informado \n';
        }
        if (!operador.pessoa.emails.some(email => (!email.alterado) || email.alterado==='' || email.alterado!=='B'))
        {
            errogrv = errogrv + 'Nenhum e-mail foi informado \n';
        }
        if (!operador.pessoa.telefones.some(telefone => (!telefone.alterado) || telefone.alterado==='' || telefone.alterado!=='B'))
        {
            errogrv = errogrv + 'Nenhum telefone foi informado \n';
        }
        if (documentos.some(doc => doc.id_doc>2 && doc.obrig_doc==='A' && (!doc.valor_psdc || doc.valor_psdc.trim().length<1)))
        {
            errogrv = errogrv + 'É necessário informar todos os documentos obrigatórios \n'
        }
        if (dadosadicionais.some(dado => !ValidaDadosAdicionais(dado)))
        {
            errogrv = errogrv + 'Verifique os dados adicionais \n'
        }
        if (errogrv.length>0)
        {
            mensagem(errogrv,'error',10000);
        }
        else
        {
            textodialogo=[]
            if (sted.enderecos)
            {
                textodialogo.push('Endereços');
            }
            if (sted.emails)
            {
                textodialogo.push('E-mails');
            }
            if (sted.telefones)
            {
                textodialogo.push('Telefones');
            }
            if (textodialogo.length>0)
            {
                botoesdialogo=[{descricao: 'Confirma', retorno: 'A'},{descricao: 'Cancela', retorno: 'B'}]
                titulodialogo='Há informações alteradas e não confirmadas nas seguintes áreas:'
                textodialogo.push('Confirma a operação?');
                setDialog(true)
            }
            else
            {
                salvaOperador();
            }
        }
    }

    const salvaOperador = () =>
    {
        let opgrv: Operadores = JSON.parse(JSON.stringify(operador));
        if (operador.pessoa.nascimento_pessoa)
        {
            opgrv.pessoa.nascimento_pessoa=DataSql(operador.pessoa.nascimento_pessoa);
        }
        opgrv.pessoa.documentos.forEach((documento,index) => {if((documento.id_doc === 1 || documento.id_doc ===2) && index!==inddoc){documento.valor_psdc=''}})
        doctodig.forEach(docto => 
        {
            if(docto.id_doc>2)
            {
                let inddc=opgrv.pessoa.documentos.findIndex(dct => dct.id_doc===docto.id_doc);
                if (inddc!==-1)
                {
                    opgrv.pessoa.documentos[inddc].valor_psdc=docto.valor_psdc;
                }
                else
                {
                    if(docto.valor_psdc && docto.valor_psdc.trim().length>0)
                    {
                        opgrv.pessoa.documentos.push({id_psdc:0, id_doc:docto.id_doc, id_pessoa: operador.pessoa.id_pessoa, valor_psdc:docto.valor_psdc, id_uf:0});
                    }
                }
            }
        })
        opgrv.pessoa.enderecos.forEach(endereco => {if(endereco.alterado==='B') {endereco.endereco.logr_end='';}});
        opgrv.pessoa.emails.forEach(email => {if(email.alterado==='B') {email.endereco_email='';}});
        opgrv.pessoa.telefones.forEach(telefone => {if(telefone.alterado==='B') {telefone.numero_fone='';}});
        ddadcdig.forEach(ddad => 
        {
            let inddc=-1;
            if (ddad.comportamento_dadc==='B')
            {
                inddc=opgrv.pessoa.dados_adicionais.findIndex(dad => dad.id_dadc===ddad.id_dadc);
            }
            else
            {
                inddc=opgrv.dados_adicionais.findIndex(dad => dad.id_dadc===ddad.id_dadc);
            }
            if (inddc!==-1)
            {
                if (ddad.comportamento_dadc==='B')
                {
                    opgrv.pessoa.dados_adicionais[inddc].id_vdad=ddad.id_vdad;
                    opgrv.pessoa.dados_adicionais[inddc].numero_tbad=ddad.numero_tbad;
                    opgrv.pessoa.dados_adicionais[inddc].texto_tbad=ddad.texto_tbad;
                }
                else
                {
                    opgrv.dados_adicionais[inddc].id_vdad=ddad.id_vdad;
                    opgrv.dados_adicionais[inddc].numero_tbad=ddad.numero_tbad;
                    opgrv.dados_adicionais[inddc].texto_tbad=ddad.texto_tbad;
                }
            }
            else
            {
                if((ddad.id_vdad && ddad.id_vdad>0) || ddad.numero_tbad || (ddad.texto_tbad))
                    {
                    if (ddad.comportamento_dadc==='B')
                    {
                        opgrv.pessoa.dados_adicionais.push({id_tbad:0, id_dadc: ddad.id_dadc, id_pessoa: operador.pessoa.id_pessoa, id_vdad: ddad.id_vdad, numero_tbad: ddad.numero_tbad, texto_tbad: ddad.texto_tbad, id_cli:0, id_forn:0, id_oper:0, id_vend:0, alterado:''});
                    }
                    else
                    {
                        opgrv.dados_adicionais.push({id_tbad:0, id_dadc: ddad.id_dadc, id_cli: 0, id_vdad: ddad.id_vdad, numero_tbad: ddad.numero_tbad, texto_tbad: ddad.texto_tbad, id_pessoa:0, id_oper: operador.id_oper, id_forn:0, id_vend:0, alterado:''});
                    }
                }
            }
        });

        if(achavalor(opgrv.id_perf,perfis,'id_perf','tipoacesso_perf',1)==='A')
        {
            opgrv.funcoes_recursos.forEach(fnrc => fnrc.id_fnrc=0);
        }

        srv.Post('Operadores/','',JSON.stringify(opgrv),dadosobt.objac.token,false)
        .then((ddret: any) => 
        {
            let dadosret: Resposta = TrataResposta(ddret);
            if (!dadosret.ok)
            {
                mensagem(dadosret.erro,'error',6000)
            }
            else
            {
                mensagem('Operador: '+dadosret.retorno.id.toString()+' salvo com sucesso','info',10000, true);
            }
        })
        .catch(Error => {mensagem('Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', 'error',6000);})
    }

    return (
        <div>
            <div className={classes.divgrid}>
                <AlertDialog aberta={dialogo} fecha={fechaDialogo} titulo={titulodialogo} texto={textodialogo} botoes={botoesdialogo}/>
                <Grid container spacing={1}>
                    <Grid item className={classes.griddados}>
                        <Grid className={classes.gridcont} container spacing={1}>
                            <Grid item xs={1}>
                                <CampoTexto valor={operador.id_oper>0 ? operador.id_oper : 0}  id='id_oper' label='Id' placeholder='Id do operador' classe={classes.textoform} tipo='number' required={false} habilitado={false} />
                            </Grid>
                            <Grid item xs={2}>
                                <CampoTexto valor={operador.pessoa.documentos[inddoc] ? operador.pessoa.documentos[inddoc].valor_psdc : ''}  id='cnpj_pessoa' label='CPF' placeholder='CPF do operador' classe={classes.textoform} tipo='text' required={true} habilitado={true} onchange={onChangeCpfCnpj} onblur={onblurCpfCnpj} onfocus={onfocusCpfCnpj} erro={!operador.pessoa.documentos[inddoc] || !VerificaCpf(operador.pessoa.documentos[inddoc].valor_psdc)} tamanho={11}/>
                            </Grid>
                            <Grid item xs={1}>
                                <CampoTexto valor={operador.login_oper ? operador.login_oper : ''}  id='login_oper' label='Login' placeholder='login do operador' classe={classes.textoform} tipo='text' required={true} habilitado={true} onchange={onChangeOperador} onblur={onblurLogin} onfocus={onfocusLogin} erro={!operador.login_oper || operador.login_oper.length<1} tamanho={20}/>
                            </Grid>
                            <Grid item xs={4}>
                                <CampoTexto valor={operador.pessoa.nome_pessoa}  id='nome_pessoa' label='Nome ou Razão Social' placeholder='Nome ou Razão Social do operador' classe={classes.textoform} tipo='text' required={true} habilitado={true} onchange={onChangePessoaInput} erro={!operador.pessoa.nome_pessoa || operador.pessoa.nome_pessoa.trim().length<1} tamanho={60}/>
                            </Grid>
                            <Grid item xs={2}>
                                <CampoTexto valor={operador.pessoa.fantasia_pessoa}  id='fantasia_pessoa' label='Apelido' placeholder='Apelido' classe={classes.textoform} tipo='text' required={false} habilitado={true} onchange={onChangePessoaInput} tamanho={50}/>
                            </Grid>
                            <Grid item xs={2}>
                                <Selecao valor={operador.inativo_oper}  nome='inativo_oper' label='Ativo' required={true} habilitado={true} onchange={onChangeOperador} conteudo={[{valor: 'A', descricao: 'Sim'},{valor: 'B', descricao: 'Não'}]} nulo={[' ','Indefinido']} classe={classes.textoform} erro={operador.inativo_oper===' '}/>
                            </Grid>
                            <Grid item xs={2}>
                                <CampoData valor={operador.pessoa.nascimento_pessoa} id='nascimento_pessoa' label='Nascimento' placeholder='Nascimento' classe={classes.textoform} required={false} habilitado={true} onchange={onChangePessoaDataInput} limpadata={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => setOperador({...operador, pessoa: {...operador.pessoa, nascimento_pessoa: undefined}})}/>
                            </Grid>
                            <Grid item xs={2}>
                                <CampoMascara valor={operador.pessoa.aniversario_pessoa} id='aniversario_pessoa' label='Aniversario' placeholder='Aniversário' classe={classes.textoform} mascara='##/##' required={false} habilitado={true} onchange={onChangePessoaInput}/>
                            </Grid>
                            <Grid item xs={2}>
                                <Selecao valor={operador.id_perf}  nome='id_perf' label='Perfil' required={true} habilitado={true} onchange={onChangeOperador} conteudo={perfis} nulo={[0,'Indefinido']} classe={classes.textoform} erro={operador.id_perf===0}/>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel style={{width: '80%', margin: '7px 10% 0 10%'}}
                                    control={<Switch color={"primary"} disabled={operador.id_oper===0} checked={operador.gravasenha} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {if (checked) {setOperador({...operador, gravasenha: checked})} else {setOperador({...operador, gravasenha: checked, senha_oper:''}); setConfsenha('');}}} />}
                                    label="Altera Senha"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <CampoTexto valor={operador.senha_oper}  id='senha_oper' label='Senha' placeholder='Senha do operador' classe={classes.textoform} tipo='password' required={operador.gravasenha} habilitado={operador.gravasenha} onchange={onChangeOperador} erro={(!operador.senha_oper || operador.senha_oper.length<1) && operador.gravasenha}/>
                            </Grid>
                            <Grid item xs={2}>
                                <CampoTexto valor={confsenha}  id='confsenha' label='Repita a senha' placeholder='Repira a senha' classe={classes.textoform} tipo='password' required={operador.gravasenha} habilitado={operador.gravasenha} onchange={onChangeOperador} erro={(!confsenha || confsenha.length<1 || !operador.senha_oper || confsenha!==operador.senha_oper) && operador.gravasenha}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CampoTexto valor={operador.pessoa.obs_pessoa} id='obs_pessoa' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={true} onchange={onChangePessoaInput} multi={true} qtdlinhas={2} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.gridfoto}>
                        <input type='file' id='filefoto' accept='image/*' ref={inputFileFoto} className={classes.fotoinput} onChange={event => {onChangeFoto(event)}}/> 
                        <img id='foto_pessoa' alt='fotooperador' src={operador.pessoa.foto_pessoa && operador.pessoa.foto_pessoa.length>0 ? 'data:image/jpeg;base64, ' + operador.pessoa.foto_pessoa : 'Imagens/maquinafotografica.png'}  className={classes.fotocliente} onClick={event => onButtonClickFoto(event)} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.divtabs}>
                <StyledAppBar position="static">
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        variant='scrollable'
                        scrollButtons="auto"
                        aria-label="scrollable prevent tabs example"
                    >
                        <Tab label={'Endereços'} icon={<HomeIcon />} aria-label="enderecos" {...a11yProps(0)} />
                        <Tab label={'Documentos'} icon={<AssignmentIndIcon/>} aria-label="documentos" {...a11yProps(1)} />
                        <Tab label={'E-mails/Telefones'} icon={<EmailIcon />} aria-label="emails" {...a11yProps(2)} />
                        <Tab label={'Adicionais'} icon={<PostAddIcon />} aria-label="adicionais" {...a11yProps(3)} />
                        {achavalor(operador.id_perf, perfis , 'id_perf', 'tipoacesso_perf',1)==='B' ?
                            <Tab label={'Acessos'} icon={<VpnKeyIcon />} aria-label="acessos" {...a11yProps(4)} />
                        :
                            null
                        }
                    </Tabs>
                </StyledAppBar>
                <TabPanel value={tabValue} index={0}>
                    <EnderecosComp ender={enddigatu} indender={indendatu} enderecos={operador.pessoa.enderecos} retindender={AtuIndEnder} retenderecos={AtuEnderecos} retender={AtuEnder} retmsg={AtuMensagem} acessos={true} ufs={ufs} tipos={tiposenderecos} nomepessoa={operador.pessoa.fantasia_pessoa ? operador.pessoa.fantasia_pessoa : ''} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={1}>
                    <Grid item className={classes.griddados}>
                        <Grid container spacing={1}>
                        {doctodig.map((doct: Documentos, index) =>
                            doct.id_doc>2
                            ?
                            <Grid item key={index+1} xs={3}>
                                <CampoTexto valor={doct.valor_psdc} id={index.toString()} label={doct.desc_doc} placeholder={doct.desc_doc} classe={classes.textoform} tipo='text' required={doct.obrig_doc==='A'} habilitado={true} onchange={onChangeDocumentosInput} erro={(!doct.valor_psdc || doct.valor_psdc.length<1) && doct.obrig_doc==='A'} tamanho={40}/>
                            </Grid>
                            :
                            null
                            )
                        }
                        </Grid>
                    </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <EmailsTelefones indem={indemailatu} indfn={indfoneatu} email={emaildigatu} fone={fonedigatu} emails={operador.pessoa.emails} fones={operador.pessoa.telefones} retemail={AtuEmail} retemails={AtuEmails} retindemail={AtuIndEmail} retfone={AtuFone} retfones={AtuFones} retindfone={AtuIndFone} retmsg={AtuMensagem} acessos={true}/>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <Grid container spacing={1}>
                        <Grid item className={classes.gridtabend}>
                            <Grid container spacing={1}>
                                {ddadcdig.map((ddadc: Dados_Adicionais, index) =>
                                    <Grid item key={index+1} xs={3}>
                                    {
                                        ddadc.tipo_dadc === 'C'
                                        ?
                                        <Selecao valor={!ddadc.id_vdad ? 0 : ddadc.id_vdad} nome={index.toString()} label={ddadc.desc_dadc} required={ddadc.opcional_dadc !=='A'} habilitado={true} onchange={onChangeDadosAdicionaisSelect} conteudo={RetornaValoresDadosAdicionais(ddadc.valores)} nulo={[0,'Indefinido']} classe={classes.textoform} erro={!ValidaDadosAdicionais(ddadc)}/>
                                        :
                                        ddadc.tipo_dadc === 'B' 
                                        ?
                                        ddadc.mascara_dadc==='decimal'
                                        ?
                                        <CampoDecimal valor={ddadc.numero_tbad ? ddadc.numero_tbad : 0}  id={index.toString()} label={ddadc.desc_dadc} placeholder={ddadc.desc_dadc} classe={classes.textoform} tipo='text' required={false} habilitado={true} onchange={onChangeDadosAdicionaisInput} erro={!ValidaDadosAdicionais(ddadc)}/>
                                        :
                                        <CampoMascara valor={ddadc.numero_tbad ? ddadc.numero_tbad : 0} id={index.toString()} label={ddadc.desc_dadc} placeholder={ddadc.desc_dadc} classe={classes.textoform} required={false} habilitado={true} mascara={ddadc.mascara_dadc} onchange={onChangeDadosAdicionaisInput} erro={!ValidaDadosAdicionais(ddadc)}/>
                                        :
                                        ddadc.tipo_dadc === 'A'
                                        ?
                                        <CampoTexto valor={ddadc.texto_tbad ? ddadc.texto_tbad : ''} id={index.toString()} label={ddadc.desc_dadc} placeholder={ddadc.desc_dadc} classe={classes.textoform} tipo='text' required={ddadc.opcional_dadc!=='A'} habilitado={true} onchange={onChangeDadosAdicionaisInput} erro={!ValidaDadosAdicionais(ddadc)} tamanho={ddadc.tamanho_dadc && ddadc.tamanho_dadc>0 ? ddadc.tamanho_dadc : 1000}/>
                                        :
                                        null
                                    }
                                    </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                    <Grid container spacing={1} className={classes.gridtabend}>
                        {recursos.map((grrc: Grupos_Recursos, idxgr) =>
                            <Grid item key={idxgr+1} xs={4}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                    <Typography color='primary' variant='h6'>{grrc.desc_grrc.trim()}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} className={classes.gridtabend}>
                                            {grrc.recursos.map((rcrs: Recursos, idxrc) => 
                                                <Grid item key={(idxgr+1*100)+idxrc} xs={12}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                        >
                                                        <Typography color='primary'>{rcrs.desc_rcrs.trim()}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                        <Grid container spacing={1} className={classes.gridtabend}>
                                                            {rcrs.funcoes.map((fncs: Funcoes_Recursos, idxfn) => 
                                                                <AchaFuncaorecurso key={fncs.id_fnrc} operador={operador} chave={((((idxgr+1)*100)+idxrc)*100)+idxfn} funcrec={fncs} funcmuda={mudaFuncao} perfis={perfis} />
                                                            )}
                                                        </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                             )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )}
                    </Grid>
                </TabPanel>
                <Grid container spacing={1}>
                    <Grid item className={classes.gridtabend}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={GravaOperador} endIcon={<SaveIcon/>}>Confirma</Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={Desiste} endIcon={<CancelIcon/>}>Desiste</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
    dadoobtido: boolean,
    openToast: boolean,
    severidadeToast: string,
    mensagemToast: string,
    duracaoToast: number,
    acessosok: boolean,
}

class EdicaoOperadores extends Component<PropsType, IState>
{
    private _dadosobt: RetornoAcesso;
    private _roteador: Roteador;
    private _id_oper: number;
    private _dadosadicionais: Dados_Adicionais[];
    private _documentos: Documentos[];
    private _tiposendereco: Tipos_Endereco[];
    private _ufs: Ufs[];
    private _recursos: Grupos_Recursos[];
    private _perfis: Perfis[];
    private _operador: Operadores;
    private _dadoobtido: boolean;
    private _acessos: number;
    private _retornamensagem: boolean;
  
  constructor(props: any)
  {
        super(props);
        this._dadosobt=new RetornoAcesso();
        this._id_oper=0;

        if (props.location.state)
        {
            if(props.location.state.dadosret)
            {
                this._dadosobt=props.location.state.dadosret;
            }
            if (props.location.state.id_edicao)
            {
                this._id_oper=props.location.state.id_edicao;
            }
        }

        if (!this._dadosobt.ok)
        {
            this.props.history.push({pathname: '/'})
        }

        this._roteador=new Roteador(this._dadosobt, '/Operadores');
        this._roteador.history=props.history;
        this._dadosadicionais=[];
        this._documentos=[];
        this._tiposendereco=[];
        this._ufs=[];
        this._recursos=[];
        this._perfis=[];
        this._operador = new Operadores();
        this._dadoobtido=false;
        this._acessos=0;
        this._retornamensagem=false;
    
        if (this._id_oper<0 && this._dadosobt.ok)
        {
            this._dadoobtido=true;
        }
        this.state =
        {
            dadoobtido: this._dadoobtido,
            openToast: false,
            severidadeToast: 'success',
            mensagemToast: '',
            duracaoToast: 6000,
            acessosok: false,
        }
        if (this._dadosobt.ok)
        {
            if (this._id_oper>0)
            {
                srv.Get('Operadores/'+this._id_oper.toString().trim(), '', '', this._dadosobt.objac.token, false)
                .then((ddret: any) => 
                {
                    let dadosret: Resposta = TrataResposta(ddret);
                    if (dadosret.ok)
                    {
                        this._operador=dadosret.retorno;
                        this.aumentaAcessos();
                    }
                    else
                    {
                        this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo operador (' + dadosret.erro + ')' });
                    }
                })
                .catch(Error => this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo operador (' + Error.name + ' - ' + Error.message + ')' }));
            }
            else
            {
                this.aumentaAcessos();
            }
            this.ObtemDados('DadosAdicionais/BC','dados adicionais',this.setadadosadicionais.bind(this));
            this.ObtemDados('Documentos/','documentos',this.setadocumentos.bind(this));
            this.ObtemDados('TiposEndereco/','tipos de endereço',this.setatiposendereco.bind(this));
            this.ObtemDados('Ufs/','UFs',this.setaufs.bind(this));
            this.ObtemDados('Recursos/','recursos',this.setarecursos.bind(this));
            this.ObtemDados('Perfis/','perfis',this.setaperfis.bind(this));
        }
    }
    
    setadadosadicionais(registros: any) {this._dadosadicionais=registros; this.aumentaAcessos();};
    setadocumentos(registros: any) {this._documentos=registros; this.aumentaAcessos();};
    setatiposendereco(registros: any) {this._tiposendereco=registros; this.aumentaAcessos();};
    setaufs(registros: any) {this._ufs=registros; this.aumentaAcessos();};
    setarecursos(registros: any) {this._recursos=registros; this.aumentaAcessos();};
    setaperfis(registros: any) {this._perfis=registros; this.aumentaAcessos();};
  
    aumentaAcessos()
    {
        this._acessos = this._acessos+1
        if(this._acessos>6)
        {
            this._documentos = AtualizaDocumentos(this._documentos, this._operador.pessoa.documentos);
            this._dadosadicionais = AtualizaDadosAdicionais(this._dadosadicionais, this._operador.dados_adicionais, 'C');
            this._dadosadicionais = AtualizaDadosAdicionais(this._dadosadicionais, this._operador.pessoa.dados_adicionais, 'B');
            InicializaEdicao();
            this.setState({acessosok: true, dadoobtido: true });
        }
    }
        
    ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
    {
        srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
            let dadosret:Resposta = TrataResposta(ddret);
            if(dadosret.ok)
            {
                obret(dadosret.retorno)
            }
            else
            {
                this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
                this.aumentaAcessos();
            }
        })
        .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
    }

    ExibeMensagem(mensagem: string, severidade: string, duracao:number, retorna:boolean=false)
    {
        this.setState({openToast: true, severidadeToast: severidade, mensagemToast: mensagem, duracaoToast: duracao})
        this._retornamensagem=retorna;
    }

    FechaMensagem()
    {
        this.setState({openToast: false});
        if (this._retornamensagem)
        {
            this._roteador.rota(1,0);
        }
    }

    render()
    {
        if (!this._dadosobt.ok)
        {
            return null
        }
        else
        {
            return (
                <div className="Home">
                    <header className="Home-header">
                        <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador} recurso='Edição de Operador'/>
                    </header>
                    <main>
                    { this.state.dadoobtido ?
                        <PrincipalEdicaoOperadores dadosoper={this._operador} dadosobt={this._dadosobt} dadosadicionais={this._dadosadicionais} documentos={this._documentos} tiposenderecos={this._tiposendereco} ufs={this._ufs} recursos={this._recursos} perfis={this._perfis} mensagem={this.ExibeMensagem.bind(this)} roteador={this._roteador.rota.bind(this._roteador)} />
                        :
                        <Progresso/>
                    }
                    </main>
                    <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
                </div>
            )
        }
    }
}

export default withRouter(EdicaoOperadores)
