import React,{ ChangeEvent, Component } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import TabelaBase from '../Componentes/TabelaBase';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { Iheaders, TrataResposta, Resposta, nulltoNumber } from '../Servicos/Utilidades';
import { Accordion, AccordionDetails, AccordionSummary, Button, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { Redes } from '../Modelos/Redes';
import { Situacoes_Clientes } from '../Modelos/Situacoes_Clientes';
import { Tipos_Grupos } from '../Modelos/Tipos_Grupos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FilterListIcon from '@material-ui/icons/FilterList'
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';
import SelecaoAuto from '../Componentes/SelecaoAuto';
import Selecao from '../Componentes/Selecao';
import Progresso from '../Componentes/Progresso';

const useStyles = makeStyles((theme: Theme) => createStyles({
  divgrid: {
    width: '90%',
    margin: '5% 7% 0'
  },
  botao: {
    margin: '2% 2% 2% 2%',
    width: '90%'
  },
  textoform: {
    margin: "0% 0%",
    width: "100%",
    background: 'white'
  }
}));

let vlgrini: {indgrp:number,vlgrp:number[]}[] = [];
let atvini: string = 'A';
let stclini: number[] = [];
let redeini: number = 0;
let abrtini: boolean = true;
let pesqvlgr: string = ' ';


const AreaFiltros = ({redes, situacoes, tpgrupos, atudados}: {redes: Redes[], situacoes: Situacoes_Clientes[], tpgrupos: Tipos_Grupos[], atudados: (idrd: number, atv: string, idsc: string, idvg: string, atualiza: boolean) => void  }) =>
{
 
  const classes = useStyles();
  const [rede, setRede]=React.useState(redeini);
  const [stcl, setStcl]=React.useState<number[]>(stclini);
  const [vlgr, setVlgr]=React.useState<{indgrp:number,vlgrp:number[]}[]>(vlgrini);
  const [atv, setAtv]=React.useState(atvini);
  const [aberto, setAberto]=React.useState(abrtini);

  const ClickFiltro = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
  {
    atudados(rede, atv, stcl.length>0 ? stcl.join('-') : ' ',  RetValoresGrupos(), true);
  }

  const onChangeAutoComp = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined, campo?: string) =>
  {
    if(campo && value)
    {
      if (value.length!==undefined)
      {
        let novoval:number[]=[];
        value.forEach((vl:any) => novoval.push(vl[campo==='id_stcl' ? 'id_stcl' : 'id_vlgr']));
        if (campo==='id_stcl')
        {
          stclini=novoval;
          setStcl(novoval);
        }
        else
        {
          Atuvlgr(campo,novoval)
        }
      }
      else
      {
        if (campo==='id_stcl')
        {
          stclini=[value[campo]];
          setStcl([value[campo]]);
        }
        else
        {
          Atuvlgr(campo,[value[campo]]);
        }
      }
    }
    else
    {
      if(campo)
      {
        if (campo==='id_stcl')
        {
          stclini=[];
          setStcl([]);
        }
        else
        {
          Atuvlgr(campo,[]);
        }
      }
    }
  }

  const Atuvlgr = (campo: string, novoval: number[]) =>
  {
    let idgrp: number = nulltoNumber(parseInt(campo));
    if (idgrp>0)
    {
      let novovlgr: {indgrp:number,vlgrp:number[]}[] = [];
      novovlgr=novovlgr.concat(vlgr);
      let indvlgr = novovlgr.findIndex(vg => vg.indgrp===idgrp)
      if (indvlgr>=0)
      {
        novovlgr[indvlgr].vlgrp=novoval;
      }
      else
      {
        novovlgr.push({indgrp: idgrp, vlgrp: novoval});
      }
      vlgrini=novovlgr;
      setVlgr(novovlgr);
    }
  }

  const Retvlgr = (idgrp: number): number[] =>
  {
    let ret: number[] = [];
    if (idgrp>0)
    {
      let indvlgr = vlgr.findIndex(vg => vg.indgrp===idgrp)
      if(indvlgr>=0)
      {
        ret=vlgr[indvlgr].vlgrp;
      }
    }
    return ret;
  }

  const RetValoresGrupos = (): string =>
  {
    let ret: number[] = []
    vlgr.forEach(vl => ret=ret.concat(vl.vlgrp));
    pesqvlgr=(ret.length>0 ? ret.join('-') : ' ');
    return pesqvlgr;
  }

  const onChangeFiltroClientesSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode, campo?: string | undefined) =>
  {
    if(campo && event.target.value)
    {
      if (campo==='id_rede')
      {
        let valor:number = 0;
        valor = typeof event.target.value === 'string' ? parseInt(event.target.value) : typeof event.target.value === 'number' ? event.target.value : 0; 
        redeini=valor;
        setRede(valor);
      }
      else
      {
        if (typeof event.target.value === 'string')
        {
          atvini=event.target.value
          setAtv(event.target.value);
        }
      }
    }
  }

  return (
    <div className={classes.divgrid}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Accordion expanded={aberto} style = {{boxShadow: "3px 3px 1px lightblue"}}>
            <AccordionSummary
            style={{cursor: 'default'}}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Typography variant='h5' color='primary'>Filtros</Typography>
              </Grid>
              <Grid item xs={3}>
                <Selecao valor={rede}  nome='id_rede' label='Rede' required={false} habilitado={true} onchange={onChangeFiltroClientesSelect} conteudo={redes} nulo={[0,'Todas']} classe={classes.textoform} />
              </Grid>
              <Grid item xs={3}>
                <Selecao valor={atv}  nome='ativos' label='Atividade' required={false} habilitado={true} onchange={onChangeFiltroClientesSelect} conteudo={[{id: 'A', desc: 'Ativos'},{id: 'B', desc: 'Inativos'}]} nulo={['C','Todos']} classe={classes.textoform} />
              </Grid>
              <Grid item xs={3}>
                <SelecaoAuto valor={stcl} nome='id_stcl' label='Situação' classe='' conteudo={situacoes} habilitado={true} colunas={{id: 'id_stcl', desc: 'desc_stcl'}} onchange={onChangeAutoComp} multiplo/>
              </Grid>
              <Grid item xs={1} >
                <IconButton onClick={() => {setAberto(!aberto); abrtini=!aberto}} style={{width: '40%', margin: '0 0 0 60%'}} > {aberto ? <ExpandLessIcon /> : <ExpandMoreIcon/> }</IconButton>
              </Grid>
            </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {tpgrupos.map((tg,idx) =>
                  {
                    return (
                      <Grid item xs={3} key={idx+1}>
                        <SelecaoAuto key={idx+1} valor={Retvlgr(tg.id_tpgr)} nome={tg.id_tpgr.toString().trim()} label={tg.desc_tpgr} classe='' conteudo={tg.valores} habilitado={true} colunas={{id: 'id_vlgr', desc: 'desc_vlgr'}} onchange={onChangeAutoComp} multiplo/>
                      </Grid>
                    )
                  })
                }
                <Grid item xs={3}>
                  <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={(event) => ClickFiltro(event)} endIcon={<FilterListIcon/>}>Aplica Filtros</Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  )
};


type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  registros: [],
  open: boolean,
  mensagem: string,
  duracao: number,
  severidade: string,
  dadoobtido: boolean,
  acessosok: boolean,
}

const srv=new ApiService();
const headers: Iheaders[] = [{titulo: 'Código', alinhamento:'right', coluna: 'id_cli', mascara:'' }, { titulo: 'CPF/CNPJ', alinhamento: 'left', coluna: 'cpfcnpj', mascara: 'cpfcnpj' }, { titulo: 'Nome', alinhamento: 'left', coluna: 'nome_pessoa', mascara:'' }, { titulo: 'Fantasia', alinhamento: 'left', coluna: 'fantasia_pessoa', mascara:'' }, { titulo: 'Rede', alinhamento: 'center', coluna: 'nome_rede', mascara:'' }, { titulo: 'Loja', alinhamento: 'right', coluna: 'loja_cli', mascara:'' }, { titulo: 'Situação', alinhamento: 'center', coluna: 'desc_stcl', mascara:'' }, { titulo: 'Ativo/Inativo', alinhamento: 'center', coluna: 'ativo', mascara:'' }]

class Clientes extends Component<PropsType, IState>
{

  private _dadosobt: RetornoAcesso;
  private _roteador: Roteador;
  private _redes: Redes[];
  private _situacoes: Situacoes_Clientes[];
  private _tiposgrupos: Tipos_Grupos[];
  private _clientes: [];
  private _adicao=false;
  private _edicao=false;
  private _acessos=0;

  constructor(props: any)
  {
    super(props);

    this._dadosobt=new RetornoAcesso();

    if (props.location.state)
    {
      if(props.location.state.dadosret)
      {
        this._dadosobt=props.location.state.dadosret;
      }
    }

    if (!this._dadosobt.ok)
    {
      this.props.history.push({pathname: '/'})
    }

    this._roteador=new Roteador(this._dadosobt, '/Clientes');
    this._roteador.history=props.history;
    this._clientes=[];
    this._redes=[];
    this._situacoes=[];
    this._tiposgrupos=[];
    this.state =
    {
      registros: [],
      open: false,
      mensagem: '',
      duracao: 2000,
      severidade: '',
      dadoobtido: false,
      acessosok: false,
    }

    if (this._dadosobt.ok)
    {
      if (this._dadosobt.objac.tipoacesso_perf==='A')
      {
        this._adicao=true;
        this._edicao=true;
      }
      else
      {
        let fnrc = this._dadosobt.objac.funcoes.filter((funcao) => funcao.id_rcrs===2 );
        this._edicao=fnrc.some(funcao => funcao.id_fnrc===5)
        this._adicao=fnrc.some(funcao => funcao.id_fnrc===4)
      }
      this.AtualizaClientes(redeini,atvini,stclini.length>0 ? stclini.join('-') : ' ', pesqvlgr,false);
      this.ObtemDados('Redes/','redes',this.setaredes.bind(this));
      this.ObtemDados('SituacoesClientes/','situações de clientes',this.setasituacoes.bind(this));
      this.ObtemDados('TiposGrupos/','tipos de grupos',this.setatiposgrupos.bind(this));
    }
  }

  aumentaAcessos(atustate: boolean)
  {
    if (!atustate)
    {
      this._acessos = this._acessos+1
    }
    if(this._acessos>3 || atustate)
    {
      this.setState({acessosok: true, dadoobtido: true });
    }
  }

  AtualizaClientes(idrd: number, atv: string, stcl: string, vlgr: string, atualiza: boolean)
  {
    if(atualiza)
    {
      this.setState({acessosok: false, dadoobtido: false});
    }
    this.ObtemDados('Clientes/'+idrd.toString().trim()+'/'+atv+'/'+stcl+'/'+vlgr+'/','clientes',this.setaclientes.bind(this), atualiza);
  }

  setaclientes(registros: any, atualiza: boolean) {this._clientes=registros; this.aumentaAcessos(atualiza)};
  setaredes(registros: any, atualiza: boolean) {this._redes=registros; this.aumentaAcessos(atualiza);};
  setasituacoes(registros: any, atualiza: boolean) {this._situacoes=registros; this.aumentaAcessos(atualiza);};
  setatiposgrupos(registros: any, atualiza: boolean) { this._tiposgrupos=registros; this.aumentaAcessos(atualiza);};
  
  ObtemDados(caminho: string, msgerr: string, obret: (registros: any, atualiza: boolean) => void, atualiza:boolean = false)
  {
    srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let dadosret:Resposta = TrataResposta(ddret);
      if(dadosret.ok)
      {
        obret(dadosret.retorno, atualiza)
      }
      else
      {
        this.setState( {open: true, severidade: 'error', duracao: 6000, mensagem: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
        this.aumentaAcessos(atualiza);
      }
    })
    .catch(Error => {this.setState( {open: true, severidade: 'error', duracao: 6000, mensagem: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos(atualiza);});
  }

  FechaMensagem()
  {
    this.setState({open: false});
  }

  render()
  {
    if (!this._dadosobt.ok)
    {
      return null
    }
    else
    {
      return (
        <div className="Home">
          <header className="Home-header">
            <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador}/>
          </header>
          <main>
            { this.state.acessosok ?
              <div>
                <AreaFiltros redes={this._redes} situacoes={this._situacoes} tpgrupos={this._tiposgrupos} atudados={this.AtualizaClientes.bind(this)} />
                <TabelaBase linhasoriginais={this._clientes} cab={headers} selecao={false} edicao={this._edicao} adicao={this._adicao} habfiltro={true} onClickEdicao={this._roteador.rota.bind(this._roteador)} titulo={'Clientes'} full={false} exclusao={false} auxedit={998} auxexclui={998} classe='filtro' />
              </div>
            :
              <Progresso/>
            }
          </main>
            <Toast open = {this.state.open} handleClose = {this.FechaMensagem.bind(this)} severity = {this.state.severidade} duracao= {this.state.duracao}>{this.state.mensagem}</Toast>
        </div>
      );
    }
  }
}

export default withRouter(Clientes)
