import React, { ReactNode } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { Dashboard_Operador } from '../Modelos/Dashboard_Operador';

type QuadroProps = 
{
    itensdash: {posicao: number, tamanho: string, dash: Dashboard_Operador}[],
    ordem: number,
    altura: number,
    conteudoiten: (quadro: number,  posicao: number, vazios: number[], dashoper?: Dashboard_Operador) => ReactNode,
    corfundo?: string,
    borda?: string,
}

export function Quadro({itensdash, ordem, altura, conteudoiten, corfundo, borda}: QuadroProps) 
{
    let grade: {tamanho: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12, cards: {w: number, h: number, item: number, posicao: number}[], vazios: number[]}[]=[];
    let letra: 'a' | 'b' | 'c' | 'd';
    let posdash: number;
    grade.push({tamanho: 6, cards: [], vazios: []});
    let sitpos: {a: boolean, b:boolean, c:boolean, d:boolean}={a:true, b:true, c:true, d:true};
    let divpos: {a: boolean, b:boolean, c:boolean, d:boolean}={a:false, b:false, c:false, d:false};
    for (let npos:number=1;npos<5; npos=npos+1)
    {
        posdash=itensdash.findIndex(iten => iten.posicao===npos);
        letra = 'abcd'.substr(npos-1,1) as 'a' | 'b' | 'c' | 'd';

        if (posdash<0)
        {
            if (sitpos[letra])
            {
                divpos[letra]=true;
                sitpos[letra]=true;
            }
        }
        else
        {
            switch (npos)
            {
                case 1:
                {
                    if (itensdash[posdash].tamanho==='D' && sitpos.a && sitpos.b && sitpos.c && sitpos.d)
                    {
                        sitpos={a: false, b: false, c:false, d:false};
                    }
                    else if (itensdash[posdash].tamanho==='B' && sitpos.a && sitpos.b)
                    {
                        sitpos.a=false;
                        sitpos.b=false;
                    }
                    else if (itensdash[posdash].tamanho==='C' && sitpos.a && sitpos.c)
                    {
                        sitpos.a=false;
                        sitpos.c=false;
                    }
                    else if (itensdash[posdash].tamanho==='A' && sitpos.a)
                    {
                        sitpos.a=false;
                        divpos.a=true;
                    }
                    break;
                }
                case 2:
                {
                    if (itensdash[posdash].tamanho==='C' && sitpos.b && sitpos.d)
                    {
                        sitpos.b=false;
                        sitpos.d=false;
                    }
                    else if (itensdash[posdash].tamanho==='A' && sitpos.b)
                    {
                        sitpos.b=false;
                        divpos.b=true;
                    }
                    break;
                }
                case 3:
                {
                    if (itensdash[posdash].tamanho==='B' && sitpos.c && sitpos.d)
                    {
                        sitpos.c=false;
                        sitpos.d=false;
                    }
                    else if (itensdash[posdash].tamanho==='A' && sitpos.c)
                    {
                        sitpos.c=false;
                        divpos.c=true;
                    }
                    break;
                }
                case 4:
                {
                    if (itensdash[posdash].tamanho==='A' && sitpos.d)
                    {
                        sitpos.d=false;
                        divpos.d=true;
                    }
                    break;
                }
            }
        }
    }

    if ((divpos.b && divpos.d) || (divpos.a && divpos.c))
    {
        grade[0].tamanho=3;
        grade.push({tamanho: 3, cards: [], vazios: []});
    }
    
    sitpos={a:true, b:true, c:true, d:true};

    for (let npos:number=1;npos<5; npos=npos+1)
    {
        posdash=itensdash.findIndex(iten => iten.posicao===npos);
        letra = 'abcd'.substr(npos-1,1) as 'a' | 'b' | 'c' | 'd';

        if (posdash<0)
        {
            if (sitpos[letra])
            {
                grade[(npos===2 || npos===4) ? grade.length-1 : 0].cards.push({w:50, h:50, item: -1, posicao: npos });
                grade.forEach(grd => grd.vazios.push(npos));
            }
            sitpos[letra]=false;
        }
        else
        {
            switch (npos)
            {
                case 1:
                {
                    if (itensdash[posdash].tamanho==='D' && sitpos.a && sitpos.b && sitpos.c && sitpos.d)
                    {
                        grade[0].cards.push({w:100, h:100, item: posdash, posicao: npos});
                        sitpos={a: false, b: false, c:false, d:false};
                    }
                    else if (itensdash[posdash].tamanho==='B' && sitpos.a && sitpos.b)
                    {
                        grade[0].cards.push({w:100, h:50, item: posdash, posicao: npos});
                        sitpos.a=false;
                        sitpos.b=false;
                    }
                    else if (itensdash[posdash].tamanho==='C' && sitpos.a && sitpos.c)
                    {
                        grade[0].cards.push({w:50, h:100, item: posdash, posicao: npos });
                        sitpos.a=false;
                        sitpos.c=false;
                    }
                    else if (itensdash[posdash].tamanho==='A' && sitpos.a)
                    {
                        grade[0].cards.push({w:50, h:50, item: posdash, posicao: npos });
                        sitpos.a=false;
                    }
                    break;
                }
                case 2:
                {
                    if (itensdash[posdash].tamanho==='C' && sitpos.b && sitpos.d)
                    {
                        grade[grade.length-1].cards.push({w:50, h:100, item: posdash, posicao: npos });
                        sitpos.b=false;
                        sitpos.d=false;
                    }
                    else if (itensdash[posdash].tamanho==='A' && sitpos.b)
                    {
                        grade[grade.length-1].cards.push({w:50, h:50, item: posdash, posicao: npos});
                        sitpos.b=false;
                    }
                    break;
                }
                case 3:
                {
                    if (itensdash[posdash].tamanho==='B' && sitpos.c && sitpos.d)
                    {
                        grade[0].cards.push({w:100, h:50, item: posdash, posicao: npos });
                        sitpos.c=false;
                        sitpos.d=false;
                    }
                    else if (itensdash[posdash].tamanho==='A' && sitpos.c)
                    {
                        grade[0].cards.push({w:50, h:50, item: posdash, posicao: npos });
                        sitpos.c=false;
                    }
                    break;
                }
                case 4:
                {
                    if (itensdash[posdash].tamanho==='A' && sitpos.d)
                    {
                        grade[grade.length-1].cards.push({w:50, h:50, item: posdash, posicao: npos });
                        sitpos.d=false;
                    }
                    break;
                }
            }
        }
    }

    return (
        <>
            {grade.map((grd, idx) =>
                {
                    return (
                        <Grid key={idx+1} item xs={grd.tamanho} style={{height: altura.toString().trim()+'px', maxHeight: altura.toString().trim()+'px'}}>
                            {grd.cards.map((crd, idxcrd) =>
                                {
                                    return (
                                        <Paper key={(idx+1*1000)+idxcrd+1} style={{margin: '0px 0px 0px 0px', width: (grade.length>1 ? '100' : crd.w.toString().trim()) +'%', height: (crd.h).toString().trim()+'%', backgroundColor: corfundo ? corfundo : '#FFFFFF', border: borda? borda : '1px solid lightcyan', float: grade.length>1 || (crd.item>=0 && 'AC'.indexOf(itensdash[crd.item].dash.tamanho_dsbo)!==-1) || crd.item<0 ? 'left' : undefined , display: 'inline-block'}} >
                                            {conteudoiten(ordem,  crd.posicao, grd.vazios, (crd.item < 0 ? undefined : itensdash[crd.item].dash))}
                                        </Paper>
                                    )
                                }
                            )}
                        </Grid>
                    )
                }        
            )}
        </>
    );
}

