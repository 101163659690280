import React,{ Component, RefObject, useRef } from 'react';
import BarraLateral from '../Componentes/Barralateral';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import ApiService from '../Servicos/ApiService';
import Toast from '../Componentes/Toast';
import { Roteador } from '../Servicos/Roteador';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Button } from '@material-ui/core';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { Clientes } from '../Modelos/Clientes';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EmailIcon from '@material-ui/icons/Email';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import SubjectIcon from '@material-ui/icons/Subject';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ReplayIcon from '@material-ui/icons/Replay';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import CampoTexto from '../Componentes/CampoTexto';
import CampoDecimal from '../Componentes/CampoDecimal';
import CampoData from '../Componentes/CampoData';
import CampoMascara from '../Componentes/CampoMascara';
import Selecao from '../Componentes/Selecao';
import { ObjPdfImg, arrayBufferToBase64, VerificaCpfCnpj, formataDecimal, Iheaders, RetornaStringData, RetornaDataString, AchaFuncoes, Resposta, ValidaDadosAdicionais, BotaoDialogo, TrataResposta, TabPanel, a11yProps, RetornaValoresGrupos, AlteraTiposGeracao, ParametrosDiversos, ClicaBotao, ChaveApiGoogle, achavalor, RetornaUrlPdfImg, achaDescricao, AlteraPadroesContrato, AtualizaGrupos, AtualizaDadosAdicionais, AtualizaDocumentos, RetornaValoresDadosAdicionais, DataSql, DifDias, formatarCampo, RetornaDataAAAAMMDD } from '../Servicos/Utilidades';
import { Contratos } from '../Modelos/Contratos';
import { Pessoa_Documentos } from '../Modelos/Pessoa_Documentos';
import { Endereco_Pessoas } from '../Modelos/Endereco_Pessoas';
import TabelaBase from '../Componentes/TabelaBase';
import { Servicos_Contratos } from '../Modelos/Servicos_Contratos';
import { Tipos_Grupos } from '../Modelos/Tipos_Grupos';
import { Dados_Adicionais } from '../Modelos/Dados_Adicionais';
import { Documentos } from '../Modelos/Documentos';
import { Servicos } from '../Modelos/Servicos';
import { Servicos_Clientes } from '../Modelos/Servicos_Clientes';
import { Contatos_Cliente } from '../Modelos/Contatos_Cliente';
import { Ocorrencias_Clientes } from '../Modelos/Ocorrencias_Clientes';
import ExibePdfImg from '../Componentes/ExibePdfImg';
import { Emails } from '../Modelos/Emails';
import { Telefones } from '../Modelos/Telefones';
import { Pessoas } from '../Modelos/Pessoas';
import AlertDialog from '../Componentes/Dialogo';
import Progresso from '../Componentes/Progresso';
import { Parametros } from '../Modelos/Parametros';
import Geocode from "react-geocode";
import EnderecosComp from '../Componentes/Enderecos';
import { Redes } from '../Modelos/Redes';
import { pdf } from '@react-pdf/renderer';
import DocumentoPDF from '../Componentes/DocumentoPDF';
import EmailsTelefones from '../Componentes/EmailsTelefones';
import MontaPDF from '../Servicos/MontaPDF';
import { Tipos_Contrato } from '../Modelos/Tipos_Contrato';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { StyledAppBar } from '../Componentes/Customizados';
import Grafico from '../Componentes/Grafico';

Geocode.setApiKey(ChaveApiGoogle);
Geocode.setLanguage('pr-BR');
Geocode.setRegion('br');
 
const srv=new ApiService();
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%'
    },
    foto: {
      float: 'right',
      widht: '80%',
    },
    divgrid: {
        width: '90%',
        margin: '5% 7% 0 7%'
    },
    divtabs: {
      width: '90%',
      margin: '0% 7% 0 7%'
    },
    gridcont: {
      margin: '5px 0 0 0'
    },
    fotocliente: {
      width: "98%",
      maxHeight: "200px",
      minHeight: "200px",
      margin: "1% 1% 0 0",
      boxShadow: "2px 2px 5px lightgrey"
    },
    fotoinput: {
      display: "none"
    },
    griddados: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridcontrato: {
      width: "60%",
      margin: "0 0 0 0"
    },
    gridfoto: {
        width: "20%",
        margin: "0 0 0 0"
    },
    gridtabend: {
      width: "100%",
      margin: "0 0 0 0"
    },
    gridtabemail: {
      width: "50%",
      margin: "0 0 0 0"
    },
    textoform: {
      fontFamily: 'Arial-Bold',
      margin: "0% 0%",
      width: "100%",
      background: 'white'
    },
    obsform: {
      margin: "0% 0%",
      width: "100%",
      background: 'white',
      minHeight: '300%'
    },
    padrao: {
      margin: "0% 0%",
      width: "100%",
      padding: '0 0 0 0'
    },
    botao: {
      margin: '5% 5%',
      width: '90%'
    },
    botaopdf: {
      margin: '0 2',
      width: '100%'
    },
  }),
);

let ultcpfcnpj: string = '';
let titulodialogo: string;
let botoesdialogo: BotaoDialogo[] = [];
let textodialogo: string[] = [];
let botcli: string='';
let enddigatu: Endereco_Pessoas = new Endereco_Pessoas();
let indendatu: number = 0;
let emaildigatu: Emails = new Emails();
let fonedigatu: Telefones = new Telefones();
let indemailatu: number = 0;
let indfoneatu: number = 0;

let sted = {
  enderecos: false,
  emails: false,
  telefones: false,
  contratos: false,
  servicos: false,
  contatos: false
};

function InicializaEdicao() 
{
  sted = {
    enderecos: false,
    emails: false,
    telefones: false,
    contratos: false,
    servicos: false,
    contatos: false
  };
  enddigatu = new Endereco_Pessoas();
  indendatu = 0;
  emaildigatu = new Emails();
  fonedigatu = new Telefones();
  indemailatu = 0;
  indfoneatu = 0;
}

type PrincipalProps=
{
  dadosobt: RetornoAcesso
  dadoscli: Clientes
  redes: Redes[]
  regioes: []
  vendedores: []
  situacoes: []
  canais: []
  servicos: []
  documentos: Documentos[]
  dadosadicionais: Dados_Adicionais[]
  grupos: Tipos_Grupos[]
  tpcont: Tipos_Contrato[]
  ocorrencias: []
  tiposenderecos: []
  indices: []
  ufs: []
  mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void
  roteador: (recurso: number, id_edicao: number) => void
}

const PrincipalEdicaoClientes = ({dadosobt, dadoscli, redes, regioes, vendedores, situacoes, canais, servicos, documentos, dadosadicionais, grupos, tpcont, ocorrencias, tiposenderecos, indices, ufs, mensagem, roteador }: PrincipalProps) =>
{
  const classes = useStyles();
  const [tabValue, settabValue] = React.useState(0);
  const [cliente,setCliente] = React.useState(dadoscli);
  const [contdig, setcontDig] = React.useState(new Contratos());
  const [indcont, setindCont] = React.useState(-1);
  const [srvcont, setsrvcontDig] = React.useState(new Servicos_Contratos());
  const [indsrvcont, setindsrvCont] = React.useState(0);
  const [grupodig, setgrupoDig] = React.useState(grupos);
  const [ddadcdig, setddadcDig] = React.useState(dadosadicionais);
  const [doctodig, setdoctoDig] = React.useState(documentos);
  const [srvcldig, setsrvclDig] = React.useState(new Servicos_Clientes());
  const [indsrvcli, setindsrvCli] = React.useState(0);
  const [contatosdig, setcontatosDig] = React.useState(new Contatos_Cliente());
  const [indcontatos, setindContatos] = React.useState(0);
  const [dialogo, setDialog] = React.useState(false);

  const AtuEnderecos = (enderecos: Endereco_Pessoas[]) => {setCliente({...cliente, pessoa: {...cliente.pessoa, enderecos: enderecos}}); sted.enderecos=false};
  const AtuEnder = (ender: Endereco_Pessoas, altdados: boolean) => {enddigatu=ender; if(altdados){sted.enderecos=true}};
  const AtuIndEnder = (indender: number) => {indendatu=indender};
  const AtuEmails = (emails: Emails[]) => {setCliente({...cliente, pessoa: {...cliente.pessoa, emails: emails}}); sted.emails=false};
  const AtuEmail = (email: Emails, altdados: boolean) => {emaildigatu=email; if(altdados){sted.emails=true}};
  const AtuIndEmail = (indemail: number) => {indemailatu=indemail};
  const AtuFones = (fones: Telefones[]) => {setCliente({...cliente, pessoa: {...cliente.pessoa, telefones: fones}}); sted.telefones=false};
  const AtuFone = (fone: Telefones, altdados: boolean) => {fonedigatu=fone; if(altdados){sted.telefones=true}};
  const AtuIndFone = (indfn: number) => {indfoneatu=indfn};
  const AtuMensagem = (msg: string) => {mensagem(msg,'error',6000,false)};

  const acessos = {
      altcad: AchaFuncoes(dadosobt.objac, 24),
      inccontr: AchaFuncoes(dadosobt.objac, 15),
      altcontr: AchaFuncoes(dadosobt.objac, 16),
      exccontr: AchaFuncoes(dadosobt.objac, 17),
      inclanc: AchaFuncoes(dadosobt.objac, 18),
      altlanc: AchaFuncoes(dadosobt.objac, 19),
      exclanc: AchaFuncoes(dadosobt.objac, 20),
      incctt: AchaFuncoes(dadosobt.objac, 21),
      altctt: AchaFuncoes(dadosobt.objac, 22),
      excctt: AchaFuncoes(dadosobt.objac, 23),
    }
  
  const fechaDialogo = (retorno: string) => 
  {
    setDialog(false);
    if (retorno==='A')
    {
      salvaCliente();
    }
  }

  let inddoc: number = cliente.pessoa.documentos.findIndex(doc => doc.id_doc===1 || doc.id_doc===2 );
  if(inddoc<0)
  {
    let docatu: Pessoa_Documentos[] = [];
    docatu.concat(cliente.pessoa.documentos);
    let novodoc=new Pessoa_Documentos();
    novodoc.id_pessoa=cliente.pessoa.id_pessoa;
    novodoc.id_doc=1;
    novodoc.valor_psdc='';
    docatu.push(novodoc);
    setCliente({...cliente, pessoa: {...cliente.pessoa, documentos: docatu}});
    inddoc=docatu.length-1;
  }

  const inputFileFoto: RefObject<HTMLInputElement> = useRef(null) ;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue===1 && documentos.length>0 && doctodig.length===0)
    {
      setdoctoDig(documentos);
    }
    if (newValue===3 && grupos.length>0 && grupodig.length===0)
    {
      setgrupoDig(grupos);
    }
    if (newValue===4 && dadosadicionais.length>0 && ddadcdig.length===0)
    {
      setddadcDig(dadosadicionais);
    }
    settabValue(newValue);
  };

  const onButtonClickFoto = (event:any) => 
  {
    if(event.ctrlKey)
    {
      setCliente({...cliente, pessoa: {...cliente.pessoa, gravafoto: true, foto_pessoa: undefined}});
    }
    else
    {
      if (inputFileFoto.current)
      {
        inputFileFoto.current.click()
      }
    }
  };

  const onChangeFoto = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
  {
    const filereader = new FileReader();
    const doc: any = document.getElementById("filefoto");
    if(doc)
    {
      if(doc.files)
      {
        if(doc.files.length>0)
        {
          filereader.readAsArrayBuffer(doc.files[0]);
        }
      }
    }
    filereader.onloadend = function()
    {
      let result: any = filereader.result
      setCliente({...cliente, pessoa: {...cliente.pessoa, gravafoto: true, foto_pessoa: arrayBufferToBase64(result)}});
    };
  }

  const onblurCpfCnpj = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
  {
    let docatu: Pessoa_Documentos[] = cliente.pessoa.documentos;
    if (event.target.value.length===11 && docatu[inddoc].id_doc===1)
    {
      docatu[inddoc].id_doc=2;
      setCliente({...cliente, pessoa: {...cliente.pessoa, documentos: docatu}});
    }
    else if(event.target.value.length===14 && docatu[inddoc].id_doc===2)
    {
      docatu[inddoc].id_doc=1;
      setCliente({...cliente, pessoa: {...cliente.pessoa, documentos: docatu}});
    }
    if(ultcpfcnpj!==cliente.pessoa.documentos[inddoc].valor_psdc && VerificaCpfCnpj(cliente.pessoa.documentos[inddoc].valor_psdc))
    {
      srv.Get('Pessoas/'+cliente.pessoa.documentos[inddoc].valor_psdc+'/'+cliente.id_cli.toString()+'/B' , '', '', dadosobt.objac.token, false)
      .then((ddret: any) => 
      {
        let dadosret: Resposta = TrataResposta(ddret);
        if (!dadosret.ok)
        {
          mensagem(dadosret.erro,'error',6000);
          let cpocpfcnpj = document.getElementById('cnpj_pessoa');
          if(cpocpfcnpj)
          {
            let docatu: Pessoa_Documentos[] = cliente.pessoa.documentos;
            docatu[inddoc].valor_psdc=ultcpfcnpj;
            setCliente({...cliente, pessoa: {...cliente.pessoa, documentos: docatu}});
            cpocpfcnpj.focus();
          }
        }
        else
        {
          if (dadosret.retorno)
          {
            if(dadosret.retorno.id_pessoa)
            {
              let novapes: Pessoas = dadosret.retorno;
              dadosadicionais=AtualizaDadosAdicionais(dadosadicionais, novapes.dados_adicionais ? novapes.dados_adicionais : [],'B');
              documentos=AtualizaDocumentos(documentos, novapes.documentos ? novapes.documentos : []);
              setCliente({...cliente, pessoa: novapes});
              setddadcDig(dadosadicionais);
              setdoctoDig(documentos);
              mensagem('Informações atualizadas a partir de cadastro existente','info',7000);
            }
          }
        }
      })
      .catch(Error => mensagem('Erro: Erro acessando a API (' + Error.name + ' - ' + Error.message + ')','error',7000));
    }
  }

  const onfocusCpfCnpj = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
  {
    ultcpfcnpj=event.target.value;
  }

  const onChangeClienteSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) =>
  {
    if (event.target.name)
    {
      let cliany: any = cliente;
      let tipo = typeof cliany[event.target.name];
      let valor: any;
      if(tipo==='number')
      {
        valor = typeof event.target.value === 'string' && event.target.value ? parseInt(event.target.value) : 0;
      }
      else
      {
        valor = typeof event.target.value === 'number' && event.target.value ? event.target.value.toString() : typeof event.target.value === 'string' ? event.target.value : '';
      }
      setCliente({...cliente, [event.target.name]: valor});
    }
  }

  const onChangePessoaDataInput = (date: any, valor: string | null | undefined) =>
  {
    if (date)
    {
      setCliente({...cliente, pessoa: {...cliente.pessoa, aniversario_pessoa: date.getDate().toString().padStart(2,'0')+(date.getMonth()+1).toString().padStart(2,'0'), nascimento_pessoa: date }});
    }
    else
    {
      setCliente({...cliente, pessoa: {...cliente.pessoa, nascimento_pessoa: date}});
    }
  }

  const onChangeClienteInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
  {
    setCliente({...cliente, [event.target.id]: event.target.id==='loja_cli' && !propratu ? 0 : propratu});
  }

  const onChangeClienteDecimalInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string) =>
  {
    setCliente({...cliente, [event.target.id]: parseFloat(formataDecimal(propratu))});
  }

  const onChangePessoaInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
  {
    if(event.target.id==='aniversario_pessoa')
    {
      propratu=propratu.toString().replace('/','');
    }
    setCliente({...cliente, pessoa: {...cliente.pessoa, [event.target.id]: propratu}});
  }

  const onChangeGrupoSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) =>
  {
    if (event.target.name)
    {
      let valor = typeof event.target.value === 'string' && event.target.value ? parseInt(event.target.value) : 0;
      let novogrupo: Tipos_Grupos[]=[]
      novogrupo=novogrupo.concat(grupodig);
      let inddado=parseInt(event.target.name)
      if (inddado>=0 && inddado<novogrupo.length)
      {
        novogrupo[inddado].id_vlgr=valor;
        setgrupoDig(novogrupo);
      }
    }
  }

  const onChangeDadosAdicionaisSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) =>
  {
    if (event.target.name)
    {
      let valor = typeof event.target.value === 'string' && event.target.value ? parseInt(event.target.value) : 0;
      let novoddadc: Dados_Adicionais[]=[]
      novoddadc=novoddadc.concat(ddadcdig);
      let inddado=parseInt(event.target.name)
      if (inddado>=0 && inddado<novoddadc.length)
      {
        novoddadc[inddado].id_vdad=valor;
        setddadcDig(novoddadc);
      }
    }
  }

  const onChangeDadosAdicionaisInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
  {
    if (event.target.id && typeof propratu==='string')
    {
      let novoddadc: Dados_Adicionais[]=[]
      novoddadc=novoddadc.concat(ddadcdig);
      let inddado=parseInt(event.target.id)
      if (inddado>=0 && inddado<novoddadc.length)
      {
        if (novoddadc[inddado].tipo_dadc==='A')
        {
          novoddadc[inddado].texto_tbad=propratu;
        }
        else
        {
          if(novoddadc[inddado].mascara_dadc==='decimal')
          {
            novoddadc[inddado].numero_tbad=parseFloat(formataDecimal(propratu))
          }
          else
          {
            novoddadc[inddado].numero_tbad=parseInt(propratu)
          }
        }
        setddadcDig(novoddadc);
      }
    }
  }

  const onChangeDocumentosInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
  {
    if (event.target.id && typeof propratu==='string')
    {
      let novodoc: Documentos[]=[]
      novodoc=novodoc.concat(doctodig);
      let inddado=parseInt(event.target.id)
      if (inddado>=0 && inddado<novodoc.length)
      {
        novodoc[inddado].valor_psdc=propratu;
        setdoctoDig(novodoc);
      }
    }
  }

  const onChangeContratoSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) =>
  {
    if (event.target.name)
    {
      let contany: any = contdig;
      let tipo = event.target.name==='cont_id_vend' ? 'number' : typeof contany[event.target.name];
      let valor: any;
      if(tipo==='number')
      {
        valor = typeof event.target.value === 'string' && event.target.value ? parseInt(event.target.value) : 0;
      }
      else
      {
        valor = typeof event.target.value === 'number' && event.target.value ? event.target.value.toString() : typeof event.target.value === 'string' ? event.target.value : '';
      }
      if (event.target.name==='freqreaj_cont' && valor==='C')
      {
        setcontDig({...contdig, [event.target.name]: valor, id_idrj: 0});
      }
      else
      {
        if (event.target.name==='cont_id_vend')
        {
          setcontDig({...contdig, id_vend: valor});
        }
        else
        {
          setcontDig({...contdig, [event.target.name]: valor});
        }
      }
      sted.contratos=true;
    }
  }

  const onChangeServicosContratoSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) =>
  {
    if (event.target.name)
    {
      let srvcontany: any = srvcont;
      let tipo = typeof srvcontany[event.target.name];
      let valor: any;
      if(tipo==='number')
      {
        valor = typeof event.target.value === 'string' && event.target.value ? parseInt(event.target.value) : 0;
      }
      else
      {
        valor = typeof event.target.value === 'number' && event.target.value ? event.target.value.toString() : typeof event.target.value === 'string' ? event.target.value : '';
      }
      if (srvcont.recor_svct!=='A' || srvcont.dia_svct===0)
      {
        setsrvcontDig({...srvcont, [event.target.name]: valor});
      }
      else
      {
        setsrvcontDig({...srvcont, [event.target.name]: valor, dia_svct:0});
      }
      sted.contratos=true;
    }
  }

  const onChangeServicosContratoInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
  {
    setsrvcontDig({...srvcont, [event.target.id]: propratu});
    sted.contratos=true;
  }

  const onChangeServicosContratoDecimalInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string) =>
  {
    setsrvcontDig({...srvcont, [event.target.id]: parseFloat(formataDecimal(propratu))});
    sted.contratos=true;
  }

  const onChangeServicosClientesSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) =>
  {
    if (event.target.name)
    {
      let srvcliany: any = srvcldig;
      let tipo = typeof srvcliany[event.target.name];
      let valor: any;
      if(tipo==='number')
      {
        valor = typeof event.target.value === 'string' && event.target.value ? parseInt(event.target.value) : 0;
      }
      else
      {
        valor = typeof event.target.value === 'number' && event.target.value ? event.target.value.toString() : typeof event.target.value === 'string' ? event.target.value : '';
      }
      setsrvclDig({...srvcldig, [event.target.name]: valor});
      sted.servicos=true;
    }
  }

  const onChangeServicosClientesInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
  {
    setsrvclDig({...srvcldig, [event.target.id]: propratu});
    sted.servicos=true;
  }

  const onChangeServicosClientesDecimalInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string) =>
  {
    setsrvclDig({...srvcldig, [event.target.id]: parseFloat(formataDecimal(propratu))});
    sted.servicos=true;
  }

  const onChangeServicosClientesDataInput = (date: any, valor: string | null | undefined) =>
  {
    setsrvclDig({...srvcldig, data_svcl: date });
    sted.servicos=true;
  }

  const geraContrato = () =>
  {
    let mntpdf: MontaPDF = new MontaPDF();
    let indtpc: number = -1;
    let idtpc: number=achavalor(cliente.id_rede,redes,'id_rede','id_tpct',0) as number;
    if (idtpc === 0)
    {
      contdig.srvcont.forEach(srvc => 
        {
          if (idtpc===0)
          {
            idtpc=achavalor(srvc.id_serv,servicos,'id_serv','id_tpct',0) as number;
          }
        });
    }

    if (idtpc>0)
    {
      indtpc=achavalor(idtpc,tpcont,'id_tpct','*index*',0) as number;
    }

    if (indtpc>=0)
    {
      pdf(DocumentoPDF(mntpdf.PreencheDados(cliente, contdig, tpcont[indtpc]))).toBlob()
      .then(result => 
        {
          let reader = new FileReader();
          reader.readAsArrayBuffer(result); 
          reader.onloadend = function() {
              let base64data: string | ArrayBuffer | null = reader.result;
              if (base64data && typeof base64data !=='string')
              {
                atualizaPdfContrato({tipo: 'B', conteudo: arrayBufferToBase64(base64data)});
              }
            }
        })
      .catch(error => console.log(error))
    }
  }

  const adicionaServico = () =>
  {
    if (srvcont.id_serv && srvcont.recor_svct && srvcont.recor_svct!==' ' && srvcont.valor_svct>0 && !(srvcont.recor_svct==='A' && (!srvcont.dia_svct || srvcont.dia_svct<1 || srvcont.dia_svct>31)) && ((srvcont.basecom_svct>=0 && srvcont.basecom_svct<=srvcont.valor_svct) || !srvcont.basecom_svct))
    {
      let srvcontnovos: Servicos_Contratos[]=[];
      srvcontnovos=srvcontnovos.concat(contdig.srvcont);
      if (indsrvcont===0)
      {
        srvcontnovos.push(srvcont);
        srvcontnovos[srvcontnovos.length-1].alterado='A'
        botcli='ultpagtab'
      }
      else
      {
        srvcontnovos[indsrvcont-1]=srvcont;
        srvcontnovos[indsrvcont-1].alterado='A';
      }
      setcontDig({...contdig, srvcont: srvcontnovos});
      setsrvcontDig(new Servicos_Contratos());
      setindsrvCont(0);
    }
    else
    {
      mensagem('Existem dados incorretos. Verifique, por gentileza','error',6000);
    }
  }

  const adicionaContrato = () =>
  {
    if (!(RetornaDataString(contdig.vigfin_cont)<RetornaDataString(contdig.vigini_cont) || !contdig.freqreaj_cont || contdig.freqreaj_cont===' ' || (contdig.freqreaj_cont!=='C' && !contdig.id_idrj) || !contdig.situacao_cont || contdig.situacao_cont===' '))
    {
      let contrnovos: Contratos[]=[];
      contrnovos=contrnovos.concat(cliente.contratos);
      if (indcont===0)
      {
        contrnovos.push(contdig);
        contrnovos[contrnovos.length-1].alterado='A'
      }
      else
      {
        contrnovos[indcont-1]=contdig;
        contrnovos[indcont-1].alterado='A';
      }
      setCliente({...cliente, contratos: contrnovos});
      setindCont(-1);
      sted.contratos=false;
    }
    else
    {
      mensagem('Existem dados incorretos. Verifique, por gentileza','error',6000);
    }
  }

  const adicionaServicoCliente = () =>
  {
    if (srvcldig.id_serv && srvcldig.valor_svcl>0 && srvcldig.valor_svcl>=srvcldig.basecom_svcl)
    {
      let srvclinovos: Servicos_Clientes[]=[];
      srvclinovos=srvclinovos.concat(cliente.servicos);
      if (indsrvcli===0)
      {
        srvclinovos.push(srvcldig);
        srvclinovos[srvclinovos.length-1].alterado='A'
        botcli='ultpagtab'
      }
      else
      {
        srvclinovos[indsrvcli-1]=srvcldig;
        srvclinovos[indsrvcli-1].alterado='A';
      }
      setCliente({...cliente, servicos: srvclinovos});
      setsrvclDig(new Servicos_Clientes());
      setindsrvCli(0);
      sted.servicos=false;
    }
    else
    {
      mensagem('Existem dados incorretos. Verifique, por gentileza','error',6000);
    }
  }

  const adicionaContato = () =>
  {
    if (contatosdig.id_occl>0)
    {
      let contatosnovos: Contatos_Cliente[]=[];
      contatosnovos=contatosnovos.concat(cliente.contatos);
      if (indcontatos===0)
      {
        contatosnovos.push(contatosdig);
        contatosnovos[contatosnovos.length-1].id_oper=dadosobt.objac.id_oper;
        contatosnovos[contatosnovos.length-1].nome_oper=dadosobt.nome;
        contatosnovos[contatosnovos.length-1].alterado='A'
        botcli='ultpagtab';
      }
      else
      {
        contatosnovos[indcontatos-1]=contatosdig;
        contatosnovos[indcontatos-1].id_oper=dadosobt.objac.id_oper;
        contatosnovos[indcontatos-1].nome_oper=dadosobt.nome;
        contatosnovos[indcontatos-1].alterado='A';
      }
      setCliente({...cliente, contatos: contatosnovos});
      setcontatosDig(new Contatos_Cliente());
      setindContatos(0);
      sted.contatos=false;
    }
    else
    {
      mensagem('Existem dados incorretos. Verifique, por gentileza','error',6000);
    }
  }

  const DesisteContrato = () =>
  {
    setindCont(-1);
    sted.contratos=false;
  }

  const editaContrato = (val1: number, val2: number) =>
  {
    if(val1===1)
    {
      if(val2<=cliente.contratos.length && val2>0)
      {
        setcontDig(cliente.contratos[val2-1]);
        setindCont(val2);
      }
      else
      {
        let novocont=new Contratos();
        novocont.id_vend=cliente.id_vend;
        setcontDig(novocont);
        setindCont(0);
      }
    }
    else
    {
      if(val1===2)
      {
        if(val2<=cliente.contratos.length && val2>0)
        {
          let contnovos: Contratos[]=[];
          contnovos=contnovos.concat(cliente.contratos);
          contnovos[val2-1].alterado='B';
          setCliente({...cliente, contratos: contnovos});
        }
      }
    }
  }

  const editaServico = (val1: number, val2: number) =>
  {
    if(val1===1)
    {
      if(val2<=contdig.srvcont.length && val2>0)
      {
        setsrvcontDig(contdig.srvcont[val2-1]);
        setindsrvCont(val2);
      }
      else
      {
        setsrvcontDig(new Servicos_Contratos());
        setindsrvCont(0);
      }
    }
    else
    {
      if(val1===2)
      {
        if(val2<=contdig.srvcont.length && val2>0)
        {
          let srvcontnovos: Servicos_Contratos[]=[]
          srvcontnovos=srvcontnovos.concat(contdig.srvcont);
          srvcontnovos[val2-1].alterado='B';
          setcontDig({...contdig, srvcont: srvcontnovos});
          if (val2===indsrvcont)
          {
            setsrvcontDig(new Servicos_Contratos());
            setindsrvCont(0);
          }
          sted.contratos=true;
        }
      }
    }
  }

  const editaServicoCliente = (val1: number, val2: number) =>
  {
    if(val1===1)
    {
      if(val2<=cliente.servicos.length && val2>0)
      {
        setsrvclDig(cliente.servicos[val2-1]);
        setindsrvCli(val2);
      }
      else
      {
        setsrvclDig(new Servicos_Clientes());
        setindsrvCli(0);
      }
    }
    else
    {
      if(val1===2)
      {
        if(val2<=cliente.servicos.length && val2>0)
        {
          let srvclinovos: Servicos_Clientes[]=[]
          srvclinovos=srvclinovos.concat(cliente.servicos);
          srvclinovos[val2-1].alterado='B';
          setCliente({...cliente, servicos: srvclinovos});
          if (val2===indsrvcli)
          {
            setsrvclDig(new Servicos_Clientes());
            setindsrvCli(0);
            sted.servicos=false;
          }
        }
      }
    }
  }

  const editaContatos = (val1: number, val2: number) =>
  {
    if(val1===1)
    {
      if(val2<=cliente.contatos.length && val2>0)
      {
        setcontatosDig(cliente.contatos[val2-1]);
        setindContatos(val2);
      }
      else
      {
        setcontatosDig(new Contatos_Cliente());
        setindContatos(0);
      }
    }
    else
    {
      if(val1===2)
      {
        if(val2<=cliente.contatos.length && val2>0)
        {
          let contatosnovos: Contatos_Cliente[]=[]
          contatosnovos=contatosnovos.concat(cliente.contatos);
          contatosnovos[val2-1].alterado='B';
          setCliente({...cliente, contatos: contatosnovos});
          if (val2===indcontatos)
          {
            setcontatosDig(new Contatos_Cliente());
            setindContatos(0);
            sted.contratos=false;
          }
        }
      }
    }
  }

  const onChangeCpfCnpj = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
  {
    let docatu: Pessoa_Documentos[] = cliente.pessoa.documentos;
    docatu[inddoc].valor_psdc=propratu.toString();
    setCliente({...cliente, pessoa: {...cliente.pessoa, documentos: docatu}});
  }

  const limpaData = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
  {
    setCliente({...cliente, pessoa: {...cliente.pessoa, nascimento_pessoa: undefined}});
  }

  const onChangeServicoContratoDataInput = (date: any, valor: string | null | undefined, campo?: string) =>
  {
    if (campo)
    {
      if (campo==='data_svct')
      {
        setsrvcontDig({...srvcont, data_svct: date});
        sted.contratos=true;
      }
    }
  }

  const onChangeContratoDataassInput = (date: any, valor: string | null | undefined) =>
  {
    setcontDig({...contdig, dataass_cont: date });
    sted.contratos=true;
  }
  const onChangeContratoViginiInput = (date: any, valor: string | null | undefined) =>
  {
    setcontDig({...contdig, vigini_cont: date });
    sted.contratos=true;
  }
  const onChangeContratoVigfinInput = (date: any, valor: string | null | undefined) =>
  {
    setcontDig({...contdig, vigfin_cont: date });
    sted.contratos=true;
  }
  const onChangeContratoDatabaseInput = (date: any, valor: string | null | undefined) =>
  {
    setcontDig({...contdig, database_cont: date });
    sted.contratos=true;
  }
  const onChangeContratoInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
  {
    setcontDig({...contdig, [event.target.id]: propratu});
    sted.contratos=true;
  }

  const onChangeContatosDataInput = (date: any, valor: string | null | undefined) =>
  {
    setcontatosDig({...contatosdig, data_ctcl: date });
    sted.contatos=true;
  }
  const onChangeContatosInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propratu: string | number | Date) =>
  {
    setcontatosDig({...contatosdig, [event.target.id]: propratu});
    sted.contatos=true;
  }

  const onChangeContatosSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) =>
  {
    if (event.target.name)
    {
      let cttany: any = contatosdig;
      let tipo = typeof cttany[event.target.name];
      let valor: any;
      if(tipo==='number')
      {
        valor = typeof event.target.value === 'string' && event.target.value ? parseInt(event.target.value) : 0;
      }
      else
      {
        valor = typeof event.target.value === 'number' && event.target.value ? event.target.value.toString() : typeof event.target.value === 'string' ? event.target.value : '';
      }
      setcontatosDig({...contatosdig, [event.target.name]: valor});
      sted.contatos=true;
    }
  }

  const hdsrvcontr: Iheaders[] = [{titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, { titulo: 'Serviço', alinhamento: 'left', coluna: 'desc_serv', mascara: '' }, { titulo: 'Dia', alinhamento: 'center', coluna: 'dia_svct', mascara: '' }, { titulo: 'Data cobrança', alinhamento: 'center', coluna: 'data_svct', mascara: '' }, { titulo: 'Valor', alinhamento: 'right', coluna: 'valor_svct', mascara: 'decimal' }, { titulo: '*', alinhamento: 'right', coluna: 'flagedicao', mascara: '' }]
 
  const RetornaServicos = () =>
  {
    let servcontret: {id: number; desc_serv: string; valor_svct: number, dia_svct: number, data_svct: string, flagedicao: string}[] = [];
    contdig.srvcont.forEach((srvc: Servicos_Contratos, index) => 
    {
      if (srvc.alterado!=='B')
      {
        servcontret.push({id: index+1, desc_serv: srvc.id_serv.toString()+'-'+achaDescricao(srvc.id_serv, servicos, 'id_serv', 'desc_serv'), dia_svct: srvc.dia_svct, data_svct: RetornaStringData(srvc.data_svct), valor_svct: srvc.valor_svct, flagedicao: !srvc.id_dtrc || srvc.id_dtrc===0 ? 'D' : srvc.recor_svct && srvc.recor_svct==='B' ? 'C' : 'B'})
      }
    })
    return servcontret;
  }

  const hdcontcli: Iheaders[] = [{titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, {titulo: 'Data', alinhamento:'center', coluna: 'data_ctcl', mascara: '' }, { titulo: 'Ocorrência', alinhamento: 'left', coluna: 'desc_occl', mascara: '' }, { titulo: 'Observações', alinhamento: 'left', coluna: 'obs_ctcl', mascara: '' }, { titulo: 'Usuário', alinhamento: 'left', coluna: 'nome_oper', mascara: '' }, {titulo: '=', alinhamento:'right', coluna: 'imgpdf', mascara: '' }, { titulo: '*', alinhamento: 'right', coluna: 'flagedicao', mascara: '' }]
 
  const RetornaContatos = () =>
  {
    let cttret: {id: number; data_ctcl: string; desc_occl: string; obs_ctcl: string, nome_oper: string, imgpdf:ObjPdfImg, flagedicao:string}[] = [];
    cliente.contatos.forEach((ctt: Contatos_Cliente, index) => 
    {
      if (ctt.alterado!=='B')
      {
        cttret.push({id: index+1, data_ctcl: RetornaStringData(ctt.data_ctcl, true), desc_occl: achaDescricao(ctt.id_occl, ocorrencias, 'id_occl', 'desc_occl'), obs_ctcl: ctt.obs_ctcl && ctt.obs_ctcl.length>0 ? ctt.obs_ctcl : '', nome_oper: ctt.nome_oper, imgpdf: {tipo: ctt.tipodoc_ctcl, conteudo: ctt.documento_ctcl}, flagedicao: achaDescricao(ctt.id_occl, ocorrencias, 'id_occl','tipo_occl')==='B' ? 'C' : 'D'});
      }
    })
    return cttret;
  }

  const hdsrvcli: Iheaders[] = [{titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' },  { titulo: 'Serviço', alinhamento: 'left', coluna: 'desc_serv', mascara: '' }, { titulo: 'Data', alinhamento: 'center', coluna: 'data_svcl', mascara: '' }, { titulo: 'Recebimento', alinhamento: 'center', coluna: 'id_dtrc', mascara: '' }, { titulo: 'Valor', alinhamento: 'right', coluna: 'valor_svcl', mascara: 'decimal' }, { titulo: 'Comissão', alinhamento: 'right', coluna: 'basecom_svcl', mascara: 'decimal' }, {titulo: '=', alinhamento:'right', coluna: 'imgpdf', mascara: '' }, { titulo: '*', alinhamento: 'right', coluna: 'flagedicao', mascara: '' }]

  const RetornaServicosClientes = () =>
  {
    let servcliret: {id: number; desc_serv: string; data_svcl: string, id_dtrc: number, valor_svcl: number, basecom_svcl: number, imgpdf:ObjPdfImg, flagedicao:string}[] = [];
    cliente.servicos.forEach((srvc: Servicos_Clientes, index) => 
    {
      if (srvc.alterado!=='B')
      {
        servcliret.push({id: index+1, desc_serv: achaDescricao(srvc.id_serv, servicos, 'id_serv', 'desc_serv'), data_svcl: RetornaStringData(srvc.data_svcl), id_dtrc: srvc.id_dtrc, valor_svcl: srvc.valor_svcl, basecom_svcl: srvc.basecom_svcl, imgpdf: {tipo: srvc.tipodoc_svcl, conteudo: srvc.documento_svcl}, flagedicao: !srvc.id_dtrc || srvc.id_dtrc<1 ? 'D' : 'C' });
      }
    })
    return servcliret;
  }

  const hdcontr: Iheaders[] = [{titulo: 'Nº', alinhamento:'right', coluna: 'id', mascara: '' }, {titulo: 'Assinatura', alinhamento:'center', coluna: 'dataass_cont', mascara: '' }, { titulo: 'Início', alinhamento: 'center', coluna: 'vigini_cont', mascara: '' }, { titulo: 'Fim', alinhamento: 'center', coluna: 'vigfin_cont', mascara: '' }, { titulo: 'Serviço', alinhamento: 'left', coluna: 'servico', mascara: '' }, { titulo: '*', alinhamento: 'right', coluna: 'flagedicao', mascara: '' }]

  const RetornaContratos = () =>
  {
    let contratos: {id: number; dataass_cont: string; vigini_cont: string; vigfin_cont: string; servico: string, flagedicao:string }[] = [];
    cliente.contratos.forEach((contrato: Contratos, index) => 
    {
      if (contrato.alterado!=='B')
      {
        let srvc=''
        if(contrato.srvcont.length>0)
        {
          srvc=achaDescricao(contrato.srvcont[0].id_serv, servicos, 'id_serv', 'desc_serv');
        }
        contratos.push({
            id: index+1, 
            dataass_cont: RetornaStringData(contrato.dataass_cont), 
            vigini_cont: RetornaStringData(contrato.vigini_cont), 
            vigfin_cont: RetornaStringData(contrato.vigfin_cont), 
            servico: srvc,
            flagedicao: (contrato.srvcont.some(svct => svct.id_dtrc && svct.id_dtrc!==0) ? 'B': 'D')
        })
      }
    })
    return contratos;
  }

  const hdtitabr: Iheaders[] = [{titulo: 'Id interno', alinhamento: 'center', coluna: 'idinterno', mascara: ''},{titulo: 'Vencimento', alinhamento: 'center', coluna: 'vencto', mascara: 'data'},{titulo: 'Valor', alinhamento: 'right', coluna: 'valor', mascara: 'decimal'},{titulo: 'Acréscimos', alinhamento: 'right', coluna: 'acrescimos', mascara: 'decimal'},{titulo: 'Desconto', alinhamento: 'right', coluna: 'desconto', mascara: 'decimal'},{titulo: 'Valor líquido', alinhamento: 'right', coluna: 'liquido', mascara: 'decimal'}]

  type retfin = {idinterno: string, vencto: Date, valor: number, acrescimos: number, desconto: number, datadesc: Date, liquido: number};

  const RetornaFinanceiro = (): retfin[] =>
  {
    let dias: number;
    let ddsc: number
    let hoje: Date=new Date();
    hoje = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(),0,0,0);
    let titabertos: retfin[] = []
    cliente.abertos.forEach(abr =>
    {
      dias=DifDias(abr.vencto_rcbt, hoje,true,true);
      ddsc=DifDias(hoje,abr.datadesc_rcbt,true,false);
      let novotit:retfin = 
      {
        idinterno: abr.idinterno_rcbt,
        vencto: abr.vencto_rcbt,
        valor: abr.valor_rcbt,
        acrescimos: (dias * abr.jurosdia_rcbt) + (dias>0 ? abr.multa_rcbt : 0),
        desconto: (ddsc>=0 ? abr.desconto_rcbt : 0),
        datadesc: abr.datadesc_rcbt,
        liquido: 0
      }
      novotit.liquido=(novotit.valor+novotit.acrescimos)-novotit.desconto;
      titabertos.push(novotit);
    });
    return titabertos;
  }

  const RetornaLinhaTotal = (): string[] =>
  {
    let dias: number;
    let ddsc: number
    let hoje: Date=new Date();
    hoje = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(),0,0,0);
    let linhatot: string[] = ['Total ==>>', ''];
    let totvlr: number = 0;
    let totacr: number = 0;
    let totdes: number = 0;
    cliente.abertos.forEach(abr =>
    {
      dias=DifDias(abr.vencto_rcbt, hoje,true,true);
      ddsc=DifDias(hoje,abr.datadesc_rcbt,true,false);
      totvlr = totvlr + abr.valor_rcbt;
      totacr = totacr + (dias * abr.jurosdia_rcbt) + (dias>0 ? abr.multa_rcbt : 0);
      totdes = totdes + (ddsc>=0 ? abr.desconto_rcbt : 0);
    });
    linhatot.push(formatarCampo(totvlr.toString(),'decimal',true));
    linhatot.push(formatarCampo(totacr.toString(),'decimal',true));
    linhatot.push(formatarCampo(totdes.toString(),'decimal',true));
    linhatot.push(formatarCampo(((totvlr+totacr)-totdes).toString(),'decimal',true));
    return linhatot;
  }

  const retornax = () =>
  {
      let retx: {name: string, color: string, data: {y: number, color: string, valorbr: string}[]}[]=
      [
          {name: 'Atraso', color: '#3CB371' ,data:[]},
      ];

      cliente.pontualidade.forEach(dd => 
      {
        let atr = DifDias(dd.vencto_rcbt, dd.pagto_rcbt, RetornaDataAAAAMMDD(dd.pagto_rcbt)>RetornaDataAAAAMMDD(dd.vencto_rcbt), false);
        retx[0].data.push({y: atr, color: (atr>0 ? 'red' : 'blue'), valorbr: 'Vencimento: '+ RetornaStringData(dd.vencto_rcbt,false)+' - Pagamento: '+RetornaStringData(dd.pagto_rcbt)});
      });

      return retx;

  }

  const retatrmed = () =>
  {
    let atrmed: number = 0;
    if (cliente.pontualidade.length>0)
    {
      cliente.pontualidade.forEach(dd => 
        {
          atrmed = atrmed+DifDias(dd.vencto_rcbt, dd.pagto_rcbt, true, false);
        });
      atrmed=atrmed/cliente.pontualidade.length
    }
    return atrmed;
  }

  const retornay = () =>
  {
      let rety: string[]=[]
      cliente.pontualidade.forEach(dd => rety.push(dd.idinterno_rcbt));
      return rety;
  }


  const listadeServicos = ((tpserv: string, apenascomp: boolean = false):{id_serv: number, desc_serv:string}[] =>
  {
    let idtpct: number = 0
    let idtpctsrv: number = 0;
    if (apenascomp)
    {
      contdig.srvcont.forEach(svctdg =>
        {
          if (idtpct===0 && svctdg.alterado!=='B')
          {
            idtpctsrv=achavalor(svctdg.id_serv,servicos,'id_serv','id_tpct',0) as number;
            if (idtpctsrv>0)
            {
              idtpct=idtpctsrv
            }
          }          
        })
    }
    let lstsrv: {id_serv: number, desc_serv:string}[] = [];
    servicos.forEach((srv: Servicos) => 
    { 
      if(tpserv.indexOf(srv.tipo_serv) !==-1) 
      {
        if (!srv.id_tpct || srv.id_tpct===idtpct || idtpct===0)
        {
          lstsrv.push({id_serv: srv.id_serv, desc_serv: srv.desc_serv})
        }
      }
    });
    return lstsrv;
  });

  const atualizaPdfContrato = (objrec: ObjPdfImg) =>
  {
    setcontDig({...contdig, gravadoc: true, documento_cont: objrec.conteudo } )
    sted.contratos=true;
  }
  const atualizaPdfImgContato = (objrec: ObjPdfImg) =>
  {
    setcontatosDig({...contatosdig, gravadoc: true, tipodoc_ctcl: objrec.tipo, documento_ctcl: objrec.conteudo } )
    sted.contatos=true;
  }
  const atualizaPdfImgServico = (objrec: ObjPdfImg) =>
  {
    setsrvclDig({...srvcldig, gravadoc: true, tipodoc_svcl: objrec.tipo, documento_svcl: objrec.conteudo});
    sted.servicos=true;
  }

  const Desiste = () =>
  {
    roteador(2,0);
  }

  const AposRender = () =>
  {
    if(botcli)
    {
      ClicaBotao(botcli);
      botcli = '';
    }
  }

  const GravaCliente = () =>
  {
    let errogrv: string = '';
    if (!VerificaCpfCnpj(cliente.pessoa.documentos[inddoc].valor_psdc))
    {
      errogrv = errogrv +'CPF/CNPJ incorreto \n' ;
    }
    if (cliente.pessoa.nome_pessoa.trim().length<1 || (cliente.id_rede===0 && cliente.loja_cli<1) || cliente.loja_cli<0 || cliente.id_reg<1 || cliente.inativo_cli.trim().length<0 || cliente.limcred_cli<0 || cliente.id_stcl<1 || cliente.tiponfe_cli.trim().length<1)
    {
      errogrv = errogrv + 'Verifique os dados cadastrais \n';
    }
    if (!cliente.pessoa.enderecos.some(endereco => (!endereco.alterado) || endereco.alterado==='' || endereco.alterado!=='B'))
    {
      errogrv = errogrv + 'Nenhum endereço foi informado \n';
    }
    if (!cliente.pessoa.emails.some(email => (!email.alterado) || email.alterado==='' || email.alterado!=='B'))
    {
      errogrv = errogrv + 'Nenhum e-mail foi informado \n';
    }
    if (!cliente.pessoa.telefones.some(telefone => (!telefone.alterado) || telefone.alterado==='' || telefone.alterado!=='B'))
    {
      errogrv = errogrv + 'Nenhum telefone foi informado \n';
    }
    if (documentos.some(doc => doc.id_doc>2 && doc.obrig_doc==='A' && (!doc.valor_psdc || doc.valor_psdc.trim().length<1)))
    {
      errogrv = errogrv + 'É necessário informar todos os documentos obrigatórios \n'
    }
    if (dadosadicionais.some(dado => !ValidaDadosAdicionais(dado)))
    {
      errogrv = errogrv + 'Verifique os dados adicionais \n'
    }
    if (errogrv.length>0)
    {
      mensagem(errogrv,'error',10000);
    }
    else
    {
      textodialogo=[]
      if (sted.enderecos)
      {
        textodialogo.push('Endereços');
      }
      if (sted.emails)
      {
        textodialogo.push('E-mails');
      }
      if (sted.telefones)
      {
        textodialogo.push('Telefones');
      }
      if (sted.contratos)
      {
        textodialogo.push('Contratos');
      }
      if (sted.servicos)
      {
        textodialogo.push('Lançamentos');
      }
      if (sted.contatos)
      {
        textodialogo.push('Contatos');
      }
      if (textodialogo.length>0)
      {
        botoesdialogo=[{descricao: 'Confirma', retorno: 'A'},{descricao: 'Cancela', retorno: 'B'}]
        titulodialogo='Há informações alteradas e não confirmadas nas seguintes áreas:'
        textodialogo.push('Confirma a operação?');
        setDialog(true)
      }
      else
      {
        salvaCliente();
      }
    }
  }

  const salvaCliente = () =>
  {
    let clgrv: Clientes = JSON.parse(JSON.stringify(cliente));
    if (cliente.pessoa.nascimento_pessoa)
    {
      clgrv.pessoa.nascimento_pessoa=DataSql(cliente.pessoa.nascimento_pessoa);
    }
    clgrv.pessoa.documentos.forEach((documento,index) => {if((documento.id_doc === 1 || documento.id_doc ===2) && index!==inddoc){documento.valor_psdc=''}})
    doctodig.forEach(docto => {
      if(docto.id_doc>2)
      {
        let inddc=clgrv.pessoa.documentos.findIndex(dct => dct.id_doc===docto.id_doc);
        if (inddc!==-1)
        {
          clgrv.pessoa.documentos[inddc].valor_psdc=docto.valor_psdc;
        }
        else
        {
          if(docto.valor_psdc && docto.valor_psdc.trim().length>0)
          {
            clgrv.pessoa.documentos.push({id_psdc:0, id_doc:docto.id_doc, id_pessoa:cliente.pessoa.id_pessoa, valor_psdc:docto.valor_psdc, id_uf:0});
          }
        }
      }
    })
    clgrv.pessoa.enderecos.forEach(endereco => {if(endereco.alterado==='B') {endereco.endereco.logr_end='';}});
    clgrv.pessoa.emails.forEach(email => {if(email.alterado==='B') {email.endereco_email='';}});
    clgrv.pessoa.telefones.forEach(telefone => {if(telefone.alterado==='B') {telefone.numero_fone='';}});
    grupodig.forEach((grp,index) => {
      if(index<clgrv.valores_grupos.length)
      {
        clgrv.valores_grupos[index].id_vlgr=grp.id_vlgr;
      }
      else
      {
        if (grp.id_vlgr>0)
        {
          clgrv.valores_grupos.push({id_clvg:0, id_cli: cliente.id_cli, id_vlgr:grp.id_vlgr, alterado:''});
        }
      }
    })
    clgrv.contratos=[];
    cliente.contratos.forEach(contr => 
    {
      if(contr.alterado && (contr.alterado==='B' || contr.alterado==='A'))
      {
        clgrv.contratos.push(contr);
        let idct = clgrv.contratos.length-1
        clgrv.contratos[idct].dataass_cont=DataSql(contr.dataass_cont);
        clgrv.contratos[idct].database_cont=DataSql(contr.dataass_cont);
        clgrv.contratos[idct].vigini_cont=DataSql(contr.vigini_cont);
        clgrv.contratos[idct].vigfin_cont=DataSql(contr.vigfin_cont);
        if (contr.alterado==='B')
        {
          clgrv.contratos[idct].situacao_cont='';
        }
        else
        {
          clgrv.contratos[idct].srvcont.forEach((srvct,index) =>
          {
            if (srvct.data_svct)
            {
              srvct.data_svct=DataSql(srvct.data_svct);
            }
            if(srvct.alterado==='B')
            {
              srvct.id_serv=0;
            }
          })
        }
      }
    })
    clgrv.servicos=[];
    cliente.servicos.forEach(srv =>
    {
      if (srv.alterado && (srv.alterado==='B' || srv.alterado==='A'))
      {
        clgrv.servicos.push(srv);
        clgrv.servicos[clgrv.servicos.length-1].data_svcl=DataSql(srv.data_svcl);
        if (srv.alterado==='B')
        {
          clgrv.servicos[clgrv.servicos.length-1].id_serv=0;
        }
      }
    });

    clgrv.contatos=[];
    cliente.contatos.forEach(ctt =>
    {
      if (ctt.alterado && (ctt.alterado==='B' || ctt.alterado==='A'))
      {
        clgrv.contatos.push(ctt);
        clgrv.contatos[clgrv.contatos.length-1].data_ctcl=DataSql(ctt.data_ctcl);
        if (ctt.alterado==='B')
        {
          clgrv.contatos[clgrv.contatos.length-1].id_occl=0;
        }
      }
    });

    ddadcdig.forEach(ddad => {
      let inddc=-1;
      if (ddad.comportamento_dadc==='B')
      {
        inddc=clgrv.pessoa.dados_adicionais.findIndex(dad => dad.id_dadc===ddad.id_dadc);
      }
      else
      {
        inddc=clgrv.dados_adicionais.findIndex(dad => dad.id_dadc===ddad.id_dadc);
      }
      if (inddc!==-1)
      {
        if (ddad.comportamento_dadc==='B')
        {
          clgrv.pessoa.dados_adicionais[inddc].id_vdad=ddad.id_vdad;
          clgrv.pessoa.dados_adicionais[inddc].numero_tbad=ddad.numero_tbad;
          clgrv.pessoa.dados_adicionais[inddc].texto_tbad=ddad.texto_tbad;
        }
        else
        {
          clgrv.dados_adicionais[inddc].id_vdad=ddad.id_vdad;
          clgrv.dados_adicionais[inddc].numero_tbad=ddad.numero_tbad;
          clgrv.dados_adicionais[inddc].texto_tbad=ddad.texto_tbad;
        }
      }
      else
      {
        if((ddad.id_vdad && ddad.id_vdad>0) || ddad.numero_tbad || (ddad.texto_tbad))
        {
          if (ddad.comportamento_dadc==='B')
          {
            clgrv.pessoa.dados_adicionais.push({id_tbad:0, id_dadc: ddad.id_dadc, id_pessoa: cliente.pessoa.id_pessoa, id_vdad: ddad.id_vdad, numero_tbad: ddad.numero_tbad, texto_tbad: ddad.texto_tbad, id_cli:0, id_forn:0, id_oper:0, id_vend:0, alterado:''});
          }
          else
          {
            clgrv.dados_adicionais.push({id_tbad:0, id_dadc: ddad.id_dadc, id_cli: cliente.id_cli, id_vdad: ddad.id_vdad, numero_tbad: ddad.numero_tbad, texto_tbad: ddad.texto_tbad, id_pessoa:0, id_forn:0, id_oper:0, id_vend:0, alterado:''});
          }
        }
      }
    });

    srv.Post('Clientes/','',JSON.stringify(clgrv),dadosobt.objac.token,false)
    .then((ddret: any) => {
      let dadosret: Resposta = TrataResposta(ddret);
      if (!dadosret.ok)
      {
        mensagem(dadosret.erro,'error',6000)
      }
      else
      {
        mensagem('Cliente: '+dadosret.retorno.id.toString()+' loja: '+dadosret.retorno.adicional+' salvo com sucesso','info',10000, true);
      }
    })
    .catch(Error => 
    {
      mensagem('Erro acessando a API (' + Error.name + ' - ' + Error.message + ')', 'error',6000);
    })
  }

  const retornaIcone = (idrd: number): string | undefined=>
  {
    let rturl: string | undefined = undefined;
    let indrd: number = achavalor(idrd, redes, 'id_rede', '*index*', 0) as number;
    if (indrd>=0)
    {
      rturl=RetornaUrlPdfImg(redes[indrd].icone_rede,'A');
    }
    return rturl
  }

  return (
      <div>
        <div className={classes.divgrid}>
            <AlertDialog aberta={dialogo} fecha={fechaDialogo} titulo={titulodialogo} texto={textodialogo} botoes={botoesdialogo}/>
            <Grid container spacing={1}>
                <Grid item className={classes.griddados}>
                    <Grid className={classes.gridcont} container spacing={1}>
                        <Grid item xs={1}>
                          <CampoTexto valor={cliente.id_cli>0 ? cliente.id_cli : 0}  id='id_cli' label='Código' placeholder='Código do cliente' classe={classes.textoform} tipo='number' required={false} habilitado={false} onchange={onChangeClienteInput}/>
                        </Grid>
                        <Grid item xs={3}>
                          <CampoTexto valor={cliente.pessoa.documentos[inddoc] ? cliente.pessoa.documentos[inddoc].valor_psdc : ''}  id='cnpj_pessoa' label='CPF/CNPJ' placeholder='CPF ou CNPJ do cliente' classe={classes.textoform} tipo='text' required={true} habilitado={acessos.altcad} onchange={onChangeCpfCnpj} onblur={onblurCpfCnpj} onfocus={onfocusCpfCnpj} erro={!cliente.pessoa.documentos[inddoc] || !VerificaCpfCnpj(cliente.pessoa.documentos[inddoc].valor_psdc)} tamanho={14}/>
                        </Grid>
                        <Grid item xs={6}>
                          <CampoTexto valor={cliente.pessoa.nome_pessoa}  id='nome_pessoa' label='Nome ou Razão Social' placeholder='Nome ou Razão Social do cliente' classe={classes.textoform} tipo='text' required={true} habilitado={acessos.altcad} onchange={onChangePessoaInput} erro={!cliente.pessoa.nome_pessoa || cliente.pessoa.nome_pessoa.trim().length<1} tamanho={60}/>
                        </Grid>
                        <Grid item xs={2}>
                          <CampoTexto valor={cliente.pessoa.fantasia_pessoa}  id='fantasia_pessoa' label='Fantasia' placeholder='Nome fantasia' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altcad} onchange={onChangePessoaInput} tamanho={50}/>
                        </Grid>
                    </Grid>
                    <Grid className={classes.gridcont} container spacing={1}>
                        <Grid item xs={2}>
                          <Selecao valor={cliente.id_rede}  nome='id_rede' label='Rede' required={false} habilitado={acessos.altcad} onchange={onChangeClienteSelect} conteudo={redes} nulo={[0,'Indefinida']} classe={classes.textoform}/>
                        </Grid>
                        <Grid item xs={2}>
                          <CampoTexto valor={cliente.loja_cli}  id='loja_cli' label='Loja' placeholder='Código da loja' classe={classes.textoform} tipo='number' required={false} habilitado={acessos.altcad} onchange={onChangeClienteInput} erro={(cliente.id_rede===0 && cliente.loja_cli===0) || cliente.loja_cli<0}/>
                        </Grid>
                        <Grid item xs={2}>
                          <Selecao valor={cliente.id_reg}  nome='id_reg' label='Região' required={true} habilitado={acessos.altcad} onchange={onChangeClienteSelect} conteudo={regioes} nulo={[0,'Indefinida']} classe={classes.textoform} erro={cliente.id_reg===0}/>
                        </Grid>
                        <Grid item xs={2}>
                          <Selecao valor={cliente.id_vend}  nome='id_vend' label='Vendedor' required={false} habilitado={acessos.altcad} onchange={onChangeClienteSelect} conteudo={vendedores} nulo={[0,'Indefinido']} classe={classes.textoform}/>
                        </Grid>
                        <Grid item xs={2}>
                          <Selecao valor={cliente.id_cnvd}  nome='id_cnvd' label='Canal' required={false} habilitado={acessos.altcad} onchange={onChangeClienteSelect} conteudo={canais} nulo={[0,'Indefinida']} classe={classes.textoform}/>
                        </Grid>
                        <Grid item xs={2}>
                          <Selecao valor={cliente.inativo_cli}  nome='inativo_cli' label='Ativo' required={true} habilitado={acessos.altcad} onchange={onChangeClienteSelect} conteudo={[{valor: 'A', descricao: 'Sim'},{valor: 'B', descricao: 'Não'}]} nulo={[' ','Indefinido']} classe={classes.textoform} erro={cliente.inativo_cli===' '}/>
                        </Grid>
                    </Grid>
                    <Grid className={classes.gridcont} container spacing={1}>
                        <Grid item xs={2}>
                          <CampoDecimal valor={cliente.limcred_cli}  id='limcred_cli' label='Limite de crédito' placeholder='Limite de crédito' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altcad} onchange={onChangeClienteDecimalInput} erro={cliente.limcred_cli<0}/>
                        </Grid>
                        <Grid item xs={2}>
                          <Selecao valor={cliente.id_stcl}  nome='id_stcl' label='Situação' required={true} habilitado={acessos.altcad} onchange={onChangeClienteSelect} conteudo={situacoes} nulo={[0,'Indefinida']} classe={classes.textoform} erro={cliente.id_stcl===0}/>
                        </Grid>
                        <Grid item xs={2}>
                          <Selecao valor={cliente.tiponfe_cli}  nome='tiponfe_cli' label='Tipo de emissão' required={true} habilitado={acessos.altcad} onchange={onChangeClienteSelect} conteudo={[{valor: '0', descricao: 'Normal'},{valor: '1', descricao: 'Consumidor final'}]} nulo={[' ','Indefinida']} classe={classes.textoform} erro={cliente.tiponfe_cli===' ' || cliente.tiponfe_cli===''} />
                        </Grid>
                        <Grid item xs={2}>
                          <CampoData valor={cliente.pessoa.nascimento_pessoa} id='nascimento_pessoa' label='Nascimento' placeholder='Nascimento' classe={classes.textoform} required={false} habilitado={acessos.altcad} onchange={onChangePessoaDataInput} limpadata={limpaData}/>
                        </Grid>
                        <Grid item xs={2}>
                          <CampoMascara valor={cliente.pessoa.aniversario_pessoa} id='aniversario_pessoa' label='Aniversario' placeholder='Aniversário' classe={classes.textoform} mascara='##/##' required={false} habilitado={!cliente.pessoa.nascimento_pessoa && acessos.altcad} onchange={onChangePessoaInput}/>
                        </Grid>
                        <Grid item xs={2}>
                          <CampoTexto valor={RetornaStringData((cliente.datacad_cli ? cliente.datacad_cli : new Date()),true)} id='datacad_cli' label='Cadastramento' placeholder='Cadastramento' classe={classes.textoform} tipo='text' required={false} habilitado={false} onchange={onChangeClienteInput}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.gridfoto}>
                    <input type='file' id='filefoto' accept='image/*' ref={inputFileFoto} className={classes.fotoinput} onChange={acessos.altcad ? event => {onChangeFoto(event)}: undefined}/> 
                    <img id='foto_pessoa' alt='fotocliente' src={cliente.pessoa.foto_pessoa && cliente.pessoa.foto_pessoa.length>0 ? 'data:image/jpeg;base64, ' + cliente.pessoa.foto_pessoa : 'Imagens/maquinafotografica.png'}  className={classes.fotocliente} onClick={acessos.altcad ? event => onButtonClickFoto(event) : undefined} />
                </Grid>
            </Grid>
        </div>
        <div className={classes.divtabs}>
          <StyledAppBar position="static">
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons="auto"
              aria-label="scrollable prevent tabs example"
            >
              <Tab label={'Endereços'} icon={<HomeIcon />} aria-label="enderecos" {...a11yProps(0)} />
              <Tab label={'Documentos'} icon={<AssignmentIndIcon/>} aria-label="documentos" {...a11yProps(1)} />
              <Tab label={'E-mails/Telefones'} icon={<EmailIcon />} aria-label="emails" {...a11yProps(2)} />
              <Tab label={'Grupos'} icon={<GroupWorkIcon />} aria-label="grupos" {...a11yProps(3)} />
              <Tab label={'Adicionais'} icon={<PostAddIcon />} aria-label="adicionais" {...a11yProps(4)} />
              <Tab label={'Contratos'} icon={<DescriptionIcon />} aria-label="contratos" {...a11yProps(5)} />
              <Tab label={'Lançamentos'} icon={<MonetizationOnIcon />} aria-label="lancamentos" {...a11yProps(6)} />
              <Tab label={'Contatos'} icon={<ContactPhoneIcon />} aria-label="contatos" {...a11yProps(7)} />
              <Tab label={'Observações'} icon={<SubjectIcon />} aria-label="observacoes" {...a11yProps(8)} />
              <Tab label={'Financeiro'} icon={<LocalAtmIcon />} aria-label="financeiro" {...a11yProps(9)} />
            </Tabs>
          </StyledAppBar>
          <TabPanel value={tabValue} index={0}>
            <EnderecosComp ender={enddigatu} indender={indendatu} enderecos={cliente.pessoa.enderecos} retindender={AtuIndEnder} retenderecos={AtuEnderecos} retender={AtuEnder} retmsg={AtuMensagem} acessos={acessos.altcad} ufs={ufs} tipos={tiposenderecos} nomepessoa={cliente.pessoa.fantasia_pessoa ? cliente.pessoa.fantasia_pessoa : ''} icone={retornaIcone(cliente.id_rede)}/>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={1}>
              <Grid item className={classes.griddados}>
                <Grid container spacing={1}>
                  {doctodig.map((doct: Documentos, index) =>
                    doct.id_doc>2
                    ?
                    <Grid item key={index+1} xs={3}>
                      <CampoTexto valor={doct.valor_psdc} id={index.toString()} label={doct.desc_doc} placeholder={doct.desc_doc} classe={classes.textoform} tipo='text' required={doct.obrig_doc==='A'} habilitado={acessos.altcad} onchange={onChangeDocumentosInput} erro={(!doct.valor_psdc || doct.valor_psdc.length<1) && doct.obrig_doc==='A'} tamanho={40}/>
                    </Grid>
                    :
                    null
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <EmailsTelefones indem={indemailatu} indfn={indfoneatu} email={emaildigatu} fone={fonedigatu} emails={cliente.pessoa.emails} fones={cliente.pessoa.telefones} retemail={AtuEmail} retemails={AtuEmails} retindemail={AtuIndEmail} retfone={AtuFone} retfones={AtuFones} retindfone={AtuIndFone} retmsg={AtuMensagem} acessos={acessos.altcad}/>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Grid container spacing={1}>
              <Grid item className={classes.gridtabend}>
                <Grid container spacing={1}>
                  {grupodig.map((grp: Tipos_Grupos, index) =>
                    <Grid item key={index+1} xs={3}>
                      <Selecao valor={!grp.id_vlgr ? 0 : grp.id_vlgr} nome={index.toString()} label={grp.desc_tpgr} required={false} habilitado={acessos.altcad} onchange={onChangeGrupoSelect} conteudo={RetornaValoresGrupos(grp.valores)} nulo={[0,'Indefinido']} classe={classes.textoform} />
                    </Grid>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <Grid container spacing={1}>
              <Grid item className={classes.gridtabend}>
                <Grid container spacing={1}>
                  {ddadcdig.map((ddadc: Dados_Adicionais, index) =>
                    <Grid item key={index+1} xs={3}>
                      {
                        ddadc.tipo_dadc === 'C'
                        ?
                          <Selecao valor={!ddadc.id_vdad ? 0 : ddadc.id_vdad} nome={index.toString()} label={ddadc.desc_dadc} required={ddadc.opcional_dadc !=='A'} habilitado={acessos.altcad} onchange={onChangeDadosAdicionaisSelect} conteudo={RetornaValoresDadosAdicionais(ddadc.valores)} nulo={[0,'Indefinido']} classe={classes.textoform} erro={!ValidaDadosAdicionais(ddadc)}/>
                        :
                        ddadc.tipo_dadc === 'B' 
                        ?
                          ddadc.mascara_dadc==='decimal'
                          ?
                          <CampoDecimal valor={ddadc.numero_tbad ? ddadc.numero_tbad : 0}  id={index.toString()} label={ddadc.desc_dadc} placeholder={ddadc.desc_dadc} classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altcad} onchange={onChangeDadosAdicionaisInput} erro={!ValidaDadosAdicionais(ddadc)}/>
                          :
                          <CampoMascara valor={ddadc.numero_tbad ? ddadc.numero_tbad : 0} id={index.toString()} label={ddadc.desc_dadc} placeholder={ddadc.desc_dadc} classe={classes.textoform} required={false} habilitado={acessos.altcad} mascara={ddadc.mascara_dadc} onchange={onChangeDadosAdicionaisInput} erro={!ValidaDadosAdicionais(ddadc)}/>
                        :
                        ddadc.tipo_dadc === 'A'
                        ?
                          <CampoTexto valor={ddadc.texto_tbad ? ddadc.texto_tbad : ''} id={index.toString()} label={ddadc.desc_dadc} placeholder={ddadc.desc_dadc} classe={classes.textoform} tipo='text' required={ddadc.opcional_dadc!=='A'} habilitado={acessos.altcad} onchange={onChangeDadosAdicionaisInput} erro={!ValidaDadosAdicionais(ddadc)} tamanho={ddadc.tamanho_dadc && ddadc.tamanho_dadc>0 ? ddadc.tamanho_dadc : 1000}/>
                        :
                        null
                      }
                    </Grid>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            {indcont<0 ?
              <Grid container spacing={1}>
                <Grid item className={classes.gridtabend}>
                  <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TabelaBase linhasoriginais={RetornaContratos()} cab={hdcontr} selecao={false} edicao={acessos.altcontr} adicao={acessos.inccontr} habfiltro={false} onClickEdicao={editaContrato} titulo={''} full={true} linhasiniciais={5} exclusao={acessos.exccontr} auxedit={1} auxexclui={2} ultpag={true} />
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
            :
              <Grid container spacing={1}>
                <Grid item className={classes.gridcontrato}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <CampoData valor={RetornaDataString(contdig.dataass_cont)} id='dataass_cont' label='Assinatura' placeholder='Assinatura' classe={classes.textoform} required={false} habilitado={true} onchange={onChangeContratoDataassInput}/>
                    </Grid>
                    <Grid item xs={3}>
                      <CampoData valor={RetornaDataString(contdig.vigini_cont)} id='vigini_cont' label='Início' placeholder='Início' classe={classes.textoform} required={false} habilitado={true} onchange={onChangeContratoViginiInput} erro={RetornaDataString(contdig.vigfin_cont)<RetornaDataString(contdig.vigini_cont)}/>
                    </Grid>
                    <Grid item xs={3}>
                      <CampoData valor={RetornaDataString(contdig.vigfin_cont)} id='vigfin_cont' label='Fim' placeholder='Fim' classe={classes.textoform} required={false} habilitado={true} onchange={onChangeContratoVigfinInput} erro={RetornaDataString(contdig.vigfin_cont)<RetornaDataString(contdig.vigini_cont)}/>
                    </Grid>
                    <Grid item xs={3}>
                      <CampoData valor={RetornaDataString(contdig.database_cont)} id='database_cont' label='Data base' placeholder='Data base' classe={classes.textoform} required={false} habilitado={true} onchange={onChangeContratoDatabaseInput}/>
                    </Grid>
                    <Grid item xs={3}>
                      <Selecao valor={contdig.situacao_cont}  nome='situacao_cont' label='Situação' required={true} habilitado={true} onchange={onChangeContratoSelect} conteudo={[{valor: 'B', descricao: 'Inativo'},{valor: 'C', descricao: 'Em implantação'},{valor: 'A', descricao: 'Ativo'}]} nulo={[' ','Indefinida']} classe={classes.textoform} erro={!contdig.freqreaj_cont || contdig.freqreaj_cont===' '}/>
                    </Grid>
                    <Grid item xs={3}>
                      <Selecao valor={contdig.freqreaj_cont}  nome='freqreaj_cont' label='Reajuste' required={true} habilitado={true} onchange={onChangeContratoSelect} conteudo={[{valor: 'A', descricao: 'Anual'},{valor: 'B', descricao: 'Mensal'},{valor: 'C', descricao: 'Sem reajuste'}]} nulo={[' ','Indefinido']} classe={classes.textoform} erro={!contdig.freqreaj_cont || contdig.freqreaj_cont===' '}/>
                    </Grid>
                    <Grid item xs={3}>
                      <Selecao valor={contdig.id_idrj} nome='id_idrj' label='Índice' required={false} habilitado={contdig.freqreaj_cont!=='C'} onchange={onChangeContratoSelect} conteudo={indices} nulo={[0,'Indefinido']} classe={classes.textoform} erro={!contdig.id_idrj && contdig.freqreaj_cont!=='C'}/>
                    </Grid>
                    <Grid item xs={3}>
                      <Selecao valor={contdig.id_vend} nome='cont_id_vend' label='Vendedor' required={false} habilitado={true} onchange={onChangeContratoSelect} conteudo={vendedores} nulo={[0,'Indefinido']} classe={classes.textoform} />
                    </Grid>
                    <Grid item xs={3}>
                      <Selecao valor={srvcont.id_serv}  nome='id_serv' label='Serviço' required={true} habilitado={true} onchange={onChangeServicosContratoSelect} conteudo={listadeServicos('AC',true)} nulo={[0,'Indefinido']} classe={classes.textoform} erro={!srvcont.id_serv}/>
                    </Grid>
                    <Grid item xs={3}>
                      <Selecao valor={srvcont.recor_svct}  nome='recor_svct' label='Recorrência' required={true} habilitado={true} onchange={onChangeServicosContratoSelect} conteudo={ParametrosDiversos.tiposservico} nulo={[' ','Indefinida']} classe={classes.textoform} erro={!srvcont.recor_svct || srvcont.recor_svct===' '}/>
                    </Grid>
                    <Grid item xs={3}>
                      <CampoTexto valor={srvcont.dia_svct ? srvcont.dia_svct : 0} id='dia_svct' label='Dia' placeholder='Dia' classe={classes.textoform} tipo='number' required={srvcont.recor_svct==='A'} habilitado={srvcont.recor_svct==='A'} onchange={onChangeServicosContratoInput} erro={srvcont.recor_svct==='A' && (!srvcont.dia_svct || srvcont.dia_svct<1 || srvcont.dia_svct>31)}/>
                    </Grid>
                    <Grid item xs={3}>
                      <CampoData valor={RetornaDataString(srvcont.data_svct)} id='data_svct' label='Data cobrança' placeholder='Data cobrança' classe={classes.textoform} required={false} habilitado={true} onchange={onChangeServicoContratoDataInput}/>
                    </Grid>
                    <Grid item xs={3}>
                      <CampoDecimal valor={srvcont.valor_svct}  id='valor_svct' label='Valor' placeholder='Valor' classe={classes.textoform} tipo='text' required={true} habilitado={true} onchange={onChangeServicosContratoDecimalInput} erro={srvcont.valor_svct<=0}/>
                    </Grid>
                    <Grid item xs={3}>
                      <CampoDecimal valor={srvcont.basecom_svct}  id='basecom_svct' label='Base comissão' placeholder='Base comissão' classe={classes.textoform} tipo='text' required={true} habilitado={contdig.id_vend>0} onchange={onChangeServicosContratoDecimalInput} erro={(srvcont.basecom_svct && srvcont.basecom_svct<0) || (srvcont.basecom_svct>srvcont.valor_svct)}/>
                    </Grid>
                    <Grid item xs={3}>
                      <CampoTexto valor={srvcont.obs_svct} id='obs_svct' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={true} onchange={onChangeServicosContratoInput}/>
                    </Grid>
                    <Grid item xs={3}>
                      <Button variant="contained" color="primary" className={classes.botao} onClick={adicionaServico} endIcon={<VerticalAlignBottomIcon/>}>Adiciona Serviço</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <TabelaBase linhasoriginais={RetornaServicos()} cab={hdsrvcontr} selecao={false} edicao={true} adicao={true} habfiltro={false} onClickEdicao={editaServico} titulo={''} full={true} linhasiniciais={5} exclusao={true} auxedit={1} auxexclui={2} ultpag={true} aposRender={AposRender}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.gridfoto}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <CampoTexto valor={contdig.obs_cont} id='obs_cont' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={true} onchange={onChangeContratoInput} multi={true} qtdlinhas={24} />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" className={classes.botao} onClick={geraContrato} endIcon={<PictureAsPdfIcon/>} disabled={contdig.documento_cont && contdig.documento_cont.length>0}>Gera Contrato</Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.gridfoto}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <ExibePdfImg id='documento_cont' exibicao={false} objeto={{tipo: 'B', conteudo: contdig.documento_cont}} tpaceito='B' retorno={atualizaPdfContrato} altera={true} />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" className={classes.botao} onClick={adicionaContrato} endIcon={<ReplayIcon/>}>Salva Contrato</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" className={classes.botao} onClick={DesisteContrato} endIcon={<CancelIcon/>}>Desiste</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <Grid container spacing={1}>
              <Grid item className={classes.griddados}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Selecao valor={srvcldig.id_serv}  nome='id_serv' label='Serviço' required={true} habilitado={(acessos.altlanc && indsrvcli>0) || (acessos.inclanc && indsrvcli===0)} onchange={onChangeServicosClientesSelect} conteudo={listadeServicos('BC')} nulo={[0,'Indefinido']} classe={classes.textoform} erro={!srvcldig.id_serv}/>
                  </Grid>
                  <Grid item xs={3}>
                    <Selecao valor={srvcldig.id_vend}  nome='id_vend' label='Vendedor' required={false} habilitado={(acessos.altlanc && indsrvcli>0) || (acessos.inclanc && indsrvcli===0)} onchange={onChangeServicosClientesSelect} conteudo={vendedores} nulo={[0,'Indefinido']} classe={classes.textoform} />
                  </Grid>
                  <Grid item xs={2}>
                    <CampoData valor={RetornaDataString(srvcldig.data_svcl)} id='data_svcl' label='Data' placeholder='Data' classe={classes.textoform} required={true} habilitado={(acessos.altlanc && indsrvcli>0) || (acessos.inclanc && indsrvcli===0)} onchange={onChangeServicosClientesDataInput}/>
                  </Grid>
                  <Grid item xs={2}>
                    <CampoDecimal valor={srvcldig.valor_svcl}  id='valor_svcl' label='Valor' placeholder='Valor' classe={classes.textoform} tipo='text' required={true} habilitado={(acessos.altlanc && indsrvcli>0) || (acessos.inclanc && indsrvcli===0)} onchange={onChangeServicosClientesDecimalInput} erro={srvcldig.valor_svcl<=0}/>
                  </Grid>
                  <Grid item xs={2}>
                    <CampoDecimal valor={srvcldig.basecom_svcl}  id='basecom_svcl' label='Comissão' placeholder='Comissão' classe={classes.textoform} tipo='text' required={true} habilitado={(acessos.altlanc && indsrvcli>0) || (acessos.inclanc && indsrvcli===0)} onchange={onChangeServicosClientesDecimalInput} erro={srvcldig.basecom_svcl>srvcldig.valor_svcl}/>
                  </Grid>
                  <Grid item xs={10}>
                    <CampoTexto valor={srvcldig.obs_svcl} id='obs_svcl' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={(acessos.altlanc && indsrvcli>0) || (acessos.inclanc && indsrvcli===0)} onchange={onChangeServicosClientesInput}/>
                  </Grid>
                  <Grid item xs={2}>
                    <Button variant="contained" color="primary" disabled={!((acessos.altlanc && indsrvcli>0) || (acessos.inclanc && indsrvcli===0))} className={classes.botao} onClick={adicionaServicoCliente} endIcon={<VerticalAlignBottomIcon/>}>Adiciona</Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TabelaBase linhasoriginais={RetornaServicosClientes()} cab={hdsrvcli} selecao={false} edicao={acessos.altlanc} adicao={acessos.inclanc} habfiltro={false} onClickEdicao={editaServicoCliente} titulo={''} full={true} linhasiniciais={5} exclusao={acessos.exclanc} auxedit={1} auxexclui={2} ultpag={true} aposRender={AposRender}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.gridfoto}>
                <ExibePdfImg id='documento_svcl' exibicao={false} objeto={{tipo: srvcldig.tipodoc_svcl, conteudo: srvcldig.documento_svcl}} tpaceito='C'  retorno={atualizaPdfImgServico} altera={(acessos.altlanc && indsrvcli>0) || (acessos.inclanc && indsrvcli===0)}/>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={7}>
            <Grid container spacing={1}>
              <Grid item className={classes.griddados}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <CampoData valor={RetornaDataString(contatosdig.data_ctcl)} id='data_ctcl' label='Data' placeholder='Data' classe={classes.textoform} required={true} habilitado={(acessos.altctt && indcontatos>0) || (acessos.incctt && indcontatos===0)} onchange={onChangeContatosDataInput} hora={true}/>
                  </Grid>
                  <Grid item xs={2}>
                    <Selecao valor={contatosdig.id_occl}  nome='id_occl' label='Ocorrência' required={true} habilitado={(acessos.altctt && indcontatos>0) || (acessos.incctt && indcontatos===0)} onchange={onChangeContatosSelect} conteudo={ocorrencias.filter((ocor: Ocorrencias_Clientes) => ocor.tipo_occl && ocor.tipo_occl!=='B') } nulo={[0,'Indefinida']} classe={classes.textoform} erro={!contatosdig.id_occl || contatosdig.id_occl<1}/>
                  </Grid>
                  <Grid item xs={6}>
                    <CampoTexto valor={contatosdig.obs_ctcl} id='obs_ctcl' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={(acessos.altctt && indcontatos>0) || (acessos.incctt && indcontatos===0)} onchange={onChangeContatosInput}/>
                  </Grid>
                  <Grid item xs={2}>
                    <Button variant="contained" color="primary" disabled={!((acessos.altctt && indcontatos>0) || (acessos.incctt && indcontatos===0))} className={classes.botao} onClick={adicionaContato} endIcon={<VerticalAlignBottomIcon/>}>Adiciona</Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TabelaBase linhasoriginais={RetornaContatos()} cab={hdcontcli} selecao={false} edicao={acessos.altctt} adicao={acessos.incctt} habfiltro={false} onClickEdicao={editaContatos} titulo={''} full={true} linhasiniciais={5} exclusao={acessos.excctt} auxedit={1} auxexclui={2} ultpag={true} aposRender={AposRender}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.gridfoto}>
                <ExibePdfImg id='documento_ctcl' exibicao={false} objeto={{tipo: contatosdig.tipodoc_ctcl, conteudo: contatosdig.documento_ctcl}} tpaceito='C'  retorno={atualizaPdfImgContato} altera={(acessos.altctt && indcontatos>0) || (acessos.incctt && indcontatos===0)} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={8}>
            <Grid container spacing={1}>
              <Grid item className={classes.gridtabend}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CampoTexto valor={cliente.pessoa.obs_pessoa} id='obs_pessoa' label='Observações' placeholder='Observações' classe={classes.textoform} tipo='text' required={false} habilitado={acessos.altcad} onchange={onChangePessoaInput} multi={true} qtdlinhas={20} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={9}>
            <Grid container spacing={1}>
              <Grid item className={classes.gridtabend}>
                <Grid container spacing={1}>
                  <Grid item xs={7}>
                    <TabelaBase linhasoriginais={RetornaFinanceiro()} cab={hdtitabr} selecao={false} edicao={false} adicao={false} habfiltro={false} titulo={'Títulos abertos'} full={true} linhasiniciais={5} exclusao={false} auxedit={1} auxexclui={2} ultpag={false} linhatot={RetornaLinhaTotal()} />
                  </Grid>
                  <Grid item xs={5}>
                    <Grafico estilo={{ backgroundColor: '#F0FFFF', width: '100%', height: '380 px', boxShadow: "3px 3px 1px lightblue" }} opcoes={{
                        chart: { type: 'spline', backgroundColor: '#F0FFFF', height: '380 px'},
                        colorAxis: {
                          minColor: -30,
                          maxColor: 40
                        },
                        credits: { enabled: false },
                        title: { text: 'Atraso médio ('+retatrmed().toFixed(2).toString().trim()+')', style: {"color": "blue", "fontFamily": "Arial" }},
                        xAxis: { categories: retornay()},
                        yAxis: { title: { text: 'Dias' } },
                        legend: { enabled: true, labelFormat: '{name}'},
                        tooltip: { pointFormat: '{point.valorbr}' },
                        plotOptions: {series: {dataLabels: {format: '{point.y}', enabled: true}}},
                        series: retornax()
                    }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <Grid container spacing={1}>
            <Grid item className={classes.gridtabend}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={GravaCliente} endIcon={<SaveIcon/>}>Confirma</Button>
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" color="primary" disabled={false} className={classes.botao} onClick={Desiste} endIcon={<CancelIcon/>}>Desiste</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
  )
}


type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  openToast: boolean,
  severidadeToast: string,
  mensagemToast: string,
  duracaoToast: number,
  acessosok: boolean,
}

class EdicaoClientes extends Component<PropsType, IState>
{

  private _dadosobt: RetornoAcesso;
  private _roteador: Roteador;
  private _id_cli: number;
  private _canalvendas: [];
  private _dadosadicionais: Dados_Adicionais[];
  private _documentos: Documentos[];
  private _indices: [];
  private _ocorrencias: [];
  private _redes: [];
  private _regioes: [];
  private _servicos: [];
  private _situacoes: [];
  private _tiposendereco: [];
  private _tiposgrupos: Tipos_Grupos[];
  private _tiposcontratos: Tipos_Contrato[];
  private _vendedores: [];
  private _ufs: [];
  private _cliente: Clientes;
  private _dadoobtido: boolean;
  private _acessos: number;
  private _retornamensagem: boolean;
  
  constructor(props: any)
  {
    super(props);

    this._dadosobt=new RetornoAcesso();
    this._id_cli=0;

    if (props.location.state)
    {
      if(props.location.state.dadosret)
      {
        this._dadosobt=props.location.state.dadosret;
      }
      if (props.location.state.id_edicao)
      {
        this._id_cli=props.location.state.id_edicao;
      }
    }

    if (!this._dadosobt.ok)
    {
      this.props.history.push({pathname: '/'})
    }

    this._roteador=new Roteador(this._dadosobt, '/Clientes');
    this._roteador.history=props.history;
    this._canalvendas=[];
    this._dadosadicionais=[];
    this._tiposgrupos=[];
    this._documentos=[];
    this._indices=[];
    this._ocorrencias=[];
    this._redes=[];
    this._regioes=[];
    this._servicos=[];
    this._situacoes=[];
    this._tiposendereco=[];
    this._tiposcontratos= [];
    this._vendedores=[];
    this._ufs=[];
    this._cliente = new Clientes();
    this._dadoobtido=false;
    this._acessos=0;
    this._retornamensagem=false;
  
    if (this._id_cli<0 && this._dadosobt.ok)
    {
      this._dadoobtido=true;
    }
    this.state =
    {
      dadoobtido: this._dadoobtido,
      openToast: false,
      severidadeToast: 'success',
      mensagemToast: '',
      duracaoToast: 6000,
      acessosok: false,
    }
    if (this._dadosobt.ok)
    {
      if (this._id_cli>0)
      {
        srv.Get('Clientes/'+this._id_cli+'/Financeiro', '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
          let dadosret: Resposta = TrataResposta(ddret);
          if (dadosret.ok)
          {
            this._cliente=dadosret.retorno; this._cliente.pontualidade.reverse(); this.aumentaAcessos();
          }
          else
          {
            this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo cliente (' + dadosret.erro + ')' });
          }
        })
        .catch(Error => this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo cliente (' + Error.name + ' - ' + Error.message + ')' }));
      }
      else
      {
        this.aumentaAcessos();
      }
      this.ObtemDados('Parametros', 'parâmetros', this.setaparametros.bind(this));
      this.ObtemDados('CanalVendas/', 'canais de vendas',this.setacanalvendas.bind(this));
      this.ObtemDados('DadosAdicionais/BD','dados adicionais',this.setadadosadicionais.bind(this));
      this.ObtemDados('Documentos/','documentos',this.setadocumentos.bind(this));
      this.ObtemDados('IndicesReajuste/','índices',this.setaindices.bind(this));
      this.ObtemDados('OcorrenciasCliente/','ocorrências de clientes',this.setaocorrencias.bind(this));
      this.ObtemDados('Redes/','redes',this.setaredes.bind(this));
      this.ObtemDados('Regioes/','regiões',this.setaregioes.bind(this));
      this.ObtemDados('Servicos/','serviços',this.setaservicos.bind(this));
      this.ObtemDados('SituacoesClientes/','situações de clientes',this.setasituacoes.bind(this));
      this.ObtemDados('TiposEndereco/','tipos de endereço',this.setatiposendereco.bind(this));
      this.ObtemDados('TiposGrupos/','tipos de grupos',this.setatiposgrupos.bind(this));
      this.ObtemDados('TiposContrato/completo','tipos de contratos',this.setatiposcontratos.bind(this));
      this.ObtemDados('Vendedores/','vendedores',this.setavendedores.bind(this));
      this.ObtemDados('Ufs/','UFs',this.setaufs.bind(this));
    }
  }
  
  setacanalvendas(registros: any) {this._canalvendas=registros; this.aumentaAcessos();};
  setadadosadicionais(registros: any) {this._dadosadicionais=registros; this.aumentaAcessos();};
  setadocumentos(registros: any) {this._documentos=registros; this.aumentaAcessos();};
  setaindices(registros: any) {this._indices=registros; this.aumentaAcessos();};
  setaocorrencias(registros: any) {this._ocorrencias=registros; this.aumentaAcessos();};
  setaredes(registros: any) {this._redes=registros; this.aumentaAcessos();};
  setaregioes(registros: any) {this._regioes=registros; this.aumentaAcessos();};
  setaservicos(registros: any) {this._servicos=registros; this.aumentaAcessos();};
  setasituacoes(registros: any) {this._situacoes=registros; this.aumentaAcessos();};
  setatiposendereco(registros: any) {this._tiposendereco=registros; this.aumentaAcessos();};
  setatiposgrupos(registros: any) { this._tiposgrupos=registros; this.aumentaAcessos();};
  setatiposcontratos(registros: any) { this._tiposcontratos=registros; this.aumentaAcessos();};
  setavendedores(registros: any) {this._vendedores=registros; this.aumentaAcessos();};
  setaufs(registros: any) {this._ufs=registros; this.aumentaAcessos();};
  setaparametros(parobt: Parametros[]) {AlteraTiposGeracao(parobt); AlteraPadroesContrato(parobt);};

  aumentaAcessos()
  {
    this._acessos = this._acessos+1
    if(this._acessos>14)
    {
      this._tiposgrupos = AtualizaGrupos(this._tiposgrupos, this._cliente.valores_grupos);
      this._documentos = AtualizaDocumentos(this._documentos, this._cliente.pessoa.documentos);
      this._dadosadicionais = AtualizaDadosAdicionais(this._dadosadicionais, this._cliente.dados_adicionais, 'D');
      this._dadosadicionais = AtualizaDadosAdicionais(this._dadosadicionais, this._cliente.pessoa.dados_adicionais, 'B');
      InicializaEdicao();
      this.setState({acessosok: true, dadoobtido: true });
    }
  }
    
  ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
  {
    srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
    .then((ddret: any) => 
    {
      let dadosret:Resposta = TrataResposta(ddret);
      if(dadosret.ok)
      {
        obret(dadosret.retorno)
      }
      else
      {
        this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
        this.aumentaAcessos();
      }
    })
    .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
  }

  ExibeMensagem(mensagem: string, severidade: string, duracao:number, retorna:boolean=false)
  {
    this.setState({openToast: true, severidadeToast: severidade, mensagemToast: mensagem, duracaoToast: duracao})
    this._retornamensagem=retorna;
  }

  FechaMensagem()
  {
    this.setState({openToast: false});
    if (this._retornamensagem)
    {
      this._roteador.rota(2,0);
    }
  }

  render()
  {
    if (!this._dadosobt.ok)
    {
      return null
    }
    else
    {
        return (
          <div className="Home">
            <header className="Home-header">
              <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador} recurso='Edição de Cliente'/>
            </header>
            <main>
              { this.state.dadoobtido ?
                <PrincipalEdicaoClientes dadoscli={this._cliente} dadosobt={this._dadosobt} redes={this._redes} regioes={this._regioes} servicos={this._servicos} indices={this._indices} dadosadicionais={this._dadosadicionais} documentos={this._documentos} ocorrencias={this._ocorrencias} tiposenderecos={this._tiposendereco} tpcont={this._tiposcontratos} grupos={this._tiposgrupos} vendedores={this._vendedores} situacoes={this._situacoes} canais={this._canalvendas} ufs={this._ufs} mensagem={this.ExibeMensagem.bind(this)} roteador={this._roteador.rota.bind(this._roteador)} />
                :
                <Progresso/>
              }
            </main>
            <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
          </div>
        )
    }
  }
}

export default withRouter(EdicaoClientes)
