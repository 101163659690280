import React,{ Component } from 'react';
import { RetornoAcesso, VerificaResetSenha } from '../Modelos/ObjetosDiversos';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps} from 'react-router';
import ApiService from '../Servicos/ApiService';
import { Roteador } from '../Servicos/Roteador';
import { TrataResposta, Resposta, Criptografia, nulltoNumber } from '../Servicos/Utilidades';
import BarraInicial from '../Componentes/Barrainicial';
import { Typography } from '@material-ui/core';
import Progresso from '../Componentes/Progresso';
import DadosUsuario from '../Componentes/DadosUsuario';

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
    dadosacesso: RetornoAcesso,
    verificacao: boolean,
    erro: string,
    idoper: number,
    operador: string,
}

const srv=new ApiService();

class Excecao extends Component<PropsType, IState>
{

    private _roteador: Roteador;
    private _cerro: string

    constructor(props: any)
    {
        super(props);
        this._cerro='Eita! Não encontrei esta página, não!';
        this.state=
        {
            dadosacesso: new RetornoAcesso(),
            verificacao: false,
            erro: 'Eita! Não encontrei esta página, não!',
            idoper: 0,
            operador: '',
        }

        if (props)
        {
            let crip=props.location.pathname.substr(1,props.location.pathname.length-1);
            let decrip=Criptografia.Decripta(crip);
            let dados: string[] = decrip.split('|');
            if (dados.length>2)
            {
                if(dados[0]==='ResetSenha')
                {
                    this._cerro='';
                    let versen: VerificaResetSenha = new VerificaResetSenha();
                    versen.datahora=dados[2];
                    versen.id_oper=nulltoNumber(parseInt(dados[1]));
                    srv.Post('Servidor','',JSON.stringify(versen),'', true)
                    .then((ddret: any) => {
                        let dadosret: Resposta = TrataResposta(ddret);
                        if (!dadosret.ok)
                        {
                            this.setState({verificacao: true, erro:dadosret.erro});
                        }
                        else
                        {
                            if (dadosret.retorno.diferenca>21600)
                            {
                                this.setState({verificacao: true, erro: 'Solicitação para reinicialização de senha expirada ('+dadosret.retorno.diferenca.toString().trim()+')'});
                            }
                            else
                            {
                                this.setState({verificacao: true, erro: '', operador: dadosret.retorno.nome, idoper: nulltoNumber(parseInt(dados[1])), dadosacesso: {...this.state.dadosacesso, 'nome': dadosret.retorno.nome }});
                            }
                        }
                    })
                    .catch(Error => 
                    {
                        this.setState({verificacao: true, erro: 'Erro acessando a api para verificação de segurança (' + Error.name + ' - ' + Error.message + ')'});
                    })
                }
            }
        }

        this._roteador=new Roteador(this.state.dadosacesso, '/Excecao');
        this._roteador.history=props.history;
    }

    fecha(botao: string, foto?: Uint32Array)
    {
        this._roteador.rota(999);
    }

    render()
    {
        return (
            <div className="Home">
                <header className="Home-header">
                    <BarraInicial />
                </header>
                <main>
                    {this.state.verificacao || this._cerro.length>0 ?
                        this.state.erro.length>0 ?
                            <Typography style={{margin: '20% 0 0 0', textAlign: 'center'} } variant='h3' color='secondary'>
                                {this._cerro.length>0 ?  this._cerro : this.state.erro}
                            </Typography>
                            :
                            <DadosUsuario aberta={true} fecha={this.fecha.bind(this)} dadosacesso={this.state.dadosacesso} idoper={this.state.idoper} ></DadosUsuario>
                        :
                        <Progresso/>
                    }
                </main>
            </div>
        );
    }
}

export default withRouter(Excecao)
