export class Detalhes_Pagamento
{
    id_dtpg: number=0;
    id_pgto: number=0;
    valor_dtpg: number=0;
    id_dsps: number=0;
    id_rcta: number=0;
    id_oper: number=0
    id_rede: number=0
    id_cli: number=0
    nome_oper: string='';
    alterado: string='';
}